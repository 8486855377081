/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import Moment from 'moment' // Npm: Moment library.
import PropTypes from 'prop-types' // Npm: react.js library.
import Debounce from 'lodash/debounce' // Npm: lodash library.
import _ from 'underscore' // Npm: underscore library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useQuery } from '@apollo/client' // Npm: Apollo client.
import { IoMdAdd } from 'react-icons/io' // Npm: React icons.
import {
  Button,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tooltip,
  Tr,
  useBreakpointValue,
  useDisclosure
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import DownloadToExcel from 'components/DownloadToExcel'
import PaginationWithPageInformation from 'components/PaginationWithPageInformation'
import Modal from 'components/Modal'
import TableSpinner from 'components/TableSpinner'
import VendorAccounttDropDownOptions from 'components/VendorAccounttDropDownOptions'
import VendorAccounttUpsert from 'components/VendorAccounttUpsert'
import VendorAccounttDelete from 'components/VendorAccounttDelete'
import { MemoizedInput } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import VendorAccounttReadQuery from './__query__/index.vendorAccountt.read.query'


/*
 * STYLES
 */
import {
  buttonStyle,
  cellStyle,
  headerStyle,
  rowStyle
} from './index.style'


/*
 * OBJECTS
 */
const Index = ({ passOn }) => {
  // Const assignment.
  const _skipDifference = 8
  const _successFlags = Object.React.App.enums.GRAPHQL_SUCCESSFUL_QUERY_FLAGS.enums.map(i => i.key)

  // Hook assignment.
  const [skipPage, setSkipPage] = React.useState(0)
  const [skipDifference, setSkipDifference] = React.useState(_skipDifference)
  const [vendorAccounttToSearch, setVendorAccounttToSearch] = React.useState('')
  const { 'isOpen': isVendorAccounttCreateOpen, 'onOpen': onVendorAccounttCreateOpen, 'onClose': onVendorAccounttCreateClose } = useDisclosure()
  const { 'isOpen': isVendorAccounttUpdateOpen, 'onOpen': onVendorAccounttUpdateOpen, 'onClose': onVendorAccounttUpdateClose } = useDisclosure()
  const { 'isOpen': isVendorAccounttDeleteOpen, 'onOpen': onVendorAccounttDeleteOpen, 'onClose': onVendorAccounttDeleteClose } = useDisclosure()
  const _QueryVendorAccounttRead = useQuery(VendorAccounttReadQuery, { 'variables': { 'vendorAccounttDirectoryId': passOn?.vendorAccounttDirectoryId, vendorAccounttToSearch, 'skip': skipPage * skipDifference, 'take': skipDifference }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })
  const _isFirstLoadCompleted = React.useRef(false)
  const _onVendorAccounttToSearchInputChange = React.useCallback(Debounce(e => setVendorAccounttToSearch(e.target.value), 800), [])
  const _tableHeaderHeightRef = React.useRef(0)
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })

  // Data Assignment.
  if (!_QueryVendorAccounttRead.loading && 0 < _QueryVendorAccounttRead.data?.VendorAccounttRead?.length && !_isFirstLoadCompleted.current) _isFirstLoadCompleted.current = true
  if (!_.every(_.pluck(_QueryVendorAccounttRead.data?.VendorAccounttRead, 'status'), i => _successFlags.includes(i))) _isFirstLoadCompleted.current = false

  // Return component.
  return (
    <>
      <Flex className='vendorAccountt base'>
        <Flex
          display='flex'
          flex={1}
          flexDirection='column'
          gap={_isCurrentViewMobile ? '12px' : '22px'}
          bg='white'
          height='100%'
          borderRadius='20px'
          p={_isCurrentViewMobile ? '12px' : '22px'}>
          <Flex pb='0px' justify='space-between' gap='12px' flexWrap={{ 'base': 'wrap', 'md': 'nowrap' }}>
            <Tooltip label='Add Vendor Account' fontSize='sm'>
              <Button
                leftIcon={<IoMdAdd />}
                onClick={onVendorAccounttCreateOpen}
                style={buttonStyle}>
                Add Vendor A/C
              </Button>
            </Tooltip>
            <DownloadToExcel
              cellsData={0 === _QueryVendorAccounttRead?.data?.VendorAccounttRead?.length || !_.every(_.pluck(_QueryVendorAccounttRead?.data?.VendorAccounttRead, 'status'), i => _successFlags.includes(i)) ? [] : _QueryVendorAccounttRead?.data?.VendorAccounttRead.map((item, __index) => ({
                'S.No.': `${(__index + (skipDifference * (skipPage))) + 1}.`,
                'CreatedAt': Moment(item?.createdAt).format('YYYY-MM-DD'),
                'UpdatedAt': Moment(item?.updatedAt).format('YYYY-MM-DD'),
                'displayName': item?.displayName ?? '-',
                'VendorAccount': item.VendorAccount?.length ?? '-'
              }))}
              headersData={['S.No.', 'CreatedAt', 'UpdatedAt', 'VendorAccount'].map(i => ({ 'key': i, 'label': i }))} />
          </Flex>
          <TableContainer
            flex={1}
            display='flex'
            borderRadius='15px'
            outline='1px solid #C5CFE8'>
            <Table variant='simple' size='sm'>
              <Thead ref={_tableHeaderHeightRef}>
                <Tr style={{ ...headerStyle, 'position': 'sticky', 'top': 0, 'zIndex': 1 }}>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>S.No</Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>DisplayName</Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>VendorAccount</Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>Created At</Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>Updated At</Td>
                  <Td
                    style={rowStyle}>Actions</Td>
                </Tr>
                <Tr key={String.random(5)} >
                  <Td borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>
                    <MemoizedInput
                      placeholder='Search'
                      className='filter searchInput'
                      defaultValue={vendorAccounttToSearch}
                      onChange={_onVendorAccounttToSearchInputChange}
                    />
                  </Td>
                  <Td borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td></Td>
                </Tr>
              </Thead>
              {_QueryVendorAccounttRead.loading && !_isFirstLoadCompleted.current ? (
                <TableSpinner isLoading={true} chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight} />
              ) : !_isFirstLoadCompleted.current && (0 === _QueryVendorAccounttRead.data?.VendorAccounttRead?.length || !_.every(_.pluck(_QueryVendorAccounttRead.data?.VendorAccounttRead, 'status'), i => _successFlags.includes(i))) ? (
                <TableSpinner isLoading={false} isEmpty={true} chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight} />
              ) : (
                <Tbody style={cellStyle}>
                  {_QueryVendorAccounttRead.data?.VendorAccounttRead?.map(({ id, createdAt, updatedAt, displayName, VendorAccount }, __index) => (
                    <Tr key={String.random(5)}>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {`${(__index + (skipDifference * (skipPage))) + 1}.`}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {displayName ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {VendorAccount?.length ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {Moment(createdAt).format('YYYY-MM-DD')}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {Moment(updatedAt).format('YYYY-MM-DD')}
                      </Td>
                      <Td
                        style={rowStyle}>
                        {' '}
                        {
                          <VendorAccounttDropDownOptions
                            vendorAccounttId={id}
                            onVendorAccounttUpdate={onVendorAccounttUpdateOpen}
                            onVendorAccounttDelete={onVendorAccounttDeleteOpen}
                          />
                        }
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              )
              }
            </Table>
          </TableContainer>
        </Flex>
        <Modal
          size='md'
          title='Add Vendor Account'
          isOpen={isVendorAccounttCreateOpen}
          onClose={onVendorAccounttCreateClose}>
          <VendorAccounttUpsert isCreateOnly={true} />
        </Modal>
        <Modal
          size='md'
          title='Add Vendor Account'
          isOpen={isVendorAccounttUpdateOpen}
          onClose={onVendorAccounttUpdateClose}>
          <VendorAccounttUpsert isCreateOnly={false} />
        </Modal>
        <Modal
          size='md'
          title='Remove Vendor Account'
          isOpen={isVendorAccounttDeleteOpen}
          onClose={onVendorAccounttDeleteClose}>
          <VendorAccounttDelete />
        </Modal>
      </Flex>
      <PaginationWithPageInformation
        skipPage={skipPage}
        skipDifference={_skipDifference}
        setSkipDifference={setSkipDifference}
        itemsPerPage={_skipDifference}
        totalCount={_.pluck(_QueryVendorAccounttRead.data?.VendorAccounttRead, '_totalCount')?.[0]}
        onPageChange={setSkipPage}
      />
    </>
  )
}



/*
 * PROPTYPES
 */
Index.propTypes = {
  'passOn': PropTypes.object
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)

