/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client.


/*
 * EXPORTS
 */
export default gql`
mutation SmppActiveInActiveMutation($smppId: ID!, $isActive: Boolean!) {
    SmppActiveInActive(smppId: $smppId, isActive: $isActive) {
        isActive,
        message
        status
    }
} 
`

