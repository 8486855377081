/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { useMutation } from '@apollo/client' // Npm: Apollo client.
import { connect } from 'react-redux' // Npm: React Redux for state management.


/*
 * SIBLINGS
 */
import DeleteCard from 'components/DeleteCard'


/*
 * GRAPHS
 */
import IpDirectoryDeleteMutation from './__mutation__/index.ipDirectory.delete.mutation'


/*
 * OBJECTS
 */
const Index = ({ passOn, onClose }) => {
  // Hook assignment.
  const [MutationIpDirectoryDelete, MutationIpDirectoryDeleteResponse] = useMutation(IpDirectoryDeleteMutation)

  // Return Component.
  return (
    <DeleteCard onClose={onClose} disabled={MutationIpDirectoryDeleteResponse.loading} onConfirm={() =>
      MutationIpDirectoryDelete({
        'variables': {
          'ipDirectoryId': passOn?.ipDirectoryId
        }
      }).then(i => {
        // Style Guide.
        toast(i?.data?.IpDirectoryDelete?.message)

        // Report success.
        return onClose?.()
      }).catch(() =>
        // Style Guide.
        toast('Something went wrong please try after some time.'))} isLoading={MutationIpDirectoryDeleteResponse.loading} />
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'passOn': PropTypes.object,
  'onClose': PropTypes.func
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
