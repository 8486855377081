/*
 * IMPORTS
 */
import {
  addDays,
  addMonths,
  differenceInCalendarDays,
  endOfDay,
  endOfMonth,
  endOfWeek,
  isSameDay,
  startOfDay,
  startOfMonth,
  startOfWeek,
  sub,
  subDays
} from 'date-fns' // Npm: date-fns library for handling time.


/*
 * GLOBALS
 */
const definitions = {
  'startOfWeek': startOfWeek(new Date()),
  'endOfWeek': endOfWeek(new Date()),
  'startOfLastWeek': startOfWeek(addDays(new Date(), -7)),
  'endOfLastWeek': endOfWeek(addDays(new Date(), -7)),
  'startOfToday': startOfDay(new Date()),
  'endOfToday': endOfDay(new Date()),
  'startOfYesterday': startOfDay(addDays(new Date(), -1)),
  'endOfYesterday': endOfDay(addDays(new Date(), -1)),
  'startOfMonth': startOfMonth(new Date()),
  'endOfMonth': endOfMonth(new Date()),
  'last30Days': sub(new Date(), { 'days': 30 }),
  'last7Days': sub(new Date(), { 'days': 7 }),
  'last90Days': sub(new Date(), { 'days': 90 }),
  'last60Days': sub(new Date(), { 'days': 60 }),
  'currentDate': startOfDay(new Date()),
  'lastDay': subDays(new Date(), 1),
  'startOfLastMonth': startOfMonth(addMonths(new Date(), -1)),
  'endOfLastMonth': endOfMonth(addMonths(new Date(), -1))
}
const staticRangeHandler = {
  'range': {},
  isSelected(range) {
    // Const assignment.
    const definedRange = this.range()

    // Return value.
    return (
      isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate)
    )
  }
}


/*
 * EXPORTS
 */
export function createStaticRanges(ranges) {
  // Return value.
  return ranges.map(range => ({ ...staticRangeHandler, ...range }))
}
export const defaultStaticRanges = createStaticRanges([
  {
    'label': 'Today',
    'range': () => ({
      'startDate': definitions.startOfToday,
      'endDate': definitions.endOfToday
    })
  },
  {
    'label': 'Yesterday',
    'range': () => ({
      'startDate': definitions.startOfYesterday,
      'endDate': definitions.endOfYesterday
    })
  },

  {
    'label': 'This Week',
    'range': () => ({
      'startDate': definitions.startOfWeek,
      'endDate': definitions.currentDate
    })
  },
  {
    'label': 'Last Week',
    'range': () => ({
      'startDate': definitions.startOfLastWeek,
      'endDate': definitions.endOfLastWeek
    })
  },
  {
    'label': 'Last 7 days',
    'range': () => ({
      'startDate': definitions.last7Days,
      'endDate': definitions.lastDay
    })
  },
  {
    'label': 'This Month',
    'range': () => ({
      'startDate': definitions.startOfMonth,
      'endDate': definitions.lastDay
    })
  },
  {
    'label': 'Last Month',
    'range': () => ({
      'startDate': definitions.startOfLastMonth,
      'endDate': definitions.endOfLastMonth
    })
  },
  {
    'label': 'Last 30 days',
    'range': () => ({
      'startDate': definitions.last30Days,
      'endDate': definitions.lastDay
    })
  },
  {
    'label': 'Last 60 days',
    'range': () => ({
      'startDate': definitions.last60Days,
      'endDate': definitions.lastDay
    })
  },
  {
    'label': 'Last 90 days',
    'range': () => ({
      'startDate': definitions.last90Days,
      'endDate': definitions.lastDay
    })
  }
])
export const defaultInputRanges = [
  {
    'label': 'days up to today',
    range(value) {
      // Return value.
      return {
        'startDate': addDays(
          definitions.startOfToday,
          (Math.max(Number(value), 1) - 1) * -1
        ),
        'endDate': definitions.endOfToday
      }
    },
    getCurrentValue(range) {
      // If statement for checking if start date.
      if (!isSameDay(range.endDate, definitions.endOfToday)) return '-'
      if (!range.startDate) return '∞'

      // Return value.
      return differenceInCalendarDays(definitions.endOfToday, range.startDate) + 1
    }
  },
  {
    'label': 'days starting today',
    range(value) {
      // Return value.
      const today = new Date()

      // Return value.
      return {
        'startDate': today,
        'endDate': addDays(today, Math.max(Number(value), 1) - 1)
      }
    },
    getCurrentValue(range) {
      // If statement for checking if start date.
      if (!isSameDay(range.startDate, definitions.startOfToday)) return '-'
      if (!range.endDate) return '∞'

      // Return value.
      return differenceInCalendarDays(range.endDate, definitions.startOfToday) + 1
    }
  }
]
