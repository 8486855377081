/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import Debounce from 'lodash/debounce' // Npm: Lodash library.
import Moment from 'moment' // Npm: Moment.js library.
import _ from 'underscore' // Npm: Underscore.js library.
import { IoMdAdd } from 'react-icons/io' // Npm: React icons.
import { useQuery } from '@apollo/client' // Npm: Apollo client.
import {
  Button,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tooltip,
  Tr,
  useBreakpointValue,
  useDisclosure
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import TableSpinner from 'components/TableSpinner'
import DownloadToExcel from 'components/DownloadToExcel'
import PaginationWithPageInformation from 'components/PaginationWithPageInformation'
import Modal from 'components/Modal'
import EntityUpsert from 'components/EntityUpsert'
import EntityDelete from 'components/EntityDelete'
import EntityDropDownOptions from 'components/EntityDropDownOptions'
import { MemoizedInput } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import EntityReadQuery from './__query__/index.entity.read.query'


/*
 * STYLES
 */
import { buttonStyle, cellStyle, headerStyle, rowStyle } from './index.style'


/*
 * OBJECTS
 */
const Index = () => {
  // Const assignment.
  const _skipDifference = 8
  const _successFlags = Object.React.App.enums.GRAPHQL_SUCCESSFUL_QUERY_FLAGS.enums.map(i => i.key)

  // Hook assignment.
  const [skipPage, setSkipPage] = React.useState(0)
  const [searchQuery, setSearchQuery] = React.useState('')
  const [skipDifference, setSkipDifference] = React.useState(_skipDifference)
  const { 'onOpen': onEntityUpdateOpen, 'isOpen': isEntityUpdateOpen, 'onClose': onEntityUpdateClose } = useDisclosure()
  const { 'onOpen': onEntityDeleteOpen, 'isOpen': isEntityDeleteOpen, 'onClose': onEntityDeleteClose } = useDisclosure()
  const { 'onOpen': onEntityCreateOpen, 'isOpen': isEntityCreateOpen, 'onClose': onEntityCreateClose } = useDisclosure()
  const _QueryEntityRead = useQuery(EntityReadQuery, {
    'variables': {
      'skip': skipPage * skipDifference,
      'take': skipDifference,
      'search': searchQuery
    },
    'fetchPolicy': Object.React.App.fetchPolicy,
    'pollInterval': Object.React.App.pollInterval
  })
  const _isFirstLoadCompleted = React.useRef(false)
  const _onSearchInputChange = React.useCallback(Debounce(e => setSearchQuery(e.target.value), 800), [])
  const _tableHeaderHeightRef = React.useRef(0)
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })

  // Data assignment.
  if (!_QueryEntityRead.loading && 0 < _QueryEntityRead?.data?.EntityRead?.length) _isFirstLoadCompleted.current = true
  if (0 === _QueryEntityRead?.data?.EntityRead?.length || !_.every(_.pluck(_QueryEntityRead?.data?.EntityRead, 'status'), j => _successFlags.includes(j))) _isFirstLoadCompleted.current = false

  // Return Component.
  return (
    <>
      <Flex className='entity base'>
        <Flex
          display='flex'
          flex={1}
          flexDirection='column'
          gap={_isCurrentViewMobile ? '12px' : '22px'}
          bg='white'
          height='100%'
          borderRadius='20px'
          p={_isCurrentViewMobile ? '12px' : '22px'}>
          <Flex pb='0px' justify='space-between'>
            <Tooltip label='Create new entity.' fontSize='sm'>
              <Button
                w='170px'
                onClick={onEntityCreateOpen}
                leftIcon={<IoMdAdd />}
                style={buttonStyle}>
                New Entity
              </Button>
            </Tooltip>
            <DownloadToExcel
              cellsData={_QueryEntityRead?.data?.EntityRead?.map(
                (item, __index) =>
                  _successFlags.includes(item.status) ? {
                    'S.No.': `${__index + (skipDifference * skipPage) + 1}.`,
                    'Entity Name': item.displayName ?? '-',
                    'Email': item.email ?? '-',
                    'Entity Status': item.entityType,
                    'CreatedAt': Moment(item?.createdAt).format('YYYY-MM-DD'),
                    'UpdatedAt': Moment(item?.updatedAt).format('YYYY-MM-DD'),
                    'Ban': item.isBlocked ?? '-'
                  } : {}
              )}
              headersData={[
                'S.No.',
                'Entity Name',
                'Email',
                'Entity Status',
                'Active As',
                'CreatedAt',
                'UpdatedAt',
                'Ban'
              ].map(k => ({ 'key': k, 'label': k }))}
            />
          </Flex>
          <TableContainer
            flex={1}
            display='flex'
            borderRadius='15px'
            outline='1px solid #C5CFE8'
          >
            <Table variant='simple' size='sm'>
              <Thead
                ref={_tableHeaderHeightRef}
                color='rgba(141, 151, 182, 1)'
                fontStyle='DM Sans'
                fontWeight='500'
                fontSize='18px'
                lineHeight='24px'
              >
                <Tr
                  style={{
                    ...headerStyle,
                    'position': 'sticky',
                    'top': '0',
                    'zIndex': '1'
                  }}
                >
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'
                  >
                    S No.
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'
                  >
                    Name
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'
                  >
                    Address
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'
                  >
                    Created At
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'
                  >
                    Updated At
                  </Td>
                  <Td
                    style={rowStyle}
                  >
                    Actions
                  </Td>
                </Tr>
                <Tr key={String.random(8)}>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'
                  ></Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'
                  ></Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'
                  >
                    <MemoizedInput
                      placeholder='Search'
                      className='filter searchInput'
                      defaultValue={searchQuery}
                      onChange={_onSearchInputChange}
                      autoFocus
                    />
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'
                  ></Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'
                  ></Td>
                  <Td
                    style={rowStyle}
                  ></Td>
                </Tr>
              </Thead>
              {_QueryEntityRead.loading && !_isFirstLoadCompleted.current ? (
                <TableSpinner
                  isLoading={true}
                  chopHeightFromHundredPercentage={
                    _tableHeaderHeightRef?.current?.clientHeight
                  }
                />
              ) : !_isFirstLoadCompleted.current && (0 === _QueryEntityRead?.data?.EntityRead?.length || (0 < _QueryEntityRead?.data?.EntityRead?.length && !_.every(
                _.pluck(_QueryEntityRead?.data?.EntityRead, 'status'),
                j => _successFlags.includes(j)
              ))) ? (
                <TableSpinner
                  isLoading={false}
                  isEmpty={true}
                  chopHeightFromHundredPercentage={
                    _tableHeaderHeightRef?.current?.clientHeight
                  }
                />
              ) : (
                <Tbody style={cellStyle}>
                  {_QueryEntityRead?.data?.EntityRead.map(
                    (
                      { id, displayName, address, updatedAt, createdAt },
                      __index
                    ) => (
                      <Tr key={id}>
                        <Td
                          style={rowStyle}
                          py='0'
                          borderRight='1px solid rgba(216, 227, 252, 1)'
                        >
                          {`${__index + (skipDifference * skipPage) + 1}.`}
                        </Td>
                        <Td
                          style={rowStyle}
                          py='0'
                          borderRight='1px solid rgba(216, 227, 252, 1)'
                        >
                          {displayName}
                        </Td>
                        <Td
                          style={rowStyle}
                          py='0'
                          borderRight='1px solid rgba(216, 227, 252, 1)'
                        >
                          {address}
                        </Td>
                        <Td
                          style={rowStyle}
                          py='0'
                          borderRight='1px solid rgba(216, 227, 252, 1)'
                        >
                          {Moment(createdAt).format('YYYY-MM-DD')}
                        </Td>
                        <Td
                          style={rowStyle}
                          py='0'
                          borderRight='1px solid rgba(216, 227, 252, 1)'
                        >
                          {Moment(updatedAt).format('YYYY-MM-DD')}
                        </Td>
                        <Td style={rowStyle}>
                          {' '}
                          {
                            <EntityDropDownOptions
                              entityId={id}
                              onEntityUpdate={onEntityUpdateOpen}
                              onEntityDelete={onEntityDeleteOpen}
                            />
                          }
                        </Td>
                      </Tr>
                    )
                  )}
                </Tbody>
              )}
            </Table>
          </TableContainer>
        </Flex>
        <Modal
          size='lg'
          title='Entity Create'
          isOpen={isEntityCreateOpen}
          onClose={onEntityCreateClose}
          isCentered={false}
        >
          <EntityUpsert isCreateOnly={true} />
        </Modal>
        <Modal
          size='lg'
          title='Entity Update'
          isOpen={isEntityUpdateOpen}
          onClose={onEntityUpdateClose}
          isCentered={false}
        >
          <EntityUpsert />
        </Modal>
        <Modal
          size='md'
          title='Entity Delete'
          isOpen={isEntityDeleteOpen}
          onClose={onEntityDeleteClose}
        >
          <EntityDelete />
        </Modal>
      </Flex>
      <PaginationWithPageInformation
        skipPage={skipPage}
        skipDifference={_skipDifference}
        setSkipDifference={setSkipDifference}
        itemsPerPage={_skipDifference}
        totalCount={_.pluck(_QueryEntityRead?.data?.EntityRead, '_totalCount')?.[0]}
        onPageChange={setSkipPage}
      />
    </>
  )
}

Index.propTypes = {}


/*
 * EXPORTS
 */
export default Index
