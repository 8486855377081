/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation KeywordUpsertMutation($keywordDirectoryId: ID, $keywordId: ID, $keyword: String) {
  KeywordUpsert(keywordDirectoryId: $keywordDirectoryId, keywordId: $keywordId, keyword: $keyword) {
    id
    message
    status
  }
}
`
