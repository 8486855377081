/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation CustomerUpdateMutation(
  $customerId: ID!,
  $type: CUSTOMER_TYPE,
  $companyName: String,
  $companyReferenceNumber: String,
  $country: String,
  $companyEmail: EmailAddress,
  $supportEmail: EmailAddress,
  $billingEmail: [EmailAddress],
  $rateEmail: [EmailAddress],
  $billingAddress: String,
  $currency: String,
  $billingPeriod: BILLING_PERIOD,
  $phone: String,
  $smsType: String,
  $overDraftLimit: Float
  $alertAmount: Float,
  $enableAutoInvoicing: Boolean
) {
  CustomerUpdate(
    customerId: $customerId,
    type: $type,
    companyName: $companyName,
    companyReferenceNumber: $companyReferenceNumber,
    country: $country,
    companyEmail: $companyEmail,
    supportEmail: $supportEmail,
    billingEmail: $billingEmail,
    rateEmail: $rateEmail,
    billingAddress: $billingAddress,
    currency: $currency,
    billingPeriod: $billingPeriod,
    phone: $phone,
    smsType: $smsType,
    overDraftLimit: $overDraftLimit,
    alertAmount: $alertAmount,
    enableAutoInvoicing: $enableAutoInvoicing
  ) {
    message
    status
  }
}
`
