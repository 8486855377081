/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation SenderIdUpsertMutation($senderIdDirectoryId: ID, $senderIdId: ID, $senderId: String) {
  SenderIdUpsert(senderIdDirectoryId: $senderIdDirectoryId, senderIdId: $senderIdId, senderId: $senderId) {
    id
    message
    status
  }
}
`
