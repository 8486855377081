/*
 * IMPORTS
 */
import React from 'react' // Npm: React.js library.
import PropTypes from 'prop-types' // Npm: Prop Types for checking the props type.
import NaturalCompare from 'natural-compare' // Npm: Sorting library.
import _ from 'underscore' // Npm: Underscore.js for utility functions.
import { BsCheckAll } from 'react-icons/bs' // Npm: React Icons for icons.
import { useQuery } from '@apollo/client' // Npm: Apollo client.
import { HiCheckCircle, HiPlusCircle, HiXCircle } from 'react-icons/hi2' // Npm: React Icons for icons.
import {
  Checkbox,
  Flex,
  FormControl,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useBreakpointValue
} from '@chakra-ui/react' // Npm: Chakra UI A simple, modular and accessible component library for React.


/*
 * COMPONENTS
 */
import { MemoizedSelect } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import MccReadQuery from './__query__/index.mcc.read.query'


/*
 * STYLES
 */
import './index.style.css'


/*
 * OBJECTS
 */
const Index = ({ mccValue, mncValue, inValidMnc, disabled, inValidMcc, onChange }) => {
  // Hook assignment.
  const [mcc, setMcc] = React.useState('')
  const [mnc, setMnc] = React.useState([])
  const _QueryMccRead = useQuery(MccReadQuery, { 'variables': { 'take': 1000, 'skip': 0 }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })

  // Event handler.
  React.useEffect(() => {
    /*
     * Update selected country if mcc value
     * Is passed in params.
     */
    mccValue && setMcc(mccValue ?? '')
    mncValue && setMnc(mncValue ?? [])
  }, [])

  // Component assignment.
  const OperatorMnc = () => React.useMemo(() => {
    // Const assignment.
    const _selectedTarget = (mcc)?.split('(')[1]?.split(')')[0]
    const _selectedMcc = _QueryMccRead?.data?.MccRead?.filter?.(j => j.mcc.includes(_selectedTarget))
    const _mcc = _selectedMcc?.[0]

    // Return the JSX.
    return (
      <Menu disabled={_.isEmpty(_mcc) || _QueryMccRead?.loading || 0 === _mcc?.Mnc?.length || disabled} w='100%'>
        <Flex w='100%' position='relative'>
          <MenuButton
            type='button'
            disabled={_QueryMccRead?.loading || disabled || 0 === _mcc?.Mnc?.length}
            h='40px'
            maxW='190px'
            color={disabled ? '#aaafc8' : void 0}
            px='4'
            onClick={e => e.stopPropagation()}
            m='0'
            transition='all 0.2s'
            textAlign='left'
            borderRadius='10px'
            bg={inValidMnc ? 'rgb(255,255,255,0.8)' : 'gray.100'}
            rightIcon={<BsCheckAll />}
            _hover={{ 'bg': 'gray.100' }}
            boxShadow={inValidMnc ? '0 0 0 1.5px #EE5D50' : void 0}
            width={{ 'base': '100%', 'md': '270px' }}>
            {(_.isString(mnc) && !_.isEmpty(mnc) ? [mnc] : mnc)?.length ?? 0} Selected
          </MenuButton>
          <Flex position='absolute' zIndex={1000} right={0}>
            <IconButton
              aria-label='Select All'
              disabled={disabled}
              _hover={{ 'bg': 'none' }}
              _active={{ 'bg': 'none' }}
              onClick={() => {
                // Const assignment.
                const _mnc = _.map(0 < _mcc?.Mnc?.length ? _mcc.Mnc : [], j => `(${j.mnc}) ${j?.network}`)

                // Update mnc.
                setMnc(_mnc)

                // Return updated mcc and mnc.
                onChange({
                  'mnc': _mnc,
                  'mcc': _mcc?.mcc ?? []
                })
              }}
              right='-10px'
              bg='none'
              p='0'>
              {mnc?.length === _mcc?.mnc?.length ? (<HiCheckCircle fontSize='20px' color='#c4c4c4' />) : (<HiPlusCircle fontSize='20px' color='#3CA55C' />)}
            </IconButton>
            <IconButton
              aria-label='Clear All'
              _hover={{ 'bg': 'none' }}
              _active={{ 'bg': 'none' }}
              disabled={disabled}
              onClick={() => {
                // Const assignment.
                setMnc([])

                // Return updated mcc and mnc.
                onChange({ 'mnc': [], 'mcc': '' })
              }}
              bg='none'
              p='0'>
              {0 === mnc?.length ? (<HiXCircle fontSize='20px' color='#c4c4c4' />) : (<HiXCircle fontSize='20px' color='#FF416C' />)}
            </IconButton>
          </Flex>
        </Flex>
        <MenuList borderRadius={12} h='250px' overflow='auto' minW='300px'>
          {_mcc?.Mnc?.map?.(item => (
            <MenuItem closeOnSelect={false} key={String.random(8)}>
              <Checkbox
                value={item.network}
                disabled={0 === _selectedMcc.length}
                name='mnc'
                onChange={() => {
                  // Return updated mcc and mnc.
                  onChange({
                    'mnc': [...mnc, `(${item.mnc}) ${item?.network}`],
                    'mcc': _mcc?.mcc ?? []
                  })

                  // Update state of mnc.
                  setMnc(j => {
                    /*
                     * If only one selection is allowed
                     * Then clear the array.
                     */
                    if (j.includes(`(${item.mnc}) ${item?.network}`)) return _.without(j, `(${item.mnc}) ${item?.network}`)

                    // Return updated mnc.
                    return [...j, `(${item.mnc}) ${item?.network}`]
                  })
                }}
                isChecked={mnc.includes(`(${item.mnc}) ${item?.network}`)}>
                {`(${item.mnc})`} {item?.network}
              </Checkbox>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    )
  }, [])
  const flexDirection = useBreakpointValue({ 'base': 'column', 'md': 'row' })

  // Return the JSX.
  return (
    <Flex w='100%' className='mccAndMncSelectorForLcr' flexDir={flexDirection} gap='12px' alignItems='center'>
      <FormControl w='180px'>
        <MemoizedSelect
          label='Mcc'
          disabled={_QueryMccRead?.loading || disabled}
          name='mcc'
          data={mcc}
          minW='100%'
          placeholder='Select Country'
          options={_.compact(_QueryMccRead?.data?.MccRead?.map?.(j => `${j.countryName} (${j.mcc})`))?.sort((a, b) => NaturalCompare(a, b))}
          isInvalid={inValidMcc}
          isRequired={true}
          onChange={__event => {
            // Const assignment.
            const _selectedTarget = __event.target.value?.split('(')[1]?.split(')')[0]
            const _selectedMcc = _QueryMccRead?.data?.MccRead?.filter?.(j => j.mcc.includes(_selectedTarget))

            // Update states.
            setMcc(__event.target.value)
            setMnc([])

            // Return updated mcc and mnc.
            onChange({
              'mnc': [],
              'mcc': _.first(_selectedMcc)?.mcc
            })
          }}
        />
      </FormControl>
      <Flex flexDir='column' gap='6px'>
        <Text>Mnc<Text as='span' color='red'>*</Text></Text>
        <OperatorMnc />
      </Flex>
    </Flex>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'mccValue': PropTypes.string,
  'mncValue': PropTypes.array,
  'inValidMnc': PropTypes.bool,
  'disabled': PropTypes.bool,
  'inValidMcc': PropTypes.bool,
  'onChange': PropTypes.func
}


/*
 * EXPORT
 */
export default Index
