/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation IpUploadMutation($ipDirectoryId: ID, $ips: [String!]!) {
  IpUpload(ipDirectoryId: $ipDirectoryId, ips: $ips) {
    id
    message
    status
  }
}
`
