/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import JoiBrowser from 'joi-browser' // Npm: Joi for frontend validation.
import _ from 'underscore' // Npm: Underscore.js library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useLazyQuery, useMutation } from '@apollo/client' // Npm: Apollo client.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { Flex } from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import SubmitButton from 'components/SubmitButton'
import { MemoizedInput, MemoizedSelect } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import VendorReadQuery from './__query__/index.vendor.read.query'
import VendorUpdateMutation from './__mutation__/index.vendor.update.mutation'
import VendorCreateMutation from './__mutation__/index.vendor.create.mutation'
import AccountRegisterWithEmailMutation from './__mutation__/index.account.create.mutation'
import VendorAttachAccountLoginMutation from './__mutation__/index.vendor.attachAccountLogin.mutation'


/*
 * OBJECTS
 */
const Index = ({ isOpen, isCreateOnly, onClose, passOn }) => {
  // Hook assignment.
  const [error, setError] = React.useState('')
  const [forceReRender, setForceReRender] = React.useState('')
  const [QueryVendorRead, QueryVendorReadResponse] = useLazyQuery(VendorReadQuery, { 'variables': { 'vendorId': passOn?.vendorId }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })
  const [MutationVendorCreate, MutationVendorCreateResponse] = useMutation(VendorCreateMutation)
  const [MutationVendorUpdate, MutationVendorUpdateResponse] = useMutation(VendorUpdateMutation)
  const [MutationVendorAttachAccountLogin] = useMutation(VendorAttachAccountLoginMutation)
  const [MutationAccountRegisterWithEmail] = useMutation(AccountRegisterWithEmailMutation)
  const _formDataRef = React.useRef({})

  // Object assignment.
  const _SubmitForm = async e => {
    // Local variable.
    let _MutationAccountRegisterWithEmail

    // Prevent default behavior.
    e.preventDefault()

    // Reset error.
    setError('')

    // Const assignment.
    const _JoiSchema = JoiBrowser.object({
      'vendorId': JoiBrowser.string().optional(),
      'displayName': JoiBrowser.string().required(),
      'email': JoiBrowser.string().required(),
      'address': JoiBrowser.string().required(),
      'phone': JoiBrowser.string().required(),
      'billingPeriod': JoiBrowser.string().required(),
      'billingEmail': JoiBrowser.array().items(JoiBrowser.string().email()).required(),
      'technicalEmail': JoiBrowser.string().required(),
      'billingAddress': JoiBrowser.string().optional(),
      'billingCurrency': JoiBrowser.string().required(),
      'country': JoiBrowser.string().required()
    }).options({ 'allowUnknown': true })

    // Remove all keys from _formDataRef.current which are undefined.
    _formDataRef.current = _.pick(_formDataRef.current, _.identity)

    // Validate form data.
    const _JoiSchemaValidate = _JoiSchema.validate(_formDataRef.current)

    // If error exists then report failure.
    if (_JoiSchemaValidate.error) return setError(_JoiSchemaValidate.error?.message)

    /*
     * Push vendorId if not found on the _formDataRef.
     * also make sure that isCreateOnly is false.
     */
    if (!isCreateOnly && _.isEmpty(_formDataRef.current?.vendorId)) _formDataRef.current = { ..._formDataRef.current, 'vendorId': passOn?.vendorId }

    // Only create account on new customer creation.
    if (isCreateOnly) {
      // Execute account registration mutation.
      _MutationAccountRegisterWithEmail = await MutationAccountRegisterWithEmail({
        'variables': {
          'displayName': _formDataRef?.current?.displayName,
          'email': _formDataRef?.current?.email,
          'password': String.random(8),
          'accountType': 'TERMINATION'
        }
      })

      // If mutation caught an exception then report failure.
      if (_MutationAccountRegisterWithEmail instanceof Error) return _MutationAccountRegisterWithEmail
      if ('ACCOUNT_WITH_EMAIL_FOUND' === _MutationAccountRegisterWithEmail?.data?.AccountRegisterWithEmail?.status) {
        // Report failure.
        setError('companyEmail already registered. Please try something else.')

        // Style Guide.
        return toast('Given company email is already registered. Please try something else.')
      }
      if ('ACCOUNT_DISPLAY_NAME_ALREADY_EXISTS' === _MutationAccountRegisterWithEmail?.data?.AccountRegisterWithEmail?.status) {
        // Report failure.
        setError('companyName already registered. Please try something else.')

        // Style Guide.
        return toast('Given company name is already registered. Please try something else.')
      }
      // On Successful response from the mutation.
      if ('CREATE_SUCCESSFUL' !== _MutationAccountRegisterWithEmail?.data?.AccountRegisterWithEmail?.status) return toast(_MutationAccountRegisterWithEmail?.data?.AccountRegisterWithEmail?.message)

      // Style Guide.
      toast(_MutationAccountRegisterWithEmail?.data?.AccountRegisterWithEmail?.message)
    }

    // Execute update mutation.
    const _MutationVendorUpdate = await [isCreateOnly ? MutationVendorCreate : MutationVendorUpdate]?.[0]({ 'variables': _.omit(_formDataRef?.current, isCreateOnly ? 'vendorId' : void 0) })

    // If mutation caught an exception then report failure.
    if (_MutationVendorUpdate instanceof Error) return _MutationVendorUpdate

    // Style Guide.
    toast(_MutationVendorUpdate?.data?.VendorCreate?.message || _MutationVendorUpdate?.data?.VendorUpdate?.message)

    // Execute onClose if response is successful.
    if ('CREATE_SUCCESSFUL' === _MutationVendorUpdate?.data?.VendorCreate?.status || 'UPDATE_SUCCESSFUL' === _MutationVendorUpdate?.data?.VendorUpdate?.status) {
      // Only attach account during creation.
      if (isCreateOnly) {
        // On Successful create attach given vendor with given account login.
        const _MutationVendorAttachAccountLogin = await MutationVendorAttachAccountLogin({
          'variables': {
            'vendorId': _MutationVendorUpdate?.data?.VendorCreate?.id,
            'accountId': _MutationAccountRegisterWithEmail?.data?.AccountRegisterWithEmail?.id
          }
        })

        // If mutation caught an exception then report failure.
        if (_MutationVendorAttachAccountLogin instanceof Error) return _MutationVendorAttachAccountLogin

        // Style Guide.
        toast(_MutationVendorAttachAccountLogin?.data?.VendorAttachAccountLogin?.message)
      }

      // Execute onClose.
      return onClose?.()
    }

    // Report void 0.
    return void 0
  }

  // Event handler.
  React.useEffect(() => {
    // _Async handler.
    const _Async = async () => {
      // Const assignment.
      const _QueryVendorReadQuery = await QueryVendorRead({ 'variables': { 'vendorId': isCreateOnly ? 'UN_KNOWN' : passOn?.vendorId } })

      // If query caught an exception then report failure.
      if (_QueryVendorReadQuery instanceof Error) return _QueryVendorReadQuery

      /*
       * If vendor details fetch complete then
       * update its value.
       */
      if (0 < _QueryVendorReadQuery?.data?.VendorRead?.length) {
        // Const assignment.
        const _data = _.first(_QueryVendorReadQuery?.data?.VendorRead)

        // Update form data.
        _formDataRef.current = {
          'vendorId': _data.id,
          'displayName': _data.displayName,
          'email': _data.email,
          'address': _data.address,
          'phone': _data.phone,
          'billingEmail': _data.Billing?.email,
          'technicalEmail': _data.technicalEmail,
          'billingAddress': _data.Billing?.address,
          'billingPeriod': _data.Billing?.period,
          'billingCurrency': _data.Billing?.currency,
          'country': _data.country
        }

        // Update state.
        return setForceReRender(String.random(8))
      }

      // Report failure.
      return void 0
    }; _Async()
  }, [passOn, isOpen])

  // Const assignment.
  const _isLoading = MutationVendorUpdateResponse?.loading || MutationVendorCreateResponse?.loading
  const _isInputDisabled = isCreateOnly ? false : _isLoading || QueryVendorReadResponse?.loading

  // Return component.
  return (
    <form onSubmit={_SubmitForm} key={forceReRender}>
      <Flex gap='22px' flexDir='column' w='100%'>
        <Flex w='100%' gap='22px' flexDir={{ 'base': 'column', 'md': 'row' }}>
          <MemoizedInput
            disabled={_isInputDisabled}
            isRequired={true}
            name='displayName'
            label='Display Name'
            placeholder='e.g. "Something Amazing"'
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: value
              }
            }}
            isInvalid={error?.includes('displayName')}
            error={error}
            data={_formDataRef?.current?.displayName}
          />
          <MemoizedInput
            disabled={_isInputDisabled}
            isRequired={true}
            name='email'
            label='Email'
            placeholder='e.g. "abc@xyz"'
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: value
              }
            }}
            error={error}
            type='email'
            isInvalid={error?.includes('email')}
            data={_formDataRef?.current?.email}
          />
        </Flex>
        <Flex w='100%' gap='22px' flexDir={{ 'base': 'column', 'md': 'row' }}>
          <MemoizedInput
            disabled={_isInputDisabled}
            isRequired={true}
            name='address'
            label='Address'
            placeholder='e.g. "Some secret location.."'
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: value
              }
            }}
            error={error}
            isInvalid={error?.includes('address')}
            data={_formDataRef?.current?.address}
          />
          <MemoizedInput
            disabled={_isInputDisabled}
            isRequired={true}
            name='phone'
            label='Phone'
            placeholder='e.g. "9188-2666-8515"'
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: value
              }
            }}
            error={error}
            isInvalid={error?.includes('phone')}
            data={_formDataRef?.current?.phone}
          />
        </Flex>
        <MemoizedInput
          disabled={_isInputDisabled}
          isRequired={true}
          name='technicalEmail'
          label='Technical Email'
          placeholder='e.g. "abc@support"'
          onChange={({ target }) => {
            // Over spreading.
            const { name, value } = target

            // Update form data.
            _formDataRef.current = {
              ..._formDataRef?.current,
              [name]: value
            }
          }}
          error={error}
          isInvalid={error?.includes('technicalEmail')}
          type='email'
          data={_formDataRef?.current?.technicalEmail}
        />
        <Flex w='100%' gap='22px' flexDir={{ 'base': 'column', 'md': 'row' }}>
          <MemoizedSelect
            disabled={_isInputDisabled}
            isRequired={true}
            name='billingPeriod'
            label='Billing Period'
            placeholder='e.g. "SIX_MONTH"'
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: value
              }
            }}
            error={error}
            isInvalid={error?.includes('billingPeriod')}
            options={Object.React.App.enums.BILLING_PERIOD.enums?.map(i => i.key)}
            data={_formDataRef?.current?.billingPeriod?.toUpperCase()}
          />
          <MemoizedInput
            disabled={_isInputDisabled}
            name='billingAddress'
            label='Billing Address'
            placeholder='Enter Billing Address'
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: value
              }
            }}
            error={error}
            isInvalid={error?.includes('billingAddress')}
            data={_formDataRef?.current?.billingAddress}
          />
        </Flex>
        <Flex w='100%' gap='22px' flexDir={{ 'base': 'column', 'md': 'row' }}>
          <MemoizedSelect
            disabled={_isInputDisabled}
            isRequired={true}
            name='billingCurrency'
            label='Currency'
            placeholder='e.g. "USD"'
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: value
              }
            }}
            error={error}
            isInvalid={error?.includes('billingCurrency')}
            options={Object.React.App.enums.CURRENCY_LIST.enums?.map(i => i.key)}
            data={_formDataRef?.current?.billingCurrency?.toUpperCase()}
          />
          <MemoizedSelect
            disabled={_isInputDisabled}
            isRequired={true}
            name='country'
            label='Country'
            placeholder='e.g. "INDIA"'
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: value
              }
            }}
            error={error}
            isInvalid={error?.includes('country')}
            options={Object.React.App.enums.COUNTRY_LIST.enums?.map(i => i.key)}
            data={_formDataRef?.current?.country?.toUpperCase()}
          />
        </Flex>
        <MemoizedInput
          disabled={_isInputDisabled}
          isRequired={true}
          name='billingEmail'
          label='Billing Email ( Press Tab, Enter or Comma to add )'
          placeholder='e.g. "abc@xyz"'
          onChange={({ target }) => {
            // Over spreading.
            const { name, value } = target

            // Update form data.
            _formDataRef.current = {
              ..._formDataRef?.current,
              [name]: value
            }
          }}
          error={error}
          type='email'
          isMultiple={true}
          separators={[',', 'Enter', 'Tab', ' ']}
          isInvalid={error?.includes('billingEmail')}
          data={_formDataRef?.current?.billingEmail}
        />
      </Flex>
      <SubmitButton disabled={_isInputDisabled} onSubmit={_SubmitForm} defaultText={isCreateOnly ? 'Create Vendor' : 'Update Vendor'} isLoading={_isLoading} />
    </form>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'isCreateOnly': PropTypes.bool,
  'isOpen': PropTypes.bool,
  'onClose': PropTypes.func,
  'passOn': PropTypes.object
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
