/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * GRAPHS
 */
const Index = gql`
  query SmsGroupByQuery($groupBy: [String!]!, $startDate: DateTime!, $endDate: DateTime!, $skip: Int!, $take: PositiveInt!) {
    SmsGroupBy(groupBy: $groupBy, startDate: $startDate, endDate: $endDate, skip: $skip, take: $take) {
      persisted__countryname,
      _totalCount,
      message,
      status
    }
  }
`

/*
 * EXPORTS
 */
export default Index
