/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client.


/*
 * EXPORTS
 */
export default gql`
mutation BlockDestinationAddressDeleteManyMutation($blockDestinationAddressIds: [ID!]!) {
  BlockDestinationAddressDeleteMany(blockDestinationAddressIds: $blockDestinationAddressIds) {
    message
    status
  }
}
`

