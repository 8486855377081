/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.

/*
 * EXPORTS
 */
export default gql`
  query KeywordReadQuery($keywordDirectoryId: ID!, $take: PositiveInt!, $skip: Int!) {
    KeywordRead(keywordDirectoryId: $keywordDirectoryId, take: $take, skip: $skip) {
      message,
      status,
      id,
      keyword,
      _totalCount,
      KeywordDirectory {
        id,
        displayName
      }
    }
  }
`
