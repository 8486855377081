
/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * GRAPHS
 */
const Index = gql`
query SmsCountDeliveredQuery($endDate: DateTime!, $startDate: DateTime!) {
  SmsCountDelivered(endDate: $endDate, startDate: $startDate){
      count,
      _totalCount,
      message,
      status
    }
  }
`

/*
 * EXPORTS
 */
export default Index
