/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import Debounce from 'lodash/debounce' // Npm: Lodash library.
import Moment from 'moment' // Npm: Moment.js library.
import PropTypes from 'prop-types' // Npm: Prop types for checking props type.
import _ from 'underscore' // Npm: Underscore.js library.
import { IoMdAdd } from 'react-icons/io' // Npm: React icons.
import { connect } from 'react-redux' // Npm: React redux.
import { useMutation, useQuery } from '@apollo/client' // Npm: Apollo client.
import {
  Button,
  Checkbox,
  Flex,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tooltip,
  Tr,
  useBreakpointValue,
  useDisclosure
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import TableSpinner from 'components/TableSpinner'
import DownloadToExcel from 'components/DownloadToExcel'
import PaginationWithPageInformation from 'components/PaginationWithPageInformation'
import Modal from 'components/Modal'
import AccountLogout from 'components/AccountLogout'
import AccountUpsert from 'components/AccountUpsert'
import AccountDelete from 'components/AccountDelete'
import TwoFactorAuthentication from 'components/2FA'
import AccountDropDownOptions from 'components/AccountDropDownOptions'
import { MemoizedInput, MemoizedSelect } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import AccountSettingMutation from './__mutation__/index.account.setting.mutation'
import AccountTwoFactorAuthenticationDeleteMutation from './__mutation__/index.account.twoFactorAuthenticationDelete.mutation'
import AccountIsBlockedWithEmailOrAccountIdMutation from './__mutation__/index.account.isBlockedWithEmailOrAccountId.mutation'
import AccountReadQuery from './__query__/index.account.read.query'


/*
 * STYLES
 */
import {
  buttonStyle,
  cellStyle,
  headerStyle,
  rowStyle
} from './index.style'


/*
 * OBJECTS
 */
const Index = ({ PassOnUpdate }) => {
  // Const assignment.
  const _skipDifference = 8
  const _successFlags = Object.React.App.enums.GRAPHQL_SUCCESSFUL_QUERY_FLAGS.enums.map(i => i.key)

  // Hook assignment.
  const [skipPage, setSkipPage] = React.useState(0)
  const [selectedAccountType, setSelectedAcccountType] = React.useState('EMPLOYEE')
  const [searchQuery, setSearchQuery] = React.useState('')
  const [isBlockInProgress, setIsBlockInProgress] = React.useState([])
  const [is2FAInProgress, setIs2FAInProgress] = React.useState([])
  const [skipDifference, setSkipDifference] = React.useState(_skipDifference)
  const { 'onOpen': on2FAOpen, 'isOpen': is2FAOpen, 'onClose': on2FAClose } = useDisclosure()
  const { 'onOpen': onAccountLogoutOpen, 'isOpen': isAccountLogoutOpen, 'onClose': onAccountLogoutClose } = useDisclosure()
  const { 'onOpen': onAccountUpdateOpen, 'isOpen': isAccountUpdateOpen, 'onClose': onAccountUpdateClose } = useDisclosure()
  const { 'onOpen': onAccountDeleteOpen, 'isOpen': isAccountDeleteOpen, 'onClose': onAccountDeleteClose } = useDisclosure()
  const { 'onOpen': onAccountCreateOpen, 'isOpen': isAccountCreateOpen, 'onClose': onAccountCreateClose } = useDisclosure()
  const [MutationAccountSetting] = useMutation(AccountSettingMutation)
  const [MutationAccountTwoFactorAuthenticationDelete] = useMutation(AccountTwoFactorAuthenticationDeleteMutation)
  const [MutationAccountIsBlockedWithEmailOrAccountId] = useMutation(AccountIsBlockedWithEmailOrAccountIdMutation)
  const _QueryAccountRead = useQuery(AccountReadQuery, { 'variables': { 'accountType': selectedAccountType, 'skip': skipPage * skipDifference, 'take': skipDifference, 'search': searchQuery }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })
  const _isFirstLoadCompleted = React.useRef(false)
  const _onSearchInputChange = React.useCallback(Debounce(e => setSearchQuery(e.target.value), 800), [])
  const _tableHeaderHeightRef = React.useRef(0)
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })

  // Data assignment.
  if (!_QueryAccountRead.loading && 0 < _QueryAccountRead?.data?.AccountRead?.length) _isFirstLoadCompleted.current = true
  if (0 === _QueryAccountRead?.data?.AccountRead?.length || !_.every(_.pluck(_QueryAccountRead?.data?.AccountRead, 'status'), j => _successFlags.includes(j))) _isFirstLoadCompleted.current = false

  // Object assignment.
  const _onBanToggle = async (e, id) => {
    // Update block status.
    setIsBlockInProgress(i => [...i, id])

    // Update block status.
    await MutationAccountIsBlockedWithEmailOrAccountId({ 'variables': { 'accountId': id, 'isBlocked': e.nativeEvent?.target?.checked } })

    // Refetch account details.
    await _QueryAccountRead.refetch()

    // Update block status.
    setIsBlockInProgress(r => _.without(r, id))
  }
  const _on2FAToggle = async (e, id, email) => {
    // Const assignment.
    const _isChecked = e.nativeEvent?.target?.checked

    // Update passOn with email.
    PassOnUpdate({ email, 'accountId': id })

    // If 2FA is enabled then open 2FA modal.
    if (_isChecked) return on2FAOpen()

    // Update 2FA status.
    setIs2FAInProgress(i => [...i, id])

    // Remove 2Fa.
    await MutationAccountTwoFactorAuthenticationDelete({ 'variables': { email } })

    // Update 2FA status.
    await MutationAccountSetting({ 'variables': { 'accountId': id, 'is2FAEnabled': _isChecked } })

    // Refetch account details.
    await _QueryAccountRead.refetch()

    // Update 2FA status.
    return setIsBlockInProgress(r => _.without(r, id))
  }

  // Return Component.
  return (
    <>
      <Flex className='account base'>
        <Flex
          display='flex'
          flex={1}
          flexDirection='column'
          gap={_isCurrentViewMobile ? '12px' : '22px'}
          bg='white'
          height='100%'
          borderRadius='20px'
          p={_isCurrentViewMobile ? '12px' : '22px'}>
          <Flex pb='0px' justify='space-between'>
            <Tooltip label='Create new account.' fontSize='sm'>
              <Button
                w='170px'
                onClick={onAccountCreateOpen}
                leftIcon={<IoMdAdd />}
                style={buttonStyle}>
                New Account
              </Button>
            </Tooltip>
            <DownloadToExcel
              cellsData={_QueryAccountRead?.data?.AccountRead?.map((item, __index) => _successFlags.includes(item.status) ? ({
                'S.No.': `${(__index + (skipDifference * (skipPage))) + 1}.`,
                'Account Name': item.displayName ?? '-',
                'Email': item.email ?? '-',
                'Account Status': item.accountType,
                'CreatedAt': Moment(item?.createdAt).format('YYYY-MM-DD'),
                'UpdatedAt': Moment(item?.updatedAt).format('YYYY-MM-DD'),
                'Ban': item.isBlocked ?? '-'
              }) : ({}))}
              headersData={[
                'S.No.',
                'Account Name',
                'Email',
                'Account Status',
                'Active As',
                'CreatedAt',
                'UpdatedAt',
                'Ban'
              ].map(k => ({ 'key': k, 'label': k }))} />
          </Flex>
          <TableContainer
            flex={1}
            display='flex'
            borderRadius='15px'
            outline='1px solid #C5CFE8'>
            <Table variant='simple' size='sm'>
              <Thead
                ref={_tableHeaderHeightRef}
                color='rgba(141, 151, 182, 1)'
                fontStyle='DM Sans'
                fontWeight='500'
                fontSize='18px'
                lineHeight='24px'>
                <Tr style={{ ...headerStyle, 'position': 'sticky', 'top': '0', 'zIndex': '1' }}>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    S No.
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Account Name
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Email
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Account Type
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    CreatedAt
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    UpdatedAt
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Ban
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    2FA
                  </Td>
                  <Td
                    style={rowStyle}>
                    Actions
                  </Td>
                </Tr>
                <Tr key={String.random(8)}>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    <MemoizedInput
                      placeholder='Search'
                      className='filter searchInput'
                      defaultValue={searchQuery}
                      onChange={_onSearchInputChange}
                      autoFocus
                    />
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    <MemoizedSelect
                      placeholder='Select Account Type'
                      className='filter searchInput'
                      onChange={e => setSelectedAcccountType(_.isEmpty(e?.target?.value) ? void 0 : e?.target?.value)}
                      options={Object.React.App.enums.ACCOUNT_TYPE.enums?.map(i => i?.key)}
                      data={selectedAccountType}
                    />
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                  </Td>
                  <Td
                    style={rowStyle}>
                  </Td>
                </Tr>
              </Thead>
              {_QueryAccountRead.loading && !_isFirstLoadCompleted.current ? (
                <TableSpinner isLoading={true} chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight} />
              ) : !_isFirstLoadCompleted.current && (0 === _QueryAccountRead?.data?.AccountRead?.length || (0 < _QueryAccountRead?.data?.AccountRead?.length && !_.every(_.pluck(_QueryAccountRead?.data?.AccountRead, 'status'), j => _successFlags.includes(j)))) ? (
                <TableSpinner isLoading={false} isEmpty={true} chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight} />
              ) : (
                <Tbody style={cellStyle}>
                  {_QueryAccountRead?.data?.AccountRead.map(({ id, displayName, email, accountType, createdAt, updatedAt, isBlocked, is2FAEnabled }, __index) => (
                    <Tr key={id}>
                      <Td
                        style={rowStyle}
                        py='0'
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {`${(__index + (skipDifference * (skipPage))) + 1}.`}
                      </Td>
                      <Td
                        style={rowStyle}
                        py='0'
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {displayName}
                      </Td>
                      <Td
                        style={rowStyle}
                        py='0'
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {email}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        <MemoizedInput
                          value={accountType}
                          borderRadius='10px'
                          h='10'
                          maxH='10'
                          fontSize='15px'
                          fontWeight='500'
                          bg='gray.100'
                          w='max-content'
                          alignItems='center'
                          disabled={true}
                          border='1px solid rgba(216, 227, 252, 1)'>
                        </MemoizedInput>
                      </Td>
                      <Td
                        style={rowStyle}
                        py='0'
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {Moment(createdAt).format('YYYY-MM-DD')}
                      </Td>
                      <Td
                        style={rowStyle}
                        py='0'
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {Moment(updatedAt).format('YYYY-MM-DD')}
                      </Td>
                      <Td
                        style={rowStyle}
                        textAlign='center'
                        py='0'
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {isBlockInProgress.includes(id) ? <Spinner color='#c1a9f4' size='sm' /> : <Checkbox bg='gray.100' isChecked={isBlocked} onChange={e => _onBanToggle(e, id)} />}
                      </Td>
                      <Td
                        style={rowStyle}
                        textAlign='center'
                        py='0'
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {is2FAInProgress.includes(id) ? <Spinner color='#c1a9f4' size='sm' /> : <Checkbox bg='gray.100' isChecked={is2FAEnabled} onChange={e => _on2FAToggle(e, id, email)} />}
                      </Td>
                      <Td
                        style={rowStyle}>
                        {' '}
                        {
                          <AccountDropDownOptions
                            accountId={id}
                            accountType={accountType}
                            onAccountUpdate={onAccountUpdateOpen}
                            onAccountLogout={onAccountLogoutOpen}
                            onAccountDelete={onAccountDeleteOpen}
                          />
                        }
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              )}
            </Table>
          </TableContainer>
        </Flex>
        <Modal
          size='lg'
          title='Account Create'
          isOpen={isAccountCreateOpen}
          onClose={onAccountCreateClose}>
          <AccountUpsert isCreateOnly={true} />
        </Modal>
        <Modal
          size='lg'
          title='Account Update'
          isOpen={isAccountUpdateOpen}
          onClose={onAccountUpdateClose}>
          <AccountUpsert />
        </Modal>
        <Modal
          size='md'
          title='Account Logout'
          isOpen={isAccountLogoutOpen}
          onClose={onAccountLogoutClose}>
          <AccountLogout />
        </Modal>
        <Modal
          size='md'
          title='Account Delete'
          isOpen={isAccountDeleteOpen}
          onClose={onAccountDeleteClose}>
          <AccountDelete />
        </Modal>
        <Modal
          size='sm'
          title='2FA Enable/Disable'
          isOpen={is2FAOpen}
          onClose={on2FAClose}>
          <TwoFactorAuthentication />
        </Modal>
      </Flex >
      <PaginationWithPageInformation
        skipPage={skipPage}
        skipDifference={_skipDifference}
        setSkipDifference={setSkipDifference}
        itemsPerPage={_skipDifference}
        totalCount={_.pluck(_QueryAccountRead?.data?.AccountRead, '_totalCount')?.[0]}
        onPageChange={setSkipPage}
      />
    </>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'PassOnUpdate': PropTypes.func.isRequired
}


/*
 * REDUX
 */
const _MapDispatchToProps = __dispatch => ({
  'PassOnUpdate': u => __dispatch({ 'type': 'PASS_ON_UPDATE', 'PassOn': u })
})


/*
 * EXPORTS
 */
export default connect(void 0, _MapDispatchToProps)(Index)
