/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * GRAPHS
 */
const Index = gql`
subscription SmppLogsSubscription($ip: IPv4!, $port: Port!) {
  SmppLogs(ip: $ip, port: $port) {
    id,
    createdAt,
    updatedAt,
    logs
  }
}
`


/*
 * EXPORTS
 */
export default Index
