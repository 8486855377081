/*
 * IMPORTS
 */
import 'react-date-range/dist/styles.css' // Npm: React date range.
import 'react-date-range/dist/theme/default.css' // Npm: React date range.
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import ImageUploading from 'react-images-uploading' // Npm: React images uploading.
import _ from 'underscore' // Npm: Underscore library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useMutation, useQuery } from '@apollo/client' // Npm: Apollo client.
import { Button, Flex, Text, useBreakpointValue } from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import { MemoizedInput } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import PlatformUpsertMutation from './__mutation__/index.platform.upsert.mutation'
import PlatformReadQuery from './__query__/index.platform.read.query'


/*
 * STYLE
 */
import './index.css'


/*
 * OBJECTS
 */
const Index = ({ platform, PlatformUpdate }) => {
  // Const assignment.
  const _maxProfilePicture = 1

  // Hook assignment.
  const [images, setImages] = React.useState([])
  const [displayName, setDisplayName] = React.useState(void 0)
  const [copyright, setCopyright] = React.useState(void 0)
  const [isSavingPlatformNameInProgress, setIsSavingPlatformNameInProgress] = React.useState(false)
  const [isSavingCopyrightInProgress, setIsSavingCopyrightInProgress] = React.useState(false)
  const [MutationPlatformUpsert, MutationPlatformUpsertResponse] = useMutation(PlatformUpsertMutation)
  const _PlatformReadQuery = useQuery(PlatformReadQuery, { 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })

  // Object assignment.
  const _onProfilePictureUpload = __imageList => {
    // Data for submit
    setImages(__imageList)

    // Update platform logo.
    PlatformUpdate({ 'logo': _.first(__imageList)?.dataURL })

    // Upload first image only.
    return MutationPlatformUpsert({ 'variables': { 'logo': _.first(__imageList)?.dataURL } })
  }

  // Event handler.
  React.useEffect(() => {
    // Update token if it is available.
    if ('UPSERT_SUCCESSFUL' === MutationPlatformUpsertResponse?.data?.PlatformUpsert?.status) { PlatformUpdate(MutationPlatformUpsertResponse?.data?.PlatformUpsert) }
  }, [MutationPlatformUpsertResponse])

  // Return component.
  return (
    <Flex
      display='flex'
      flexDirection='column'
      gap={_isCurrentViewMobile ? '12px' : '22px'}
      bg='white'
      borderRadius='20px'
      p={_isCurrentViewMobile ? '12px' : '22px'}
      background='linear-Gradient(73deg, #390EF6 0%, #390EF6 44%, rgba(146, 68, 244, 1) 130%)'
      WebkitBackgroundClip='text'
      WebkitTextFillColor='transparent'
      flexDir='column'
      justify='space-between'
      boxShadow='14px 17px 40px 4px rgba(112, 144, 176, 0.17)'
      className='platformSetting'>
      <Text as='h2' fontSize='xl' color='white'>Platform Setting</Text>
      <Text w='70%' as='p' fontSize='17px' color='white'>Update your platform information here, Upload company logo, name and license footer.</Text>
      <Flex w='100%' mt='12px' gap='16px' flexDir='column'>
        <ImageUploading
          multiple
          value={images}
          onChange={_onProfilePictureUpload}
          maxNumber={_maxProfilePicture}>
          {({ onImageUpdate, onImageRemove }) => (
            <Flex gap='6px'>
              <div className='profilePictureWrapper'>
                <img src={_.first(images)?.dataURL ?? platform.logo} alt='' />
              </div>
              <Flex className='actionWrapper'>
                <button onClick={() => onImageUpdate(0)}>Update</button>
                <button onClick={() => { MutationPlatformUpsert({ 'variables': { 'logo': '' } }); onImageRemove(0) }}>Remove</button>
              </Flex>
            </Flex>
          )}
        </ImageUploading>
        <Flex w='100%' mt='12px' flexDir='column' alignItems='center' gap='12px'>
          <Flex
            w='100%'
            flex={1}
            flexDirection='row'
            borderRadius={12}
            overflow='hidden'
            position='relative'
            flexDir='row'
            border='1px solid white'
            alignItems='center'>
            <MemoizedInput padding='12px' borderRadius={0} placeholder='Company Name' defaultValue={displayName ?? _.first(_PlatformReadQuery?.data?.PlatformRead)?.displayName} bg='rgba(255, 255, 255, 0.33)' border='none' color='white' disabled={_PlatformReadQuery?.loading} onChange={j => setDisplayName(j?.nativeEvent?.target?.value)} />
            <Button
              px='12px'
              h='40px'
              w='100px'
              right='0'
              gap='6px'
              borderRadius={0}
              borderLeft='1px solid white'
              bg='rgba(255, 255, 255, 0.10)'
              color='white'
              _hover={{ 'bg': 'rgba(255, 255, 255, 0.33)' }}
              onClick={async () => { setIsSavingPlatformNameInProgress(true); await MutationPlatformUpsert({ 'variables': { displayName } }); setIsSavingPlatformNameInProgress(false) }}
              _active={{ 'bg': 'rgba(255, 255, 255, 0.33)' }}
              disabled={_PlatformReadQuery?.loading}>
              {isSavingPlatformNameInProgress ? 'Saving' : 'Save'}
            </Button>
          </Flex>
          <Flex
            w='100%'
            flex={1}
            borderRadius={12}
            overflow='hidden'
            position='relative'
            flexDir='row'
            border='1px solid white'
            alignItems='center'>
            <MemoizedInput padding='12px' borderRadius={0} placeholder='License Plate' defaultValue={copyright ?? _.first(_PlatformReadQuery?.data?.PlatformRead)?.copyright} bg='rgba(255, 255, 255, 0.33)' border='none' color='white' disabled={_PlatformReadQuery?.loading} onChange={e => setCopyright(e?.nativeEvent?.target?.value)} />
            <Button
              px='12px'
              h='40px'
              w='100px'
              right='0'
              gap='6px'
              borderRadius={0}
              borderLeft='1px solid white'
              bg='rgba(255, 255, 255, 0.10)'
              color='white'
              _hover={{ 'bg': 'rgba(255, 255, 255, 0.33)' }}
              onClick={async () => { setIsSavingCopyrightInProgress(true); await MutationPlatformUpsert({ 'variables': { 'displayName': displayName, copyright } }); setIsSavingCopyrightInProgress(false) }}
              _active={{ 'bg': 'rgba(255, 255, 255, 0.33)' }}
              disabled={_PlatformReadQuery?.loading}>
              {isSavingCopyrightInProgress ? 'Saving' : 'Save'}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'platform': PropTypes.object.isRequired,
  'PlatformUpdate': PropTypes.func.isRequired
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'platform': __state.Platform })
const _MapDispatchToProps = __dispatch => ({ 'PlatformUpdate': __platform => __dispatch({ 'type': 'PLATFORM_UPDATE', 'Platform': __platform }) })


/*
 * EXPORT
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index)



