/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client.


/*
 * EXPORTS
 */
export default gql`
mutation BlockIpDeleteManyMutation($blockIpIds: [ID!]!) {
  BlockIpDeleteMany(blockIpIds: $blockIpIds) {
    message
    status
  }
}
`

