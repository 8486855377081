/*
 * IMPORTS
 */
import 'react-date-range/dist/styles.css' // Npm: React date range.
import 'react-date-range/dist/theme/default.css' // Npm: React date range.
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useMutation, useQuery } from '@apollo/client' // Npm: Apollo client.
import { HiCodeBracketSquare } from 'react-icons/hi2' // Npm: React icons.
import { Button, Flex, Spinner, Text, useBreakpointValue } from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import { MemoizedInput } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import AccessTokenGenerateQuery from './__mutation__/index.accessToken.generate.mutation'
import AccessTokenReadUniqueQuery from './__query__/index.accessToken.readUnique.query'


/*
 * OBJECTS
 */
const Index = ({ account, AccountUpdate }) => {
  // Const assignment.
  const _bindableTable = 'account'

  // Hook assignment.
  const [MutationAccessTokenGenerate, MutationAccessTokenGenerateResponse] = useMutation(AccessTokenGenerateQuery, { 'variables': { 'bindToTable': _bindableTable, 'bindableId': account?.id } })
  const _AccessTokenReadUniqueQuery = useQuery(AccessTokenReadUniqueQuery, { 'variables': { 'bindToTable': _bindableTable, 'bindableId': account?.id }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })

  // Event handler.
  React.useEffect(() => {
    // Update token if it is available.
    if (MutationAccessTokenGenerateResponse?.data?.AccessTokenGenerate?.token) {
      // Update state.
      AccountUpdate({ ...account, 'accessToken': MutationAccessTokenGenerateResponse?.data?.AccessTokenGenerate?.token })
    }
  }, [MutationAccessTokenGenerateResponse])

  // Return component.
  return (
    <Flex
      w='100%'
      borderRadius='20px'
      p={_isCurrentViewMobile ? '12px' : '22px'}
      gap='12px'
      background='linear-Gradient(73deg, #390EF6 0%, #390EF6 44%, rgba(146, 68, 244, 1) 130%)'
      WebkitBackgroundClip='text'
      WebkitTextFillColor='transparent'
      flexDir='column'
      justify='space-between'
      boxShadow='14px 17px 40px 4px rgba(112, 144, 176, 0.17)'>
      <Text as='h2' fontSize='xl' color='white'>Access Token</Text>
      <Text as='p' fontSize='17px' color='white'>Pass this token in your &quot;Request&quot; headers as &quot;d-authorization&quot; with token as authorization for your request.</Text>
      <Flex
        w='100%'
        mt='22px'
        borderRadius={12}
        overflow='hidden'
        position='relative'
        flexDir='row'
        border='1px solid white'
        alignItems='center'
        gap='20%'>
        <MemoizedInput padding='12px' value={_AccessTokenReadUniqueQuery?.data?.AccessTokenReadUnique?.token ?? 'Generate token'} bg='rgba(255, 255, 255, 0.33)' border='none' color='white' readOnly disabled={_AccessTokenReadUniqueQuery?.loading} />
        <Button
          px='12px'
          h='40px'
          position='absolute'
          right='0'
          gap='6px'
          borderRadius={0}
          borderLeft='1px solid white'
          bg='rgba(255, 255, 255, 0.10)'
          color='white'
          _hover={{ 'bg': 'rgba(255, 255, 255, 0.33)' }}
          onClick={() => MutationAccessTokenGenerate()}
          _active={{ 'bg': 'rgba(255, 255, 255, 0.33)' }}
          disabled={_AccessTokenReadUniqueQuery?.loading}>
          {MutationAccessTokenGenerateResponse?.loading ? <Spinner size='sm' /> : <HiCodeBracketSquare size={19} />}
          Generate
        </Button>
      </Flex>
    </Flex>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'account': PropTypes.object.isRequired,
  'AccountUpdate': PropTypes.func.isRequired
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'account': __state.Account })
const _MapDispatchToProps = __dispatch => ({ 'AccountUpdate': __account => __dispatch({ 'type': 'ACCOUNT_UPDATE', 'Account': __account }) })


/*
 * EXPORT
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index)



