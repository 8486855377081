/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * GRAPHS
 */
const Index = gql`
  mutation SmppUpdate($smppId: ID!, $tps: Int!) {
    SmppUpdate(smppId: $smppId, tps: $tps) {
      message,
      status
    }
  }
`

/*
 * EXPORTS
 */
export default Index
