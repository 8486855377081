/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.

/*
 * EXPORTS
 */
export default gql`
mutation BlockDestinationAddressUpsertMutation(
  $customerAccountIds: [ID!],
  $vendorAccountIds: [ID!],
  $senderIdDirectoryId: ID,
  $senderIds: [ID!],
  $blockDestinationIds: [ID!],
  $type: CUSTOMER_TYPE,
  $mcc: String,
  $mnc: [String!],
  $omitDestinationAddress: [String!]
) {
  BlockDestinationAddressUpsert(
    customerAccountIds: $customerAccountIds,
    vendorAccountIds: $vendorAccountIds,
    blockDestinationIds: $blockDestinationIds,
    type: $type,
    mcc: $mcc,
    mnc: $mnc,
    omitDestinationAddress: $omitDestinationAddress,
    senderIdDirectoryId: $senderIdDirectoryId,
    senderIds: $senderIds
  ) {
    message
    status
  }
}
`
