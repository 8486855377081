/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: prop-types library.
import { HiArrowUpCircle } from 'react-icons/hi2' // Npm: react icons library.
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Spinner,
  Text,
  useBreakpointValue
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * OBJECTS
 */
const Index = ({ bg, color, label, uploadingFile, ...props }) => {
  // Hook assignment.
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': true, 'lg': true, 'xl': false, 'sm': true, 'xs': true })

  // Return component.
  return (
    <FormControl w='max-content' bg={bg ?? 'brand.100'} borderRadius={12} borderWidth={0} overflow='hidden'>
      <FormLabel h='100%' display='flex' alignItems='center' ml='12px' htmlFor='file' color={color ?? 'brand.600'} _hover={{ 'bg': 'transparent' }} flexDir='row' gap='12px'>{_isCurrentViewMobile ? void 0 : (<Text as='h4' cursor='pointer'>{label ?? 'Click to upload'}</Text>)}{uploadingFile ? (<Spinner size='sm' />) : (<HiArrowUpCircle size={20} />)}</FormLabel>
      <Box position='absolute' top='0' left='0' width='100%' height='100%' opacity={0}>
        <Input
          cursor='pointer'
          type='file'
          border='none'
          w={{ 'base': '100%', 'md': 'auto' }}
          sx={{
            '::file-selector-button': {
              'padding': 0,
              'fontWeight': '400',
              'background': 'none',
              'border': 'none',
              'color': color
            }
          }}
          _focus={{
            'border': 'none'
          }}
          _hover={{
            'border': 'none'
          }}
          {...props}
        />
      </Box>
    </FormControl>
  )
}

/*
 * PROPTYPES
 */
Index.propTypes = {
  'bg': PropTypes.string,
  'color': PropTypes.string,
  'label': PropTypes.string,
  'uploadingFile': PropTypes.bool
}


/*
 * EXPORTS
 */
export default Index
