/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query AccountReadQuery($accountType: ACCOUNT_TYPE, $search: String, $take: PositiveInt, $skip: Int) {
  AccountRead(accountType: $accountType, search: $search, take: $take, skip: $skip) {
    id,
    displayName,
    message,
    status
  }
}
`
