/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import Moment from 'moment' // Npm: Moment library.
import Debounce from 'lodash/debounce' // Npm: lodash library.
import _ from 'underscore' // Npm: underscore library.
import { useQuery } from '@apollo/client' // Npm: Apollo client.
import { IoMdAdd } from 'react-icons/io' // Npm: React icons.
import {
  Button,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tooltip,
  Tr,
  useBreakpointValue,
  useDisclosure
} from '@chakra-ui/react' // Npm: Chakra UI components.

/*
 * PACKAGES
 */
import DownloadToExcel from 'components/DownloadToExcel'
import PaginationWithPageInformation from 'components/PaginationWithPageInformation'
import TableSpinner from 'components/TableSpinner'
import Modal from 'components/Modal'
import MccUpsert from 'components/MccUpsert'
import MccDropDownOptions from 'components/MccDropDownOptions'
import MccDelete from 'components/MccDelete'
import { MemoizedInput } from 'components/MemoizedInput'


/*
 * STYLES
 */
import { buttonStyle, cellStyle, headerStyle, rowStyle } from './index.style'


/*
 * GRAPHS
 */
import MccReadQuery from './__query__/index.mcc.read.query'


/*
 * OBJECTS
 */
const Index = () => {
  // Const assignment.
  const _skipDifference = 8
  const _successFlags = Object.React.App.enums.GRAPHQL_SUCCESSFUL_QUERY_FLAGS.enums.map(i => i.key)

  // Hook assignment.
  const [skipPage, setSkipPage] = React.useState(0)
  const [skipDifference, setSkipDifference] = React.useState(_skipDifference)
  const [mccToSearch, setMccToSearch] = React.useState('')
  const [dialingCodeToSearch, setDialingCodeToSearch] = React.useState('')
  const [countryNameToSearch, setCountryNameToSearch] = React.useState('')
  const { 'isOpen': isMccCreateOpen, 'onOpen': onMccCreateOpen, 'onClose': onMccCreateClose } = useDisclosure()
  const { 'isOpen': isMccUpdateOpen, 'onOpen': onMccUpdateOpen, 'onClose': onMccUpdateClose } = useDisclosure()
  const { 'isOpen': isMccDeleteOpen, 'onOpen': onMccDeleteOpen, 'onClose': onMccDeleteClose } = useDisclosure()
  const _QueryMccRead = useQuery(MccReadQuery, {
    'variables': {
      mccToSearch,
      dialingCodeToSearch,
      countryNameToSearch,
      'skip': skipPage * skipDifference,
      'take': skipDifference
    },
    'fetchPolicy': Object.React.App.fetchPolicy,
    'pollInterval': Object.React.App.pollInterval
  })
  const _isFirstLoadCompleted = React.useRef(false)
  const _onMccToSearchInputChange = React.useCallback(Debounce(e => setMccToSearch(e.target.value), 800), [])
  const _onDialingCodeToSearchInputChange = React.useCallback(Debounce(e => setDialingCodeToSearch(e.target.value), 800), [])
  const _onCountryNameToSearchInputChange = React.useCallback(Debounce(e => setCountryNameToSearch(e.target.value), 800), [])
  const _tableHeaderHeightRef = React.useRef(0)
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })

  // Data assignment.
  if (!_QueryMccRead.loading && 0 < _QueryMccRead.data?.MccRead?.length) _isFirstLoadCompleted.current = true
  if (0 === _QueryMccRead.data?.MccRead?.length || !_.every(_.pluck(_QueryMccRead.data?.MccRead, 'status'), i => _successFlags?.includes(i))) _isFirstLoadCompleted.current = false

  // Return component.
  return (
    <>
      <Flex className='mcc base'>
        <Flex
          display='flex'
          flex={1}
          flexDirection='column'
          gap={_isCurrentViewMobile ? '12px' : '22px'}
          bg='white'
          height='100%'
          borderRadius='20px'
          p={_isCurrentViewMobile ? '12px' : '22px'}>
          <Flex pb='0px' justify='space-between'>
            <Flex gap='12px'>
              <Tooltip label='Create New Operator' fontSize='sm'>
                <Button
                  w='170px'
                  leftIcon={<IoMdAdd />}
                  onClick={onMccCreateOpen}
                  style={buttonStyle}
                >
                  Create Mcc
                </Button>
              </Tooltip>
            </Flex>
            <DownloadToExcel
              cellsData={
                0 === _QueryMccRead?.data?.MccRead?.length || !_.every(_.pluck(_QueryMccRead?.data?.MccRead, 'status'), i =>
                  _successFlags?.includes(i)) ? [] : _QueryMccRead?.data?.MccRead.map((item, __index) => ({
                    'S.No.': `${__index + (skipDifference * skipPage) + 1}.`,
                    'Dialing Code': item.dialingCode ?? '-',
                    'Mcc': item?.mcc ?? '-',
                    'Country': item?.countryName ?? '-',
                    'MinOperatorPrefixLength':
                      item?.minOperatorPrefixLength ?? '-',
                    'MaxOperatorPrefixLength':
                      item?.maxOperatorPrefixLength ?? '-',
                    'CreatedAt': Moment(item?.createdAt).format('YYYY-MM-DD'),
                    'UpdatedAt': Moment(item?.updatedAt).format('YYYY-MM-DD')
                  }))
              }
              headersData={[
                'S.No.',
                'Dialing Code',
                'Mcc',
                'Country',
                'MinOperatorPrefixLength',
                'MaxOperatorPrefixLength',
                'CreatedAt',
                'UpdatedAt'
              ].map(i => ({ 'key': i, 'label': i }))}
            />
          </Flex>
          <TableContainer
            flex={1}
            display='flex'
            borderRadius='15px'
            outline='1px solid #C5CFE8'
          >
            <Table variant='simple' size='sm'>
              <Thead ref={_tableHeaderHeightRef}>
                <Tr
                  style={{
                    ...headerStyle,
                    'position': 'sticky',
                    'top': 0,
                    'zIndex': 1
                  }}
                >
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'
                  >
                    S.No
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'
                  >
                    Dialing Code
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'
                  >
                    Mcc
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'
                  >
                    Country
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'
                  >
                    MinOperatorPrefixLength
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'
                  >
                    MaxOperatorPrefixLength
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'
                  >
                    CreatedAt
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'
                  >
                    UpdatedAt
                  </Td>
                  <Td
                    style={rowStyle}
                  >
                    Actions
                  </Td>
                </Tr>
                <Tr>
                  <Td borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'
                  >
                    <MemoizedInput
                      placeholder='Search'
                      className='filter searchInput'
                      defaultValue={dialingCodeToSearch}
                      onChange={_onDialingCodeToSearchInputChange}
                    />
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'
                  >
                    <MemoizedInput
                      placeholder='Search'
                      className='filter searchInput'
                      defaultValue={mccToSearch}
                      onChange={_onMccToSearchInputChange}
                    />
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'
                  >
                    <MemoizedInput
                      placeholder='Search'
                      className='filter searchInput'
                      defaultValue={countryNameToSearch}
                      onChange={_onCountryNameToSearchInputChange}
                    />
                  </Td>
                  <Td borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td borderRight='1px solid rgba(216, 227, 252, 1)'> </Td>
                  <Td borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td borderRight='1px solid rgba(216, 227, 252, 1)'> </Td>
                  <Td></Td>
                </Tr>
              </Thead>
              {_QueryMccRead.loading && !_isFirstLoadCompleted.current ? (
                <TableSpinner
                  isLoading={true}
                  chopHeightFromHundredPercentage={
                    _tableHeaderHeightRef?.current?.clientHeight
                  }
                />
              ) : !_isFirstLoadCompleted.current && (0 === _QueryMccRead.data?.MccRead?.length || !_.every(
                _.pluck(_QueryMccRead.data?.MccRead, 'status'),
                i => 'READ_SUCCESSFUL' === i
              )) ? (
                <TableSpinner
                  isLoading={false}
                  isEmpty={true}
                  chopHeightFromHundredPercentage={
                    _tableHeaderHeightRef?.current?.clientHeight
                  }
                />
              ) : (
                <Tbody style={cellStyle}>
                  {_QueryMccRead.data?.MccRead?.map(
                    (
                      {
                        id,
                        createdAt,
                        updatedAt,
                        mcc,
                        countryName,
                        dialingCode,
                        minOperatorPrefixLength,
                        maxOperatorPrefixLength
                      },
                      __index
                    ) => (
                      <Tr key={String.random(9)}>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'
                        >
                          {`${__index + (skipDifference * skipPage) + 1}.`}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'
                        >
                          {dialingCode ?? '-'}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'
                        >
                          {mcc ?? '-'}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'
                        >
                          {countryName ?? '-'}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'
                        >
                          {minOperatorPrefixLength ?? '-'}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'
                        >
                          {maxOperatorPrefixLength ?? '-'}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'
                        >
                          {Moment(createdAt).format('YYYY-MM-DD')}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'
                        >
                          {Moment(updatedAt).format('YYYY-MM-DD')}
                        </Td>
                        <Td style={rowStyle}>
                          {' '}
                          {
                            <MccDropDownOptions
                              mccId={id}
                              onMccUpdate={onMccUpdateOpen}
                              onMccDelete={onMccDeleteOpen}
                            />
                          }
                        </Td>
                      </Tr>
                    )
                  )}
                </Tbody>
              )}
            </Table>
          </TableContainer>
        </Flex>
        <Modal
          size='md'
          title='Mcc Create'
          isOpen={isMccCreateOpen}
          onClose={onMccCreateClose}>
          <MccUpsert isCreateOnly={true} />
        </Modal>
        <Modal
          size='md'
          title='Mcc Update'
          isOpen={isMccUpdateOpen}
          onClose={onMccUpdateClose}>
          <MccUpsert />
        </Modal>
        <Modal
          size='md'
          title='Mcc Delete'
          isOpen={isMccDeleteOpen}
          onClose={onMccDeleteClose}>
          <MccDelete />
        </Modal>
      </Flex>
      <PaginationWithPageInformation
        skipPage={skipPage}
        skipDifference={_skipDifference}
        setSkipDifference={setSkipDifference}
        itemsPerPage={_skipDifference}
        totalCount={_.pluck(_QueryMccRead.data?.MccRead, '_totalCount')?.[0]}
        onPageChange={setSkipPage}
      />
    </>
  )
}

/*
 * PROPTYPES
 */
Index.propTypes = {}


/*
 * EXPORTS
 */
export default Index
