/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation SenderIdDirectoryUpsertMutation($senderIdDirectoryId: ID, $displayName: String) {
  SenderIdDirectoryUpsert(senderIdDirectoryId: $senderIdDirectoryId, displayName: $displayName) {
    id
    message
    status
  }
}
`
