/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation AccountUpdateMutation(
  $accountId: ID!,
  $password: String,
) {
  AccountSetting(
    accountId: $accountId,
    password: $password
  ) {
    message
    status
  }
}
`
