/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import JoiBrowser from 'joi-browser' // Npm: Joi for frontend validation.
import _ from 'underscore' // Npm: Underscore.js library.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useLazyQuery, useMutation } from '@apollo/client' // Npm: Apollo client.
import { Flex } from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import SubmitButton from 'components/SubmitButton'
import { MemoizedInput } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import EntityReadQuery from './__query__/index.entity.read.query'
import EntityUpdateMutation from './__mutation__/index.entity.update.mutation'
import EntityCreateMutation from './__mutation__/index.entity.create.mutation'


/*
 * OBJECTS
 */
const Index = ({ isOpen, isCreateOnly, onClose, passOn }) => {
  // Hook assignment.
  const [error, setError] = React.useState('')
  const [forceReRender, setForceReRender] = React.useState('')
  const [QueryEntityRead, QueryEntityReadResponse] = useLazyQuery(EntityReadQuery, { 'variables': { 'entityId': passOn?.entityId } })
  const [MutationEntityCreate, MutationEntityCreateResponse] = useMutation(EntityCreateMutation)
  const [MutationEntityUpdate, MutationEntityUpdateResponse] = useMutation(EntityUpdateMutation)
  const _formDataRef = React.useRef({})

  // Object assignment.
  const _SubmitForm = async e => {
    // Prevent default behavior.
    e?.preventDefault()

    // Reset error.
    setError('')

    // Const assignment.
    const _JoiSchema = JoiBrowser.object({
      'displayName': JoiBrowser.string().required(),
      'address': JoiBrowser.string().allow('').optional(),
      'email': JoiBrowser.string().email().allow('').optional(),
      'logo': JoiBrowser.string().uri().allow('').optional(),
      'prefix': JoiBrowser.string().allow('').optional(),
      'bankDetails': JoiBrowser.string().allow('').optional(),
      'disclaimer': JoiBrowser.string().allow('').optional()
    }).options({ 'allowUnknown': true })

    // Remove all keys from _formDataRef.current which are undefined.
    _formDataRef.current = _.pick(_formDataRef.current, _.identity)

    // Validate form data.
    const _JoiSchemaValidate = _JoiSchema.validate(_formDataRef.current)

    // If error exists then report failure.
    if (_JoiSchemaValidate.error) return setError(_JoiSchemaValidate.error?.message)

    /*
     * Update _formDataRef with entityId
     * if it is not available on _formDataRef.
     * also make sure that mode is not create only.
     */
    if (!isCreateOnly) _formDataRef.current = { ..._formDataRef?.current, 'id': passOn?.entityId }

    // Execute update mutation.
    const _MutationEntityUpdate = await [isCreateOnly ? MutationEntityCreate : MutationEntityUpdate]?.[0]({ 'variables': { 'input': _.omit(_formDataRef?.current, isCreateOnly ? 'id' : void 0, 'entityId') } })

    // If mutation caught an exception then report failure.
    if (_MutationEntityUpdate instanceof Error) return _MutationEntityUpdate

    // Style Guide.
    toast(_.first(_MutationEntityUpdate?.data?.EntityUpdate)?.message ?? _.first(_MutationEntityUpdate?.data?.EntityCreate)?.message)

    /*
     * Update error if mutation execution contains
     * status.
     */
    if ('UPDATE_SUCCESSFUL' === _.first(_MutationEntityUpdate?.data?.EntityUpdate)?.status) return onClose?.()
    if ('CREATE_SUCCESSFUL' === _.first(_MutationEntityUpdate?.data?.EntityCreate)?.status) return onClose?.()

    // Report void.
    return void 0
  }

  // Event handler.
  React.useEffect(() => {
    // _Async handler.
    const _Async = async () => {
      // Const assignment.
      const _QueryEntityReadQuery = await QueryEntityRead({ 'variables': { 'entityId': isCreateOnly ? 'UN_KNOWN' : passOn?.entityId } })

      // If query caught an exception then report failure.
      if (_QueryEntityReadQuery instanceof Error) return _QueryEntityReadQuery

      /*
       * If entity details fetch complete then
       * update its value.
       */
      if (_.first(_QueryEntityReadQuery?.data?.EntityRead)) {
        // Update form data.
        _formDataRef.current = {
          'entityId': passOn?.entityId,
          'displayName': _.first(_QueryEntityReadQuery?.data?.EntityRead)?.displayName,
          'address': _.first(_QueryEntityReadQuery?.data?.EntityRead)?.address,
          'email': _.first(_QueryEntityReadQuery?.data?.EntityRead)?.email,
          'logo': _.first(_QueryEntityReadQuery?.data?.EntityRead)?.logo,
          'prefix': _.first(_QueryEntityReadQuery?.data?.EntityRead)?.prefix,
          'bankDetails': _.first(_QueryEntityReadQuery?.data?.EntityRead)?.bankDetails,
          'disclaimer': _.first(_QueryEntityReadQuery?.data?.EntityRead)?.disclaimer
        }

        // Update state.
        return setForceReRender(String.random(8))
      }

      // Report failure.
      return void 0
    }; _Async()
  }, [passOn, isOpen])

  // Const assignment.
  const _isLoading = MutationEntityCreateResponse.loading || MutationEntityUpdateResponse.loading
  const _isInputDisabled = isCreateOnly ? false : _isLoading || QueryEntityReadResponse?.loading

  // Return component.
  return (
    <form onSubmit={_SubmitForm} key={forceReRender}>
      <Flex gap='22px' flexDir='column' w='100%'>
        <Flex w='100%' gap='22px' flexDir={{ 'base': 'column', 'md': 'row' }}>
          <MemoizedInput
            disabled={_isInputDisabled}
            name='displayName'
            label='Display Name'
            placeholder='Enter Display Name'
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: value
              }
            }}
            isInvalid={error?.includes('displayName')}
            error={error}
            isRequired={true}
            data={_formDataRef?.current?.displayName}
          />
          <MemoizedInput
            disabled={_isInputDisabled}
            name='address'
            label='Address'
            placeholder='Enter Address'
            error={error}
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: value
              }
            }}
            isInvalid={error?.includes('address')}
            data={_formDataRef?.current?.address}
          />
        </Flex>
        <Flex w='100%' gap='22px' flexDir={{ 'base': 'column', 'md': 'row' }}>
          <MemoizedInput
            disabled={_isInputDisabled}
            name='email'
            label='Email'
            placeholder='Enter Email'
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: value
              }
            }}
            isInvalid={error?.includes('email')}
            error={error}
            data={_formDataRef?.current?.email}
          />
          <MemoizedInput
            disabled={_isInputDisabled}
            name='logo'
            label='Logo (Base64 encoded)'
            placeholder='e.g. https://example.com/logo.png'
            error={error}
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: value
              }
            }}
            isInvalid={error?.includes('logo')}
            data={_formDataRef?.current?.logo}
          />
        </Flex>
        <MemoizedInput
          disabled={_isInputDisabled}
          name='prefix'
          label='Prefix'
          placeholder='e.g. Special Invoice'
          error={error}
          onChange={({ target }) => {
            // Over spreading.
            const { name, value } = target

            // Update form data.
            _formDataRef.current = {
              ..._formDataRef?.current,
              [name]: value
            }
          }}
          isInvalid={error?.includes('prefix')}
          data={_formDataRef?.current?.prefix}
        />
        <MemoizedInput
          disabled={_isInputDisabled}
          name='bankDetails'
          label='Bank Details'
          placeholder='e.g. "China, Bank of China, 1234567890"'
          onChange={({ target }) => {
            // Over spreading.
            const { name, value } = target

            // Update form data.
            _formDataRef.current = {
              ..._formDataRef?.current,
              [name]: value
            }
          }}
          isInvalid={error?.includes('bankDetails')}
          error={error}
          data={_formDataRef?.current?.bankDetails}
        />
        <MemoizedInput
          disabled={_isInputDisabled}
          name='disclaimer'
          label='Disclaimer'
          placeholder='e.g. "NOTE: This is something special"'
          onChange={({ target }) => {
            // Over spreading.
            const { name, value } = target

            // Update form data.
            _formDataRef.current = {
              ..._formDataRef?.current,
              [name]: value
            }
          }}
          isInvalid={error?.includes('disclaimer')}
          error={error}
          data={_formDataRef?.current?.disclaimer}
        />
      </Flex>
      <SubmitButton
        disabled={_isInputDisabled}
        onSubmit={_SubmitForm}
        isLoading={_isLoading}
        defaultText={isCreateOnly ? 'Create Entity' : 'Update Entity'}
      />
    </form>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'isCreateOnly': PropTypes.bool,
  'isOpen': PropTypes.bool,
  'onClose': PropTypes.func,
  'passOn': PropTypes.object
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
