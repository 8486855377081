/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation BilateralCreateMutation($customerId: ID!, $vendorId: ID!) {
  BilateralCreate(customerId: $customerId, vendorId: $vendorId) {
    message,
    status
  }
}
`
