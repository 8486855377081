/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
    query PhoneNumberoReadQuery($phoneNumberoDirectoryId: ID, $phoneNumberoToSearch: String, $take: PositiveInt, $skip: Int) {
      PhoneNumberoRead(phoneNumberoDirectoryId: $phoneNumberoDirectoryId, phoneNumberoToSearch: $phoneNumberoToSearch, take: $take, skip: $skip) {
        id,
        createdAt,
        updatedAt,
        phoneNumbero,
        status,
        message,
        _totalCount
    }
  }
`
