/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation RouteUpdateMutation($routeId: ID!, $type: CUSTOMER_TYPE, $displayName: String) {
  RouteUpdate(routeId: $routeId, type: $type, displayName: $displayName) {
    message
    status
  }
}
`
