/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query VendorAccountReadQuery($vendorId: ID!) {
  VendorAccountRead(vendorId: $vendorId) {
    id,
    displayName
  }
}
`
