/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import {
  Flex,
  Text,
  useBreakpointValue
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import Clock from 'components/Clock'
import VendorAccountConnected from 'components/VendorAccountConnected'
import PlatformMargin from 'components/PlatformMargin'
import CustomerAccountConnected from 'components/CustomerAccountConnected'
import Map from 'components/MapCard'


/*
 * EXPORTS
 */
const Index = () => {
  // Const statement.
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })
  const _isCurrentViewTablet = useBreakpointValue({ 'base': 'false', 'md': true, 'lg': false, 'xl': false, 'sm': false, 'xs': false })
  const _isCurrentViewLargeScreen = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': true, 'xl': false, 'sm': false, 'xs': false })

  // Return component.
  return (
    <Flex marginTop={_isCurrentViewMobile && !_isCurrentViewTablet ? '680px' : _isCurrentViewTablet && !_isCurrentViewLargeScreen ? '390px' : _isCurrentViewLargeScreen ? '420px' : '240px'} direction={{ 'base': 'column' }} fontFamily='DM Sans' >
      <Flex flexDir={_isCurrentViewMobile || _isCurrentViewTablet ? 'column' : 'row'} gap={_isCurrentViewMobile ? '12px' : '22px'} mb={_isCurrentViewMobile ? '12px' : '22px'}>
        <Flex flex={_isCurrentViewLargeScreen ? 0.5 : 0.6}>
          <Clock />
        </Flex>
        <Flex flexDir='column' flex={_isCurrentViewLargeScreen ? 0.5 : 0.4} gap='22px'>
          <PlatformMargin />
          <Map />
        </Flex>
      </Flex>
      <Flex flexDir='column'>
        <Text
          textColor='#2B3674'
          fontWeight='bold'
          fontFamily='Poppins'
          fontSize={['clamp(20px, 3vw, 40px)']}
          lineHeight='40px'
          letterSpacing='0.48px'
          py='20px'
          pl='20px'>
          Monitoring
        </Text>
        <Flex mt={_isCurrentViewMobile ? '12px' : '22px'} flexDir={_isCurrentViewMobile || _isCurrentViewTablet ? 'column' : 'row'} gap={_isCurrentViewMobile ? '12px' : '22px'}>
          <Flex flex={0.5}>
            <VendorAccountConnected
              title='CONNECTED VENDOR ACCOUNTS'
              isConnect={true}
            />
          </Flex>
          <Flex flex={0.5}>
            <VendorAccountConnected
              title='NOT CONNECTED VENDOR ACCOUNTS'
              isConnect={false}
            />
          </Flex>

        </Flex>
      </Flex>
      <Flex mt={_isCurrentViewMobile ? '12px' : '22px'} flexDir={_isCurrentViewMobile || _isCurrentViewTablet ? 'column' : 'row'} gap={_isCurrentViewMobile ? '12px' : '22px'}>
        <Flex flex={0.5}>
          <CustomerAccountConnected
            title='CONNECTED CUSTOMER ACCOUNTS'
            isConnect={true}
          />
        </Flex>
        <Flex flex={0.5}>
          <CustomerAccountConnected
            title='NOT CONNECTED CUSTOMER ACCOUNTS'
            isConnect={false}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

/*
 * EXPORTS
 */
export default Index
