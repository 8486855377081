/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.




/*
 * GRAPHS
 */

const Index = gql`
    mutation AccountLogoutMutation {
        AccountLogout {
            message
            status
        }
    }
`


/*
 * EXPORTS
 */
export default Index
