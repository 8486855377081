/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query CustomerReadQuery($search: String, $customerType: CUSTOMER_TYPE, $smsStartDate: DateTime!, $smsEndDate: DateTime!, $take: PositiveInt, $skip: Int) {
  CustomerRead(search: $search, customerType: $customerType, smsStartDate: $smsStartDate, smsEndDate: $smsEndDate, take: $take, skip: $skip) {
    id,
    createdAt,
    displayName,
    message,
    phone,
    status,
    country,
    type,
    _smsCountForGivenCustomer,
    _totalCount,
    AccountManager {
      displayName
    },
    Company {
      displayName,
    }
    Billing {
      currency
    }
  }
}
`
