/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query VendorTrafficQuery($vendorId: ID!, $endDate: DateTime!, $startDate: DateTime!) {
  VendorTraffic(vendorId: $vendorId, endDate: $endDate, startDate: $startDate) {
    id,
    status,
    message,
    _report
  }
}
`
