/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query CustomerReadAccountManagerQuery($customerId: ID!) {
  CustomerReadAccountManager(customerId: $customerId) {
    id,
    displayName,
    message,
    status,
    AccountManager {
      id,
      displayName
    }
  }
}
`
