/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query SmsReadQuery($vendorId: ID, $endDate: DateTime!, $startDate: DateTime!, $customerAccountSmppNameToSearch: String, $countryNameToSearch: String, $mccToSearch: String, $mncToSearch: String, $vendorNameToSearch: String, $mobileNumberToSearch: String, $dlrStatusToSearch: SMS_STATUS, $smsIdToSearch: String, $vendorSmsIdToSearch: String, $senderIdToSearch: String, $take: PositiveInt, $skip: Int) {
  SmsRead(vendorId: $vendorId, endDate: $endDate, startDate: $startDate, customerAccountSmppNameToSearch: $customerAccountSmppNameToSearch, countryNameToSearch: $countryNameToSearch, mccToSearch: $mccToSearch, mncToSearch: $mncToSearch, vendorNameToSearch: $vendorNameToSearch, dlrStatusToSearch: $dlrStatusToSearch, mobileNumberToSearch: $mobileNumberToSearch, smsIdToSearch: $smsIdToSearch, vendorSmsIdToSearch: $vendorSmsIdToSearch, senderIdToSearch: $senderIdToSearch, take: $take, skip: $skip) {
    id,
    createdAt,
    submittedAt,
    updatedAt,
    receiptId,
    sourceAddress,
    destinationAddress,
    registeredDelivery,
    submittedAt,
    originalCopyOfMessage,
    error,
    dlr,
    transformedSourceAddress,
    isFakeDlr,
    deliveredThrough,
    isUnicodeMessage,
    messageLength,
    messageChunkSize,
    message,
    status,
    persisted__customerrateplan,
    persisted__customer,
    persisted__routeplan,
    persisted__vendorsmpp,
    persisted__vendorrateplan,
    persisted__customersmpp,
    persisted__hlrlookup,
    _totalCount
  }
}
`
