/*
 * EXPORTS
 */
export const tabStyles = {
  'textAlign': 'center',
  'fontFamily': 'DM Sans',
  'fontSize': '16px',
  'fontStyle': 'normal',
  'fontWeight': 600,
  'lineHeight': '24px',
  'letterSpacing': '-0.48px'
}
export const rowStyle = {
  'fontWeight': '500',
  'fontSize': 'Clamp(13px, 1.5vw, 15px)',
  'textAlign': 'center',
  'minWidth': '240px',
  'maxWidth': '240px',
  'overflow': 'auto'
}
export const headerStyle = {
  'fontFamily': 'DM Sans',
  'fontWeight': '600',
  'fontSize': '17px',
  'height': '50px',
  'color': '#8D97B5',
  'backgroundColor': '#F4F7FD',
  'textAlign': 'center'
}
export const cellStyle = {
  'fontFamily': 'Dm Sans',
  'color': 'rgba(43, 54, 116, 1)',
  'fontWeight': '700',
  'fontSize': '18px',
  'lineHeight': '24px',
  'letterSpacing': '-0.36px'
}
export const buttonStyle = {
  'backgroundColor': 'rgba(244, 247, 254, 0.35)',
  'color': '#FFF',
  'fontFamily': 'Dm Sans',
  'fontWeight': '500',
  'fontSize': '16px',
  'lineHeight': '32px',
  'letterSpacing': '-0.22px',
  'borderRadius': '10px'
}

