/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query VendorAccountReadQuery($vendorId: ID, $vendorAccountNameToSearch: String, $vendorNameToSearch: String, $rateNameToSearch: String, $take: PositiveInt, $skip: Int) {
  VendorAccountRead(vendorId: $vendorId, vendorAccountNameToSearch: $vendorAccountNameToSearch, vendorNameToSearch: $vendorNameToSearch, rateNameToSearch: $rateNameToSearch, take: $take, skip: $skip) {
    id
    createdAt
    updatedAt
    displayName
    status
    message
    _totalCount
    Rate {
      displayName
      currency
      RatePlan {
        rate
      }
    }
    RoutePlan {
      id,
      Route {
        id
        displayName
      }
    }
    Vendor {
      displayName,
      Billing {
        email
        currency
      }
    }
    Smpp {
      id
      isActive
    }
  }
}
`
