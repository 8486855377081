/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { Flex } from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import VendorMessageReport from 'pages/Reports/Message'
import VendorTrafficReport from 'components/VendorTrafficReport'
import VendorBillingReport from 'components/VendorBillingReport'


/*
 * OBJECTS
 */
const Index = ({ passOn }) => (
  <Flex flexDir='column' w='100%' gap='22px' borderRadius='22px' pb='42px'>
    <Flex w='100%' gap='22px' flexDir={{ 'base': 'column', 'md': 'row' }}>
      <Flex flexDir='column' w={{ 'base': '100%', 'md': '50%' }} h='100%'>
        <VendorTrafficReport />
      </Flex>
      <Flex w={{ 'base': '100%', 'md': '50%' }}>
        <VendorBillingReport />
      </Flex>
    </Flex>
    <VendorMessageReport vendorId={passOn.vendorId} />
  </Flex>
)



/*
 * PROPTYPES
 */
Index.propTypes = {
  'passOn': PropTypes.object.isRequired
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)