/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import JoiBrowser from 'joi-browser' // Npm: Joi for frontend validation.
import _ from 'underscore' // Npm: Underscore.js library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { useLazyQuery, useMutation } from '@apollo/client' // Npm: Apollo client.


/*
 * PACKAGES
 */
import SubmitButton from 'components/SubmitButton'
import { MemoizedInput } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import KeywordReadUniqueQuery from './__query__/index.keyword.read.query'
import KeywordUpsertMutation from './__mutation__/index.keyword.upsert.mutation'


/*
 * OBJECTS
 */
const Index = ({ isOpen, isCreateOnly, onClose, passOn }) => {
  // Hook assignment.
  const [error, setError] = React.useState('')
  const [forceReRender, setForceReRender] = React.useState('')
  const [QueryKeywordReadUnique, QueryKeywordReadUniqueResponse] = useLazyQuery(KeywordReadUniqueQuery, { 'variables': { 'keywordId': passOn?.keywordId } })
  const [MutationKeywordUpsert, MutationKeywordUpsertResponse] = useMutation(KeywordUpsertMutation)
  const _formDataRef = React.useRef({})

  // Object assignment.
  const _SubmitForm = async e => {
    // Prevent default behavior.
    e.preventDefault()

    // Reset error.
    setError('')

    // Const assignment.
    const _JoiSchema = JoiBrowser.object({
      'keyword': JoiBrowser.string().required()
    }).options({ 'allowUnknown': true })

    // Remove all keys from _formDataRef.current which are undefined.
    _formDataRef.current = _.pick(_formDataRef.current, _.identity)

    // Validate form data.
    const _JoiSchemaValidate = _JoiSchema.validate(_formDataRef.current)

    // If error exists then report failure.
    if (_JoiSchemaValidate.error) return setError(_JoiSchemaValidate.error?.message)

    // Execute account registration mutation.
    const _MutationKeywordUpsert = await MutationKeywordUpsert({
      'variables': {
        'keywordDirectoryId': isCreateOnly ? passOn?.keywordDirectoryId : void 0,
        'keyword': _formDataRef?.current?.keyword,
        'keywordId': isCreateOnly ? void 0 : passOn?.keywordId
      }
    })

    // If mutation caught an exception then report failure.
    if (_MutationKeywordUpsert instanceof Error) return _MutationKeywordUpsert

    // On Successful response from the mutation.
    if ('UPSERT_SUCCESSFUL' === _MutationKeywordUpsert?.data?.KeywordUpsert?.status) onClose?.()

    // Style Guide.
    return toast(_MutationKeywordUpsert?.data?.KeywordUpsert?.message)
  }

  // Event handler.
  React.useEffect(() => {
    // _Async handler.
    const _Async = async () => {
      // Const assignment.
      const _QueryKeywordReadUniqueQuery = await QueryKeywordReadUnique({ 'variables': { 'keywordId': isCreateOnly ? 'UN_KNOWN' : passOn?.keywordId } })

      // If query caught an exception then report failure.
      if (_QueryKeywordReadUniqueQuery instanceof Error) return _QueryKeywordReadUniqueQuery

      /*
       * If customer details fetch complete then
       * update its value.
       */
      if (_QueryKeywordReadUniqueQuery?.data?.KeywordReadUnique) {
        // Update form data.
        _formDataRef.current = {
          'keywordId': passOn?.keywordId,
          'keyword': _QueryKeywordReadUniqueQuery?.data?.KeywordReadUnique?.keyword
        }

        // Update state.
        return setForceReRender(String.random(8))
      }

      // Report failure.
      return void 0
    }; _Async()
  }, [passOn, isOpen])

  // Const assignment.
  const _isLoading = MutationKeywordUpsertResponse.loading
  const _isInputDisabled = isCreateOnly ? false : _isLoading || QueryKeywordReadUniqueResponse?.loading

  // Return component.
  return (
    <form style={{ 'width': '100%' }} onSubmit={_SubmitForm} key={forceReRender}>
      <MemoizedInput
        disabled={_isInputDisabled}
        isRequired={true}
        name='keyword'
        label='Keyword'
        placeholder='e.g. "@V1 is your Otp"'
        onChange={({ target }) => {
          // Over spreading.
          const { name, value } = target

          // Update form data.
          _formDataRef.current = {
            ..._formDataRef?.current,
            [name]: value
          }
        }}
        error={error}
        isInvalid={error?.includes('keyword')}
        data={_formDataRef?.current?.keyword}
      />
      <SubmitButton
        onSubmit={_SubmitForm}
        isLoading={_isLoading}
        disabled={_isInputDisabled}
        defaultText={isCreateOnly ? 'Create Keyword' : 'Update Keyword'} />
    </form>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'isCreateOnly': PropTypes.bool,
  'isOpen': PropTypes.bool,
  'onClose': PropTypes.func,
  'passOn': PropTypes.object
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
