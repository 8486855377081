/*
 * IMPORTS
 */
import 'react-date-range/dist/styles.css' // Npm: React date range.
import 'react-date-range/dist/theme/default.css' // Npm: React date range.
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import _ from 'underscore' // NPM: Underscore.js library.
import { toast } from 'react-hot-toast' // Npm: React toast library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useMutation, useQuery } from '@apollo/client' // Npm: Apollo client.
import { HiFire, HiMiniMinusCircle } from 'react-icons/hi2' // Npm: React icons.
import { Button, Flex, Spinner, Text } from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import { MemoizedInput } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import NotifyClearMutation from './__mutation__/index.notify.clear.mutation'
import NotifyCustomerMutation from './__mutation__/index.notify.customer.mutation'
import NotifyReadQuery from './__query__/index.notify.read.query'


/*
 * OBJECTS
 */
const Index = ({ passOn, onClose }) => {
  // Hook assignment.
  const [MutationNotifyClear, MutationNotifyClearResponse] = useMutation(NotifyClearMutation)
  const [MutationNotifyCustomer, MutationNotifyCustomerResponse] = useMutation(NotifyCustomerMutation)
  const _NotifyReadQuery = useQuery(NotifyReadQuery, { 'variables': { 'customerId': passOn?.customerId }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })
  const _formDataRef = React.useRef({})

  // Return component.
  return (
    <Flex w='100%' flexDir='column' gap='22px'>
      <Text w='100%' as='p' fontSize='17px' color='gray.500'>Enter your message for given customer, they will going to be notified once they logged in.</Text>
      <Flex
        w='100%'
        borderRadius={12}
        overflow='hidden'
        position='relative'
        flexDir='row'
        border='1px solid var(--chakra-colors-brand-500)'
        alignItems='center'
        gap='12px'>
        <Flex flex={1} gap='22px' justifyContent='space-between'>
        <MemoizedInput
          padding='12px'
          defaultValue={'READ_SUCCESSFUL' === _NotifyReadQuery?.data?.NotifyRead?.status ? _NotifyReadQuery?.data?.NotifyRead?.message : void 0}
          placeholder='e.g. Kindly, Clear your dues'
          onChange={e => _formDataRef.current.message = e.target.value}
          bg='rgba(255, 255, 255, 0.33)'
          border='none'
          color='gray.500'
          disabled={_NotifyReadQuery?.loading}
          _focus={{ 'border': 'none', 'outline': 'none' }}
          _active={{ 'border': 'none', 'outline': 'none' }}
        />
        </Flex>
        <Button
          px='12px'
          h='40px'
          gap='6px'
          borderRadius={0}
          borderLeft='1px solid var(--chakra-colors-brand-500)'
          bg='brand.500'
          color='white'
          _hover={{ 'bg': 'brand.500' }}
          onClick={() => { _.isEmpty(_formDataRef?.current?.message) ? toast.error('Kindly add message') : MutationNotifyCustomer({ 'variables': { 'customerId': passOn?.customerId, 'message': _formDataRef?.current?.message } })?.then(e => { toast(e?.data?.NotifyCustomer?.message); onClose() }) }}
          _active={{ 'bg': 'brand.500' }}
          disabled={_NotifyReadQuery?.loading}>
          {MutationNotifyCustomerResponse?.loading ? <Spinner size='sm' /> : <HiFire size={19} />}
          Notify
        </Button>
      </Flex>
      <Button
        bg='brand.100'
        variant='outline'
        color='brand.500'
        gap='6px'
        onClick={() => { MutationNotifyClear({ 'variables': { 'customerId': passOn?.customerId } })?.then(e => { toast(e?.data?.NotifyClear?.message); onClose() }) }}>
        {MutationNotifyClearResponse?.loading ? 'Clearing...' : 'Clear Notifications'}{MutationNotifyClearResponse?.loading ? <Spinner size='sm' /> : <HiMiniMinusCircle size={19} />}
      </Button>
    </Flex>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'passOn': PropTypes.object.isRequired
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
