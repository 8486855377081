/*
 * IMPORTS
 */
import 'react-date-range/dist/styles.css' // Npm: React date range.
import 'react-date-range/dist/theme/default.css' // Npm: React date range.
import React from 'react' // Npm: react.js library.
import _ from 'underscore' // Npm: Utility module.
import { useQuery } from '@apollo/client' // Npm: Apollo client.
import { HiMiniArrowSmallDown, HiMiniArrowSmallUp, HiMiniChartPie, HiMiniChevronUpDown } from 'react-icons/hi2' // Npm: React icons.
import { Flex, Text, useBreakpointValue } from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import DateRangePicker from 'components/DateRangePicker'


/*
 * GRAPHS
 */
import MccReadSmsQuery from './__query__/index.mcc.readSms.query'


/*
 * OBJECTS
 */
const Index = () => {
  // Hook assignment.
  const [dateRange, setDateRange] = React.useState({
    'selection': {
      'startDate': Object.DateRangeProvider()?.startDate,
      'endDate': Object.DateRangeProvider()?.endDate,
      'key': 'selection'
    }
  })
  const _MccReadSmsQuery = useQuery(MccReadSmsQuery, { 'variables': { 'startDate': dateRange?.selection?.startDate, 'endDate': dateRange?.selection?.endDate }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })

  // Return component.
  return (
    <Flex
      w='100%'
      borderRadius='20px'
      p={_isCurrentViewMobile ? '12px' : '22px'}
      gap='12px'
      background='linear-gradient(-223deg, #BCC0FF 0%, #FFDBE7 60.69%, #FFEED4 99.80%)'
      WebkitBackgroundClip='text'
      WebkitTextFillColor='transparent'
      flexDir='column'
      justify='space-between'
      boxShadow='14px 17px 40px 4px rgba(112, 144, 176, 0.17)'>
      <Flex justify='space-between'>
        <Flex gap='6px' alignItems='center'><HiMiniChartPie size='21px' color='black' /><Text as='h2' fontSize='xl' color='black'>Platform Chart</Text></Flex>
        <DateRangePicker
          startDate={dateRange?.selection?.startDate}
          endDate={dateRange?.selection?.endDate}
          w='100%'
          fontSize='13px'
          height='max-content'
          gap='6px'
          bg='white'
          setSelectDate={setDateRange} />
      </Flex>
      <Flex w='100%' alignItems='center' justifyContent='space-between' p='12px'>
        <Flex alignItems='center' gap='12px'>
          <HiMiniChevronUpDown size='21px' color='black' />
          <Flex flexDir='row' gap='6px'>
            <Text as='h5' fontSize='md' color='gray.800'>{_MccReadSmsQuery?.data?.MccReadSms?._report?.businessmargin ?? 0}</Text>
            <Text as='h5' fontSize='md' color='gray.600' fontWeight='bold'>Margin</Text>
          </Flex>
        </Flex>
        <Flex alignItems='center' gap='12px'>
          <HiMiniArrowSmallDown size='21px' color='black' />
          <Flex flexDir='row' gap='6px'>
            <Text as='h5' fontSize='md' color='gray.800'>{_MccReadSmsQuery?.data?.MccReadSms?._report?.businessrevenue ?? 0}</Text>
            <Text as='h5' fontSize='md' color='gray.600' fontWeight='bold'>Revenue</Text>
          </Flex>
        </Flex>
        <Flex alignItems='center' gap='12px'>
          <HiMiniArrowSmallUp size='21px' color='black' />
          <Flex flexDir='row' gap='6px'>
            <Text as='h5' fontSize='md' color='gray.800'>{_MccReadSmsQuery?.data?.MccReadSms?._report?.businesscost ?? 0}</Text>
            <Text as='h5' fontSize='md' color='gray.600' fontWeight='bold'>Cost</Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {}


/*
 * EXPORT
 */
export default Index
