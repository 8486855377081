/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation VendorAccountAttachRateMutation($vendorAccountId: ID!, $rateId: ID!) {
  VendorAccountAttachRate(vendorAccountId: $vendorAccountId, rateId: $rateId ) {
    message
    status
  }
}
`
