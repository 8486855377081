/*
 * IMPORTS
 */
import React from 'react' // Npm: React LIBRARY.
import PropTypes from 'prop-types' // Npm: Prop types for React.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import {
  Box,
  Flex,
  Stack,
  Text,
  useColorModeValue
} from '@chakra-ui/react' // Npm: Chakra UI for design system.


/*
 * PACKAGES
 */
import PlatformLogo from 'components/PlatformLogo'


/*
 * SIBLINGS
 */
import Links from './Links'
import { HSeparator } from './Separator'


/*
 * OBJECTS
 */
const Index = ({ routes, account, platform }) => {
  // Hook assignment.
  const _textColorRef = useColorModeValue('navy.700', 'white')

  // Return component.
  return (
    <Flex
      direction='column'
      minH='100%'
      height='max-content'
      borderRadius='20px'
      mb='56px'>
      <Flex mt='35px' mb='36px' justifyContent='center' alignItems='center' flexDirection='column'>
        <PlatformLogo />
        <Box mt='12px'>
          <Text position='relative' top='2.5px' color={_textColorRef} fontSize='lg' fontWeight='400' textAlign='center'>
            {account?.displayName}
          </Text>
          <Text position='relative' top='-2.5px' color='brand.500' fontWeight='600' fontSize='15px' textAlign='center'>
            {platform?.displayName ?? account?.email}
          </Text>
        </Box>
      </Flex>
      <HSeparator mb='56px' />
      <Stack direction='column' mb='auto' mt='44px'>
        <Links routes={routes} />
      </Stack>
    </Flex>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'routes': PropTypes.array.isRequired,
  'account': PropTypes.object.isRequired,
  'platform': PropTypes.object.isRequired
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'account': __state.Account, 'platform': __state.Platform })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
