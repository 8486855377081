/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation PhoneNumberoUploadMutation($phoneNumberoDirectoryId: ID, $phoneNumberos: [String!]!) {
  PhoneNumberoUpload(phoneNumberoDirectoryId: $phoneNumberoDirectoryId, phoneNumberos: $phoneNumberos) {
    id
    message
    status
  }
}
`
