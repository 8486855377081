/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import JoiBrowser from 'joi-browser' // Npm: Joi for frontend validation.
import _ from 'underscore' // Npm: Underscore.js library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useMutation, useQuery } from '@apollo/client' // Npm: Apollo client.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { Flex } from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import SubmitButton from 'components/SubmitButton'
import IpDirectorySelector from 'components/IpDirectorySelector'
import { MemoizedInput } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import BlockIpUpsertMutation from './__mutation__/index.blockIp.upsert.mutation'
import BlockIpReadUniqueQuery from './__query__/index.blockIp.readUnique.query'


/*
 * OBJECTS
 */
const Index = ({ isOpen, isCreateOnly, onClose, passOn }) => {
  // Const assignment.
  const _successFlags = Object.React.App.enums.GRAPHQL_SUCCESSFUL_QUERY_FLAGS.enums.map(i => i.key)

  // Hook assignment.
  const [error, setError] = React.useState('')
  const [ipDirectoryId, setIpDirectoryId] = React.useState(void 0)
  const [ip, setIp] = React.useState([])
  const [displayName, setDisplayName] = React.useState('')
  const [MutationBlockIpUpsert, MutationBlockIpUpsertResponse] = useMutation(BlockIpUpsertMutation)
  const _QueryBlockIpReadUnique = useQuery(BlockIpReadUniqueQuery, { 'variables': { 'blockIpId': passOn?.blockIpId }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })

  // Object assignment.
  const _SubmitForm = async e => {
    // Local variable.
    let _ip, _ipDirectoryId

    // Prevent default behavior.
    e?.preventDefault()

    // Reset error.
    setError('')

    // Variable assignment.
    _ip = ip
    _ipDirectoryId = ipDirectoryId

    // Only update mnc if it is available.
    if (ipDirectoryId && (ipDirectoryId?.includes('(') || ipDirectoryId?.includes(')'))) _ipDirectoryId = ipDirectoryId.split('(')[1].split(')')[0]
    if (ip && 0 < ip?.length) _ip = _.compact(_.flatten(ip.map(r => r?.includes('(') && r?.includes(')') ? r.split('(')[1].split(')')[0] : r)))

    // Variable assignment.
    const _data = {
      'displayName': displayName,
      'ip': _ip,
      'ipDirectoryId': _ipDirectoryId
    }

    // Schema assignment for joi validation.
    const _JoiSchema = JoiBrowser.object({
      'displayName': JoiBrowser.string().required(),
      'ipDirectoryId': JoiBrowser.string().required(),
      'ip': JoiBrowser.array().items(JoiBrowser.string().allow('').allow(null))
    }).options({ 'allowUnknown': true })

    // Validate form data.
    const _JoiSchemaValidate = _JoiSchema.validate(_data)

    // If error exists then report failure.
    if (_JoiSchemaValidate.error) return setError(_JoiSchemaValidate.error?.message)

    // Update data with blockIpId if available.
    if (!isCreateOnly) _data.blockIpId = passOn?.blockIpId

    // If isCreateOnly is true then create new BDA else update existing BDA.
    const _MutationBlockIp = await MutationBlockIpUpsert({ 'variables': _data })

    // If creating or updating BDA caught an exception then report failure.
    if (_MutationBlockIp instanceof Error) return _MutationBlockIp

    // Report success.
    toast(_MutationBlockIp?.data?.BlockIpUpsert?.message)

    // Close modal.
    onClose()

    // Return void 0.
    return void 0
  }

  // Event handler.
  React.useEffect(() => {
    // Update all states if data is available.
    if (!isCreateOnly && !_.isEmpty(_QueryBlockIpReadUnique?.data?.BlockIpReadUnique) && _successFlags?.includes(_QueryBlockIpReadUnique?.data?.BlockIpReadUnique?.status)) {
      // Update all states.
      setDisplayName(_QueryBlockIpReadUnique?.data?.BlockIpReadUnique?.displayName)
      setIpDirectoryId(_.isEmpty(_QueryBlockIpReadUnique?.data?.BlockIpReadUnique?.IpDirectory) ? void 0 : `${_QueryBlockIpReadUnique?.data?.BlockIpReadUnique?.IpDirectory?.displayName} (${_QueryBlockIpReadUnique?.data?.BlockIpReadUnique?.IpDirectory?.id})`)
      setIp(_.isEmpty(_QueryBlockIpReadUnique?.data?.BlockIpReadUnique?.Ip) && !_.isEmpty(_QueryBlockIpReadUnique?.data?.BlockIpReadUnique?.IpDirectory) ? ['ALL'] : _QueryBlockIpReadUnique?.data?.BlockIpReadUnique?.Ip?.map?.(j => `${j?.ip} (${j?.id})`))
    }
  }, [isOpen, passOn, _QueryBlockIpReadUnique])

  // Const assignment.
  const _isLoading = MutationBlockIpUpsertResponse.loading
  const _isInputDisabled = _isLoading || _QueryBlockIpReadUnique?.loading

  // Return component.
  return (
    <form onSubmit={_SubmitForm}>
      <Flex>
        <Flex gap='22px' flexDir='column' w='100%'>
          <MemoizedInput
            isRequired={true}
            disabled={_isInputDisabled}
            name='displayName'
            label='Display Name'
            placeholder='e.g. "Spam Ip"'
            onChange={({ target }) => setDisplayName(target?.value)}
            isInvalid={error?.includes('displayName')}
            error={error}
            data={displayName}
          />
          <IpDirectorySelector
            disabled={_isInputDisabled}
            ipDirectoryValue={ipDirectoryId}
            ipValue={ip}
            inValidIp={error?.includes('ip')}
            inValidIpDirectory={error?.includes('ipDirectory')}
            onChange={i => {
              // Update form data.
              setIpDirectoryId(i.ipDirectory)
              setIp(i.ip)
            }}
          />
        </Flex>
      </Flex>
      <SubmitButton
        onSubmit={_SubmitForm}
        defaultText='Upsert Block Number'
        isLoading={_isLoading}
        disabled={_isInputDisabled}
        minW='150px' />
    </form >
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'passOn': PropTypes.object,
  'onClose': PropTypes.func,
  'isOpen': PropTypes.bool,
  'isCreateOnly': PropTypes.bool
}
Index.defaultProps = {}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
