/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query KeywordReadUniqueQuery($keywordId: ID!) {
  KeywordReadUnique(keywordId: $keywordId) {
    id,
    keyword,
    message,
    status
  }
}
`
