/*
 * IMPORTS
 */
import 'react-date-range/dist/styles.css' // Npm: React date range.
import 'react-date-range/dist/theme/default.css' // Npm: React date range.
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import JoiBrowser from 'joi-browser' // Npm: Joi for frontend validation.
import { toast } from 'react-hot-toast' // Npm: React toastify for notifications.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useMutation, useQuery } from '@apollo/client' // Npm: Apollo client.
import { HiLockClosed } from 'react-icons/hi2' // Npm: React icons.
import { Box, Flex, Text } from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import SubmitButton from 'components/SubmitButton'
import { MemoizedInput } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import AccountTwoFactorAuthenticationCreateMutation from './__mutation__/index.account.twoFactorAuthenticationCreate.mutation'
import AccountReadTwoFactorAuthQuery from './__query__/index.account.readTwoFactorAuth.query'


/*
 * OBJECTS
 */
const Index = ({ passOn, onClose }) => {
  // Hook assignment.
  const [error, setError] = React.useState('')
  const [MutationAccountTwoFactorAuthenticationCreate, MutationAccountTwoFactorAuthenticationCreateResponse] = useMutation(AccountTwoFactorAuthenticationCreateMutation, { 'variables': { 'email': passOn?.email } })
  const _QueryAccountReadTwoFactorAuth = useQuery(AccountReadTwoFactorAuthQuery, { 'variables': { 'accountId': passOn?.accountId }, 'fetchPolicy': Object.React.App.fetchPolicy })
  const _formDataRef = React.useRef({})

  // Object assignment.
  const _ValidateOtp = async () => {
    // Reset error.
    setError('')

    // Const assignment.
    const _JoiSchema = JoiBrowser.object({
      'token': JoiBrowser.string().required()
    }).options({ 'allowUnknown': true })

    // Validate form data.
    const _JoiSchemaValidate = _JoiSchema.validate(_formDataRef.current)

    // If error exists then report failure.
    if (_JoiSchemaValidate.error) return setError(_JoiSchemaValidate.error?.message)

    // Check if otp is not empty.
    const _MutationAccountTwoFactorAuthenticationCreate = await MutationAccountTwoFactorAuthenticationCreate({ 'variables': { 'email': passOn?.email, 'token': _formDataRef.current.token } })

    // If success then close modal.
    if ('2FA_VERIFIED' === _MutationAccountTwoFactorAuthenticationCreate?.data?.AccountTwoFactorAuthenticationCreate?.status) onClose()
    if ('INVALID_2FA_TOKEN' === _MutationAccountTwoFactorAuthenticationCreate?.data?.AccountTwoFactorAuthenticationCreate?.status) setError('Invalid token, Please make sure otp is correct.')

    // Toast message.
    return toast(_MutationAccountTwoFactorAuthenticationCreate?.data?.AccountTwoFactorAuthenticationCreate?.message)
  }

  // Return component.
  return (
    <Flex
      w='100%'
      borderRadius='20px'
      flexDir='column'
      justify='space-between'>
      <Box w='100%' h={{ 'sm': '320px', 'md': '420px' }} backgroundPosition='center' backgroundRepeat='no-repeat' backgroundImage={`url(${_QueryAccountReadTwoFactorAuth?.data?.AccountReadTwoFactorAuth?.qrCode})`} backgroundSize='contain'></Box>
      <Text as='h2' fontSize='xl' textAlign='center'>2 Factor Authentication</Text>
      <Text my='22px' as='p' fontSize='16px' textAlign='center'>Kindly Scan the QRCode with any Authenticator Application</Text>
      <Flex
        w='100%'
        position='relative'
        flexDir='row'
        alignItems='center'
        justify='center'
        gap='22px'>
        <MemoizedInput
          isInvalid={error?.includes('token')}
          placeholder='Enter Otp'
          onChange={e => (_formDataRef.current.token = e.target.value)}
          disabled={MutationAccountTwoFactorAuthenticationCreateResponse?.loading} />
        <SubmitButton
          mt={0}
          onSubmit={_ValidateOtp}
          disabled={_QueryAccountReadTwoFactorAuth?.loading}
          isLoading={MutationAccountTwoFactorAuthenticationCreateResponse?.loading}
          defaultText='Validate'
          icon={<HiLockClosed />}
        />
      </Flex>
    </Flex>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'passOn': PropTypes.object.isRequired,
  'onClose': PropTypes.func.isRequired
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)



