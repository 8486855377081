/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
    query SenderIdReadQuery($senderIdDirectoryId: ID, $senderIdToSearch: String, $take: PositiveInt, $skip: Int) {
      SenderIdRead(senderIdDirectoryId: $senderIdDirectoryId, senderIdToSearch: $senderIdToSearch, take: $take, skip: $skip) {
        id,
        createdAt,
        updatedAt,
        senderId,
        status,
        message,
        _totalCount
    }
  }
`
