/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import { Box } from '@chakra-ui/react' // Npm: Chakra ui for ui handling.


/*
 * EXPORTS
 */
export const renderTrack = ({ style, ...props }) => {
  // Const assignment.
  const _trackStyle = {
    'position': 'absolute',
    'maxWidth': '100%',
    'width': 6,
    'transition': 'opacity 200ms ease 0s',
    'opacity': 0,
    'background': 'transparent',
    'bottom': 2,
    'top': 2,
    'borderRadius': 3,
    'right': 0
  }

  // Return scrollbar.
  return <div style={{ ...style, ..._trackStyle }} {...props} />
}
export const renderThumb = ({ style, ...props }) => {
  // Const assignment.
  const _thumbStyle = {
    'borderRadius': 15,
    'background': 'rgba(222, 222, 222, .1)'
  }

  // Return component.
  return <div style={{ ...style, ..._thumbStyle }} {...props} />
}
export const renderView = ({ style, ...props }) => {
  // Const assignment.
  const _viewStyle = { 'marginBottom': -22 }

  // Return component.
  return (
    <Box
      me={{ 'base': '0px !important', 'lg': '-16px !important' }}
      w='100%'
      style={{ ...style, ..._viewStyle }}
      {...props}
    />
  )
}
