/*
 * IMPORTS
 */
import 'react-clock/dist/Clock.css' // Npm: React clock.
import React, { useState } from 'react' // Npm: react.js library.
import Clock from 'react-clock' // Npm: React clock.
import Moment from 'moment' // Npm: Moment.js library.
import { Box, Flex, Text } from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * EXPORTS
 */
export default function Index() {
  // Const assignment.
  const _gradientStyle = {
    'background':
      'linear-gradient(243.84deg, #8C33FC 22.73%, #4F27FF 59.05%, #F81DA0 91.61%)',
    'WebkitBackgroundClip': 'text',
    'WebkitTextFillColor': 'transparent'
  }

  // Hook assignment.
  const [value, setValue] = useState(new Date())
  const [newYork, setNewYorkValue] = useState(new Date())
  const [paris, setParisValue] = useState(new Date())
  const [london, setLondonValue] = useState(new Date())

  // Event handler.
  React.useEffect(() => {
    // Const assignment.
    const _interval = setInterval(() => {
      // Update states.
      setValue(new Date())
      setLondonValue(new Date())
      setParisValue(new Date())
      setNewYorkValue(new Date())
    }, 1000)

    // On component dismount.
    return () => {
      // Clear interval.
      clearInterval(_interval)
    }
  }, [])

  // Const assignment.
  const _formattedParis = paris.toLocaleTimeString('en-EU', {
    'timeZone': 'Europe/Paris',
    'hour12': false
  })
  const _formattedNewYork = newYork.toLocaleTimeString('en-US', {
    'timeZone': 'America/New_York',
    'hour12': false
  })
  const _formattedLondon = london.toLocaleTimeString('en-EU', {
    'timeZone': 'Europe/London',
    'hour12': false
  })
  const _formattedGMT = new Date().toLocaleTimeString('en-US', {
    'timeZone': 'GMT',
    'hour12': false
  })
  const _formattedDateGMT = Moment(value).utc().format(' Do MMMM YYYY')
  const _dayGMT = Moment(value).utc().format('dddd')

  // Return component.
  return (
    <Flex
      justifyContent='center'
      align='center'
      direction='column'
      bg='linear-gradient(223deg, #BCC0FF 0%, #FFDBE7 60.69%, #FFEED4 99.80%)'
      w='100%'
      borderRadius='20px'
      p='20px'
      h='100%'
      color='#1B2559'
      fontSize={['clamp(13px, 1.5vw, 16px)']}
      gap='20px'
      boxShadow='14px 17px 40px 4px rgba(112, 144, 176, 0.17)'>
      <Flex
        alignItems={{ 'md': 'center' }}
        w='100%'
        gap='20px'
        justifyContent='space-between'
        direction={{ 'base': 'column', 'md': 'row' }}>
        <Text
          mt={{ 'sm': '22px' }}
          fontFamily='Poppins'
          style={_gradientStyle}>
          <Text as='span' display='block' fontSize={{ 'base': 'xl', 'lg': '3xl' }}>Welcome to</Text>
          <Text as='span' display='block' fontWeight={900} fontSize={{ 'base': '6xl', 'lg': '6xl', 'md': '3xl', 'sm': '3xl' }}>SMS Flash</Text>
          <Text as='span' display='block' fontSize={{ 'base': 'lg', 'sm': 'md', 'lg': 'lg', 'md': 'lg' }} w='90%'>Seamless Conversation , Unleash the Potential to Swift Connectivity</Text>
        </Text>
        <Flex
          w={{ 'base': '100%', 'md': '50%' }}
          bg='#FFF'
          borderRadius='20px'
          minW='240px'
          height='260px'
          p='20px'
          justifyContent='space-between'
          textAlign='left'
          flexDir='column'>
          <Box>
            <Text fontWeight='700'>{_formattedDateGMT}</Text>
            <Text color='#3e424d' fontSize='12px' fontWeight='700'>
              {_dayGMT}
            </Text>
          </Box>
          <Flex alignItems='center' justify='center' transform='translateY(-3%)'>
            <Clock size='175px' value={_formattedGMT} />
          </Flex>
          <Text fontWeight='700' textAlign='center'>
            GMT 0
          </Text>
        </Flex>
      </Flex>
      <Flex
        bg='white'
        borderRadius='20px'
        padding='20px'
        width='100%'
        gap='22px'
        justifyContent='space-between'
        direction='row'
        flexWrap='wrap'
        textAlign='center'>
        <Flex
          gap='12px'
          flexDirection='column'>
          <Clock size='130px' value={_formattedNewYork} Text='India' />
          <Text fontWeight={700}>New York</Text>
        </Flex>
        <Flex
          gap='12px'
          flexDirection='column'>
          <Clock size='130px' value={_formattedParis} Text='Paris' />
          <Text fontWeight={700}>Paris</Text>
        </Flex>
        <Flex
          gap='12px'
          flexDirection='column'>
          <Clock size='130px' value={_formattedLondon} />
          <Text fontWeight={700}>London</Text>
        </Flex>
        <Flex
          gap='12px'
          flexDirection='column'>
          <Clock size='130px' value={value} />
          <Text fontWeight={700}>india</Text>
        </Flex>

      </Flex>
    </Flex>
  )
}
