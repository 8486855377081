/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query RateReadQueryQuery($rateId: ID) {
  RateRead(rateId: $rateId) {
    id,
    displayName,
    type,
    currency,
    Account {
      id
    }
  }
}
`
