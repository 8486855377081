/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useMutation } from '@apollo/client' // Npm: Apollo client.


/*
 * PACKAGES
 */
import DeleteCard from 'components/DeleteCard'


/*
 * GRAPHS
 */
import VendorDeleteMutation from './__mutation__/index.vendor.delete.mutation'


/*
 * OBJECTS
 */
const Index = ({ passOn, onClose }) => {
  // Hook assignment.
  const [MutationVendorDelete, MutationVendorDeleteResponse] = useMutation(VendorDeleteMutation)

  // Return Component.
  return (
    <DeleteCard onClose={onClose} disabled={MutationVendorDeleteResponse.loading} onConfirm={() => MutationVendorDelete({ 'variables': { 'vendorId': passOn?.vendorId } }).then(i => {
      // Style Guide.
      toast(i?.data?.VendorDelete?.message)

      // Report success.
      return onClose?.()
    }).catch(() =>
      // Style Guide.
      toast('Something went wrong please try after some time.'))} isLoading={MutationVendorDeleteResponse.loading} />
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'passOn': PropTypes.object,
  'onClose': PropTypes.func
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
