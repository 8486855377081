/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import Moment from 'moment' // Npm: Moment library.
import PropTypes from 'prop-types' // Npm: react.js library.
import Debounce from 'lodash/debounce' // Npm: lodash library.
import _ from 'underscore' // Npm: underscore library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useQuery } from '@apollo/client' // Npm: Apollo client.
import { IoMdAdd } from 'react-icons/io' // Npm: React icons.
import { HiInformationCircle } from 'react-icons/hi' // Npm: React icons.
import {
  Button,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tooltip,
  Tr,
  useBreakpointValue,
  useDisclosure
} from '@chakra-ui/react' // Npm: Chakra UI components.

/*
 * PACKAGES
 */
import DownloadToExcel from 'components/DownloadToExcel'
import PaginationWithPageInformation from 'components/PaginationWithPageInformation'
import Modal from 'components/Modal'
import TableSpinner from 'components/TableSpinner'
import BlockIpDropDownOptions from 'components/BlockIpDropDownOptions'
import BlockIpUpsert from 'components/BlockIpUpsert'
import BlockIpDelete from 'components/BlockIpDelete'
import { MemoizedInput } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import BlockIpReadQuery from './__query__/index.blockIp.read.query'


/*
 * STYLES
 */
import { buttonStyle, cellStyle, headerStyle, rowStyle } from './index.style'


/*
 * OBJECTS
 */
const Index = ({ passOn }) => {
  // Const assignment.
  const _skipDifference = 8
  const _successFlags = Object.React.App.enums.GRAPHQL_SUCCESSFUL_QUERY_FLAGS.enums.map(i => i.key)

  // Hook assignment.
  const [skipPage, setSkipPage] = React.useState(0)
  const [skipDifference, setSkipDifference] = React.useState(_skipDifference)
  const [blockIpDisplayNameToSearch, setBlockIpDisplayNameToSearch] = React.useState('')
  const { 'isOpen': isBlockIpCreateOpen, 'onOpen': onBlockIpCreateOpen, 'onClose': onBlockIpCreateClose } = useDisclosure()
  const { 'isOpen': isBlockIpUpdateOpen, 'onOpen': onBlockIpUpdateOpen, 'onClose': onBlockIpUpdateClose } = useDisclosure()
  const { 'isOpen': isBlockIpDeleteOpen, 'onOpen': onBlockIpDeleteOpen, 'onClose': onBlockIpDeleteClose } = useDisclosure()
  const _QueryBlockIpRead = useQuery(BlockIpReadQuery, {
    'variables': {
      'blockIpDirectoryId': passOn?.blockIpDirectoryId,
      blockIpDisplayNameToSearch,
      'skip': skipPage * skipDifference,
      'take': skipDifference
    },
    'fetchPolicy': Object.React.App.fetchPolicy,
    'pollInterval': Object.React.App.pollInterval
  })
  const _isFirstLoadCompleted = React.useRef(false)
  const _onBlockIpDisplayNameToSearchInputChange = React.useCallback(Debounce(e => setBlockIpDisplayNameToSearch(e.target.value), 800), [])
  const _tableHeaderHeightRef = React.useRef(0)
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })

  // Data Assignment.
  if (!_QueryBlockIpRead.loading && 0 < _QueryBlockIpRead.data?.BlockIpRead?.length && !_isFirstLoadCompleted.current) _isFirstLoadCompleted.current = true
  if (!_.every(_.pluck(_QueryBlockIpRead.data?.BlockIpRead, 'status'), i => _successFlags.includes(i))) _isFirstLoadCompleted.current = false

  // Return component.
  return (
    <>
      <Flex className='blockIp base'>
        <Flex
          display='flex'
          flex={1}
          flexDirection='column'
          gap={_isCurrentViewMobile ? '12px' : '22px'}
          bg='white'
          height='100%'
          borderRadius='20px'
          p={_isCurrentViewMobile ? '12px' : '22px'}>
          <Flex
            pb='0px'
            justify='space-between'
            flexDir='row'
            gap='12px'>
            <Flex gap='12px' flexDir={{ 'base': 'column', 'md': 'row' }}>
              <Tooltip label='Create New BlockIp' fontSize='sm'>
                <Button
                  w='170px'
                  leftIcon={<IoMdAdd />}
                  onClick={onBlockIpCreateOpen}
                  style={buttonStyle}>
                  Create Block Ip
                </Button>
              </Tooltip>
              {
                _isCurrentViewMobile ? void 0 : (
                  <Button
                    leftIcon={<HiInformationCircle />}
                    style={_.omit(buttonStyle, 'backgroundColor', 'color')}
                    _hover={{ 'bg': 'red.50' }}
                    color='red'
                    bg='red.50'
                    h={{ 'base': 'fit-content', 'md': 'inherit' }}>
                    <Text
                      whiteSpace='normal'
                      width='100%'
                      textAlign='start'
                      display='flex'
                      flexDirection='column'>
                      All IP&apos;s Folder listed are blocked on Network Level of OSI.
                    </Text>
                  </Button>
                )
              }
            </Flex>
            <Flex>
              <DownloadToExcel
                cellsData={
                  0 === _QueryBlockIpRead?.data?.BlockIpRead?.length || !_.every(_.pluck(_QueryBlockIpRead?.data?.BlockIpRead, 'status'), i => _successFlags.includes(i)) ? [] : _QueryBlockIpRead?.data?.BlockIpRead.map(
                    (item, __index) => ({
                      'S.No.': `${__index + (skipDifference * skipPage) + 1}.`,
                      'Created At': Moment(item?.createdAt).format('YYYY-MM-DD'),
                      'Updated At': Moment(item?.updatedAt).format('YYYY-MM-DD'),
                      'Display Name': item.displayName ?? '-'
                    })
                  )
                }
                headersData={['S.No.', 'Created At', 'Updated At', 'Display Name'].map(i => ({ 'key': i, 'label': i }))}
              />
            </Flex>
          </Flex>
          <TableContainer
            flex={1}
            display='flex'
            borderRadius='15px'
            outline='1px solid #C5CFE8'>
            <Table variant='simple' size='sm'>
              <Thead ref={_tableHeaderHeightRef}>
                <Tr
                  style={{
                    ...headerStyle,
                    'position': 'sticky',
                    'top': 0,
                    'zIndex': 1
                  }}>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    S.No
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Display Name
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Created At
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Updated At
                  </Td>
                  <Td
                    style={rowStyle}>
                    Actions
                  </Td>
                </Tr>
                <Tr key={String.random(5)}>
                  <Td borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    <MemoizedInput
                      placeholder='Search'
                      className='filter searchInput'
                      autoFocus={true}
                      defaultValue={blockIpDisplayNameToSearch}
                      onChange={_onBlockIpDisplayNameToSearchInputChange}
                    />
                  </Td>
                  <Td borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td></Td>
                </Tr>
              </Thead>
              {_QueryBlockIpRead.loading && !_isFirstLoadCompleted.current ? (
                <TableSpinner
                  isLoading={true}
                  chopHeightFromHundredPercentage={
                    _tableHeaderHeightRef?.current?.clientHeight
                  }
                />
              ) : !_isFirstLoadCompleted.current && (0 === _QueryBlockIpRead.data?.BlockIpRead?.length || !_.every(_.pluck(_QueryBlockIpRead.data?.BlockIpRead, 'status'), i => _successFlags.includes(i))) ? (
                <TableSpinner
                  isLoading={false}
                  isEmpty={true}
                  chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight}
                />
              ) : (
                <Tbody style={cellStyle}>
                  {_QueryBlockIpRead.data?.BlockIpRead?.map(
                    ({ id, createdAt, updatedAt, displayName }, __index) => (
                      <Tr key={String.random(5)}>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {`${__index + (skipDifference * skipPage) + 1}.`}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {displayName ?? '-'}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {Moment(createdAt).format('YYYY-MM-DD')}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {Moment(updatedAt).format('YYYY-MM-DD')}
                        </Td>
                        <Td style={rowStyle}>
                          {' '}
                          {
                            <BlockIpDropDownOptions
                              blockIpId={id}
                              blockIpDirectoryId={passOn?.blockIpDirectoryId}
                              onBlockIpUpdate={onBlockIpUpdateOpen}
                              onBlockIpDelete={onBlockIpDeleteOpen}
                            />
                          }
                        </Td>
                      </Tr>
                    )
                  )}
                </Tbody>
              )}
            </Table>
          </TableContainer>
        </Flex>
        <Modal
          size='sm'
          title='Block Ip Create'
          isOpen={isBlockIpCreateOpen}
          onClose={onBlockIpCreateClose}
        >
          <BlockIpUpsert isCreateOnly={true} />
        </Modal>
        <Modal
          size='sm'
          title='Block Ip Update'
          isOpen={isBlockIpUpdateOpen}
          onClose={onBlockIpUpdateClose}
        >
          <BlockIpUpsert />
        </Modal>
        <Modal
          size='sm'
          title='Block Ip Delete'
          isOpen={isBlockIpDeleteOpen}
          onClose={onBlockIpDeleteClose}
        >
          <BlockIpDelete />
        </Modal>
      </Flex>
      <PaginationWithPageInformation
        skipPage={skipPage}
        skipDifference={_skipDifference}
        setSkipDifference={setSkipDifference}
        itemsPerPage={_skipDifference}
        totalCount={_.pluck(_QueryBlockIpRead.data?.BlockIpRead, '_totalCount')?.[0]}
        onPageChange={setSkipPage}
      />
    </>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'passOn': PropTypes.object
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
