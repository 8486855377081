/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
    query IpDirectoryReadQuery($ipDirectoryToSearch: String, $take: PositiveInt, $skip: Int) {
      IpDirectoryRead(ipDirectoryToSearch: $ipDirectoryToSearch, take: $take, skip: $skip) {
        id,
        createdAt,
        updatedAt,
        displayName,
        status,
        message,
        _totalCount,
        _count {
          Ip
        }
    }
  }
`
