/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation RouteCreateMutation($type: CUSTOMER_TYPE!, $displayName: String!) {
  RouteCreate(type: $type, displayName: $displayName) {
    message
    status
  }
}
`
