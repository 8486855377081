/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.

/*
 * EXPORTS
 */
export default gql`
  query BlockDestinationAddressReadUniqueQuery($blockDestinationAddressId: ID!) {
    BlockDestinationAddressReadUnique(blockDestinationAddressId: $blockDestinationAddressId) {
      omitDestinationAddress,
      message,
      status,
      CustomerAccount {
        id,
        displayName
      },
      VendorAccount {
        id,
        displayName
      },
      Mcc {
        id,
        mcc,
        countryName
      },
      Mnc {
        id,
        mnc,
        network
      },
      SenderId {
        id,
        senderId
      },
      SenderIdDirectory {
        id,
        displayName
      }
    }
  }
`
