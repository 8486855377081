/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import { useMutation } from '@apollo/client' // Npm: Apollo client.
import { toast } from 'react-hot-toast' // Npm: React hot toast.


/*
 * SIBLINGS
 */
import DeleteCard from 'components/DeleteCard'


/*
 * GRAPHS
 */
import AccessTokenDelete from './__mutation__/index.accessToken.delete.mutation'


/*
 * OBJECTS
 */
const Index = ({ accessTokenId, bindableId, bindToTable, onClose }) => {
  // Hook assignment.
  const [MutationAccessTokenDelete, MutationAccessTokenDeleteResponse] = useMutation(AccessTokenDelete)

  // Return Component.
  return (
    <DeleteCard onClose={onClose} disabled={MutationAccessTokenDeleteResponse.loading} onConfirm={() => MutationAccessTokenDelete({ 'variables': { accessTokenId, bindableId, bindToTable } }).then(i => {
      // Style Guide.
      toast(i?.data?.AccessTokenDelete?.message)

      // Report success.
      return onClose?.()
    }).catch(() =>
      // Style Guide.
      toast('Something went wrong please try after some time.'))} isLoading={MutationAccessTokenDeleteResponse.loading} />
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'accessTokenId': PropTypes.string.isRequired,
  'bindableId': PropTypes.string.isRequired,
  'bindToTable': PropTypes.string.isRequired,
  'onClose': PropTypes.func.isRequired
}


/*
 * EXPORT
 */
export default Index
