/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
    query MncReadQuery($mccId: ID!, $operatorToSearch: String, $mccToSearch: String, $mncToSearch: String, $countryNameToSearch: String, $take: PositiveInt, $skip: Int) {
    MncRead(mccId: $mccId, operatorToSearch: $operatorToSearch, mccToSearch: $mccToSearch, mncToSearch: $mncToSearch, countryNameToSearch: $countryNameToSearch, take: $take, skip: $skip) {
        id,
        createdAt,
        updatedAt,
        network,
        mnc,
        status,
        message,
        _totalCount,
        Mcc {
          mcc,
          countryName
      }
    }
  }
`
