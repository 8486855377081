/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation KeywordDirectoryDeleteMutation($keywordDirectoryId: ID!) {
  KeywordDirectoryDelete(keywordDirectoryId: $keywordDirectoryId) {
    id
    message
    status
  }
}
`
