/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import Moment from 'moment' // Npm: moment.js library.
import _ from 'underscore' // Npm: underscore library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useQuery } from '@apollo/client' // Npm: Apollo client.
import { IoMdAdd } from 'react-icons/io' // Npm: React icons.
import {
  Button,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tooltip,
  Tr,
  useBreakpointValue,
  useDisclosure
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import TableSpinner from 'components/TableSpinner'
import DownloadToExcel from 'components/DownloadToExcel'
import PaginationWithPageInformation from 'components/PaginationWithPageInformation'
import Modal from 'components/Modal'
import VendorInvoiceUpsert from 'components/VendorInvoiceUpsert'
import VendorInvoiceDelete from 'components/VendorInvoiceDelete'
import VendorInvoiceDropDownOptions from 'components/VendorInvoiceDropDownOptions'


/*
 * STYLES
 */
import {
  buttonStyle,
  cellStyle,
  headerStyle,
  rowStyle
} from './index.style'


/*
 * GRAPHS
 */
import VendorInvoiceReadQuery from './__query__/index.vendorInvoice.read.query'


/*
 * OBJECTS
 */
const Index = ({ passOn }) => {
  // Const assignment.
  const _skipDifference = 8
  const _successFlags = Object.React.App.enums.GRAPHQL_SUCCESSFUL_QUERY_FLAGS.enums.map(i => i.key)

  // Hook assignment.
  const [skipPage, setSkipPage] = React.useState(0)
  const [skipDifference, setSkipDifference] = React.useState(_skipDifference)
  const { 'onOpen': onVendorInvoiceCreateOpen, 'isOpen': isVendorInvoiceCreateOpen, 'onClose': onVendorInvoiceCreateClose } = useDisclosure()
  const { 'onOpen': onVendorInvoiceUpdateOpen, 'isOpen': isVendorInvoiceUpdateOpen, 'onClose': onVendorInvoiceUpdateClose } = useDisclosure()
  const { 'onOpen': onVendorInvoiceDeleteOpen, 'isOpen': isVendorInvoiceDeleteOpen, 'onClose': onVendorInvoiceDeleteClose } = useDisclosure()
  const _QueryVendorInvoiceRead = useQuery(VendorInvoiceReadQuery, { 'variables': { 'vendorId': passOn?.vendorId, 'skip': skipPage * skipDifference, 'take': skipDifference }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })
  const _isFirstLoadCompleted = React.useRef(false)
  const _tableHeaderHeightRef = React.useRef(0)
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })

  // Data assignment.
  if (!_QueryVendorInvoiceRead?.loading && 0 < _QueryVendorInvoiceRead?.data?.VendorInvoiceRead?.length) _isFirstLoadCompleted.current = true
  if (0 === _QueryVendorInvoiceRead?.data?.VendorInvoiceRead?.length || !_.every(_.pluck(_QueryVendorInvoiceRead?.data?.VendorInvoiceRead, 'status'), i => _successFlags.includes(i))) _isFirstLoadCompleted.current = false

  // Return component.
  return (
    <>
      <Flex className='vendorInvoice base'>
        <Flex
          display='flex'
          flex={1}
          flexDirection='column'
          gap={_isCurrentViewMobile ? '12px' : '22px'}
          bg='white'
          height='100%'
          borderRadius='20px'
          p={_isCurrentViewMobile ? '12px' : '22px'}>
          <Flex pb='0px' justify='space-between' flexWrap={{ 'base': 'wrap', 'md': 'nowrap' }}>
            <Tooltip label='Add new Invoice.' fontSize='sm'>
              <Button
                onClick={onVendorInvoiceCreateOpen}
                leftIcon={<IoMdAdd />}
                style={buttonStyle}>
                Add Invoice
              </Button>
            </Tooltip>
            <DownloadToExcel
              cellsData={_QueryVendorInvoiceRead?.data?.VendorInvoiceRead?.map((item, __index) => _successFlags.includes(item.status) ? ({
                'S.No.': `${(__index + (skipDifference * (skipPage))) + 1}.`,
                'Invoice Number': item?.invoiceNumber ?? '-',
                'Invoiced On': Moment(item?.datedOn).format('DD-MM-YYYY') ?? '-',
                [`Amount ( in ${passOn?.currency ?? 'EUR'} )`]: item?.amount ?? '-',
                'Billing Start Date': Moment(item?.billingStartDate).format('DD-MM-YYYY'),
                'Billing End Date': Moment(item?.billingEndDate).format('DD-MM-YYYY')
              }) : ({}))}
              headersData={[
                'S.No.',
                'Invoice Number',
                'Invoiced On',
                `Amount ( in ${passOn?.currency ?? 'EUR'} )`,
                'Billing Start Date',
                'Billing End Date'
              ].map(i => ({ 'key': i, 'label': i }))} />
          </Flex>
          <TableContainer
            flex={1}
            display='flex'
            borderRadius='15px'
            outline='1px solid #C5CFE8'>
            <Table variant='simple' size='sm'>
              <Thead
                ref={_tableHeaderHeightRef}
                color='rgba(141, 151, 182, 1)'
                fontStyle='DM Sans'
                fontWeight='500'
                fontSize='18px'
                lineHeight='24px'>
                <Tr style={headerStyle}>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>S.No.</Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Invoice Number
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Invoiced On
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    {`Amount ( in ${passOn?.currency ?? 'EUR'} )`}
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>Billing Start Date</Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>Billing End Date</Td>
                  <Td
                    style={rowStyle}>
                    Actions</Td>
                </Tr>
              </Thead>
              {_QueryVendorInvoiceRead?.loading && !_isFirstLoadCompleted.current ? (
                <TableSpinner isLoading={true} chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight} />
              ) : !_isFirstLoadCompleted.current && (0 === _QueryVendorInvoiceRead?.data?.VendorInvoiceRead?.length || !_.every(_.pluck(_QueryVendorInvoiceRead?.data?.VendorInvoiceRead, 'status'), i => _successFlags.includes(i.status))) ? (
                <TableSpinner isLoading={false} isEmpty={true} chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight} />
              ) : (
                <Tbody style={cellStyle}>
                  {_QueryVendorInvoiceRead?.data?.VendorInvoiceRead?.map((__item, __index) => (
                    <Tr key={__item?.id}>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {' '}
                        {`${(__index + (skipDifference * (skipPage))) + 1}.`}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {__item?.invoiceNumber ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {Moment(__item?.datedOn).format('DD-MM-YYYY') ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {__item?.amount ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {Moment(__item?.billingStartDate).format('DD-MM-YYYY')}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {Moment(__item?.billingEndDate).format('DD-MM-YYYY')}
                      </Td>
                      <Td
                        style={rowStyle}>
                        <Flex align='center' justify='center'>
                          {
                            <VendorInvoiceDropDownOptions
                              vendorId={passOn?.vendorId}
                              vendorInvoiceId={__item?.id}
                              onVendorInvoiceUpdate={onVendorInvoiceUpdateOpen}
                              onVendorInvoiceDelete={onVendorInvoiceDeleteOpen}
                            />
                          }
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              )}
            </Table>
          </TableContainer>
        </Flex>
        <Modal
          size='sm'
          title='Add Invoice'
          isOpen={isVendorInvoiceCreateOpen}
          onClose={onVendorInvoiceCreateClose}>
          <VendorInvoiceUpsert isCreateOnly={true} isVendorOnly />
        </Modal>
        <Modal
          size='sm'
          title='Update Invoice'
          isOpen={isVendorInvoiceUpdateOpen}
          onClose={onVendorInvoiceUpdateClose}>
          <VendorInvoiceUpsert isVendorOnly />
        </Modal>
        <Modal
          size='md'
          title='Delete Invoice'
          isOpen={isVendorInvoiceDeleteOpen}
          onClose={onVendorInvoiceDeleteClose}>
          <VendorInvoiceDelete />
        </Modal>
      </Flex>
      <PaginationWithPageInformation
        skipPage={skipPage}
        skipDifference={_skipDifference}
        setSkipDifference={setSkipDifference}
        itemsPerPage={_skipDifference}
        totalCount={_.pluck(_QueryVendorInvoiceRead?.data?.VendorInvoiceRead, '_totalCount')?.[0]}
        onPageChange={setSkipPage}
      />
    </>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'passOn': PropTypes.object
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
