/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query EntityReadQuery($entityId: ID!) {
  EntityRead(entityId: $entityId) {
    id,
    displayName,
    address,
    logo,
    prefix,
    bankDetails,
    disclaimer,
    email,
    status,
    message,
  }
}
`
