/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * GRAPHS
 */
const Index = gql`
  query TestReadQueryQuery($customerAccountId: ID!, $take: PositiveInt, $skip: Int) {
    TestRead(customerAccountId: $customerAccountId, take: $take, skip: $skip) {
      id,
      createdAt,
      displayName,
      response,
      message,
      status
    }
  }
`

/*
 * EXPORTS
 */
export default Index
