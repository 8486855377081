/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation SenderIdDirectoryDeleteMutation($senderIdDirectoryId: ID!) {
  SenderIdDirectoryDelete(senderIdDirectoryId: $senderIdDirectoryId) {
    id
    message
    status
  }
}
`
