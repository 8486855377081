/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
  query RouteReadQuery($type: CUSTOMER_TYPE!, $take: PositiveInt, $skip: Int, $search: String!) {
    RouteRead(type: $type, take: $take, skip: $skip, search: $search) {
      id,
      createdAt,
      updatedAt,
      displayName,
      type,
      message,
      status,
      _totalCount,
      Account {
        email
        displayName
      }
      RoutePlan {
        id,
        isPaused,
        Mcc {
          mcc
        },
        Mnc {
          mnc,
          network
        }
      }
    }
  }
`
