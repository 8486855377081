/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client.


/*
 * EXPORTS
 */
export default gql`
mutation RatePlanDeleteMutation($ratePlanIds: [ID!]!) {
    RatePlanDelete(ratePlanIds: $ratePlanIds) {
        message
        status
    }
} 
`

