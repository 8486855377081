/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query VendorAccountReadQuery($take: PositiveInt!, $skip: Int!) {
  VendorAccountRead(take: $take, skip: $skip) {
    id,
    displayName,
    message,
    status
  }
}
`
