/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query VendorAccounttReadUniqueQuery($vendorAccounttId: ID!) {
  VendorAccounttReadUnique(vendorAccounttId: $vendorAccounttId) {
    id,
    displayName,
    VendorAccount {
      id,
      displayName,
      Vendor {
        id,
        displayName
      }
    }
    message,
    status
  }
}
`
