/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query CustomerReadRateAndRouteQuery($customerId: ID!) {
  CustomerReadRateAndRoute(customerId: $customerId) {
    message,
    status,
    CustomerAccount {
      Rate {
        id,
        displayName
      },
      Route {
        id,
        displayName
      }
    }
  }
}
`
