/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query CustomerAccountReadQuery($accountId: ID, $customerType: CUSTOMER_TYPE, $take: PositiveInt!, $skip: Int!) {
  CustomerAccountRead(accountId: $accountId, type: $customerType, take: $take, skip: $skip) {
    id,
    displayName,
    message,
    status,
    Customer {
      type
    }
  }
}
`
