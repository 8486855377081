/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query AccountReadQuery($accountType: ACCOUNT_TYPE, $take: PositiveInt, $skip: Int, $search: String!) {
    AccountRead(accountType: $accountType, take: $take, skip: $skip, search: $search) {
      id
      displayName,
      isActive,
      accountType,
      status,
      updatedAt,
      createdAt,
      email,
      isBlocked,
      is2FAEnabled,
      _totalCount
    }
  }`
