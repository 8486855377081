/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query CustomerAccountReadQuery($customerAccountId: ID!) {
  CustomerAccountRead(customerAccountId: $customerAccountId) {
    status,
    message,
    displayName,
    enableLossProtection,
    blockBetweenFromHour,
    blockBetweenToHour,
    blockBetweenFromDate,
    blockBetweenToDate,
    blockInBetweenWeeks
  }
}
`
