/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import JoiBrowser from 'joi-browser' // Npm: Joi for frontend validation.
import _ from 'underscore' // Npm: Underscore.js library.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useLazyQuery, useMutation } from '@apollo/client' // Npm: Apollo client.


/*
 * PACKAGES
 */
import SubmitButton from 'components/SubmitButton'
import { MemoizedInput } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import CreditReadQuery from './__query__/index.credit.read.query'
import CreditUpsertMutation from './__mutation__/index.credit.upsert.mutation'


/*
 * OBJECTS
 */
const Index = ({
  isOpen,
  onClose,
  passOn
}) => {
  // Hook assignment.
  const [MutationCreditUpsert, MutationCreditUpsertResponse] = useMutation(CreditUpsertMutation)
  const [QueryCreditRead, QueryCreditReadResponse] = useLazyQuery(CreditReadQuery)
  const [error, setError] = React.useState('')
  const [forceReRender, setForceReRender] = React.useState('')
  const _formDataRef = React.useRef({})

  // Object assignment.
  const _SubmitForm = async e => {
    // Prevent default behavior.
    e?.preventDefault()

    // Reset error.
    setError('')

    // Update currency.
    _formDataRef.current = { ..._formDataRef?.current }

    // Const assignment.
    const _JoiSchema = JoiBrowser.object({
      'limit': JoiBrowser.number().required()
    }).options({ 'allowUnknown': true })

    // Remove all keys from _formDataRef.current which are undefined.
    _formDataRef.current = _.pick(_formDataRef.current, _.identity)

    // Validate form data.
    const _JoiSchemaValidate = _JoiSchema.validate(_formDataRef.current)

    // If error exists then report failure.
    if (_JoiSchemaValidate.error) return setError(_JoiSchemaValidate.error?.message)

    // Create CustomerAccount for given Customer.
    const _MutationCreditUpsert = await MutationCreditUpsert({
      'variables': {
        'customerId': passOn?.customerId,
        ..._formDataRef?.current
      }
    })

    // If creating or updating customer account caught an exception then report failure.
    if (_MutationCreditUpsert instanceof Error) return _MutationCreditUpsert

    // Style Guide.
    toast(_MutationCreditUpsert?.data?.CreditUpsert?.message)

    // On Successful response from the mutation.
    if ('UPSERT_SUCCESSFUL' === _MutationCreditUpsert?.data?.CreditUpsert?.status) onClose?.()

    // Return void 0.
    return void 0
  }

  // Event handler.
  React.useEffect(() => {
    // _Async handler.
    const _Async = async () => {
      // Const assignment.
      const _QueryCreditReadQuery = await QueryCreditRead({ 'variables': { 'customerId': passOn?.customerId } })

      // If query caught an exception then report failure.
      if (_QueryCreditReadQuery instanceof Error) return _QueryCreditReadQuery

      // Const assignment.
      const _data = _QueryCreditReadQuery?.data?.CreditRead

      /*
       * If smpp details fetch complete then
       * update its value.
       */
      if (!_.isEmpty(_data)) {
        // Update form data.
        _formDataRef.current = {
          'limit': _data.limit
        }

        // Update state.
        return setForceReRender(String.random(8))
      }

      // Report failure.
      return void 0
    }; _Async()
  }, [passOn, isOpen])

  // Return component.
  return (
    <form style={{ 'width': '100%' }} onSubmit={_SubmitForm} key={forceReRender}>
      <MemoizedInput
        disabled={QueryCreditReadResponse.loading || MutationCreditUpsertResponse.loading}
        isRequired={true}
        name='limit'
        label={`Credit Amount ( ${passOn?.currency} )`}
        placeholder='e.g. "1000"'
        onChange={({ target }) => {
          // Over spreading.
          const { name, value } = target

          // Update form data.
          _formDataRef.current = {
            ..._formDataRef?.current,
            [name]: parseFloat(value)
          }
        }}
        isInvalid={error?.includes('limit')}
        error={error}
        data={_formDataRef?.current?.limit}
      />
      <SubmitButton
        disabled={QueryCreditReadResponse.loading || MutationCreditUpsertResponse.loading}
        defaultText='Update Credit'
        isLoading={MutationCreditUpsertResponse.loading}
        onSubmit={_SubmitForm} />
    </form>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'passOn': PropTypes.object,
  'onClose': PropTypes.func,
  'isOpen': PropTypes.bool
}
Index.defaultProps = {}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
