/*
 * IMPORTS
 */
import React from 'react' // Npm: React.js library.
import PropTypes from 'prop-types' // Npm: Prop Types for checking the props type.
import Debounce from 'lodash/debounce' // Npm: Debounce library.
import AutoSizer from 'react-virtualized-auto-sizer' // Npm: React virtualized auto sizer.
import InfiniteLoader from 'react-window-infinite-loader' // Npm: React window infinite loader.
import NaturalCompare from 'natural-compare' // Npm: Sorting library.
import _ from 'underscore' // Npm: Underscore.js for utility functions.
import { BsCheckAll } from 'react-icons/bs' // Npm: React Icons for icons.
import { useLazyQuery, useQuery } from '@apollo/client' // Npm: Apollo client.
import { FixedSizeList as List } from 'react-window' // Npm: React Virtualized for virtualized list.
import { HiCheckCircle, HiPlusCircle, HiXCircle } from 'react-icons/hi2' // Npm: React Icons for icons.
import {
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text
} from '@chakra-ui/react' // Npm: Chakra UI A simple, modular and accessible component library for React.


/*
 * COMPONENTS
 */
import { MemoizedSelect } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import KeywordDirectoryReadQuery from './__query__/index.keywordDirectory.read.query'
import KeywordReadQuery from './__query__/index.keyword.read.query'


/*
 * OBJECTS
 */
const Index = ({ keywordDirectoryValue, keywordValue, inValidKeyword, disabled, inValidKeywordDirectory, isRequired, onChange }) => {
  // Const assignment.
  const _skipDifference = 1000

  // Hook assignment.
  const [keywordDirectory, setKeywordDirectory] = React.useState('')
  const [keyword, setKeyword] = React.useState([])
  const [, setForceReRender] = React.useState(void 0)
  const [QueryKeywordRead, QueryKeywordReadResponse] = useLazyQuery(KeywordReadQuery)
  const _keywordListRef = React.useRef([])
  const _oldKeywordDirectory = React.useRef(void 0)
  const _QueryKeywordDirectoryRead = useQuery(KeywordDirectoryReadQuery, { 'variables': { 'take': _skipDifference, 'skip': 0 }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })

  // Object assignment.
  const _LoadMore = async (__take, __skip, __replaceInstead) => {
    // Const assignment.
    const _keywordDirectoryId = (keywordDirectory)?.split('(')[1]?.split(')')[0]

    // If id is empty then just return.
    if (_.isEmpty(_keywordDirectoryId)) return void 0

    // Query for given directory read.
    const _QueryKeywordRead = await QueryKeywordRead({ 'variables': { 'take': __take, 'skip': __skip, 'keywordDirectoryId': _keywordDirectoryId } })

    // Report failure if caught exception.
    if (_QueryKeywordRead?.error) throw _QueryKeywordRead?.error

    // Update keyword list available.
    _keywordListRef.current = __replaceInstead ? _QueryKeywordRead?.data?.KeywordRead?.filter(r => 'READ_SUCCESSFUL' === r.status) : [..._keywordListRef.current, ...(_QueryKeywordRead?.data?.KeywordRead?.filter(r => 'READ_SUCCESSFUL' === r.status) ?? [])]

    // Return void.
    return void 0
  }

  // Event handler.
  React.useEffect(() => {
    /*
     * Update selected country if keywordDirectory value
     * Is passed in params.
     */
    keywordDirectoryValue && _.isEmpty(keywordDirectory) && setKeywordDirectory(keywordDirectoryValue ?? '')
    keywordValue && _.isEmpty(keyword) && setKeyword(keywordValue ?? [])
  }, [keywordDirectoryValue, keywordValue])
  React.useEffect(() => {
    // Load more keywords.
    !_.isEmpty(keywordDirectory) && _LoadMore(10, 0).then(() => setForceReRender(Math.random()))
  }, [keywordDirectory])
  React.useEffect(() => {
    /*
     * If directory change occurs then refetch
     * new data.
     */
    if (_oldKeywordDirectory.current !== keywordDirectory) {
      // Load new batch of data.
      _LoadMore(10, 0, true).then(() => setForceReRender(Math.random()))

      // Update old ref.
      _oldKeywordDirectory.current = keywordDirectory
    }
  }, [keywordDirectory])

  // Component assignment.
  const _List = ({ index, style }) => _.isEmpty(_keywordListRef.current[index]) ? void 0 : (
    <MenuItem key={String.random(8)} style={style}>
      <Checkbox
        disabled={0 === _keywordListRef.current.length}
        name='keyword'
        onChange={() => {
          // Update state of keywords.
          setKeyword(j => {
            /*
             * If only one selection is allowed
             * Then clear the array.
             */
            if (j.includes(`${_keywordListRef.current[index].keyword} (${_keywordListRef.current[index]?.id})`)) {
              // Const assignment.
              const _data = _.without(j, `${_keywordListRef.current[index].keyword} (${_keywordListRef.current[index]?.id})`, 'ALL')

              // Return updated mcc and mnc.
              onChange({ 'keyword': _data, 'keywordDirectory': keywordDirectory })

              // Return updated keyword.
              return _data
            }

            // Return updated keyword.
            onChange({ 'keyword': [...j, `${_keywordListRef.current[index].keyword} (${_keywordListRef.current[index]?.id})`], 'keywordDirectory': keywordDirectory })

            // Return updated keyword and id.
            return [...j, `${_keywordListRef.current[index].keyword} (${_keywordListRef.current[index]?.id})`]
          })
        }}
        isChecked={keyword.includes('ALL') || keyword.includes('ALL (ALL)') || keyword.includes(`${_keywordListRef.current[index].keyword} (${_keywordListRef.current[index]?.id})`)}>
        {`${_keywordListRef.current[index].keyword} (${_keywordListRef.current[index]?.id})`}
      </Checkbox>
    </MenuItem>
  )

  // Prop types.
  _List.propTypes = {
    'index': PropTypes.number,
    'style': PropTypes.object
  }

  // Return the JSX.
  return (
    <Flex w='100%' className='keywordDirectoryAndKeywordSelector' flexDir='column' gap='22px'>
      <MemoizedSelect
        label='Keyword Folder'
        disabled={_QueryKeywordDirectoryRead?.loading || disabled}
        name='keywordDirectory'
        value={keywordDirectory}
        placeholder='Select Folder'
        options={_.compact(_QueryKeywordDirectoryRead?.data?.KeywordDirectoryRead?.map?.(j => 'READ_SUCCESSFUL' === j.status ? `${j.displayName} (${j.id})` : void 0))?.sort((a, b) => NaturalCompare(a, b))}
        isInvalid={inValidKeywordDirectory}
        onChange={__event => {
          // Update states.
          setKeywordDirectory(__event.target.value)
          setKeyword([])

          // Return updated keywordDirectory and keyword.
          onChange({ 'keyword': [], 'keywordDirectory': __event.target.value })
        }}
      />
      <FormControl flexDir='column' w='100%'>
        <FormLabel
          display='flex'
          alignItems='center'
          style={{ 'pointerEvents': 'none' }}>
          Keywords{' '}{isRequired ? <Text color='red'>*</Text> : void 0}
        </FormLabel>
        <Menu closeOnSelect={false} position='relative' disabled={QueryKeywordReadResponse.loading || _QueryKeywordDirectoryRead?.loading || disabled} w='100%'>
          <MenuButton
            type='button'
            disabled={QueryKeywordReadResponse.loading || _QueryKeywordDirectoryRead?.loading || disabled}
            h='40px'
            color={disabled ? '#aaafc8' : void 0}
            px='4'
            onClick={e => e.stopPropagation()}
            m='0'
            w='100%'
            borderRadius='12px'
            transition='all 0.2s'
            textAlign='left'
            rightIcon={<BsCheckAll />}
            bg={inValidKeyword ? 'rgb(255,255,255,0.8)' : 'gray.100'}
            _hover={{ 'bg': 'gray.100' }}
            _expanded={{ 'bg': 'gray.100' }}
            _focus={{ 'bg': 'gray.100' }}
            boxShadow={inValidKeyword ? '0 0 0 1.5px #EE5D50' : void 0}>
            {keyword.includes('ALL') || keyword.includes('ALL (ALL)') ? _.first(_keywordListRef.current)?._totalCount : (_.isString(keyword) && !_.isEmpty(keyword) ? [keyword] : keyword)?.length ?? 0} Selected
          </MenuButton>
          <MenuList borderRadius={12} h='250px' w='100%'>
            <AutoSizer>
              {({ height, width }) => (
                <InfiniteLoader
                  width={width}
                  height={height}
                  isItemLoaded={__index => Boolean(_keywordListRef.current[__index])}
                  itemCount={_keywordListRef.current.length}
                  threshold={0.9}
                  loadMoreItems={(__startIndex, __endIndex) => _LoadMore(10, parseInt(__endIndex, 10) + 1)}>
                  {({ onItemsRendered, ref }) => (
                    <List
                      ref={ref}
                      height={height}
                      itemCount={_keywordListRef.current.length}
                      itemSize={52}
                      onItemsRendered={onItemsRendered}
                      overflow='hidden'
                      width={width}>
                      {_List}
                    </List>
                  )}
                </InfiniteLoader>
              )}
            </AutoSizer>
          </MenuList>
          <Flex position='absolute' right={0} bottom={0} zIndex={1000}>
            <IconButton
              aria-label='Select All'
              disabled={0 === _keywordListRef.current.length || QueryKeywordReadResponse.loading || _QueryKeywordDirectoryRead?.loading || disabled}
              _hover={{ 'bg': 'none' }}
              _active={{ 'bg': 'none' }}
              onClick={() => {
                // Const assignment.
                const _keywordIds = ['ALL']

                // Update keyword.
                setKeyword(_keywordIds)

                // Return updated keyword.
                onChange({ 'keyword': _keywordIds, 'keywordDirectory': keywordDirectory })
              }}
              right='-10px'
              bg='none'
              p='0'>
              {keyword?.length === _keywordListRef.current?.length ? (<HiCheckCircle fontSize='20px' color='#c4c4c4' />) : (<HiPlusCircle fontSize='20px' color='#3CA55C' />)}
            </IconButton>
            <IconButton
              aria-label='Clear All'
              disabled={0 === _keywordListRef.current.length || QueryKeywordReadResponse.loading || _QueryKeywordDirectoryRead?.loading || disabled}
              _hover={{ 'bg': 'none' }}
              _active={{ 'bg': 'none' }}
              onClick={() => {
                // Update states.
                setKeyword([])

                // Return updated mcc and mnc.
                onChange({ 'keyword': [], 'keywordDirectory': '' })
              }}
              bg='none'
              p='0'>
              {0 === keyword?.length ? (<HiXCircle fontSize='20px' color='#c4c4c4' />) : (<HiXCircle fontSize='20px' color='#FF416C' />)}
            </IconButton>
          </Flex>
        </Menu>
      </FormControl>
    </Flex>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'keywordDirectoryValue': PropTypes.string,
  'keywordIdValue': PropTypes.array,
  'inValidKeywordId': PropTypes.bool,
  'keywordValue': PropTypes.array,
  'inValidKeyword': PropTypes.bool,
  'disabled': PropTypes.bool,
  'inValidKeywordDirectory': PropTypes.bool,
  'onChange': PropTypes.func,
  'isRequired': PropTypes.bool
}


/*
 * EXPORT
 */
export default Index
