/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation AccountSettingMutation($accountId: ID!, $accountType: ACCOUNT_TYPE, $is2FAEnabled: Boolean) {
  AccountSetting(accountId: $accountId, accountType: $accountType, is2FAEnabled: $is2FAEnabled) {
    message,
    status
  }
}`
