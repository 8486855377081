/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query BlockDestinationAddressReadQuery($type: CUSTOMER_TYPE!, $take: PositiveInt!, $skip: Int!) {
  BlockDestinationAddressRead(type: $type, take: $take, skip: $skip) {
    id,
    createdAt,
    updatedAt,
    omitDestinationAddress,
    status,
    message,
    _totalCount,
    CustomerAccount {
      displayName,
      id
    },
    VendorAccount {
      displayName,
      id
    },
    SenderId {
      id,
      senderId
    }
  }
}
`
