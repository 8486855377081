/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import JoiBrowser from 'joi-browser' // Npm: Joi for frontend validation.
import _ from 'underscore' // Npm: utility module.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { HiBuildingOffice2 } from 'react-icons/hi2' // Npm: React icons.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useMutation, useQuery } from '@apollo/client' // Npm: Apollo client.
import { Button, Flex, Text } from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import SubmitButton from 'components/SubmitButton'
import { MemoizedInput } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import VendorAccountAddSenderIdMutation from './__mutation__/index.vendorAccount.addSenderId.mutation'
import SmppReadQuery from './__query__/index.smpp.read.query'


/*
 * OBJECTS
 */
const Index = ({ passOn, onClose }) => {
  // Hook assignment.
  const [error, setError] = React.useState('')
  const [MutationVendorAccountAddSenderId, MutationVendorAccountAddSenderIdResponse] = useMutation(VendorAccountAddSenderIdMutation)
  const _QuerySmppRead = useQuery(SmppReadQuery, { 'variables': { 'smppId': passOn?.smppId }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })
  const _formDataRef = React.useRef({})

  // Object assignment.
  const _SubmitForm = async e => {
    // Prevent default behavior.
    e.preventDefault()

    // Reset error.
    setError('')

    // Const assignment.
    const _JoiSchema = JoiBrowser.object({
      'senderId': JoiBrowser.string().required()
    })

    // Remove all keys from _formDataRef.current which are undefined.
    _formDataRef.current = _.pick(_formDataRef.current, _.identity)

    // Validate form data.
    const _JoiSchemaValidate = _JoiSchema.validate(_formDataRef.current)

    // If error exists then report failure.
    if (_JoiSchemaValidate.error) return setError(_JoiSchemaValidate.error?.message)

    // Execute update mutation.
    const _MutationVendorAccountUpdate = await MutationVendorAccountAddSenderId({ 'variables': { 'vendorAccountId': passOn?.vendorAccountId, 'senderId': _formDataRef.current?.senderId } })

    // If mutation caught an exception then report failure.
    if (_MutationVendorAccountUpdate instanceof Error) return _MutationVendorAccountUpdate

    // Style Guide.
    toast(_MutationVendorAccountUpdate?.data?.VendorAccountAddSenderId?.message)

    // Execute onClose if response is successful.
    if ('UPDATE_SUCCESSFUL' === _MutationVendorAccountUpdate?.data?.VendorAccountAddSenderId?.status) return onClose?.()

    // Report void 0.
    return void 0
  }

  // Return Component.
  return (
    <Flex flexDir='column'>
      <Flex
        direction='row'
        align='center'
        justifyContent='start'
        px='12px'
        gap='42px'>
        <Button px='0' py='0' w='auto' h='auto' bg='none' _hover={{ 'bg': 'none' }} _focus={{ 'bg': 'none' }} color='red.400'><HiBuildingOffice2 size={37} /></Button>
        <Flex
          w='100%'
          direction='column'
          align='left'
          justifyContent='left'>
          <Text
            color='rgba(43, 54, 116, 1)'
            fontStyle='DM Sans'
            fontWeight='500'
            fontSize='19px'
            lineHeight='32px'
          >Enter Your Sender ID</Text>
          <Text
            color='rgba(43, 54, 116, 0.5)'
            fontStyle='DM Sans'
            fontWeight='500'
            fontSize='16px'
            lineHeight='24px'
          >ID will going to be added to your Smpp.</Text>
          <MemoizedInput
            name='senderId'
            placeholder='Enter Sender ID'
            w='100%'
            mt='12px'
            error={error}
            isInvalid={error?.includes('senderId')}
            data={_.isEmpty(_.first(_QuerySmppRead?.data?.SmppRead)?.sourceAddress) ? _formDataRef.current?.senderId : _.first(_QuerySmppRead?.data?.SmppRead)?.sourceAddress}
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: value
              }
            }}
          />
        </Flex>
      </Flex>
      <SubmitButton
        defaultText='Add Sender Id'
        onSubmit={_SubmitForm}
        disabled={MutationVendorAccountAddSenderIdResponse.loading}
        isLoading={MutationVendorAccountAddSenderIdResponse.loading} />
    </Flex>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'passOn': PropTypes.object,
  'onClose': PropTypes.func
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
