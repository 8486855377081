/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation AccountIsBlockedWithEmailOrAccountIdMutation($accountId: ID!, $isBlocked: Boolean!) {
  AccountIsBlockedWithEmailOrAccountId(accountId: $accountId, isBlocked: $isBlocked) {
    message,
    status
  }
}`
