/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useMutation } from '@apollo/client' // Npm: Apollo client.
import { toast } from 'react-hot-toast' // Npm: React hot toast.


/*
 * SIBLINGS
 */
import DeleteCard from 'components/DeleteCard'


/*
 * GRAPHS
 */
import ContentTranslationDeleteMany from './__mutation__/index.contentTranslation.delete.mutation'


/*
 * OBJECTS
 */
const Index = ({ passOn, onClose }) => {
  // Hook assignment.
  const [MutationContentTranslationDelete, MutationContentTranslationDeleteResponse] = useMutation(ContentTranslationDeleteMany)

  // Return Component.
  return (
    <DeleteCard onClose={onClose} disabled={MutationContentTranslationDeleteResponse.loading} onConfirm={() => MutationContentTranslationDelete({ 'variables': { 'contentTranslationIds': passOn?.contentTranslationIds ? passOn?.contentTranslationIds : passOn?.contentTranslationId } }).then(i => {
      // Style Guide.
      toast(i?.data?.ContentTranslationDeleteMany?.message)

      // Report success.
      return onClose?.()
    }).catch(() =>
    // Style Guide.
      toast('Something went wrong please try after some time.'))} isLoading={MutationContentTranslationDeleteResponse.loading} />
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'passOn': PropTypes.object,
  'onClose': PropTypes.func
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
