/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation CustomerAccountAttachRateMutation($customerAccountId: ID!, $rateId: ID!) {
  CustomerAccountAttachRate(customerAccountId: $customerAccountId, rateId: $rateId) {
    message
    status
  }
}
`
