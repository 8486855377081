/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation AccountTwoFactorAuthenticationDeleteMutation($email: EmailAddress!) {
  AccountTwoFactorAuthenticationDelete(email: $email) {
    message,
    status
  }
}`
