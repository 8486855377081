/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import JoiBrowser from 'joi-browser' // Npm: Joi for frontend validation.
import _ from 'underscore' // Npm: Underscore.js library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { useLazyQuery, useMutation } from '@apollo/client' // Npm: Apollo client.


/*
 * PACKAGES
 */
import SubmitButton from 'components/SubmitButton'
import { MemoizedInput } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import IpReadUniqueQuery from './__query__/index.ip.read.query'
import IpUpsertMutation from './__mutation__/index.ip.upsert.mutation'


/*
 * OBJECTS
 */
const Index = ({ isOpen, isCreateOnly, onClose, passOn }) => {
  // Hook assignment.
  const [error, setError] = React.useState('')
  const [forceReRender, setForceReRender] = React.useState('')
  const [QueryIpReadUnique, QueryIpReadUniqueResponse] = useLazyQuery(IpReadUniqueQuery, { 'variables': { 'ipId': passOn?.ipId } })
  const [MutationIpUpsert, MutationIpUpsertResponse] = useMutation(IpUpsertMutation)
  const _formDataRef = React.useRef({})

  // Object assignment.
  const _SubmitForm = async e => {
    // Prevent default behavior.
    e.preventDefault()

    // Reset error.
    setError('')

    // Const assignment.
    const _JoiSchema = JoiBrowser.object({
      'ip': JoiBrowser.string().required()
    }).options({ 'allowUnknown': true })

    // Remove all keys from _formDataRef.current which are undefined.
    _formDataRef.current = _.pick(_formDataRef.current, _.identity)

    // Validate form data.
    const _JoiSchemaValidate = _JoiSchema.validate(_formDataRef.current)

    // If error exists then report failure.
    if (_JoiSchemaValidate.error) return setError(_JoiSchemaValidate.error?.message)

    // Execute account registration mutation.
    const _MutationIpUpsert = await MutationIpUpsert({
      'variables': {
        'ipDirectoryId': isCreateOnly ? passOn?.ipDirectoryId : void 0,
        'ip': _formDataRef?.current?.ip,
        'ipId': isCreateOnly ? void 0 : passOn?.ipId
      }
    })

    // If mutation caught an exception then report failure.
    if (_MutationIpUpsert instanceof Error) return _MutationIpUpsert

    // On Successful response from the mutation.
    if ('UPSERT_SUCCESSFUL' === _MutationIpUpsert?.data?.IpUpsert?.status) onClose?.()

    // Style Guide.
    return toast(_MutationIpUpsert?.data?.IpUpsert?.message)
  }

  // Event handler.
  React.useEffect(() => {
    // _Async handler.
    const _Async = async () => {
      // Const assignment.
      const _QueryIpReadUniqueQuery = await QueryIpReadUnique({ 'variables': { 'ipId': isCreateOnly ? 'UN_KNOWN' : passOn?.ipId } })

      // If query caught an exception then report failure.
      if (_QueryIpReadUniqueQuery instanceof Error) return _QueryIpReadUniqueQuery

      /*
       * If customer details fetch complete then
       * update its value.
       */
      if (_QueryIpReadUniqueQuery?.data?.IpReadUnique) {
        // Update form data.
        _formDataRef.current = {
          'ipId': passOn?.ipId,
          'ip': _QueryIpReadUniqueQuery?.data?.IpReadUnique?.ip
        }

        // Update state.
        return setForceReRender(String.random(8))
      }

      // Report failure.
      return void 0
    }; _Async()
  }, [passOn, isOpen])

  // Const assignment.
  const _isLoading = MutationIpUpsertResponse.loading
  const _isInputDisabled = isCreateOnly ? false : _isLoading || QueryIpReadUniqueResponse?.loading

  // Return component.
  return (
    <form style={{ 'width': '100%' }} onSubmit={_SubmitForm} key={forceReRender}>
      <MemoizedInput
        disabled={_isInputDisabled}
        isRequired={true}
        name='ip'
        label='Ip'
        placeholder='e.g. "223.190.87.243"'
        onChange={({ target }) => {
          // Over spreading.
          const { name, value } = target

          // Update form data.
          _formDataRef.current = {
            ..._formDataRef?.current,
            [name]: value
          }
        }}
        error={error}
        isInvalid={error?.includes('ip')}
        data={_formDataRef?.current?.ip}
      />
      <SubmitButton
        onSubmit={_SubmitForm}
        isLoading={_isLoading}
        disabled={_isInputDisabled}
        defaultText={isCreateOnly ? 'Create Ip' : 'Update Ip'} />
    </form>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'isCreateOnly': PropTypes.bool,
  'isOpen': PropTypes.bool,
  'onClose': PropTypes.func,
  'passOn': PropTypes.object
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
