/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query RatePlanReadQuery($ratePlanId: ID) {
  RatePlanRead(ratePlanId: $ratePlanId) {
    id
    createdAt
    updatedAt
    rate,
    smsType,
    senderIdBasedRate,
    phoneNumberoBasedRate,
    message
    Rate {
      type
    }
    status
    Rate {
      displayName
    }
    Mcc {
      mcc,
      countryName,
      Mnc {
        mnc,
        network
      }
    },
    Mnc {
      id,
      mnc,
      network
    },
    SenderIdDirectory {
      id,
      createdAt,
      updatedAt,
      displayName
    },
    SenderId {
      id,
      createdAt,
      updatedAt,
      senderId
    },
    PhoneNumberoDirectory {
      id,
      createdAt,
      updatedAt,
      displayName
    },
    PhoneNumbero {
      id,
      createdAt,
      updatedAt,
      phoneNumbero
    }
  }
}
`
