/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation OperatorPrefixUpdateMutation(
  $operatorPrefixId: ID!,
  $prefix: String!
) {
  OperatorPrefixUpdate(
    operatorPrefixId: $operatorPrefixId,
    prefix: $prefix
  ) {
    id
    message
    status
  }
}
`
