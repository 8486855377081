/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation PhoneNumberoUpsertMutation($phoneNumberoDirectoryId: ID, $phoneNumberoId: ID, $phoneNumbero: String) {
  PhoneNumberoUpsert(phoneNumberoDirectoryId: $phoneNumberoDirectoryId, phoneNumberoId: $phoneNumberoId, phoneNumbero: $phoneNumbero) {
    id
    message
    status
  }
}
`
