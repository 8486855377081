/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation MccCreateMutation(
  $countryName: String!,
  $dialingCode: String,
  $mcc: String!,
  $minOperatorPrefixLength: Int,
  $maxOperatorPrefixLength: Int
) {
  MccCreate(
    countryName: $countryName,
    dialingCode: $dialingCode,
    mcc: $mcc,
    minOperatorPrefixLength: $minOperatorPrefixLength,
    maxOperatorPrefixLength: $maxOperatorPrefixLength,
  ) {
    id
    message
    status
  }
}
`
