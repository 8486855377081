/*
 * IMPORTS
 */
import 'react-date-range/dist/styles.css' // Npm: React date range.
import 'react-date-range/dist/theme/default.css' // Npm: React date range.
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import { useHistory, useLocation } from 'react-router-dom' // Npm: React router dom.
import {
  Box,
  Button,
  Flex,
  Link,
  Text,
  useBreakpointValue,
  useColorModeValue
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * SIBLINGS
 */
import Links from './Links'


/*
 * IMPORTS
 */
const Index = ({ secondary, message, brandText, fixed, logoText, onOpen, timelineChildren }) => {
  // Hook assignment.
  const [scrolled, setScrolled] = React.useState(false)
  const _location = useLocation()
  const _history = useHistory()
  const _textColor = useColorModeValue('white.300', 'white.300')
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })

  // Object assignment.
  const _PathBuilder = __path => {
    // Local variable.
    let currentPath

    // Variable assignment.
    currentPath = ''

    // Return object.
    return __path.reduce((__acc, __pot) => {
      // Variable assignment.
      currentPath += `/${__pot}`

      // Object assignment.
      __acc[__pot] = currentPath

      // Return object.
      return __acc
    }, {})
  }

  // Const assignment.
  const _splitPath = _location?.pathname?.split('/')?.filter(i => '' !== i)
  const _locationFiltered = _PathBuilder(_splitPath)

  // Event handler.
  React.useEffect(() => {
    // Const assignment.
    const _ChangeNavBar = () => setScrolled(1 < window.scrollY)

    // Event handler.
    window.addEventListener('scroll', _ChangeNavBar)

    // On Component dismount.
    return () => {
      // Remove listener.
      window.removeEventListener('scroll', _ChangeNavBar)
    }
  })

  // Return component.
  return (
    <Box
      boxShadow='none'
      bg='linear-Gradient(73deg,#390EF6 0%, #9244F4 100%)'
      borderColor='transparent'
      filter='none'
      backdropFilter='blur(20px)'
      backgroundPosition='center'
      backgroundSize='cover'
      borderRadius='20px'
      h={'/' === _location.pathname ? '170px' : void 0}
      borderStyle='solid'
      transitionDelay='0s, 0s, 0s, 0s'
      transitionDuration=' 0.25s, 0.25s, 0.25s, 0s'
      transition-property='box-shadow, background-color, filter, border'
      transitionTimingFunction='linear, linear, linear, linear'
      display={secondary ? 'block' : 'flex'}
      minH='75px'
      justifyContent={{ 'xl': 'center' }}
      mt='0px'
      p='22px'
      pb='10px'
      right={{ 'base': '12px', 'md': '30px', 'lg': '30px', 'xl': '30px' }}
      px={{ 'sm': '15px', 'md': '10px' }}
      ps={{ 'xl': '12px' }}
      pt='8px'
      top={{ 'base': '12px', 'md': '16px', 'xl': '18px' }}
      w='100%'>
      <Flex w='100%' position='relative' flexDirection='column'>
        <Flex
          pl={_isCurrentViewMobile ? 0 : '12px'}
          w='100%'
          flexWrap='wrap'
          justifyContent='space-between'
          alignItems='center'>
          <Box
            mb={{ 'sm': '8px', 'md': '0px' }}>
            <Link
              color='#FFF'
              href='#'
              bg='inherit'
              borderRadius='inherit'
              fontWeight='300'
              fontSize='34px'
              alignItems='center'
              height='unset'
              _hover={{ 'color': _textColor }}
              _active={{
                'bg': 'inherit',
                'transform': 'none',
                'borderColor': 'transparent'
              }}
              _focus={{ 'boxShadow': 'none' }}>
              {!_isCurrentViewMobile && brandText ? <Text as='h4'>{brandText}</Text> : void 0}
              {
                _isCurrentViewMobile || ('/' === _location.pathname || 1 === _splitPath?.length) ? void 0 : (
                  <Text as='h4' display='flex' flexDirection='row' gap='5px' position='relative' top={_isCurrentViewMobile ? 0 : '-5px'}>
                    <Text as='p' fontSize='16px' color='gray.300' fontWeight='500'>Navigation :</Text>
                    {
                      _splitPath.map((i, index) => (
                        <Button
                          bg='none'
                          px='0'
                          py='0'
                          height='unset'
                          color='white'
                          textTransform='capitalize'
                          _hover={{ 'bg': 'none', 'opacity': 0.7 }}
                          _active={{ 'bg': 'none' }}
                          _focus={{ 'bg': 'none' }}
                          onClick={() => _history.replace(_locationFiltered[_splitPath[index]])}
                          key={String.random(8)}>
                          {i} {(index === _splitPath?.length - 1) ? '' : '>'}
                        </Button>))
                    }
                  </Text>
                )
              }
            </Link>
          </Box>
          <Box w={_isCurrentViewMobile ? '100%' : void 0}>
            <Links
              onOpen={onOpen}
              logoText={logoText}
              fixed={fixed}
              scrolled={scrolled}
            />
          </Box>
        </Flex>
        {'/' === _location.pathname ? timelineChildren : void 0}
      </Flex>
      {secondary && message ? <Text color='white'>{message}</Text> : null}
    </Box>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'brandText': PropTypes.string,
  'variant': PropTypes.string,
  'secondary': PropTypes.bool,
  'fixed': PropTypes.bool,
  'onOpen': PropTypes.func,
  'message': PropTypes.any,
  'timelineChildren': PropTypes.object,
  'logoText': PropTypes.string
}


/*
 * EXPORT
 */
export default Index


