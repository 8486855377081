/*
 * IMPORTS
 */
import { createContext } from 'react' // Npm: react.js library.


/*
 * EXPORTS
 */
export default createContext(null)
