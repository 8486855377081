/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query VendorReadQuery($smsStartDate: DateTime!, $smsEndDate: DateTime!, $take: PositiveInt, $skip: Int) {
  VendorRead(smsStartDate: $smsStartDate, smsEndDate: $smsEndDate, take: $take, skip: $skip) {
    id,
    createdAt,
    displayName,
    status,
    _smsCountForGivenVendor,
    _totalCount,
    VendorAccount {
      Smpp {
        ip
      }
    }
    Billing {
      currency
    }
  }
}
`
