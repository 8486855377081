/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation CustomerAttachAccountManagerMutation($customerId: ID!, $accountManagerId: ID!) {
  CustomerAttachAccountManager(customerId: $customerId accountManagerId: $accountManagerId ) {
    message
    status
  }
}
`
