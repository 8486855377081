/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import Debounce from 'lodash/debounce' // Npm: lodash debounce.
import _ from 'underscore' // Npm: underscore.js library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useMutation, useQuery } from '@apollo/client' // Npm: Apollo client.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { IoIosSave } from 'react-icons/io' // Npm: React icons.
import {
  Button,
  Flex,
  Spinner
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import { MemoizedSelect } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import AttachAccountManagerMutation from './__mutation__/index.customer.attachAccountManager.mutation'
import AccountReadQuery from './__query__/index.account.read.query'
import CustomerReadAccountManagerQuery from './__query__/index.customer.readAccountManager.query'


/*
 * OBJECTS
 */
const Index = ({ onClose, passOn }) => {
  // Const assignment.
  const _skipDifference = 500

  // Hook assignment.
  const [accountManager, setAccountManager] = React.useState('')
  const [MutationAttachAccountManager, MutationAttachAccountManagerResponse] = useMutation(AttachAccountManagerMutation)
  const _QueryCustomerReadAccountManager = useQuery(CustomerReadAccountManagerQuery, { 'variables': { 'customerId': passOn?.customerId }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })
  const _QueryAccountManagerRead = useQuery(AccountReadQuery, { 'variables': { 'accountType': 'EMPLOYEE', 'take': _skipDifference, 'skip': 0 }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })

  // Object assignment.
  const _AttachAccountManagerAndAccountManagerToCustomer = async () => {
    // Local variable.
    let _MutationAttachAccountManagerToCustomer, _accountManagerId

    // Conditionally update senderId and senderIdDirectoryId.
    if (accountManager && (accountManager?.includes('(') || accountManager?.includes(')'))) _accountManagerId = accountManager.split('(')[1].split(')')[0]

    // Only proceed if accountManager has data.
    if (!_.isEmpty(_accountManagerId)) {
      // Bind AccountManager to given customer.
      _MutationAttachAccountManagerToCustomer = await MutationAttachAccountManager({
        'variables': {
          'customerId': passOn?.customerId,
          'accountManagerId': _accountManagerId
        }
      })

      // If mutation caught an exception then report failure.
      if (_MutationAttachAccountManagerToCustomer instanceof Error) {
        // Report failure.
        return _MutationAttachAccountManagerToCustomer
      }
    }

    // Style Guide.
    toast(_MutationAttachAccountManagerToCustomer?.data?.CustomerAttachAccountManager?.message)

    // Report failure if both route and accountManager are empty.
    if (!_.isEmpty(_MutationAttachAccountManagerToCustomer) && 'UPDATE_SUCCESSFUL' === _MutationAttachAccountManagerToCustomer?.data?.CustomerAttachAccountManager?.status) return onClose?.()

    // Play toast on completion.
    return void 0
  }

  // Event handler.
  React.useEffect(() => {
    // Update accountManager if it is available.
    if ('READ_SUCCESSFUL' === _QueryCustomerReadAccountManager?.data?.CustomerReadAccountManager?.status) {
      // Const assignment.
      const _accountManagerId = _QueryCustomerReadAccountManager?.data?.CustomerReadAccountManager?.AccountManager

      // Update accountManager.
      !_.isEmpty(_accountManagerId) && setAccountManager(`${_accountManagerId?.displayName} (${_accountManagerId?.id})`)
    }
  }, [_QueryCustomerReadAccountManager])

  // Return component.
  return (
    <>
      <Flex w='100%' className='attachAccountManagerAndAccountManager' direction='column' gap='30px'>
        <MemoizedSelect
          w='100%'
          label='Assign Manager'
          value={accountManager}
          placeholder='e.g. "Awesome AccountManager"'
          onChange={e => setAccountManager(e.target.value)}
          options={_QueryAccountManagerRead?.data?.AccountRead?.map(({ id, displayName }) => `${displayName} (${id})`)}
          isRequired={true}
        />
      </Flex>
      <Flex justifyContent='space-between' mt='22px'>
        <Button
          width='100%'
          height='40px'
          borderRadius='10px'
          bg='rgba(117, 81, 255, 1)'
          _hover={{ 'bg': 'rgba(117, 81, 255, 1)' }}
          color='rgba(255, 255, 255, 1)'
          disabled={_.isEmpty(accountManager)}
          onClick={_AttachAccountManagerAndAccountManagerToCustomer}>
          Save Setting {MutationAttachAccountManagerResponse.loading || MutationAttachAccountManagerResponse.loading ? <Spinner color='#c1a9f4' size='sm' ml='10px' /> : <IoIosSave size={16} style={{ 'marginLeft': '10px' }} />}
        </Button>
      </Flex>
    </>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'passOn': PropTypes.object,
  'onClose': PropTypes.func
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
