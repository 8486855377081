/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * GRAPHS
 */
const Index = gql`
  query SmppReadQuery($customerId: ID!) {
    SmppRead(customerId: $customerId, take: 1) {
      id,
      tps,
      message,
      status
    }
  }
`

/*
 * EXPORTS
 */
export default Index
