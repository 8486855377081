/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query RateLcrReadQuery($mcc: String!, $mnc: [String!]!, $trunkType: TRUNK_TYPE, $skip: Int!, $take: PositiveInt!) {
  RateLcrRead(mcc: $mcc, mnc: $mnc, trunkType: $trunkType, skip: $skip, take: $take) {
    smsType,
    rate,
    message,
    status,
    _totalCount,
    Mcc {
      dialingCode,
      countryName,
      mcc
    },
    Mnc {
      network,
      mnc
    },
    Rate {
      displayName,
      type,
      currency
    }
  }
}
`
