/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import Moment from 'moment' // Npm: moment.js library.
import _ from 'underscore' // Npm: underscore.js library.
import { HiArrowUturnDown } from 'react-icons/hi2' // Npm: react-icons library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useQuery } from '@apollo/client' // Npm: Apollo client.
import {
  Flex,
  Table,
  TableContainer,
  Tbody,
  Text,
  Td,
  useBreakpointValue,
  Thead,
  Tr
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import TableSpinner from 'components/TableSpinner'
import PaginationWithPageInformation from 'components/PaginationWithPageInformation'
import DateRangePicker from 'components/DateRangePicker'
import DownloadToExcel from 'components/DownloadToExcel'


/*
 * STYLES
 */
import {
  cellStyle,
  headerStyle,
  rowStyle
} from './index.style'


/*
 * GRAPHS
 */
import CreditHistoryReadQuery from './__query__/index.credit.historyRead.query'


/*
 * OBJECTS
 */
const Index = ({ passOn }) => {
  // Const assignment.
  const _skipDifference = 8

  // Hook assignment.
  const [skipDifference, setSkipDifference] = React.useState(_skipDifference)
  const [skipPage, setSkipPage] = React.useState(0)
  const [dateRange, setDateRange] = React.useState({
    'selection': {
      'startDate': Object.DateRangeProvider()?.startDate,
      'endDate': Object.DateRangeProvider()?.endDate,
      'key': 'selection'
    }
  })
  const _QueryCreditHistoryRead = useQuery(CreditHistoryReadQuery, {
    'variables': {
      'creditId': passOn?.creditId,
      'skip': skipPage * skipDifference,
      'take': skipDifference
    },
    'fetchPolicy': Object.React.App.fetchPolicy,
    'pollInterval': Object.React.App.pollInterval
  })
  const _tableHeaderHeightRef = React.useRef(0)
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })

  // Return component.
  return (
    <>
      <Flex className='reportsVendor base'>
        <Flex
          display='flex'
          flex={1}
          flexDirection='column'
          gap='22px'
          bg='white'
          height='100%'
          borderRadius='20px'
          p='16px'>
          <Flex w='100%' pb='0px' alignItems='center' justifyContent='space-between'>
          <Flex flex={0.6} gap='12px' flexDir='column'>
              <Text as='h3' fontSize={17} fontWeight={500} display='flex' alignItems='center' color='brand.500' gap='6px'><HiArrowUturnDown />{_isCurrentViewMobile ? 'Filter Rows' : 'Filter Rows based on Date'}.</Text>
              <DateRangePicker
                startDate={dateRange?.selection?.startDate}
                endDate={dateRange?.selection?.endDate}
                w='100%'
                fontSize='13px'
                height='max-content'
                gap='6px'
                setSelectDate={setDateRange}
              />
            </Flex>
            <Flex justifyContent='flex-end'>
              <DownloadToExcel
                cellsData={_.compact(_QueryCreditHistoryRead?.data?.CreditHistoryRead?.map((item, __index) => ({
                  'S.No.': `${__index + 1}.`,
                  'Credited At': Moment(item.creditedAt).format('YYYY-MM-DD hh:mm:ss A'),
                  'Last Limit': item?.limit ?? '-',
                  'Last Balance': item?.balance ?? '-',
                  'Credited By': item?.creditedBy?.displayName ?? '-',
                  'Creditor Email': item?.creditedBy?.email ?? '-'
                })))}
                headersData={[
                  'S.No.',
                  'Credited At',
                  'UpdatedAt',
                  'Last Limit',
                  'Last Balance',
                  'Credited By',
                  'Creditor Email'
                ].map(i => ({ 'key': i, 'label': i }))} />
              </Flex>
          </Flex>
          <TableContainer
            flex={1}
            display='flex'
            borderRadius='15px'
            outline='1px solid #C5CFE8'>
            <Table variant='simple' size='md'>
              <Thead ref={_tableHeaderHeightRef}>
                <Tr style={{ ...headerStyle, 'position': 'sticky', 'top': 0, 'zIndex': 1 }}>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>S.No.</Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>Last Payment</Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>Last Balance</Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>Credited By</Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>Creditor Email</Td>
                  <Td style={rowStyle}>Credited At</Td>
                </Tr>
              </Thead>
              { 0 === _QueryCreditHistoryRead?.data?.CreditHistoryRead?.length || (0 < _QueryCreditHistoryRead?.data?.CreditHistoryRead?.length && _.every(_.flatten(_.pluck(_QueryCreditHistoryRead?.data?.CreditHistoryRead, 'status')), j => j !== 'READ_SUCCESSFUL')) ? (<TableSpinner isLoading={false} isEmpty={true} chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight} />) : (
                <Tbody style={cellStyle}>
                  {_QueryCreditHistoryRead?.data?.CreditHistoryRead?.map((item, __index) => (
                    <Tr key={String.random(8)}>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {' '}
                        {`${__index + 1}.`}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {item?.limit?.toFixed?.(5) ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {item?.balance?.toFixed?.(5) ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {item?.creditedBy?.displayName ?? '-'}
                      </Td>
                      <Td
                        borderRight='1px solid rgba(216, 227, 252, 1)'
                        style={rowStyle}>
                        {item?.creditedBy?.email ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}>
                        {Moment(item.creditedAt).format('YYYY-MM-DD hh:mm:ss A')}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              ) }
            </Table>
          </TableContainer>
        </Flex>
      </Flex>
      <PaginationWithPageInformation
        skipPage={skipPage}
        skipDifference={_skipDifference}
        setSkipDifference={setSkipDifference}
        itemsPerPage={_skipDifference}
        totalCount={_.pluck(_QueryCreditHistoryRead?.data?.CreditHistoryRead, '_totalCount')?.[0]}
        onPageChange={setSkipPage}
      />
    </>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'passOn': PropTypes.object
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)

