/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import Moment from 'moment' // Npm: Moment library.
import PropTypes from 'prop-types' // Npm: prop-types library.
import Debounce from 'lodash/debounce' // Npm: lodash library.
import * as XLSX from 'xlsx' // Npm: XLSX library.
import _ from 'underscore' // Npm: underscore library.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useMutation, useQuery } from '@apollo/client' // Npm: Apollo client.
import { IoMdAdd } from 'react-icons/io' // Npm: React icons.
import {
  Button,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tooltip,
  Tr,
  useBreakpointValue,
  useDisclosure
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import PaginationWithPageInformation from 'components/PaginationWithPageInformation'
import Modal from 'components/Modal'
import TableSpinner from 'components/TableSpinner'
import MncDropDownOptions from 'components/MncDropDownOptions'
import MncUpsert from 'components/MncUpsert'
import MncDelete from 'components/MncDelete'
import FileUpload from 'components/FileUpload'
import { MemoizedInput } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import MncCreateThroughExcelMutation from './__mutation__/index.mnc.createThroughExcel.mutation'
import MncReadQuery from './__query__/index.mnc.read.query'


/*
 * STYLES
 */
import {
  buttonStyle,
  cellStyle,
  headerStyle,
  rowStyle
} from './index.style'


/*
 * OBJECTS
 */
const Index = ({ passOn }) => {
  // Const assignment.
  const _skipDifference = 8
  const _successFlags = Object.React.App.enums.GRAPHQL_SUCCESSFUL_QUERY_FLAGS.enums.map(i => i.key)

  // Hook assignment.
  const [skipPage, setSkipPage] = React.useState(0)
  const [skipDifference, setSkipDifference] = React.useState(_skipDifference)
  const [operatorToSearch, setOperatorToSearch] = React.useState('')
  const [mccToSearch, setMccToSearch] = React.useState('')
  const [mncToSearch, setMncToSearch] = React.useState('')
  const [countryNameToSearch, setCountryNameToSearch] = React.useState('')
  const [onSuccessfulFileUpload, setOnSuccessfulFileUpload] = React.useState(false)
  const [uploadingFile, setUploadingFile] = React.useState(false)
  const { 'isOpen': isMncCreateOpen, 'onOpen': onMncCreateOpen, 'onClose': onMncCreateClose } = useDisclosure()
  const { 'isOpen': isMncUpdateOpen, 'onOpen': onMncUpdateOpen, 'onClose': onMncUpdateClose } = useDisclosure()
  const { 'isOpen': isMncDeleteOpen, 'onOpen': onMncDeleteOpen, 'onClose': onMncDeleteClose } = useDisclosure()
  const [MutationMncCreateThroughExcel] = useMutation(MncCreateThroughExcelMutation)
  const _QueryMncRead = useQuery(MncReadQuery, { 'variables': { 'mccId': passOn?.mccId, operatorToSearch, mccToSearch, mncToSearch, countryNameToSearch, 'skip': skipPage * skipDifference, 'take': skipDifference }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })
  const _isFirstLoadCompleted = React.useRef(false)
  const _onOperatorToSearchInputChange = React.useCallback(Debounce(e => setOperatorToSearch(e.target.value), 800), [])
  const _onMccToSearchInputChange = React.useCallback(Debounce(e => setMccToSearch(e.target.value), 800), [])
  const _onMncToSearchInputChange = React.useCallback(Debounce(e => setMncToSearch(e.target.value), 800), [])
  const _onCountryNameSearchInputChange = React.useCallback(Debounce(e => setCountryNameToSearch(e.target.value), 800), [])
  const _tableHeaderHeightRef = React.useRef(0)
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })

  // Data Assignment.
  if (!_QueryMncRead.loading && 0 < _QueryMncRead.data?.MncRead?.length) _isFirstLoadCompleted.current = true
  if (0 === _QueryMncRead.data?.MncRead?.length || !_.every(_.pluck(_QueryMncRead.data?.MncRead, 'status'), i => _successFlags?.includes(i))) _isFirstLoadCompleted.current = false

  // Return component.
  return (
    <>
      <Flex className='mnc base'>
        <Flex
          display='flex'
          flex={1}
          flexDirection='column'
          gap={_isCurrentViewMobile ? '12px' : '22px'}
          bg='white'
          height='100%'
          borderRadius='20px'
          p={_isCurrentViewMobile ? '12px' : '22px'}>
          <Flex pb='0px' justify='space-between' gap='12px'>
            <Tooltip label='Create New Operator' fontSize='sm'>
              <Button
                w='170px'
                leftIcon={<IoMdAdd />}
                onClick={onMncCreateOpen}
                style={buttonStyle}>
                Create Mnc
              </Button>
            </Tooltip>
            <FileUpload
              uploadingFile={uploadingFile}
              bg={onSuccessfulFileUpload ? 'green.100' : void 0}
              color={onSuccessfulFileUpload ? 'green' : void 0}
              label={_isCurrentViewMobile ? '' : onSuccessfulFileUpload ? 'Upload Successful' : 'Upload Mnc'}
              onChange={e => {
                // Set uploadingFile to true when file upload begins
                setUploadingFile(true)

                // Promise all file uploads.
                Promise.all(Array.from(e.target.files).map(file => {
                  // Const assignment.
                  const _FileReader = new FileReader()

                  // Event listener.
                  _FileReader.readAsBinaryString(file)
                  _FileReader.onload = () => {
                    // Const assignment.
                    const _data = _FileReader.result
                    const _workbook = XLSX.read(_data, { 'type': 'binary' })
                    const _sheetName = _workbook.SheetNames[0]
                    const _sheet = _workbook.Sheets[_sheetName]
                    const _parsedData = XLSX.utils.sheet_to_json(_sheet)

                    // Call the mutation to create Mnc through excel
                    MutationMncCreateThroughExcel({ 'variables': { 'file': _parsedData, 'name': file.name } }).then(e => {
                      // Set uploadingFile to false when upload completes
                      setUploadingFile(false)
                      setOnSuccessfulFileUpload(true)

                      // Set a timeout to remove the success message
                      const j = setTimeout(() => { setOnSuccessfulFileUpload(false); clearTimeout(j) }, 3000)

                      // Toast the success message
                      e?.data?.MncCreateThroughExcel?.map(r => !_.isEmpty(r?.message) && toast(r?.message))
                    })
                  }

                  // Return void 0
                  return void 0
                }))
              }}
              accept='.xlsx, .xls, .csv'
              disabled={uploadingFile}
            />
          </Flex>
          <TableContainer
            flex={1}
            display='flex'
            borderRadius='15px'
            outline='1px solid #C5CFE8'>
            <Table variant='simple' size='sm'>
              <Thead ref={_tableHeaderHeightRef}>
                <Tr style={{ ...headerStyle, 'position': 'sticky', 'top': 0, 'zIndex': 1 }}>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>S.No</Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>Operator</Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>Mcc</Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>Mnc</Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>Country</Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>CreatedAt</Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>UpdatedAt</Td>
                  <Td
                    style={rowStyle}>Action</Td>
                </Tr>
                <Tr key={String.random(5)} >
                  <Td borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>
                    <MemoizedInput
                      placeholder='Search'
                      className='filter searchInput'
                      defaultValue={operatorToSearch}
                      onChange={_onOperatorToSearchInputChange}
                    />
                  </Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>
                    <MemoizedInput
                      placeholder='Search'
                      className='filter searchInput'
                      defaultValue={mccToSearch}
                      onChange={_onMccToSearchInputChange}
                    />
                  </Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>
                    <MemoizedInput
                      placeholder='Search'
                      className='filter searchInput'
                      defaultValue={mncToSearch}
                      onChange={_onMncToSearchInputChange}
                    />
                  </Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>
                    <MemoizedInput
                      placeholder='Search'
                      className='filter searchInput'
                      defaultValue={countryNameToSearch}
                      onChange={_onCountryNameSearchInputChange}
                    />
                  </Td>
                  <Td borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td></Td>
                </Tr>
              </Thead>
              {_QueryMncRead.loading && !_isFirstLoadCompleted.current ? (
                <TableSpinner isLoading={true} chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight} />
              ) : !_isFirstLoadCompleted.current && (0 === _QueryMncRead.data?.MncRead?.length || !_.every(_.pluck(_QueryMncRead.data?.MncRead, 'status'), i => _successFlags?.includes(i))) ? (
                <TableSpinner isLoading={false} isEmpty={true} chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight} />
              ) : (
                <Tbody style={cellStyle}>
                  {_QueryMncRead.data?.MncRead?.map(({ id, createdAt, updatedAt, mnc, network, Mcc }, __index) => (
                    <Tr key={String.random(5)}>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {`${(__index + (skipDifference * (skipPage))) + 1}.`}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {network ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {Mcc?.mcc ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {mnc ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {Mcc?.countryName ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {Moment(createdAt).format('YYYY-MM-DD hh:mm:ss A')}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {Moment(updatedAt).format('YYYY-MM-DD hh:mm:ss A')}
                      </Td>
                      <Td
                        style={rowStyle}>
                        {' '}
                        {
                          <MncDropDownOptions
                            mncId={id}
                            onMncUpdate={onMncUpdateOpen}
                            onMncDelete={onMncDeleteOpen}
                          />
                        }
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              )
              }
            </Table>
          </TableContainer>
        </Flex>
        <Modal
          size='sm'
          title='Mnc Create'
          isOpen={isMncCreateOpen}
          onClose={onMncCreateClose}>
          <MncUpsert isCreateOnly={true} />
        </Modal>
        <Modal
          size='sm'
          title='Mnc Update'
          isOpen={isMncUpdateOpen}
          onClose={onMncUpdateClose}>
          <MncUpsert />
        </Modal>
        <Modal
          size='md'
          title='Mnc Delete'
          isOpen={isMncDeleteOpen}
          onClose={onMncDeleteClose}>
          <MncDelete />
        </Modal>
      </Flex>
      <PaginationWithPageInformation
        skipPage={skipPage}
        skipDifference={_skipDifference}
        setSkipDifference={setSkipDifference}
        itemsPerPage={_skipDifference}
        totalCount={_.pluck(_QueryMncRead.data?.MncRead, '_totalCount')?.[0]}
        onPageChange={setSkipPage}
      />
    </>
  )
}



/*
 * PROPTYPES
 */
Index.propTypes = {
  'passOn': PropTypes.object
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)

