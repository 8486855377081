/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
    mutation BilateralStatementGenerateMutation($customerId: ID!, $startDate: DateTime!, $endDate: DateTime!) {
      BilateralStatementGenerate(customerId: $customerId, startDate: $startDate, endDate: $endDate) {
        statement,
        status,
        message
    }
  }
`
