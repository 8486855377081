/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * GRAPH
 */
const Index = gql`
query CustomerAccountReadQuery($customerId: ID, $take: PositiveInt, $skip: Int ,$search:  String! )  {
  CustomerAccountRead(customerId: $customerId, take: $take, skip: $skip, search: $search) {
    id,
    message,
    status,
    Smpp {
        id,
        createdAt,
        updatedAt,
        isActive,
        displayName,
        ip,
        txPort,
        rxPort,
        username,
        password,
        sessionTimeoutInterval,
        sessionAllowed,
        tps,
        debuggingLevel,
        message,
        status,
    },
    Account {
      email,
      displayName
    },
    _totalCount
  }
} 
`

/*
 * EXPORTS
 */
export default Index
