/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.

/*
 * EXPORTS
 */
export default gql`
  query PhoneNumberoReadQuery($phoneNumberoDirectoryId: ID!, $take: PositiveInt!, $skip: Int!) {
    PhoneNumberoRead(phoneNumberoDirectoryId: $phoneNumberoDirectoryId, take: $take, skip: $skip) {
      id,
      phoneNumbero,
      message,
      status,
      _totalCount
      PhoneNumberoDirectory {
        id,
        displayName
      }
    }
  }
`
