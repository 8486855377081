/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client.


/*
 * EXPORTS
 */
export default gql`
mutation AccessTokenDeleteMutation($bindToTable: String!, $bindableId: ID!, $accessTokenId: ID!) {
  AccessTokenDelete(bindToTable: $bindToTable, bindableId: $bindableId, accessTokenId: $accessTokenId) {
    message
    status
  }
}
`

