/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import JoiBrowser from 'joi-browser' // Npm: Joi for frontend validation.
import _ from 'underscore' // Npm: Underscore.js library.
import { useLazyQuery, useMutation } from '@apollo/client' // Npm: Apollo client for graphql.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { toast } from 'react-hot-toast' // Npm: React toastify for notifications.


/*
 * PACKAGES
 */
import SubmitButton from 'components/SubmitButton'
import { MemoizedInput } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import MncCreateMutation from './__mutation__/index.mnc.create.mutation'
import MncUpdateMutation from './__mutation__/index.mnc.update.mutation'
import MncReadQuery from './__query__/index.mnc.read.query'


/*
 * OBJECTS
 */
const Index = ({ formContainerStyle, isOpen, isCreateOnly, onClose, passOn }) => {
  // Hook assignment.
  const [error, setError] = React.useState('')
  const [forceReRender, setForceReRender] = React.useState('')
  const [MutationMncCreate, MutationMncCreateResponse] = useMutation(MncCreateMutation)
  const [MutationMncUpdate, MutationMncUpdateResponse] = useMutation(MncUpdateMutation)
  const [QueryMncRead, QueryMncReadResponse] = useLazyQuery(MncReadQuery)
  const _formDataRef = React.useRef({})

  // Object assignment.
  const _SubmitForm = async e => {
    // Prevent default behavior.
    e.preventDefault()

    // Reset error.
    setError('')

    // Const assignment.
    const _JoiSchema = JoiBrowser.object({
      'network': JoiBrowser.string().required(),
      'mnc': JoiBrowser.string().required()
    }).options({ 'allowUnknown': true })

    // Remove all keys from _formDataRef.current which are undefined.
    _formDataRef.current = _.pick(_formDataRef.current, _.identity)

    // Validate form data.
    const _JoiSchemaValidate = _JoiSchema.validate(_formDataRef.current)

    // If error exists then report failure.
    if (_JoiSchemaValidate.error) return setError(_JoiSchemaValidate.error?.message)

    // Create or Update given mnc.
    const _MutationMnc = await [isCreateOnly ? MutationMncCreate : MutationMncUpdate]?.[0]({
      'variables': {
        [isCreateOnly ? 'mccId' : 'mncId']: isCreateOnly ? passOn?.mccId : passOn?.mncId,
        ..._formDataRef.current
      }
    })

    // If creating or updating mnc caught an exception then report failure.
    if (_MutationMnc instanceof Error) return _MutationMnc

    // Style Guide.
    toast(_MutationMnc?.data?.MncCreate?.message || _MutationMnc?.data?.MncUpdate?.message)

    // On Successful update or create close the modal.
    if ('CREATE_SUCCESSFUL' === _MutationMnc?.data?.MncCreate?.status || 'UPDATE_SUCCESSFUL' === _MutationMnc?.data?.MncUpdate?.status) return onClose()

    // Report void 0.
    return void 0
  }

  // Event handler.
  React.useEffect(() => {
    // _Async handler.
    const _Async = async () => {
      // Const assignment.
      const _QueryMncRead = await QueryMncRead({ 'variables': { 'mncId': isCreateOnly ? 'UN_KNOWN' : passOn?.mncId } })

      // If query caught an exception then report failure.
      if (_QueryMncRead instanceof Error) return _QueryMncRead

      // Const assignment.
      const _mncRead = _.first(_QueryMncRead?.data?.MncRead)

      /*
       * If details fetch complete then
       * update its value.
       */
      if (!_.isEmpty(_mncRead)) {
        // Update form data.
        _formDataRef.current = {
          'network': _mncRead?.network,
          'mnc': _mncRead?.mnc
        }

        // Update state.
        return setForceReRender(String.random(8))
      }

      // Report failure.
      return void 0
    }; _Async()
  }, [passOn, isOpen])

  // Const assignment.
  const _isLoading = MutationMncCreateResponse.loading || MutationMncUpdateResponse.loading
  const _isInputDisabled = isCreateOnly ? false : _isLoading || QueryMncReadResponse?.loading

  // Return component.
  return (
    <form style={formContainerStyle} onSubmit={_SubmitForm} key={forceReRender}>
      <MemoizedInput
        disabled={_isInputDisabled}
        name='mnc'
        label='Mnc'
        placeholder='e.g. "Mnc"'
        onChange={({ target }) => {
          // Over spreading.
          const { name, value } = target

          // Update form data.
          _formDataRef.current = {
            ..._formDataRef?.current,
            [name]: value
          }
        }}
        error={error}
        isInvalid={error?.includes('mnc')}
        isRequired={true}
        data={_formDataRef?.current?.mnc}
      />
      <MemoizedInput
        disabled={_isInputDisabled}
        name='network'
        label='Network Name'
        placeholder='e.g. "Network Name"'
        onChange={({ target }) => {
          // Over spreading.
          const { name, value } = target

          // Update form data.
          _formDataRef.current = {
            ..._formDataRef?.current,
            [name]: value
          }
        }}
        error={error}
        isInvalid={error?.includes('network')}
        isRequired={true}
        data={_formDataRef?.current?.network}
      />
      <SubmitButton
        margin={0}
        disabled={_isInputDisabled}
        onSubmit={_SubmitForm}
        isLoading={_isLoading}
        defaultText={isCreateOnly ? 'Create Mnc' : 'Update Mnc'}
      />
    </form>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'passOn': PropTypes.object,
  'isOpen': PropTypes.bool,
  'isCreateOnly': PropTypes.bool,
  'onClose': PropTypes.func,
  'formContainerStyle': PropTypes.object
}
Index.defaultProps = {
  'formContainerStyle': {
    'width': '100%',
    'display': 'flex',
    'gap': '22px',
    'flexDirection': 'column'
  }
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
