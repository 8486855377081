/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: apollo client.


/*
 * EXPORTS
 */
export default gql`
    query VendorAccountConnectedQuery($isActive: Boolean!) {
        VendorAccountConnected(isActive: $isActive){
            displayName,
            message,
            status,
            Smpp {
                id,
                isActive,
                mode
            }
        }
    }
`


