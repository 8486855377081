/*
 * IMPORTS
 */
import 'react-date-range/dist/styles.css' // Npm: react date range module.
import 'react-date-range/dist/theme/default.css' // Npm: React date range module.
import Moment from 'moment' // Npm: Moment.js library.
import React from 'react' // Npm import: React.js library.
import PropTypes from 'prop-types' // Npm import: Prop types validator.
import { BiChevronDown } from 'react-icons/bi' // Npm import: React icons.
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  Skeleton
} from '@chakra-ui/react' // Npm import: Chakra UI components.


/*
 * SIBLINGS
 */
import Calendar from './Calendar'


/*
 * STYLES
 */
import './index.style.css'


/*
 * OBJECTS
 */
const Index = ({ bg, color, startColor, endColor, asFatButton, startDate, endDate, setSelectDate, calendarOnly, loading }) => {
  // Const assignment.
  const _now = new Date()

  // SetHours to 0, 0, 0, 0.
  _now.setHours(0, 0, 0, 0)

  // Const assignment.
  const _tomorrow = new Date(Moment().add(1, 'days'))

  // SetHours to 0, 0, 0, 0.
  _tomorrow.setHours(0, 0, 0, 0)

  // Hook assignment.
  const [turnOnCompare, setTurnOnCompare] = React.useState(false)
  const [state, setState] = React.useState({
    'selection': {
      'startDate': startDate ? new Date(new Date(Moment(startDate).zone('+0000')).toUTCString()) : new Date(_now),
      'endDate': endDate ? new Date(new Date(Moment(endDate).zone('+0000')).toUTCString()) : new Date(_tomorrow),
      'key': 'selection'
    }
  })

  // Object assignment.
  const _DateFormat = __state => {
    // Const assignment.
    const _startDate = new Date(__state?.selection?.startDate)
    const _endDate = new Date(__state?.selection?.endDate)

    // Formate date into shorthand style.
    const startFormatted = _startDate.toLocaleDateString('en-US', {
      'month': 'short',
      'day': 'numeric'
    })
    const endFormatted = _endDate.toLocaleDateString('en-US', {
      'month': 'short',
      'day': 'numeric'
    })


    // Return format.
    return `${startFormatted} -  ${endFormatted}, ${_endDate.getFullYear()}`
  }

  // Return component.
  return (
    <Flex alignItems='center' className={['dateRangePicker', asFatButton ? 'asFatButton' : void 0]?.join(' ')}>
      <Menu>
        {({ isOpen, onClose }) => (
          <>
            {loading ? (
              <Skeleton
                borderRadius='12px'
                width='200px'
                startColor={startColor ?? '#ffffff'}
                endColor={endColor ?? '#f3f2f1'}
                height='45px'
              />
            ) : (
              <MenuButton
                isActive={isOpen}
                bg={bg ?? '#F4F7FE'}
                border='none'
                fontSize='12px'
                fontWeight='700'
                textAlign='left'
                id='edit_product'
                variant='main'
                h='28px'
                borderRadius='12px'
                as={Button}
                disabled={loading}
                color={color ?? '#828797'}
                rightIcon={asFatButton ? void 0 : <BiChevronDown />}>
                {_DateFormat(state)}
              </MenuButton>
            )}
            <MenuList padding={0} border='none' overflow='hidden' borderRadius={22} boxShadow='2xl' zIndex={100}>
              <Calendar
                setTurnOnCompare={setTurnOnCompare}
                turnOnCompare={turnOnCompare}
                onChange={item => setState({ ...state, ...item })}
                months={1}
                onClose={onClose}
                _handleSetDate={setSelectDate(state)}
                calendarOnly={calendarOnly}
                direction='vertical'
                scroll={true}
                ranges={[state.selection]}
                showDateDisplay={true}
              />
            </MenuList>
          </>
        )}
      </Menu>
    </Flex>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'startDate': PropTypes.instanceOf(Date),
  'endDate': PropTypes.instanceOf(Date),
  'calendarOnly': PropTypes.bool,
  'loading': PropTypes.bool,
  'asFatButton': PropTypes.bool,
  'setSelectDate': PropTypes.func,
  'bg': PropTypes.string,
  'color': PropTypes.string,
  'startColor': PropTypes.string,
  'endColor': PropTypes.string
}


/*
 * EXPORTS
 */
export default Index
