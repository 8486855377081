/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation SenderIdUploadMutation($senderIdDirectoryId: ID, $senderIds: [String!]!) {
  SenderIdUpload(senderIdDirectoryId: $senderIdDirectoryId, senderIds: $senderIds) {
    id
    message
    status
  }
}
`
