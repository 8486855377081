/*
 * IMPORTS
 */
import 'react-date-range/dist/styles.css' // Npm: React date range.
import 'react-date-range/dist/theme/default.css' // Npm: React date range.
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import _ from 'underscore' // NPM: Underscore.js library.
import { toast } from 'react-hot-toast' // Npm: React toast library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useMutation, useQuery } from '@apollo/client' // Npm: Apollo client.
import { HiMiniRocketLaunch } from 'react-icons/hi2' // Npm: React icons.
import { Button, Flex, Spinner, Text } from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import { MemoizedInput } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import SmppUpdateMutation from './__mutation__/index.smpp.update.mutation'
import SmppReadQuery from './__query__/index.smpp.read.query'


/*
 * OBJECTS
 */
const Index = ({ passOn, onClose }) => {
  // Hook assignment.
  const [MutationSmppUpdate, MutationSmppUpdateResponse] = useMutation(SmppUpdateMutation)
  const _SmppReadQuery = useQuery(SmppReadQuery, { 'variables': { 'customerId': passOn?.customerId }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })
  const _formDataRef = React.useRef({})

  // Return component.
  return (
    <Flex w='100%' flexDir='column' gap='22px'>
      <Text w='100%' as='p' fontSize='17px' color='gray.500'>Enter tps for given customer, High tps might submit faster but will cause system heat.</Text>
      <Flex
        w='100%'
        borderRadius={12}
        overflow='hidden'
        position='relative'
        flexDir='row'
        border='1px solid var(--chakra-colors-brand-500)'
        alignItems='center'
        gap='12px'>
        <Flex flex={1} gap='22px' justifyContent='space-between'>
        <MemoizedInput
          padding='12px'
          data={'READ_SUCCESSFUL' === _.first(_SmppReadQuery?.data?.SmppRead)?.status ? _.first(_SmppReadQuery?.data?.SmppRead)?.tps : void 0}
          placeholder='e.g. "40"'
          onChange={e => _formDataRef.current.tps = parseInt(e.target.value, 10)}
          bg='rgba(255, 255, 255, 0.33)'
          border='none'
          color='gray.500'
          disabled={_SmppReadQuery?.loading}
          _focus={{ 'border': 'none', 'outline': 'none' }}
          _active={{ 'border': 'none', 'outline': 'none' }}
        />
        </Flex>
        <Button
          px='12px'
          h='40px'
          gap='6px'
          borderRadius={0}
          borderLeft='1px solid var(--chakra-colors-brand-500)'
          bg='brand.500'
          color='white'
          _hover={{ 'bg': 'brand.500' }}
          onClick={() => {
            /*
             * If tps is not added, show error.
             * Else, update tps.
             */
            if (!_formDataRef?.current?.tps) return toast.error('Kindly add tps')

            // Update tps.
            return MutationSmppUpdate({ 'variables': { 'smppId': _.first(_SmppReadQuery?.data?.SmppRead)?.id, 'tps': _formDataRef?.current?.tps } })?.then(e => { _SmppReadQuery.refetch(); toast(e?.data?.SmppUpdate?.message); onClose() })
          }}
          _active={{ 'bg': 'brand.500' }}
          disabled={_SmppReadQuery?.loading}>
          {MutationSmppUpdateResponse?.loading ? <Spinner size='sm' /> : <HiMiniRocketLaunch size={19} />}
          Change
        </Button>
      </Flex>
    </Flex>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'passOn': PropTypes.object.isRequired,
  'onClose': PropTypes.func.isRequired
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
