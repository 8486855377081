/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import JoiBrowser from 'joi-browser' // Npm: Joi for frontend validation.
import _ from 'underscore' // Npm: Underscore.js library.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useLazyQuery, useMutation } from '@apollo/client' // Npm: Apollo client.
import { Flex } from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import SubmitButton from 'components/SubmitButton'
import { MemoizedInput } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import VendorReadQuery from './__query__/index.vendor.read.query'
import AccountRegisterWithEmailMutation from './__mutation__/index.account.registerWithEmail.mutation'


/*
 * OBJECTS
 */
const Index = ({ onClose, passOn }) => {
  // Hook assignment.
  const [QueryVendorRead, QueryVendorReadResponse] = useLazyQuery(VendorReadQuery)
  const [MutationAccountRegisterWithEmail, MutationAccountRegisterWithEmailResponse] = useMutation(AccountRegisterWithEmailMutation)
  const [error, setError] = React.useState('')
  const _formDataRef = React.useRef({})

  // Object assignment.
  const _SubmitForm = async e => {
    // Prevent default behavior.
    e?.preventDefault()

    // Reset error.
    setError('')

    // Const assignment.
    const _JoiSchema = JoiBrowser.object({
      'password': JoiBrowser.string().required()
    }).options({ 'allowUnknown': true })

    // Remove all keys from _formDataRef.current which are undefined.
    _formDataRef.current = _.pick(_formDataRef.current, _.identity)

    // Validate form data.
    const _JoiSchemaValidate = _JoiSchema.validate(_formDataRef.current)

    // If error exists then report failure.
    if (_JoiSchemaValidate.error) return setError(_JoiSchemaValidate.error?.message)

    // Fetch vendor account login information.
    const _QueryVendorRead = await QueryVendorRead({ 'variables': { 'vendorId': passOn?.vendorId } })

    // If fetching vendor account login information caught an exception then report failure.
    if (_QueryVendorRead instanceof Error) return _QueryVendorRead
    if ('READ_SUCCESSFUL' !== _.first(_QueryVendorRead?.data?.VendorRead)?.status) return toast(_.first(_QueryVendorRead?.data?.VendorRead)?.message)
    if ('READ_SUCCESSFUL' === _.first(_QueryVendorRead?.data?.VendorRead)?.status && _.isEmpty(_.first(_QueryVendorRead?.data?.VendorRead)?.AccountLogin)) return toast('Vendor not found. Seems that you are Wholesale user.')

    // Create VendorAccount for given Vendor.
    const _MutationAccountRegisterWithEmail = await MutationAccountRegisterWithEmail({
      'variables': {
        'accountId': _.first(_QueryVendorRead?.data?.VendorRead)?.AccountLogin?.id,
        'password': _formDataRef.current?.password
      }
    })

    // If creating or updating vendor account caught an exception then report failure.
    if (_MutationAccountRegisterWithEmail instanceof Error) return _MutationAccountRegisterWithEmail

    // Style Guide.
    toast(_MutationAccountRegisterWithEmail?.data?.AccountSetting?.message)

    // On Successful response from the mutation.
    if ('UPDATE_SUCCESSFUL' === _MutationAccountRegisterWithEmail?.data?.AccountSetting?.status) onClose?.()

    // Return void 0.
    return void 0
  }

  // Return component.
  return (
    <form onSubmit={_SubmitForm}>
      <Flex gap='22px' flexDir='column' w='100%'>
        <Flex w='100%' gap='22px'>
          <MemoizedInput
            disabled={QueryVendorReadResponse.loading || MutationAccountRegisterWithEmailResponse.loading}
            isRequired={true}
            name='password'
            label='New Password'
            placeholder='e.g. "Super Secret"'
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: value
              }
            }}
            isInvalid={error?.includes('password')}
            error={error}
          />
        </Flex>
      </Flex>
      <SubmitButton disabled={QueryVendorReadResponse.loading || MutationAccountRegisterWithEmailResponse.loading} onSubmit={_SubmitForm} defaultText='Update Password' isLoading={MutationAccountRegisterWithEmailResponse.loading} />
    </form>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'passOn': PropTypes.object,
  'onClose': PropTypes.func,
  'isOpen': PropTypes.bool
}
Index.defaultProps = {}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
