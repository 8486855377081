/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation RatePlanCreateMutation($input: [RatePlanCreateInput!]!) {
  RatePlanCreate(input: $input) {
    message
    status
  }
}
`
