/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import JoiBrowser from 'joi-browser' // Npm: Joi for frontend validation.
import _ from 'underscore' // Npm: Underscore.js library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { useLazyQuery, useMutation } from '@apollo/client' // Npm: Apollo client.
import { Flex, Text } from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import SubmitButton from 'components/SubmitButton'
import DateRangePicker from 'components/DateRangePicker'
import { MemoizedInput } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import VendorInvoiceReadQuery from './__query__/index.vendorInvoice.read.query'
import VendorInvoiceUpdateMutation from './__mutation__/index.vendorInvoice.update.mutation'
import VendorInvoiceCreateMutation from './__mutation__/index.vendorInvoice.create.mutation'


/*
 * OBJECTS
 */
const Index = ({ isOpen, isCreateOnly, onClose, passOn }) => {
  // Hook assignment.
  const [dateRange, setDateRange] = React.useState({})
  const [datedOn, setDatedOn] = React.useState({})
  const [forceReRender, setForceReRender] = React.useState(void 0)
  const [error, setError] = React.useState('')
  const [QueryVendorInvoiceRead, QueryVendorInvoiceReadResponse] = useLazyQuery(VendorInvoiceReadQuery)
  const [MutationVendorInvoiceCreate, MutationVendorInvoiceCreateResponse] = useMutation(VendorInvoiceCreateMutation)
  const [MutationVendorInvoiceUpdate, MutationVendorInvoiceUpdateResponse] = useMutation(VendorInvoiceUpdateMutation)
  const _formDataRef = React.useRef({})
  const _datedOnRef = React.useRef({
    'selection': {
      'startDate': Object.DateRangeProvider()?.startDate,
      'endDate': Object.DateRangeProvider()?.endDate,
      'key': 'selection'
    }
  })
  const _dateRangeRef = React.useRef({
    'selection': {
      'startDate': Object.DateRangeProvider()?.startDate,
      'endDate': Object.DateRangeProvider()?.endDate,
      'key': 'selection'
    }
  })


  // Object assignment.
  const _SubmitForm = async e => {
    // Prevent default behavior.
    e.preventDefault()

    // Reset error.
    setError('')

    // Const assignment.
    const _JoiSchema = JoiBrowser.object({
      'amount': JoiBrowser.number().required(),
      'invoiceNumber': JoiBrowser.string().required()
    }).options({ 'allowUnknown': true })

    // Remove all keys from _formDataRef.current which are undefined.
    _formDataRef.current = _.pick(_formDataRef.current, _.identity)

    // Validate form data.
    const _JoiSchemaValidate = _JoiSchema.validate(_formDataRef.current)

    // If error exists then report failure.
    if (_JoiSchemaValidate.error) return setError(_JoiSchemaValidate.error?.message)

    // Execute account registration mutation.
    const _MutationVendorInvoice = await (isCreateOnly ? MutationVendorInvoiceCreate : MutationVendorInvoiceUpdate)({
      'variables': {
        [isCreateOnly ? 'vendorId' : 'vendorInvoiceId']: isCreateOnly ? passOn?.vendorId : passOn?.vendorInvoiceId,
        'amount': _formDataRef?.current?.amount,
        'billingStartDate': dateRange?.selection?.startDate,
        'billingEndDate': dateRange?.selection?.endDate,
        'datedOn': datedOn?.selection?.startDate,
        'invoiceNumber': _formDataRef?.current?.invoiceNumber
      }
    })

    // If mutation caught an exception then report failure.
    if (_MutationVendorInvoice instanceof Error) return _MutationVendorInvoice

    // On Successful response from the mutation.
    if ('CREATE_SUCCESSFUL' === _MutationVendorInvoice?.data?.VendorInvoiceCreate?.status) onClose?.()
    if ('UPDATE_SUCCESSFUL' === _MutationVendorInvoice?.data?.VendorInvoiceUpdate?.status) onClose?.()

    // Style Guide.
    return toast(_MutationVendorInvoice?.data?.[isCreateOnly ? 'VendorInvoiceCreate' : 'VendorInvoiceUpdate']?.message)
  }

  // Event handler.
  React.useEffect(() => {
    // _Async handler.
    const _Async = async () => {
      // Const assignment.
      const _QueryVendorInvoiceReadQuery = await QueryVendorInvoiceRead({ 'variables': { [isCreateOnly ? 'vendorId' : 'vendorInvoiceId']: isCreateOnly ? 'UN_KOWN' : passOn?.vendorInvoiceId } })

      // If query caught an exception then report failure.
      if (_QueryVendorInvoiceReadQuery instanceof Error) return _QueryVendorInvoiceReadQuery

      /*
       * If customer details fetch complete then
       * update its value.
       */
      if (!_.isEmpty(_QueryVendorInvoiceReadQuery?.data?.VendorInvoiceRead)) {
        // Update form data.
        _formDataRef.current = {
          'amount': _.first(_QueryVendorInvoiceReadQuery?.data?.VendorInvoiceRead)?.amount,
          'invoiceNumber': _.first(_QueryVendorInvoiceReadQuery?.data?.VendorInvoiceRead)?.invoiceNumber
        }

        // Update dated on.
        _dateRangeRef.current = {
          'selection': {
            'startDate': _.first(_QueryVendorInvoiceReadQuery?.data?.VendorInvoiceRead)?.billingStartDate,
            'endDate': _.first(_QueryVendorInvoiceReadQuery?.data?.VendorInvoiceRead)?.billingEndDate,
            'key': 'selection'
          }
        }
        _datedOnRef.current = {
          'selection': {
            'startDate': _.first(_QueryVendorInvoiceReadQuery?.data?.VendorInvoiceRead)?.datedOn,
            'endDate': _.first(_QueryVendorInvoiceReadQuery?.data?.VendorInvoiceRead)?.datedOn,
            'key': 'selection'
          }
        }

        // Update force re-render.
        setForceReRender(Math.random())
      }

      // Report failure.
      return void 0
    }; _Async()
  }, [passOn, isOpen])

  // Const assignment.
  const _isLoading = MutationVendorInvoiceCreateResponse.loading || MutationVendorInvoiceUpdateResponse.loading
  const _isInputDisabled = isCreateOnly ? false : _isLoading || QueryVendorInvoiceReadResponse?.loading

  // Return component.
  return (
    <form onSubmit={_SubmitForm}>
      <Flex w='100%' gap='22px' flexDir='column'>
        <Flex w='100%' flexDir='column' gap='22px'>
          <Flex w='100%' flexDir='column' justifyContent='space-between' gap='22px'>
            <Flex flexDir='column' gap='5px'>
              <Text fontWeight={500}>Billing Period<Text as='span' color='red.500'>*</Text></Text>
              <DateRangePicker
                startDate={_dateRangeRef.current?.selection?.startDate}
                endDate={_dateRangeRef.current?.selection?.endDate}
                w='100%'
                fontSize='13px'
                asFatButton={true}
                height='40px'
                gap='6px'
                setSelectDate={setDateRange}
                key={forceReRender}
              />
            </Flex>
            <Flex flexDir='column' gap='6px'>
              <Text fontWeight={500}>Invoice Date<Text as='span' color='red.500'>*</Text></Text>
              <DateRangePicker
                startDate={_datedOnRef.current?.selection?.startDate}
                endDate={_datedOnRef.current?.selection?.endDate}
                asFatButton={true}
                setSelectDate={setDatedOn}
                key={forceReRender}
              />
            </Flex>
          </Flex>
          <MemoizedInput
            disabled={_isInputDisabled}
            isRequired={true}
            name='invoiceNumber'
            label='Invoice Number'
            placeholder={`e.g. AGDK234P-01`}
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: value
              }
            }}
            error={error}
            isInvalid={error?.includes('invoiceNumber')}
            data={_formDataRef?.current?.invoiceNumber}
          />
          <MemoizedInput
            disabled={_isInputDisabled}
            isRequired={true}
            name='amount'
            label={`Amount (in ${passOn?.currency ?? 'EUR'})`}
            placeholder={`e.g. "200 ${passOn?.currency ?? 'EUR'}"`}
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: isNaN(parseFloat(value, 10)) ? 0 : parseFloat(value, 10)
              }
            }}
            error={error}
            isInvalid={error?.includes('amount')}
            data={_formDataRef?.current?.amount}
          />
        </Flex>
      </Flex>
      <SubmitButton onSubmit={_SubmitForm} disabled={_isInputDisabled} defaultText='Upsert Vendor Invoice' isLoading={_isLoading} />
    </form>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'isCreateOnly': PropTypes.bool,
  'isOpen': PropTypes.bool,
  'onClose': PropTypes.func,
  'passOn': PropTypes.object
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
