/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useMutation } from '@apollo/client' // Npm: Apollo client.
import { toast } from 'react-hot-toast' // Npm: React hot toast.


/*
 * SIBLINGS
 */
import DeleteCard from 'components/DeleteCard'


/*
 * GRAPHS
 */
import BlockDestinationAddressDeleteMany from './__mutation__/index.blockDestinationAddress.delete.mutation'


/*
 * OBJECTS
 */
const Index = ({ passOn, onClose }) => {
  // Hook assignment.
  const [MutationBlockDestinationAddressDelete, MutationBlockDestinationAddressDeleteResponse] = useMutation(BlockDestinationAddressDeleteMany)

  // Return Component.
  return (
    <DeleteCard onClose={onClose} disabled={MutationBlockDestinationAddressDeleteResponse.loading} onConfirm={() => MutationBlockDestinationAddressDelete({ 'variables': { 'blockDestinationAddressIds': passOn?.blockDestinationAddressIds ? passOn?.blockDestinationAddressIds : passOn?.blockDestinationAddressId } }).then(i => {
      // Style Guide.
      i?.data?.BlockDestinationAddressDeleteMany?.map(ir => toast(ir?.message))

      // Report success.
      return onClose?.()
    }).catch(() =>
      // Style Guide.
      toast('Something went wrong please try after some time.'))} isLoading={MutationBlockDestinationAddressDeleteResponse.loading} />
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'passOn': PropTypes.object,
  'onClose': PropTypes.func
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
