/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client.


/*
 * EXPORTS
 */
export default gql`
mutation RoutePlanDeleteManyMutation($routePlanIds: [ID!] !) {
    RoutePlanDeleteMany(routePlanIds: $routePlanIds){
      message
      status
    }
  }
`

