/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query CreditHistoryReadQuery($creditId: ID!, $startDate: DateTime, $endDate: DateTime, $take: PositiveInt, $skip: Int) {
  CreditHistoryRead(creditId: $creditId, startDate: $startDate, endDate: $endDate, take: $take, skip: $skip) {
    id,
    creditedBy,
    balance,
    limit,
    message,
    status,
    _totalCount
  }
}
`
