/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
    query IpReadQuery($ipDirectoryId: ID, $ipToSearch: String, $take: PositiveInt, $skip: Int) {
      IpRead(ipDirectoryId: $ipDirectoryId, ipToSearch: $ipToSearch, take: $take, skip: $skip) {
        id,
        createdAt,
        updatedAt,
        ip,
        status,
        message,
        _totalCount
    }
  }
`
