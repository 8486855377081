/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client.


/*
 * EXPORTS
 */
export default gql`
query CustomerAccountConnectedQuery($type: CUSTOMER_TYPE!, $isActive: Boolean!) {
    CustomerAccountConnected(type: $type, isActive: $isActive) {
        displayName,
        message,
        status,
        Smpp {
            id,
            ip,
            isActive,
            mode
        }
    }
} 
`

