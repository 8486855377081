/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import { HiTrash } from 'react-icons/hi2' // Npm: React icons.
import {
  Button,
  Flex,
  Spinner,
  Text
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * OBJECTS
 */
const Index = ({ icon, subHeading, onConfirm, isLoading, onClose, disabled }) => (
  <>
    <Flex
      direction='row'
      align='center'
      justifyContent='start'
      gap='22px'>
      <Button px='0' py='0' w='auto' h='auto' bg='none' _hover={{ 'bg': 'none' }} _focus={{ 'bg': 'none' }} color='red.400'>{icon ? icon : <HiTrash size={37} />}</Button>
      <Flex
        direction='column'
        align='left'
        justifyContent='left'>
        <Text
          color='rgba(43, 54, 116, 1)'
          fontStyle='DM Sans'
          fontWeight='500'
          fontSize='lg'
          lineHeight='32px'>
                  Are you sure ?
        </Text>
        <Text
          color='rgba(43, 54, 116, 0.5)'
          fontStyle='DM Sans'
          fontWeight='500'
          fontSize='md'
          lineHeight='24px'>
          {subHeading}
        </Text>
      </Flex>
    </Flex>
    <Flex flexDirection='row' align='center' gap='22px' mt='22px'>
      <Button
        flex='1'
        height='46px'
        fontStyle='DM Sans'
        fontWeight='700'
        fontSize='16px'
        lineHeight='24px'
        color='rgba(255, 255, 255, 1)'
        bg='red.500'
        disabled={disabled}
        _hover={{ 'bg': 'red.500', 'color': 'rgba(255, 255, 255, 1)' }}
        _active={{ 'bg': 'red.500', 'color': 'rgba(255, 255, 255, 1)' }}
        onClick={onConfirm}>
        {isLoading ? <Spinner color='rgba(255, 255, 255, 1)' size='sm' ml='10px' /> : 'Yes'}
      </Button>
      <Button
        flex='1'
        height='46px'
        fontStyle='DM Sans'
        fontWeight='700'
        fontSize='16px'
        lineHeight='24px'
        bg='rgba(117, 81, 255, 1)'
        color='rgba(255, 255, 255, 1)'
        disabled={disabled}
        _hover={{ 'bg': 'rgba(117, 81, 255, 1)', 'color': 'rgba(255, 255, 255, 1)' }}
        _active={{ 'bg': 'rgba(117, 81, 255, 1)', 'color': 'rgba(255, 255, 255, 1)' }}
        onClick={onClose}>
                    No
      </Button>
    </Flex>
  </>
)


/*
 * PROPTYPES
 */
Index.propTypes = {
  'icon': PropTypes.any,
  'subHeading': PropTypes.string,
  'onConfirm': PropTypes.func,
  'isLoading': PropTypes.bool,
  'onClose': PropTypes.func
}
Index.defaultProps = {
  'subHeading': 'This action cannot be undone.'
}


/*
 * EXPORT
 */
export default Index
