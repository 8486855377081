/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
    mutation CustomerInvoiceCreateMutation($customerId: ID!, $entityId: ID!, $smsStartDate: DateTime!, $smsEndDate: DateTime!) {
      CustomerInvoiceCreate(customerId: $customerId, entityId: $entityId, smsStartDate: $smsStartDate, smsEndDate: $smsEndDate) {
        status,
        message
    }
  }
`
