/*
 * IMPORTS
 */
import React from 'react' // Npm: React.js library.
import PropTypes from 'prop-types' // Npm: Prop Types for checking the props type.
import Debounce from 'lodash/debounce' // Npm: Debounce library.
import AutoSizer from 'react-virtualized-auto-sizer' // Npm: React virtualized auto sizer.
import InfiniteLoader from 'react-window-infinite-loader' // Npm: React window infinite loader.
import NaturalCompare from 'natural-compare' // Npm: Sorting library.
import _ from 'underscore' // Npm: Underscore.js for utility functions.
import { BsCheckAll } from 'react-icons/bs' // Npm: React Icons for icons.
import { useLazyQuery, useQuery } from '@apollo/client' // Npm: Apollo client.
import { FixedSizeList as List } from 'react-window' // Npm: React Virtualized for virtualized list.
import { HiCheckCircle, HiPlusCircle, HiXCircle } from 'react-icons/hi2' // Npm: React Icons for icons.
import {
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text
} from '@chakra-ui/react' // Npm: Chakra UI A simple, modular and accessible component library for React.


/*
 * COMPONENTS
 */
import { MemoizedSelect } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import IpDirectoryReadQuery from './__query__/index.ipDirectory.read.query'
import IpReadQuery from './__query__/index.ip.read.query'


/*
 * OBJECTS
 */
const Index = ({ ipDirectoryValue, ipValue, inValidIp, disabled, inValidIpDirectory, isRequired, onChange }) => {
  // Const assignment.
  const _skipDifference = 1000

  // Hook assignment.
  const [ipDirectory, setIpDirectory] = React.useState('')
  const [ip, setIp] = React.useState([])
  const [, setForceReRender] = React.useState(void 0)
  const [QueryIpRead, QueryIpReadResponse] = useLazyQuery(IpReadQuery)
  const _ipListRef = React.useRef([])
  const _oldIpDirectory = React.useRef(void 0)
  const _QueryIpDirectoryRead = useQuery(IpDirectoryReadQuery, { 'variables': { 'take': _skipDifference, 'skip': 0 }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })

  // Object assignment.
  const _LoadMore = async (__take, __skip, __replaceInstead) => {
    // Const assignment.
    const _ipDirectoryId = (ipDirectory)?.split('(')[1]?.split(')')[0]

    // If id is empty then just return.
    if (_.isEmpty(_ipDirectoryId)) return void 0

    // Query for given directory read.
    const _QueryIpRead = await QueryIpRead({ 'variables': { 'take': __take, 'skip': __skip, 'ipDirectoryId': _ipDirectoryId } })

    // Report failure if caught exception.
    if (_QueryIpRead?.error) throw _QueryIpRead?.error

    // Update ip list available.
    _ipListRef.current = __replaceInstead ? _QueryIpRead?.data?.IpRead?.filter(j => 'READ_SUCCESSFUL' === j.status) : [..._ipListRef.current, ...(_QueryIpRead?.data?.IpRead?.filter(j => 'READ_SUCCESSFUL' === j.status) ?? [])]

    // Return void.
    return void 0
  }

  // Event handler.
  React.useEffect(() => {
    /*
     * Update selected country if ipDirectory value
     * Is passed in params.
     */
    ipDirectoryValue && _.isEmpty(ipDirectory) && setIpDirectory(ipDirectoryValue ?? '')
    ipValue && _.isEmpty(ip) && setIp(ipValue ?? [])
  }, [ipDirectoryValue, ipValue])
  React.useEffect(() => {
    // Load more ips.
    !_.isEmpty(ipDirectory) && _LoadMore(10, 0).then(() => setForceReRender(Math.random()))
  }, [ipDirectory])
  React.useEffect(() => {
    /*
     * If directory change occurs then refetch
     * new data.
     */
    if (_oldIpDirectory.current !== ipDirectory) {
      // Load new batch of data.
      _LoadMore(10, 0, true).then(() => setForceReRender(Math.random()))

      // Update old ref.
      _oldIpDirectory.current = ipDirectory
    }
  }, [ipDirectory])

  // Component assignment.
  const _List = ({ index, style }) => _.isEmpty(_ipListRef.current[index]) ? void 0 : (
    <MenuItem key={String.random(8)} style={style}>
      <Checkbox
        disabled={0 === _ipListRef.current.length}
        name='ip'
        onChange={() => {
          // Update state of ips.
          setIp(j => {
            /*
             * If only one selection is allowed
             * Then clear the array.
             */
            if (j.includes(`${_ipListRef.current[index].ip} (${_ipListRef.current[index]?.id})`)) {
              // Const assignment.
              const _data = _.without(j, `${_ipListRef.current[index].ip} (${_ipListRef.current[index]?.id})`, 'ALL')

              // Return updated mcc and mnc.
              onChange({ 'ip': _data, 'ipDirectory': ipDirectory })

              // Return updated ip.
              return _data
            }

            // Return updated ip.
            onChange({ 'ip': [...j, `${_ipListRef.current[index].ip} (${_ipListRef.current[index]?.id})`], 'ipDirectory': ipDirectory })

            // Return updated ip and id.
            return [...j, `${_ipListRef.current[index].ip} (${_ipListRef.current[index]?.id})`]
          })
        }}
        isChecked={ip.includes('ALL') || ip.includes('ALL (ALL)') || ip.includes(`${_ipListRef.current[index].ip} (${_ipListRef.current[index]?.id})`)}>
        {`${_ipListRef.current[index].ip} (${_ipListRef.current[index]?.id})`}
      </Checkbox>
    </MenuItem>
  )

  // Prop types.
  _List.propTypes = {
    'index': PropTypes.number,
    'style': PropTypes.object
  }

  // Return the JSX.
  return (
    <Flex w='100%' className='ipDirectoryAndIpSelector' flexDir='column' gap='22px'>
      <MemoizedSelect
        label='Ip Folder'
        disabled={_QueryIpDirectoryRead?.loading || disabled}
        name='ipDirectory'
        value={ipDirectory}
        placeholder='Select Folder'
        options={_.compact(_QueryIpDirectoryRead?.data?.IpDirectoryRead?.map?.(j => 'READ_SUCCESSFUL' === j.status ? `${j.displayName} (${j.id})` : void 0))?.sort((a, b) => NaturalCompare(a, b))}
        isInvalid={inValidIpDirectory}
        onChange={__event => {
          // Update states.
          setIpDirectory(__event.target.value)
          setIp([])

          // Return updated ipDirectory and ip.
          onChange({ 'ip': [], 'ipDirectory': __event.target.value })
        }}
      />
      <FormControl flexDir='column' w='100%'>
        <FormLabel
          display='flex'
          alignItems='center'
          style={{ 'pointerEvents': 'none' }}>
          Ips{' '}{isRequired ? <Text color='red'>*</Text> : void 0}
        </FormLabel>
        <Menu closeOnSelect={false} position='relative' disabled={QueryIpReadResponse.loading || _QueryIpDirectoryRead?.loading || disabled} w='100%'>
          <MenuButton
            type='button'
            disabled={QueryIpReadResponse.loading || _QueryIpDirectoryRead?.loading || disabled}
            h='40px'
            color={disabled ? '#aaafc8' : void 0}
            px='4'
            onClick={e => e.stopPropagation()}
            m='0'
            w='100%'
            borderRadius='12px'
            transition='all 0.2s'
            textAlign='left'
            rightIcon={<BsCheckAll />}
            bg={inValidIp ? 'rgb(255,255,255,0.8)' : 'gray.100'}
            _hover={{ 'bg': 'gray.100' }}
            _expanded={{ 'bg': 'gray.100' }}
            _focus={{ 'bg': 'gray.100' }}
            boxShadow={inValidIp ? '0 0 0 1.5px #EE5D50' : void 0}>
            {(ip.includes('ALL') || ip.includes('ALL (ALL)') ? _.first(_ipListRef.current)?._totalCount : (_.isString(ip) && !_.isEmpty(ip) ? [ip] : ip)?.length) || 0} Selected
          </MenuButton>
          <MenuList borderRadius={12} h='250px' w='100%'>
            <AutoSizer>
              {({ height, width }) => (
                <InfiniteLoader
                  width={width}
                  height={height}
                  isItemLoaded={__index => Boolean(_ipListRef.current[__index])}
                  itemCount={_ipListRef.current.length}
                  threshold={0.9}
                  loadMoreItems={(__startIndex, __endIndex) => _LoadMore(10, parseInt(__endIndex, 10) + 1)}>
                  {({ onItemsRendered, ref }) => (
                    <List
                      ref={ref}
                      height={height}
                      itemCount={_ipListRef.current.length}
                      itemSize={52}
                      onItemsRendered={onItemsRendered}
                      overflow='hidden'
                      width={width}>
                      {_List}
                    </List>
                  )}
                </InfiniteLoader>
              )}
            </AutoSizer>
          </MenuList>
          <Flex position='absolute' right={0} bottom={0} zIndex={1000}>
            <IconButton
              aria-label='Select All'
              disabled={QueryIpReadResponse.loading || _QueryIpDirectoryRead?.loading || disabled}
              _hover={{ 'bg': 'none' }}
              _active={{ 'bg': 'none' }}
              onClick={() => {
                // Const assignment.
                const _ipIds = ['ALL']

                // Update ip.
                setIp(_ipIds)

                // Return updated ip.
                onChange({ 'ip': _ipIds, 'ipDirectory': ipDirectory })
              }}
              right='-10px'
              bg='none'
              p='0'>
              {ip?.length === _ipListRef.current?.length ? (<HiCheckCircle fontSize='20px' color='#c4c4c4' />) : (<HiPlusCircle fontSize='20px' color='#3CA55C' />)}
            </IconButton>
            <IconButton
              aria-label='Clear All'
              disabled={0 === ip?.length || QueryIpReadResponse.loading || _QueryIpDirectoryRead?.loading || disabled}
              _hover={{ 'bg': 'none' }}
              _active={{ 'bg': 'none' }}
              onClick={() => {
                // Update states.
                setIp([])

                // Return updated mcc and mnc.
                onChange({ 'ip': [], 'ipDirectory': '' })
              }}
              bg='none'
              p='0'>
              {0 === ip?.length ? (<HiXCircle fontSize='20px' color='#c4c4c4' />) : (<HiXCircle fontSize='20px' color='#FF416C' />)}
            </IconButton>
          </Flex>
        </Menu>
      </FormControl>
    </Flex>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'ipDirectoryValue': PropTypes.string,
  'ipIdValue': PropTypes.array,
  'inValidIpId': PropTypes.bool,
  'ipValue': PropTypes.array,
  'inValidIp': PropTypes.bool,
  'disabled': PropTypes.bool,
  'inValidIpDirectory': PropTypes.bool,
  'onChange': PropTypes.func,
  'isRequired': PropTypes.bool
}


/*
 * EXPORT
 */
export default Index
