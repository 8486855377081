/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query CustomerReadQuery($customerId: ID!) {
  CustomerRead(customerId: $customerId) {
    status,
    message,
    blockBetweenFromHour,
    blockBetweenToHour,
    blockBetweenFromDate,
    blockBetweenToDate,
    blockInBetweenWeeks
  }
}
`
