/*
 * IMPORTS
 */
import React from 'react' // Npm: React.js library.
import PropTypes from 'prop-types' // Npm: Prop Types for checking the props type.
import Debounce from 'lodash/debounce' // Npm: Debounce library.
import AutoSizer from 'react-virtualized-auto-sizer' // Npm: React virtualized auto sizer.
import InfiniteLoader from 'react-window-infinite-loader' // Npm: React window infinite loader.
import NaturalCompare from 'natural-compare' // Npm: Sorting library.
import _ from 'underscore' // Npm: Underscore.js for utility functions.
import { BsCheckAll } from 'react-icons/bs' // Npm: React Icons for icons.
import { useLazyQuery, useQuery } from '@apollo/client' // Npm: Apollo client.
import { FixedSizeList as List } from 'react-window' // Npm: React Virtualized for virtualized list.
import { HiCheckCircle, HiPlusCircle, HiXCircle } from 'react-icons/hi2' // Npm: React Icons for icons.
import {
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text
} from '@chakra-ui/react' // Npm: Chakra UI A simple, modular and accessible component library for React.


/*
 * COMPONENTS
 */
import { MemoizedSelect } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import PhoneNumberoDirectoryReadQuery from './__query__/index.phoneNumberoDirectory.read.query'
import PhoneNumberoReadQuery from './__query__/index.phoneNumbero.read.query'


/*
 * OBJECTS
 */
const Index = ({ phoneNumberoDirectoryValue, phoneNumberoValue, inValidPhoneNumbero, disabled, inValidPhoneNumberoDirectory, isRequired, onChange }) => {
  // Const assignment.
  const _skipDifference = 1000

  // Hook assignment.
  const [phoneNumberoDirectory, setPhoneNumberoDirectory] = React.useState('')
  const [phoneNumbero, setPhoneNumbero] = React.useState([])
  const [, setForceReRender] = React.useState(void 0)
  const [QueryPhoneNumberoRead, QueryPhoneNumberoReadResponse] = useLazyQuery(PhoneNumberoReadQuery)
  const _phoneNumberoListRef = React.useRef([])
  const _oldPhoneNumberoDirectory = React.useRef(void 0)
  const _QueryPhoneNumberoDirectoryRead = useQuery(PhoneNumberoDirectoryReadQuery, { 'variables': { 'take': _skipDifference, 'skip': 0 }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })

  // Object assignment.
  const _LoadMore = async (__take, __skip, __replaceInstead) => {
    // Const assignment.
    const _phoneNumberoDirectoryId = (phoneNumberoDirectory)?.split('(')[1]?.split(')')[0]

    // If id is empty then just return.
    if (_.isEmpty(_phoneNumberoDirectoryId)) return void 0

    // Query for given directory read.
    const _QueryPhoneNumberoRead = await QueryPhoneNumberoRead({ 'variables': { 'take': __take, 'skip': __skip, 'phoneNumberoDirectoryId': _phoneNumberoDirectoryId } })

    // Report failure if caught exception.
    if (_QueryPhoneNumberoRead?.error) throw _QueryPhoneNumberoRead?.error

    // Update phoneNumbero list available.
    _phoneNumberoListRef.current = __replaceInstead ? _QueryPhoneNumberoRead?.data?.PhoneNumberoRead?.filter(r => 'READ_SUCCESSFUL' === r.status) : [..._phoneNumberoListRef.current, ...(_QueryPhoneNumberoRead?.data?.PhoneNumberoRead?.filter(r => 'READ_SUCCESSFUL' === r.status) ?? [])]

    // Return void 0.
    return void 0
  }

  // Event handler.
  React.useEffect(() => {
    /*
     * Update selected country if phoneNumberoDirectory value
     * Is passed in params.
     */
    phoneNumberoDirectoryValue && _.isEmpty(phoneNumberoDirectory) && setPhoneNumberoDirectory(phoneNumberoDirectoryValue ?? '')
    phoneNumberoValue && _.isEmpty(phoneNumbero) && setPhoneNumbero(phoneNumberoValue ?? [])
  }, [phoneNumberoDirectoryValue, phoneNumberoValue])
  React.useEffect(() => {
    // Load more phoneNumberos.
    !_.isEmpty(phoneNumberoDirectory) && _LoadMore(10, 0).then(() => setForceReRender(Math.random()))
  }, [phoneNumberoDirectory])
  React.useEffect(() => {
    /*
     * If directory change occurs then refetch
     * new data.
     */
    if (_oldPhoneNumberoDirectory.current !== phoneNumberoDirectory) {
      // Load new batch of data.
      _LoadMore(10, 0, true).then(() => setForceReRender(Math.random()))

      // Update old ref.
      _oldPhoneNumberoDirectory.current = phoneNumberoDirectory
    }
  }, [phoneNumberoDirectory])

  // Component assignment.
  const _List = ({ index, style }) => _.isEmpty(_phoneNumberoListRef.current[index]) ? void 0 : (
    <MenuItem key={String.random(8)} style={style}>
      <Checkbox
        disabled={0 === _phoneNumberoListRef.current.length}
        name='phoneNumbero'
        onChange={() => {
          // Update state of phoneNumberos.
          setPhoneNumbero(j => {
            /*
             * If only one selection is allowed
             * Then clear the array.
             */
            if (j.includes(`${_phoneNumberoListRef.current[index].phoneNumbero} (${_phoneNumberoListRef.current[index]?.id})`)) {
              // Const assignment.
              const _data = _.without(j, `${_phoneNumberoListRef.current[index].phoneNumbero} (${_phoneNumberoListRef.current[index]?.id})`, 'ALL')

              // Return updated mcc and mnc.
              onChange({ 'phoneNumbero': _data, 'phoneNumberoDirectory': phoneNumberoDirectory })

              // Return updated phoneNumbero.
              return _data
            }

            // Return updated phoneNumbero.
            onChange({ 'phoneNumbero': [...j, `${_phoneNumberoListRef.current[index].phoneNumbero} (${_phoneNumberoListRef.current[index]?.id})`], 'phoneNumberoDirectory': phoneNumberoDirectory })

            // Return updated phoneNumbero and id.
            return [...j, `${_phoneNumberoListRef.current[index].phoneNumbero} (${_phoneNumberoListRef.current[index]?.id})`]
          })
        }}
        isChecked={phoneNumbero.includes('ALL') || phoneNumbero.includes('ALL (ALL)') || phoneNumbero.includes(`${_phoneNumberoListRef.current[index].phoneNumbero} (${_phoneNumberoListRef.current[index]?.id})`)}>
        {`${_phoneNumberoListRef.current[index].phoneNumbero} (${_phoneNumberoListRef.current[index]?.id})`}
      </Checkbox>
    </MenuItem>
  )

  // Prop types.
  _List.propTypes = {
    'index': PropTypes.number,
    'style': PropTypes.object
  }

  // Return the JSX.
  return (
    <Flex w='100%' className='phoneNumberoDirectoryAndPhoneNumberoSelector' flexDir='column' gap='22px'>
      <MemoizedSelect
        label='PhoneNumbero Folder'
        disabled={_QueryPhoneNumberoDirectoryRead?.loading || disabled}
        name='phoneNumberoDirectory'
        value={phoneNumberoDirectory}
        placeholder='Select Folder'
        options={_.compact(_QueryPhoneNumberoDirectoryRead?.data?.PhoneNumberoDirectoryRead?.map?.(j => 'READ_SUCCESSFUL' === j.status ? `${j.displayName} (${j.id})` : void 0))?.sort((a, b) => NaturalCompare(a, b))}
        isInvalid={inValidPhoneNumberoDirectory}
        onChange={__event => {
          // Update states.
          setPhoneNumberoDirectory(__event.target.value)
          setPhoneNumbero([])

          // Return updated phoneNumberoDirectory and phoneNumbero.
          onChange({ 'phoneNumbero': [], 'phoneNumberoDirectory': __event.target.value })
        }}
      />
      <FormControl flexDir='column' w='100%'>
        <FormLabel
          display='flex'
          alignItems='center'
          style={{ 'pointerEvents': 'none' }}>
          PhoneNumberos{' '}{isRequired ? <Text color='red'>*</Text> : void 0}
        </FormLabel>
        <Menu closeOnSelect={false} position='relative' disabled={QueryPhoneNumberoReadResponse.loading || _QueryPhoneNumberoDirectoryRead?.loading || disabled} w='100%'>
          <MenuButton
            type='button'
            disabled={QueryPhoneNumberoReadResponse.loading || _QueryPhoneNumberoDirectoryRead?.loading || disabled}
            h='40px'
            color={disabled ? '#aaafc8' : void 0}
            px='4'
            onClick={e => e.stopPropagation()}
            m='0'
            w='100%'
            borderRadius='12px'
            transition='all 0.2s'
            textAlign='left'
            rightIcon={<BsCheckAll />}
            bg={inValidPhoneNumbero ? 'rgb(255,255,255,0.8)' : 'gray.100'}
            _hover={{ 'bg': 'gray.100' }}
            _expanded={{ 'bg': 'gray.100' }}
            _focus={{ 'bg': 'gray.100' }}
            boxShadow={inValidPhoneNumbero ? '0 0 0 1.5px #EE5D50' : void 0}>
            {phoneNumbero.includes('ALL') || phoneNumbero.includes('ALL (ALL)') ? _.first(_phoneNumberoListRef.current)?._totalCount : (_.isString(phoneNumbero) && !_.isEmpty(phoneNumbero) ? [phoneNumbero] : phoneNumbero)?.length ?? 0} Selected
          </MenuButton>
          <MenuList borderRadius={12} h='250px' w='100%'>
            <AutoSizer>
              {({ height, width }) => (
                <InfiniteLoader
                  width={width}
                  height={height}
                  isItemLoaded={__index => Boolean(_phoneNumberoListRef.current[__index])}
                  itemCount={_phoneNumberoListRef.current.length}
                  threshold={0.9}
                  loadMoreItems={(__startIndex, __endIndex) => _LoadMore(10, parseInt(__endIndex, 10) + 1)}>
                  {({ onItemsRendered, ref }) => (
                    <List
                      ref={ref}
                      height={height}
                      itemCount={_phoneNumberoListRef.current.length}
                      itemSize={52}
                      onItemsRendered={onItemsRendered}
                      overflow='hidden'
                      width={width}>
                      {_List}
                    </List>
                  )}
                </InfiniteLoader>
              )}
            </AutoSizer>
          </MenuList>
          <Flex position='absolute' right={0} bottom={0} zIndex={1000}>
            <IconButton
              aria-label='Select All'
              disabled={0 === _phoneNumberoListRef.current.length || QueryPhoneNumberoReadResponse.loading || _QueryPhoneNumberoDirectoryRead?.loading || disabled}
              _hover={{ 'bg': 'none' }}
              _active={{ 'bg': 'none' }}
              onClick={() => {
                // Const assignment.
                const _phoneNumberoIds = ['ALL']

                // Update phoneNumbero.
                setPhoneNumbero(_phoneNumberoIds)

                // Return updated phoneNumbero.
                onChange({ 'phoneNumbero': _phoneNumberoIds, 'phoneNumberoDirectory': phoneNumberoDirectory })
              }}
              right='-10px'
              bg='none'
              p='0'>
              {phoneNumbero?.length === _phoneNumberoListRef.current?.length ? (<HiCheckCircle fontSize='20px' color='#c4c4c4' />) : (<HiPlusCircle fontSize='20px' color='#3CA55C' />)}
            </IconButton>
            <IconButton
              aria-label='Clear All'
              disabled={0 === _phoneNumberoListRef.current.length || QueryPhoneNumberoReadResponse.loading || _QueryPhoneNumberoDirectoryRead?.loading || disabled}
              _hover={{ 'bg': 'none' }}
              _active={{ 'bg': 'none' }}
              onClick={() => {
                // Update states.
                setPhoneNumbero([])

                // Return updated mcc and mnc.
                onChange({ 'phoneNumbero': [], 'phoneNumberoDirectory': '' })
              }}
              bg='none'
              p='0'>
              {0 === phoneNumbero?.length ? (<HiXCircle fontSize='20px' color='#c4c4c4' />) : (<HiXCircle fontSize='20px' color='#FF416C' />)}
            </IconButton>
          </Flex>
        </Menu>
      </FormControl>
    </Flex>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'phoneNumberoDirectoryValue': PropTypes.string,
  'phoneNumberoIdValue': PropTypes.array,
  'phoneNumberoValue': PropTypes.array,
  'inValidPhoneNumbero': PropTypes.bool,
  'disabled': PropTypes.bool,
  'inValidPhoneNumberoDirectory': PropTypes.bool,
  'onChange': PropTypes.func,
  'isRequired': PropTypes.bool
}


/*
 * EXPORT
 */
export default Index
