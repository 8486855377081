/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * GRAPH
 */
const Index = gql`
query PaymentReadQuery($customerId: ID, $take: PositiveInt, $skip: Int)  {
  PaymentRead(customerId: $customerId, take: $take, skip: $skip) {
    id,
    message,
    status,
    amount,
    _totalCount
  }
} 
`

/*
 * EXPORTS
 */
export default Index
