/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import Moment from 'moment' // Npm: moment.js library.
import Stringg from 'string' // Npm: string.js library.
import Debounce from 'lodash/debounce' // Npm: lodash library.
import _ from 'underscore' // Npm: underscore.js library
import 'react-calendar/dist/Calendar.css' // Npm: react-calendar css.
import { HiArrowUturnDown } from 'react-icons/hi2' // Npm: react-icons library.
import { useQuery } from '@apollo/client' // Npm: Apollo client.
import {
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  useBreakpointValue
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import DateRangePicker from 'components/DateRangePicker'
import DownloadToExcel from 'components/DownloadToExcel'
import TableSpinner from 'components/TableSpinner'
import PaginationWithPageInformation from 'components/PaginationWithPageInformation'
import { MemoizedInput, MemoizedSelect } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import SmsReadQuery from './__query__/index.sms.read.query'


/*
 * STYLES
 */
import { cellStyle, headerStyle, rowStyle } from './index.style'


/*
 * OBJECTS
 */
const Index = ({ vendorId }) => {
  // Const assignment.
  const _skipDifference = 8

  // Hook assignment.
  const [dateRange, setDateRange] = React.useState({
    'selection': {
      'startDate': Object.DateRangeProvider()?.startDate,
      'endDate': Object.DateRangeProvider()?.endDate,
      'key': 'selection'
    }
  })
  const [skipPage, setSkipPage] = React.useState(0)
  const [mobileNumberSearch, setMobileNumberSearch] = React.useState('')
  const [smsIdToSearch, setSmsIdToSearch] = React.useState('')
  const [vendorNameToSearch, setVendorNameToSearch] = React.useState('')
  const [vendorSmsIdToSearch, setVendorSmsIdToSearch] = React.useState('')
  const [dlrStatusToSearch, setDlrStatusToSearch] = React.useState(void 0)
  const [senderIdToSearch, setSenderIdToSearch] = React.useState('')
  const [countryNameToSearch, setCountryNameToSearch] = React.useState(void 0)
  const [customerAccountSmppNameToSearch, setCustomerAccountSmppNameToSearch] = React.useState(void 0)
  const [mncToSearch, setMncToSearch] = React.useState(void 0)
  const [mccToSearch, setMccToSearch] = React.useState(void 0)
  const [skipDifference, setSkipDifference] = React.useState(_skipDifference)
  const _QuerySmsRead = useQuery(SmsReadQuery, {
    'variables': {
      'skip': skipPage * skipDifference,
      'take': skipDifference,
      'vendorId': vendorId,
      'mobileNumberToSearch': mobileNumberSearch,
      'vendorSmsIdToSearch': vendorSmsIdToSearch,
      'smsIdToSearch': smsIdToSearch,
      'dlrStatusToSearch': dlrStatusToSearch,
      'vendorNameToSearch': vendorNameToSearch,
      'senderIdToSearch': senderIdToSearch,
      'countryNameToSearch': countryNameToSearch,
      'mccToSearch': mccToSearch,
      'mncToSearch': mncToSearch,
      'customerAccountSmppNameToSearch': customerAccountSmppNameToSearch,
      'startDate': dateRange?.selection.startDate,
      'endDate': dateRange?.selection.endDate
    },
    'fetchPolicy': Object.React.App.fetchPolicy,
    'pollInterval': Object.React.App.pollInterval
  })
  const _isFirstLoadCompleted = React.useRef(false)
  const _onMobileNumberSearchInputChange = React.useCallback(Debounce(e => setMobileNumberSearch(e.target.value), 800), [])
  const _onVendorSmsIdToSearchInputChange = React.useCallback(Debounce(e => setVendorSmsIdToSearch(e.target.value), 800), [])
  const _onSmsIdToSearchInputChange = React.useCallback(Debounce(e => setSmsIdToSearch(e.target.value), 800), [])
  const _onDlrStatusToSearchInputChange = React.useCallback(Debounce(e => setDlrStatusToSearch(e.target.value || void 0), 800), [])
  const _onSenderIdToSearchInputChange = React.useCallback(Debounce(e => setSenderIdToSearch(e.target.value), 800), [])
  const _onVendorNameToSearchInputChange = React.useCallback(Debounce(e => setVendorNameToSearch(e.target.value), 800), [])
  const _onMccToSearchInputChange = React.useCallback(Debounce(e => setMccToSearch(e.target.value), 800), [])
  const _onMncToSearchInputChange = React.useCallback(Debounce(e => setMncToSearch(e.target.value), 800), [])
  const _onCountryNameToSearchInputChange = React.useCallback(Debounce(e => setCountryNameToSearch(e.target.value), 800), [])
  const _onCustomerNameToSearchInputChange = React.useCallback(Debounce(e => setCustomerAccountSmppNameToSearch(e.target.value), 800), [])
  const _tableHeaderHeightRef = React.useRef(0)
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })

  // Check if first load is completed.
  if (!_QuerySmsRead?.loading && 0 < _QuerySmsRead?.data?.SmsRead?.length) _isFirstLoadCompleted.current = true

  // Return component.
  return (
    <>
      <Flex className='reportsMessage base'>
        <Flex
          display='flex'
          flex={1}
          flexDirection='column'
          gap={_isCurrentViewMobile ? '12px' : '22px'}
          bg='white'
          height='100%'
          overflow='auto'
          borderRadius='20px'
          p={_isCurrentViewMobile ? '12px' : '22px'}>
          <Flex pb='0px' justify='space-between' alignItems='center'>
            <Flex flex={0.6} gap='6px' flexDir='column'>
              <Text as='h3' fontSize={17} fontWeight={500} display='flex' alignItems='center' color='brand.500' gap='6px'><HiArrowUturnDown />{_isCurrentViewMobile ? 'Filter Rows' : 'Filter Rows based on Date.'}</Text>
              <DateRangePicker startDate={dateRange?.selection?.startDate} endDate={dateRange?.selection?.endDate} w='100%' fontSize='13px' height='max-content' gap='6px' setSelectDate={setDateRange} />
            </Flex>
            <DownloadToExcel
              cellsData={_QuerySmsRead?.data?.SmsRead.map((item, __index) => ({
                'S.No.': `${(__index + (skipDifference * (skipPage))) + 1}.`,
                'Company Name': item?.persisted__customer?.Company?.displayName,
                'Smpp Account': item?.persisted__customersmpp?.displayName ?? '-',
                'Phone Number': item?.destinationAddress?.toString() ?? '-',
                'Country Name': item?.persisted__hlrlookup?.Mnc?.countryName ?? '-',
                'Mcc': item?.persisted__hlrlookup?.Mnc?.Mcc?.mcc ?? '-',
                'Mnc': item?.persisted__hlrlookup?.Mnc?.mnc ?? '-',
                'Operator': item?.persisted__hlrlookup?.Mnc?.network ?? '-',
                'Dlr Status': item?.status ?? '-',
                'Vendor Name': item?.persisted__vendorsmpp?.displayName ?? '-',
                'Sender ID': item?.sourceAddress ?? '-',
                'Client Rate': item?.persisted__customerrateplan?.rate ?? '-',
                'Client Cost': item?.persisted__customerrateplan?.rate ?? '-',
                'Accepted Date': Moment(item?.createdAt).format('YYYY-MM-DD hh:mm:ss A'),
                'Submit Date': item?.dlr ? Moment(Date(Stringg(item?.dlr)?.between('submit date:', ' done date:')?.s))?.format('YYYY-MM-DD hh:mm:ss A') : '-',
                'Dlr Date': item?.dlr ? Moment(Date(Stringg(item?.dlr)?.between('done date:', ' stat')?.s))?.format('YYYY-MM-DD hh:mm:ss A') : '-',
                'Character Count': item?.messageLength ?? '-',
                'Parts': item?.messageChunkSize ?? '-',
                'Termination Rate': item?.persisted__vendorrateplan?.rate ?? '-',
                'Termination Cost': item?.persisted__vendorrateplan?.rate ?? '-',
                'Error Code': 'undefined' !== item?.error && undefined !== item?.error && null !== item?.error && '' !== item?.error ? item?.error : '-',
                'Sms Id': item?.id ?? '-',
                'Vendor Sms Id': item?.receiptId ?? '-',
                'Dlr': item?.dlr ?? '-',
                'Message': item?.originalCopyOfMessage ?? '-',
                'Transformed Message': item?.message ?? '-'
              }))}
              headersData={[
                'S.No.',
                'Company Name',
                'Smpp Account',
                'Phone Number',
                'Country Name',
                'Mcc',
                'Mnc',
                'Operator',
                'Dlr Status',
                'Vendor Name',
                'Sender ID',
                'Client Rate',
                'Client Cost',
                'Accepted Date',
                'Submit Date',
                'Dlr Date',
                'Character Count',
                'Parts',
                'Termination Rate',
                'Termination Cost',
                'Error Code',
                'SmsCount',
                'Sms Id',
                'Vendor Sms Id',
                'Message',
                'Transformed Message'
              ].map(i => ({ 'key': i, 'label': i }))} />
          </Flex>
          <TableContainer
            flex={1}
            display='flex'
            borderRadius='15px'
            minH='50vh'
            outline='1px solid #C5CFE8'>
            <Table variant='simple' size='sm'>
              <Thead ref={_tableHeaderHeightRef}>
                <Tr style={headerStyle} position='sticky' top={0} zIndex='1'>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>S.No.</Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>Company Name</Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>Smpp Account</Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>Phone Number</Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>Country Name</Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>Mcc</Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>Mnc</Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>Operator</Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>Dlr Status</Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>Vendor Name</Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>Sender ID</Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>Transformed Sender ID</Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>Client Rate</Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>Client Cost</Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>Accepted Date</Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>Submit Date</Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>Dlr Date</Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>Character Count</Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>Parts</Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>Termination Rate</Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>Termination Cost</Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>Error Code</Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>Sms Id</Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>Vendor Sms Id</Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>Response Body</Td>
                  <Td style={rowStyle} py='18px' borderRight='1px solid rgba(216, 227, 252, 1)'>Original Message</Td>
                  <Td style={rowStyle} py='18px'>Transformed Message</Td>
                </Tr>
                <Tr key={String.random(8)}>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>
                    <MemoizedInput
                      placeholder='e.g. "C_SMPP"'
                      className='filter searchInput'
                      defaultValue={customerAccountSmppNameToSearch}
                      onChange={_onCustomerNameToSearchInputChange}
                    />
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    <MemoizedInput
                      placeholder='e.g. "923001234567"'
                      className='filter searchInput'
                      defaultValue={mobileNumberSearch}
                      onChange={_onMobileNumberSearchInputChange}
                    />
                  </Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>
                    <MemoizedInput
                      placeholder='e.g. "France"'
                      className='filter searchInput'
                      defaultValue={countryNameToSearch}
                      onChange={_onCountryNameToSearchInputChange}
                    />
                  </Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)' minW='180px' px='20px'>
                    <MemoizedInput
                      placeholder='e.g. "402"'
                      className='filter searchInput'
                      defaultValue={mccToSearch}
                      onChange={_onMccToSearchInputChange}
                    />
                  </Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)' minW='180px' px='20px'>
                    <MemoizedInput
                      placeholder='e.g. "02"'
                      className='filter searchInput'
                      defaultValue={mncToSearch}
                      onChange={_onMncToSearchInputChange}
                    />
                  </Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    <MemoizedSelect
                      maxWidth='100%'
                      style={{ 'minHeight': 'unset', 'borderRadius': 12 }}
                      name='dlrStatus'
                      placeholder='Select Status'
                      backgroundColor='gray.100'
                      data={dlrStatusToSearch}
                      onChange={_onDlrStatusToSearchInputChange}
                      options={Object.React.App.enums.SMS_STATUS.enums?.map(i => i.key)}
                    />
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    <MemoizedInput
                      placeholder='Search Vendor'
                      className='filter searchInput'
                      defaultValue={vendorNameToSearch}
                      onChange={_onVendorNameToSearchInputChange}
                    />
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    <MemoizedInput
                      placeholder='e.g. "SENDER_ID"'
                      className='filter searchInput'
                      defaultValue={senderIdToSearch}
                      onChange={_onSenderIdToSearchInputChange}
                    />
                  </Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    <MemoizedInput
                      placeholder='Search'
                      className='filter searchInput'
                      defaultValue={smsIdToSearch}
                      onChange={_onSmsIdToSearchInputChange}
                    />
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    <MemoizedInput
                      placeholder='Search'
                      className='filter searchInput'
                      defaultValue={vendorSmsIdToSearch}
                      onChange={_onVendorSmsIdToSearchInputChange}
                    />
                  </Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td style={rowStyle}></Td>
                </Tr>
              </Thead>
              {_QuerySmsRead?.loading ? (
                <TableSpinner isLoading={true} chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight} />
              ) : 0 === _QuerySmsRead?.data?.SmsRead.length || (0 < _QuerySmsRead?.data?.SmsRead.length && _.every(_.pluck(_QuerySmsRead?.data?.SmsRead, 'status'), j => 'NO_SMS_FOUND' === j)) ? (
                <TableSpinner isLoading={false} isEmpty={true} chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight} />
              ) : (
                <Tbody style={cellStyle}>
                  {_QuerySmsRead?.data?.SmsRead.map((item, __index) => {
                    // Cosnt assignment.
                    const _error = []

                    // If dlr has error then update it.
                    if (item && item?.dlr) {
                      // Const assignment.
                      const _dlrError = Stringg(item?.dlr ?? '').between(' err:', 'text')?.s

                      // Update error.
                      !_dlrError?.includes('001') && _error.push(_dlrError)
                    }
                    if (item && item?.error) {
                      // Update error.
                      _error.push(item.error)
                    }

                    // Return component.
                    return (
                      <Tr key={String?.random(8)}>
                        <Td
                          style={rowStyle}
                          py='20px'
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {' '}
                          {`${(__index + (skipDifference * (skipPage))) + 1}.`}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {item?.persisted__customer?.Company?.displayName ?? 'Might be bind hit.'}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {['RETAIL', 'RESELLER']?.includes(item?.persisted__customer?.type) ? 'System Smpp' : item?.persisted__customersmpp?.displayName ?? '-'}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {item?.destinationAddress ?? '-'}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {item?.persisted__hlrlookup?.Mnc?.countryName ?? '-'}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {item?.persisted__hlrlookup?.Mnc?.Mcc?.mcc ?? '-'}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {item?.persisted__hlrlookup?.Mnc?.mnc ?? '-'}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {item?.persisted__hlrlookup?.Mnc?.network ?? '-'}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {item?.status ?? '-'}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {item?.isFakeDlr ? 'P_SUNNY_DAY' : item?.persisted__vendorsmpp?.displayName ?? '-'}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {item?.sourceAddress ?? '-'}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {item?.transformedSourceAddress ?? '-'}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {item?.persisted__customerrateplan?.rate ?? '-'}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {item?.persisted__customerrateplan?.rate ?? '-'}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {Moment(item?.createdAt).format('YYYY-MM-DD hh:mm:ss A')}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {item?.submittedAt ? Moment(item?.submittedAt).format('YYYY-MM-DD hh:mm:ss A') : '-'}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {item?.submittedAt && item?.updatedAt && item?.dlr ? Moment(item?.updatedAt).format('YYYY-MM-DD hh:mm:ss A') : '-'}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {item?.messageLength ?? '-'}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {item?.messageChunkSize ?? '-'}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {item?.persisted__vendorrateplan?.rate ?? '-'}
                        </Td >
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {item?.persisted__vendorrateplan?.rate ?? '-'}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {0 === _error?.length ? '-' : _.without(_error, 'NONE')?.join(', ')}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {item?.id ?? '-'}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {item?.receiptId ?? '-'}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {item?.dlr ?? '-'}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {item?.originalCopyOfMessage ?? '-'}
                        </Td>
                        <Td
                          style={rowStyle}>
                          {item?.message ?? '-'}
                        </Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              )}
            </Table>
          </TableContainer>
        </Flex>
      </Flex>
      <PaginationWithPageInformation
        skipPage={skipPage}
        skipDifference={_skipDifference}
        setSkipDifference={setSkipDifference}
        itemsPerPage={_skipDifference}
        totalCount={_.pluck(_QuerySmsRead?.data?.SmsRead, '_totalCount')?.[0]}
        onPageChange={setSkipPage}
      />
    </>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'vendorId': PropTypes.object.isRequired
}


/*
 * EXPORT
 */
export default Index
