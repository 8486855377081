/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query CreditReadQuery($customerId: ID!) {
  CreditRead(customerId: $customerId) {
    limit,
    message,
    status
  }
}
`
