/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
    query VendorReadQuery($take: PositiveInt, $skip: Int , $search: String) {
    VendorRead(take: $take, skip: $skip, search: $search) {
        id,
        createdAt,
        updatedAt,
        displayName,
        phone,
        email,
        technicalEmail,
        address,
        status,
        message,
        _totalCount,
        VendorAccount {
            id
            displayName
        }
        Billing {
            id
            email
            period
            currency
        }
    }
}
`
