/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useMutation } from '@apollo/client' // Npm: Apollo client.


/*
 * SIBLINGS
 */
import DeleteCard from 'components/DeleteCard'


/*
 * GRAPHS
 */
import RatePlanDeleteMutation from './__mutation__/index.ratePlan.delete.mutation'


/*
 * OBJECTS
 */
const Index = ({ passOn, onClose }) => {
  // Hook assignment.
  const [MutationRatePlanDelete, MutationRatePlanDeleteResponse] = useMutation(RatePlanDeleteMutation)

  // Return Component.
  return (
    <DeleteCard onClose={onClose} disabled={MutationRatePlanDeleteResponse.loading} onConfirm={() => MutationRatePlanDelete({
      'variables': {
        'ratePlanIds': passOn?.ratePlanIds
      }
    }).then(i => {
      // Style Guide.
      toast(i?.data?.RatePlanDelete?.message)

      // Report success.
      return onClose?.()
    }).catch(() =>
      // Style Guide.
      toast('Something went wrong please try after some time.'))} isLoading={MutationRatePlanDeleteResponse.loading} />
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'passOn': PropTypes.object,
  'onClose': PropTypes.func
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
