/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation KeywordDeleteMutation($keywordId: ID!) {
  KeywordDelete(keywordId: $keywordId) {
    id
    message
    status
  }
}
`
