/*
 * IMPORT
 */
import 'mapbox-gl/dist/mapbox-gl.css' // Npm: Mapbox css.
import React from 'react' // Npm: React.
import ReactDOM from 'react-dom' // Npm: React dom.
import { Provider } from 'react-redux' // Npm: Redux provider.
import { ErrorBoundary } from 'react-error-boundary' // Npm: React error boundary.
import { PersistGate } from 'redux-persist/integration/react' // Npm: Redux persist gate.
import { ChakraProvider } from '@chakra-ui/react' // Npm: Chakra UI provider.
import { BrowserRouter as Router } from 'react-router-dom' // Npm: React router dom library.


/*
 * PACKAGES
 */
import 'iife'
import theme from 'theme'
import Redux, { Persist } from 'store'


/*
 * SIBLINGS
 */
import App from 'index.app.js'
import ErrorComponent from 'index.error.js'


/*
 * STYLES
 */
import 'index.style.css'



/*
 * FIXES
 */
if ('production' === process.env.NODE_ENV) console.log = console.warn = console.error = () => { }


/*
 * RENDER
 */
ReactDOM.render(
  <ErrorBoundary fallback={<ErrorComponent />}>
    <Provider store={Redux}>
      <PersistGate persistor={Persist}>
        <ChakraProvider theme={theme}>
          <React.StrictMode>
            <Router>
              <App isAnimating />
            </Router>
          </React.StrictMode>
        </ChakraProvider>
      </PersistGate>
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root')
)
