/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation VendorCreateMutation(
  $displayName: String!,
  $email: EmailAddress!,
  $address: String!,
  $phone: String!,
  $billingPeriod: BILLING_PERIOD!,
  $billingEmail: [EmailAddress!]!,
  $technicalEmail: EmailAddress!,
  $billingAddress: String,
  $billingCurrency: String,
  $country: String
) {
  VendorCreate(
    displayName: $displayName,
    email: $email,
    address: $address,
    billingPeriod: $billingPeriod,
    billingEmail: $billingEmail,
    billingCurrency: $billingCurrency,
    technicalEmail: $technicalEmail,
    billingAddress: $billingAddress,
    phone: $phone,
    country: $country
  ) {
    id,
    message,
    status
  }
}
`
