/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useHistory } from 'react-router-dom' // Npm: React router dom.
import { HiBookOpen, HiPencil, HiTrash } from 'react-icons/hi2' // Npm: React icons.
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * OBJECTS
 */
const Index = ({
  keywordDirectoryId,
  onKeywordDirectoryUpdate,
  onKeywordDirectoryDelete,
  PassOnUpdate
}) => {
  // Hook assignment.
  const _historyRef = useHistory()

  // Return component.
  return (
    <Menu placement='left'>
      <MenuButton
        as={Button}
        margin='auto'
        borderRadius='8px'
        color='gray.500'
        bg='transparent'>
        <HiPencil size={18} />
      </MenuButton>
      <MenuList
        className='dropDownMenu'
        maxHeight='400px'
        overflow='auto'>
        <MenuItem
          gap='12px'
          display='flex'
          pl='20px'
          pr='20px'
          color='gray.500'
          onClick={() => {
            // Push keywordDirectory id to url.
            PassOnUpdate({ keywordDirectoryId })

            // Execute handler.
            onKeywordDirectoryUpdate()
          }}
          minH='40px'>
          <HiPencil size={18} />
          <span>Edit Folder</span>
        </MenuItem>
        <MenuItem
          gap='12px'
          display='flex'
          pl='20px'
          pr='20px'
          color='gray.500'
          onClick={() => {
            // Push keywordDirectory id to url.
            PassOnUpdate({ keywordDirectoryId })

            // Execute handler.
            onKeywordDirectoryDelete()
          }}
          minH='40px'>
          <HiTrash />
          <span>Delete Folder</span>
        </MenuItem>
        <MenuItem
          onClick={() => {
            // Push keywordDirectory id to url.
            PassOnUpdate({ keywordDirectoryId })

            // Execute handler.
            _historyRef.push('/keywords/keyword')
          }}
          gap='12px'
          display='flex'
          pl='20px'
          pr='20px'
          color='gray.500'
          minH='40px'>
          <HiBookOpen size={18} />
          <span>Manage Keywords</span>
        </MenuItem>
      </MenuList>
    </Menu>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'keywordDirectoryId': PropTypes.string,
  'onKeywordDirectoryUpdate': PropTypes.func,
  'onKeywordDirectoryDelete': PropTypes.func,
  'PassOnUpdate': PropTypes.func
}


/*
 * REDUX
 */
const _MapDispatchToProps = __dispatch => ({
  'PassOnUpdate': u => __dispatch({ 'type': 'PASS_ON_UPDATE', 'PassOn': u })
})


/*
 * EXPORT
 */
export default connect(void 0, _MapDispatchToProps)(Index)
