/* eslint no-magic-numbers: 0 */
/* eslint class-methods-use-this: 0 */
/* eslint max-lines: 0 */

/*
 * IMPORTS
 */
import React, { PureComponent } from 'react' // Npm: React.js library
import PropTypes from 'prop-types' // Npm: Prop types validator.
import classnames from 'classnames' // Npm: classnames utility.
import ReactList from 'react-list' // Npm: React List. A versatile infinite scroll React component.
import defaultLocale from 'date-fns/locale/en-US' // Npm: date-fns locale.
import { HiArrowLeftCircle, HiArrowRightCircle } from 'react-icons/hi2' // Npm: React icons.
import { shallowEqualObjects } from 'shallow-equal' // Npm: shallow equal objects.
import {
  addDays,
  addMonths,
  addYears,
  differenceInCalendarMonths,
  differenceInDays,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  isSameDay,
  isSameMonth,
  max,
  min,
  setMonth,
  setYear,
  startOfMonth,
  startOfWeek,
  subMonths
} from 'date-fns' // Npm: date-fns library.
import {
  Button
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * UTILS
 */
import coreStyles from '../../../util/index.style'
import { calcFocusDate, generateStyles, getMonthDisplayRange } from '../../../util'


/*
 * SIBLINGS
 */
import Month from './Month'


/*
 * CLASS
 */
class Calendar extends PureComponent {
  constructor(props, context) {
    // Constructor.
    super(props, context)

    // Update state.
    this.dateOptions = { 'locale': props.locale }

    // If props.weekStartsOn is not undefined.
    if (props.weekStartsOn !== void 0) this.dateOptions.weekStartsOn = props.weekStartsOn

    // Update state.
    this.styles = generateStyles([coreStyles, props.classNames])
    this.listSizeCache = {}
    this.isFirstRender = true
    this.state = {
      'monthNames': this.getMonthNames(),
      'focusedDate': calcFocusDate(null, props),
      'drag': {
        'status': false,
        'range': { 'startDate': null, 'endDate': null },
        'disablePreview': false
      },
      'scrollArea': this.calcScrollArea(props)
    }
  }

  /*
   * Get month names
   * @return {array}
   * @description Get month names.
   */
  getMonthNames() {
    // Return value.
    return [...Array(12).keys()].map(i => this.props.locale.localize.month(i))
  }

  /*
   * Calculate scroll area
   * @param {object} props
   * @return {object}
   * @description Calculate scroll area.
   */
  calcScrollArea(props) {
    // Const assignment.
    const { direction, months, scroll } = props

    // If scroll is not enabled.
    if (!scroll.enabled) return { 'enabled': false }

    // Const assignment.
    const longMonthHeight = scroll.longMonthHeight || scroll.monthHeight

    // If vertical direction.
    if ('vertical' === direction) {
      // Return value.
      return {
        'enabled': true,
        'monthHeight': scroll.monthHeight || 220,
        'longMonthHeight': longMonthHeight || 260,
        'calendarWidth': 'auto',
        'calendarHeight': (scroll.calendarHeight || longMonthHeight || 280) * months
      }
    }

    // Return value.
    return {
      'enabled': true,
      'monthWidth': scroll.monthWidth || 332,
      'calendarWidth': (scroll.calendarWidth || scroll.monthWidth || 332) * months,
      'monthHeight': longMonthHeight || 300,
      'calendarHeight': longMonthHeight || 300
    }
  }

  /*
   * Focus to date
   * @param {object} date
   * @param {object} props
   * @param {boolean} preventUnnecessary
   * @description Focus to date.
   */
  focusToDate = (date, props = this.props, preventUnnecessary = true) => {
    // If scroll is not enabled.
    if (!props.scroll.enabled) {
      // If prevent unnecessary and prevent snap refocus.
      if (preventUnnecessary && props.preventSnapRefocus) {
        // Const assignment.
        const focusedDateDiff = differenceInCalendarMonths(date, this.state.focusedDate)
        const isAllowedForward = 'forwards' === props.calendarFocus && 0 <= focusedDateDiff
        const isAllowedBackward = 'backwards' === props.calendarFocus && 0 >= focusedDateDiff

        // If is allowed forward or backward and absolute focused date difference is less than props.months.
        if ((isAllowedForward || isAllowedBackward) && Math.abs(focusedDateDiff) < props.months) return
      }

      // Update state.
      this.setState({ 'focusedDate': date })
    }

    // Const assignment.
    const targetMonthIndex = differenceInCalendarMonths(date, props.minDate, this.dateOptions)
    const visibleMonths = this.list?.getVisibleRange()

    // If prevent unnecessary and visible months includes target month index.
    if (preventUnnecessary && visibleMonths?.includes(targetMonthIndex)) return

    // Update state.
    this.isFirstRender = true
    this.list?.scrollTo(targetMonthIndex)
    this.setState({ 'focusedDate': date })
  }

  /*
   * Update shown date
   * @param {object} props
   * @description Update shown date.
   */
  updateShownDate = (props = this.props) => {
    // If scroll is enabled.
    const newProps = props.scroll.enabled ? {
      ...props,
      'months': this.list?.getVisibleRange().length
    } : props
    const newFocus = calcFocusDate(this.state.focusedDate, newProps)

    // If new focus is not same month as focused date.
    this.focusToDate(newFocus, newProps)
  }

  /*
   * Update preview
   * @param {object} val
   * @description Update preview.
   */
  updatePreview = val => {
    // If not val.
    if (!val) {
      // Update state.
      this.setState({ 'preview': null })

      // Return.
      return
    }

    // Const assignment.
    const preview = {
      'startDate': val,
      'endDate': val,
      'color': this.props.color
    }

    // Update state.
    this.setState({ preview })
  }

  /*
   * Component did mount
   * @description Component did mount.
   * @see https://reactjs.org/docs/react-component.html#componentdidmount
   */
  componentDidMount() {
    // If scroll is enabled.
    if (this.props.scroll.enabled) {
      // Prevent react-list's initial render focus problem
      setTimeout(() => this.focusToDate(this.state.focusedDate))
    }
  }

  /*
   * Component did update
   * @param {object} prevProps
   * @description Component did update.
   */
  componentDidUpdate(prevProps) {
    // If props.scroll.enabled.
    const propMapper = {
      'dateRange': 'ranges',
      'date': 'date'
    }
    const targetProp = propMapper[this.props.displayMode]

    // If target prop and props[target prop] and props[target prop] is not same as prev props[target prop].
    if (this.props[targetProp] !== prevProps[targetProp]) this.updateShownDate(this.props)

    // If props.scroll.enabled and props.scroll.monthHeight and props.scroll.monthHeight is not same as prev props.scroll.monthHeight.
    if (prevProps.locale !== this.props.locale || prevProps.weekStartsOn !== this.props.weekStartsOn) {
      // Update state.
      this.dateOptions = { 'locale': this.props.locale }

      // If props.weekStartsOn is not undefined.
      if (this.props.weekStartsOn !== void 0) this.dateOptions.weekStartsOn = this.props.weekStartsOn

      // Update state.
      this.setState({ 'monthNames': this.getMonthNames() })
    }

    // If props.scroll.enabled and props.scroll.monthHeight and props.scroll.monthHeight is not same as prev props.scroll.monthHeight.
    if (!shallowEqualObjects(prevProps.scroll, this.props.scroll)) this.setState({ 'scrollArea': this.calcScrollArea(this.props) })
  }

  /*
   * Change shown date
   * @param {object} value
   * @param {string} mode
   * @description Change shown date.
   */
  changeShownDate = (value, mode = 'set') => {
    // If value is not number.
    const { focusedDate } = this.state
    const { onShownDateChange, minDate, maxDate } = this.props
    const modeMapper = {
      'monthOffset': () => addMonths(focusedDate, value),
      'setMonth': () => setMonth(focusedDate, value),
      'setYear': () => setYear(focusedDate, value),
      'set': () => value
    }

    // If value is not number.
    const newDate = min([max([modeMapper[mode](), minDate]), maxDate])

    // Update state.
    this.focusToDate(newDate, this.props, false)
    onShownDateChange && onShownDateChange(newDate)
  }

  /*
   * Handle range focus change
   * @param {number} rangesIndex
   * @param {number} rangeItemIndex
   * @description Handle range focus change.
   */
  handleRangeFocusChange = (rangesIndex, rangeItemIndex) => {
    // If props.onRangeFocusChange.
    this.props.onRangeFocusChange && this.props.onRangeFocusChange([rangesIndex, rangeItemIndex])
  }

  /*
   * Handle scroll
   * @description Handle scroll.
   * @see https://reactjs.org/docs/react-component.html#componentdidupdate
   */
  handleScroll = () => {
    // If props.scroll.enabled.
    const { onShownDateChange, minDate } = this.props
    const { focusedDate } = this.state
    const { isFirstRender } = this

    // If props.scroll.enabled.
    const visibleMonths = this.list?.getVisibleRange()

    // Prevent scroll jump with wrong visible value
    if (visibleMonths[0] === void 0) return

    // Const assignment.
    const visibleMonth = addMonths(minDate, visibleMonths[0] || 0)
    const isFocusedToDifferent = !isSameMonth(visibleMonth, focusedDate)

    // If is focused to different and not first render.
    if (isFocusedToDifferent && !isFirstRender) {
      // Update state.
      this.setState({ 'focusedDate': visibleMonth })
      onShownDateChange && onShownDateChange(visibleMonth)
    }

    // Update state.
    this.isFirstRender = false
  }

  renderMonthAndYear = (focusedDate, changeShownDate, props) => {
    // Const assignment.
    const { showMonthArrow, minDate, maxDate, showMonthAndYearPickers, ariaLabels } = props
    const upperYearLimit = (maxDate || Calendar.defaultProps.maxDate).getFullYear()
    const lowerYearLimit = (minDate || Calendar.defaultProps.minDate).getFullYear()
    const styles = this.styles

    // Return value.
    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div style={{ 'padding': '0 20px' }} onMouseUp={e => e.stopPropagation()} className={styles.monthAndYearWrapper}>
        {showMonthArrow ? (
          <Button
            px={0}
            py={0}
            type='button'
            bg='none'
            _hover={{ 'bg': 'none' }}
            className={classnames(styles.nextPrevButton, styles.prevButton)}
            onClick={() => changeShownDate(-1, 'monthOffset')}
            aria-label={ariaLabels.prevButton}>
            <HiArrowLeftCircle size={18} color='#FFF' />
          </Button>
        ) : null}
        {showMonthAndYearPickers ? (
          <span className={styles.monthAndYearPickers}>
            <span className={styles.monthPicker}>
              <select
                value={focusedDate.getMonth()}
                onChange={e => changeShownDate(e.target.value, 'setMonth')}
                aria-label={ariaLabels.monthPicker}>
                {this.state.monthNames.map((monthName, i) => (
                  <option key={i} value={i}>
                    {monthName}
                  </option>
                ))}
              </select>
            </span>
            <span className={styles.monthAndYearDivider} />
            <span className={styles.yearPicker}>
              <select
                value={focusedDate.getFullYear()}
                onChange={e => changeShownDate(e.target.value, 'setYear')}
                aria-label={ariaLabels.yearPicker}>
                {new Array(upperYearLimit - lowerYearLimit + 1)
                  .fill(upperYearLimit)
                  .map((val, i) => {
                    const year = val - i

                    return (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    )
                  })}
              </select>
            </span>
          </span>
        ) : (
          <span className={styles.monthAndYearPickers}>
            {this.state.monthNames[focusedDate.getMonth()]} {focusedDate.getFullYear()}
          </span>
        )}
        {showMonthArrow ? (
          <Button
            px={0}
            py={0}
            type='button'
            bg='none'
            _hover={{ 'bg': 'none' }}
            className={classnames(styles.nextPrevButton, styles.nextButton)}
            onClick={() => changeShownDate(+1, 'monthOffset')}
            aria-label={ariaLabels.nextButton}>
            <HiArrowRightCircle size={18} color='#FFF' />
          </Button>
        ) : null}
      </div>
    )
  }

  /*
   * Render weekdays
   * @return {object}
   * @description Render weekdays.
   */
  renderWeekdays() {
    // Const assignment.
    const now = new Date()

    // Return value.
    return (
      <div className={this.styles.weekDays}>
        {eachDayOfInterval({
          'start': startOfWeek(now, this.dateOptions),
          'end': endOfWeek(now, this.dateOptions)
        }).map((day, i) => (
          <span className={this.styles.weekDay} key={i}>
            {format(day, this.props.weekdayDisplayFormat, this.dateOptions)}
          </span>
        ))}
      </div>
    )
  }


  /*
   * OnDragSelectionStart
   * @param {object}
   * @description OnDragSelectionStart.
   */
  onDragSelectionStart = date => {
    // Const assignment.
    const { onChange, dragSelectionEnabled } = this.props

    // If drag selection enabled.
    if (dragSelectionEnabled) {
      // Update state.
      this.setState({
        'drag': {
          'status': true,
          'range': { 'startDate': date, 'endDate': date },
          'disablePreview': true
        }
      })
    } else {
      // Update state.
      onChange && onChange(date)
    }
  }

  /*
   * OnDragSelectionEnd
   * @param {object}
   * @description OnDragSelectionEnd.
   */
  onDragSelectionEnd = date => {
    // Const assignment.
    const { updateRange, displayMode, onChange, dragSelectionEnabled } = this.props

    // If not drag selection enabled.
    if (!dragSelectionEnabled) return

    // If date range.
    if ('date' === displayMode || !this.state.drag.status) {
      // Update state.
      onChange && onChange(date)

      // Return.
      return
    }

    // Const assignment.
    const newRange = {
      'startDate': this.state.drag.range.startDate,
      'endDate': date
    }

    // If date range.
    if ('dateRange' !== displayMode || isSameDay(newRange.startDate, date)) {
      // Update state.
      this.setState({ 'drag': { 'status': false, 'range': {} } }, () => onChange && onChange(date))
    } else {
      // Update state.
      this.setState({ 'drag': { 'status': false, 'range': {} } }, () => { updateRange && updateRange(newRange) })
    }
  }

  /*
   * OnDragSelectionMove
   * @param {object}
   * @description OnDragSelectionMove.
   */
  onDragSelectionMove = date => {
    // Const assignment.
    const { drag } = this.state

    // If not drag status or not props.dragSelectionEnabled.
    if (!drag.status || !this.props.dragSelectionEnabled) return

    // Update state.
    this.setState({
      'drag': {
        'status': drag.status,
        'range': { 'startDate': drag.range.startDate, 'endDate': date },
        'disablePreview': true
      }
    })
  }


  /*
   * Estimate month size
   * @param {number} index
   * @param {object} cache
   * @return {number}
   * @description Estimate month size.
   */
  estimateMonthSize = (index, cache) => {
    // Const assignment.
    const { direction, minDate } = this.props
    const { scrollArea } = this.state

    // If cache.
    if (cache) {
      // Update state.
      this.listSizeCache = cache

      // Return value.
      if (cache[index]) return cache[index]
    }

    // If vertical direction.
    if ('horizontal' === direction) return scrollArea.monthWidth

    // Const assignment.
    const monthStep = addMonths(minDate, index)
    const { start, end } = getMonthDisplayRange(monthStep, this.dateOptions)
    const isLongMonth = differenceInDays(end, start, this.dateOptions) + 1 > 7 * 5

    // Return value.
    return isLongMonth ? scrollArea.longMonthHeight : scrollArea.monthHeight
  }

  /*
   * Render
   * @return {object}
   * @description Render component.
   */
  render() {
    // Const assignment.
    const {
      onPreviewChange,
      scroll,
      direction,
      maxDate,
      minDate,
      rangeColors,
      color,
      navigatorRenderer,
      className,
      preview
    } = this.props

    // If scroll is enabled.
    const { scrollArea, focusedDate } = this.state

    // If vertical direction.
    const isVertical = 'vertical' === direction
    const monthAndYearRenderer = navigatorRenderer || this.renderMonthAndYear

    // If scroll is enabled.
    const ranges = this.props.ranges.map((range, i) => ({ ...range, 'color': range.color || rangeColors[i] || color }))

    // Return value.
    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        className={classnames(this.styles.calendarWrapper, className)}
        onMouseUp={() => this.setState({ 'drag': { 'status': false, 'range': {} } })}
        onMouseLeave={() => { this.setState({ 'drag': { 'status': false, 'range': {} } }) }}>
        {monthAndYearRenderer(focusedDate, this.changeShownDate, this.props)}
        {scroll.enabled ? (
          <div>
            {isVertical && this.renderWeekdays(this.dateOptions)}
            <div
              className={classnames(this.styles.infiniteMonths, isVertical ? this.styles.monthsVertical : this.styles.monthsHorizontal)}
              onMouseLeave={() => onPreviewChange && onPreviewChange()}
              style={{ 'width': scrollArea.calendarWidth + 11, 'height': scrollArea.calendarHeight + 11 }}
              onScroll={this.handleScroll}>
              <ReactList
                length={differenceInCalendarMonths(
                  endOfMonth(maxDate),
                  addDays(startOfMonth(minDate), -1),
                  this.dateOptions
                )}
                treshold={500}
                type='variable'
                ref={target => (this.list = target)}
                itemSizeEstimator={this.estimateMonthSize}
                axis={isVertical ? 'y' : 'x'}
                itemRenderer={(index, key) => {
                  // Const assignment.
                  const monthStep = addMonths(minDate, index)

                  // Return component.
                  return (
                    <Month
                      {...this.props}
                      onPreviewChange={onPreviewChange || this.updatePreview}
                      preview={preview || this.state.preview}
                      ranges={ranges}
                      key={key}
                      drag={this.state.drag}
                      dateOptions={this.dateOptions}
                      month={monthStep}
                      onDragSelectionStart={this.onDragSelectionStart}
                      onDragSelectionEnd={this.onDragSelectionEnd}
                      onDragSelectionMove={this.onDragSelectionMove}
                      onMouseLeave={() => onPreviewChange && onPreviewChange()}
                      styles={this.styles}
                      style={isVertical ? { 'height': this.estimateMonthSize(index) } : { 'height': scrollArea.monthHeight, 'width': this.estimateMonthSize(index) }}
                      showMonthName
                      showWeekDays={!isVertical}
                    />
                  )
                }}
              />
            </div>
          </div>
        ) : (
          <div
            className={classnames(this.styles.months, isVertical ? this.styles.monthsVertical : this.styles.monthsHorizontal)}>
            {new Array(this.props.months).fill(null).map((_, i) => {
              // Local variable.
              let monthStep

              // Variable assignment.
              monthStep = addMonths(this.state.focusedDate, i)

              // If backwards.
              if ('backwards' === this.props.calendarFocus) {
                // Const assignment.
                monthStep = subMonths(this.state.focusedDate, this.props.months - 1 - i)
              }

              // Return component.
              return (
                <Month
                  {...this.props}
                  onPreviewChange={onPreviewChange || this.updatePreview}
                  preview={preview || this.state.preview}
                  ranges={ranges}
                  key={i}
                  drag={this.state.drag}
                  dateOptions={this.dateOptions}
                  month={monthStep}
                  onDragSelectionStart={this.onDragSelectionStart}
                  onDragSelectionEnd={this.onDragSelectionEnd}
                  onDragSelectionMove={this.onDragSelectionMove}
                  onMouseLeave={() => onPreviewChange && onPreviewChange()}
                  styles={this.styles}
                  showWeekDays={!isVertical || 0 === i}
                  showMonthName={!isVertical || 0 < i}
                />
              )
            })}
          </div>
        )}
      </div>
    )
  }
}


/*
 * PROPTYPES
 */
Calendar.propTypes = {
  'showMonthArrow': PropTypes.bool,
  'showMonthAndYearPickers': PropTypes.bool,
  'disabledDates': PropTypes.array,
  'disabledDay': PropTypes.func,
  'minDate': PropTypes.object,
  'maxDate': PropTypes.object,
  'date': PropTypes.object,
  'onChange': PropTypes.func,
  'onPreviewChange': PropTypes.func,
  'onRangeFocusChange': PropTypes.func,
  'classNames': PropTypes.object,
  'locale': PropTypes.object,
  'shownDate': PropTypes.object,
  'onShownDateChange': PropTypes.func,
  'ranges': PropTypes.arrayOf(PropTypes.shape({
    'startDate': PropTypes.object,
    'endDate': PropTypes.object,
    'color': PropTypes.string,
    'key': PropTypes.string,
    'autoFocus': PropTypes.bool,
    'disabled': PropTypes.bool,
    'showDateDisplay': PropTypes.bool
  })),
  'preview': PropTypes.shape({
    'startDate': PropTypes.object,
    'endDate': PropTypes.object,
    'color': PropTypes.string
  }),
  'dateDisplayFormat': PropTypes.string,
  'monthDisplayFormat': PropTypes.string,
  'weekdayDisplayFormat': PropTypes.string,
  'weekStartsOn': PropTypes.number,
  'dayDisplayFormat': PropTypes.string,
  'focusedRange': PropTypes.arrayOf(PropTypes.number),
  'initialFocusedRange': PropTypes.arrayOf(PropTypes.number),
  'months': PropTypes.number,
  'className': PropTypes.string,
  'showDateDisplay': PropTypes.bool,
  'showPreview': PropTypes.bool,
  'displayMode': PropTypes.oneOf(['dateRange', 'date']),
  'color': PropTypes.string,
  'updateRange': PropTypes.func,
  'scroll': PropTypes.shape({
    'enabled': PropTypes.bool,
    'monthHeight': PropTypes.number,
    'longMonthHeight': PropTypes.number,
    'monthWidth': PropTypes.number,
    'calendarWidth': PropTypes.number,
    'calendarHeight': PropTypes.number
  }),
  'direction': PropTypes.oneOf(['vertical', 'horizontal']),
  'startDatePlaceholder': PropTypes.string,
  'endDatePlaceholder': PropTypes.string,
  'navigatorRenderer': PropTypes.func,
  'rangeColors': PropTypes.arrayOf(PropTypes.string),
  'editableDateInputs': PropTypes.bool,
  'dragSelectionEnabled': PropTypes.bool,
  'fixedHeight': PropTypes.bool,
  'calendarFocus': PropTypes.string,
  'preventSnapRefocus': PropTypes.bool,
  'ariaLabels': PropTypes.shape({
    'dateInput': PropTypes.objectOf(
      PropTypes.shape({ 'startDate': PropTypes.string, 'endDate': PropTypes.string })
    ),
    'monthPicker': PropTypes.string,
    'yearPicker': PropTypes.string,
    'prevButton': PropTypes.string,
    'nextButton': PropTypes.string
  })
}
Calendar.defaultProps = {
  'showMonthArrow': true,
  'showMonthAndYearPickers': true,
  'disabledDates': [],
  'disabledDay': i => i,
  'classNames': {},
  'locale': defaultLocale,
  'ranges': [],
  'focusedRange': [0, 0],
  'dateDisplayFormat': 'MMM d, yyyy',
  'monthDisplayFormat': 'MMM yyyy',
  'weekdayDisplayFormat': 'E',
  'dayDisplayFormat': 'd',
  'showDateDisplay': true,
  'showPreview': true,
  'displayMode': 'date',
  'months': 1,
  'color': '#3d91ff',
  'scroll': { 'enabled': false },
  'direction': 'vertical',
  'maxDate': addMonths(new Date(), 0),
  'minDate': addYears(new Date(), -5),
  'rangeColors': ['#3d91ff', '#3ecf8e', '#fed14c'],
  'startDatePlaceholder': 'Early',
  'endDatePlaceholder': 'Continuous',
  'editableDateInputs': false,
  'dragSelectionEnabled': true,
  'fixedHeight': false,
  'calendarFocus': 'forwards',
  'preventSnapRefocus': false,
  'ariaLabels': {}
}


/*
 * EXPORTS
 */
export default Calendar
