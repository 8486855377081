/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation AccountCreateMutation(
  $email: EmailAddress!,
  $password: String!,
  $displayName: String!,
  $accountType: ACCOUNT_TYPE
) {
  AccountRegisterWithEmail(
    email: $email,
    password: $password,
    displayName: $displayName,
    accountType: $accountType
  ) {
    id,
    message
    status
  }
}
`
