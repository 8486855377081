/*
 * IMPORTS
 */
import 'react-date-range/dist/styles.css' // Npm: React date range.
import 'react-date-range/dist/theme/default.css' // Npm: React date range.
import React from 'react' // Npm: react.js library.
import TimePicker from 'react-time-picker' // Npm: Time picker for react.
import PropTypes from 'prop-types' // Npm: react.js library.
import _ from 'underscore' // NPM: Underscore.js library.
import { toast } from 'react-hot-toast' // Npm: React toast library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useMutation, useQuery } from '@apollo/client' // Npm: Apollo client.
import { HiMinusCircle } from 'react-icons/hi2' // Npm: React icons.
import { Button, Checkbox, Flex, Text } from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import DateRangePicker from 'components/DateRangePicker'



/*
 * GRAPHS
 */
import CustomerUpdateMutation from './__mutation__/index.customer.update.mutation'
import CustomerAccountUpdateMutation from './__mutation__/index.customerAccount.update.mutation'
import CustomerReadQuery from './__query__/index.customer.read.query'
import CustomerAccountReadQuery from './__query__/index.customerAccount.read.query'


/*
 * STYLES
 */
import './index.css'


/*
 * OBJECTS
 */
const Index = ({ showingFor, passOn, onClose }) => {
  // Hook assignment.
  const [dateRange, setDateRange] = React.useState({
    'selection': {
      'startDate': Object.DateRangeProvider()?.startDate,
      'endDate': Object.DateRangeProvider()?.endDate,
      'key': 'selection'
    }
  })
  const [days, setDays] = React.useState([])
  const [blockFrom, setBlockFrom] = React.useState('12:00:00')
  const [blockTo, setBlockTo] = React.useState('12:00:00')
  const [forceReRender, setForceReRender] = React.useState(void 0)
  const [UpdateMutation, UpdateMutationResponse] = useMutation('CUSTOMER' === showingFor ? CustomerUpdateMutation : CustomerAccountUpdateMutation)
  const _ReadQuery = useQuery('CUSTOMER' === showingFor ? CustomerReadQuery : CustomerAccountReadQuery, { 'variables': { ['CUSTOMER' === showingFor ? 'customerId' : 'customerAccountId']: 'CUSTOMER' === showingFor ? passOn?.customerId : passOn?.customerAccountId }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })

  // Object assignment.
  const _TimeConverstion = i => {
    /*
     * If no time provided then report
     * failure.
     */
    if (_.isEmpty(i)) return void 0

    // Create a Date object using the current date and the time string
    const currentDate = new Date()
    const dateString = currentDate.toISOString().split('T')[0] // Get current date in 'YYYY-MM-DD' format

    // Combine the date and time to form a complete date-time string
    return new Date(`${dateString}T${i}Z`).toISOString()
  }
  const _SubmitForm = async (e, __clear) => {
    // Prevent default behavior.
    e.preventDefault()

    // Execute update mutation.
    const _MutationCustomerUpdate = await UpdateMutation({
      'variables': {
      ['CUSTOMER' === showingFor ? 'customerId' : 'customerAccountId']: 'CUSTOMER' === showingFor ? passOn?.customerId : passOn?.customerAccountId,
        'blockBetweenFromHour': __clear ? null : (blockFrom?.includes('T') || blockFrom?.includes('Z') ? blockFrom : _TimeConverstion(blockFrom)),
        'blockBetweenToHour': __clear ? null : (blockTo?.includes('T') || blockTo?.includes('Z') ? blockTo : _TimeConverstion(blockTo)),
        'blockBetweenFromDate': __clear ? null : dateRange?.selection?.startDate,
        'blockBetweenToDate': __clear ? null : dateRange?.selection?.endDate,
        'blockInBetweenWeeks': __clear ? [] : days
      }
    })

    // If mutation caught an exception then report failure.
    if (_MutationCustomerUpdate instanceof Error) return _MutationCustomerUpdate

    // Style Guide.
    toast(_MutationCustomerUpdate?.data?.CustomerUpdate?.message)

    // Close modal.
    onClose?.()

    // Return void.
    return void 0
  }

  // Event handler.
  React.useEffect(() => {
    // If data is available then update.
    if (_ReadQuery && !_.isEmpty(_ReadQuery?.data?.['CUSTOMER' === showingFor ? 'CustomerRead' : 'CustomerAccountRead'])) {
      // Const assignment.
      const _data = _.first(_ReadQuery?.data?.['CUSTOMER' === showingFor ? 'CustomerRead' : 'CustomerAccountRead'])

      // Update states.
      setDays(_data?.blockInBetweenWeeks)
      setDateRange({
        'selection': {
          'startDate': _data?.blockBetweenFromDate,
          'endDate': _data?.blockBetweenToDate,
          'key': 'selection'
        }
      })
      setBlockFrom(new Date(_data?.blockBetweenFromHour)?.toISOString().split('T')[1].split('.')[0])
      setBlockTo(new Date(_data?.blockBetweenToHour)?.toISOString().split('T')[1].split('.')[0])
      setForceReRender(String.random(9))
    }
  }, [_ReadQuery])

  // Data assignment.
  const _isDisabled = UpdateMutationResponse?.loading

  // Return component.
  return (
    <Flex w='100%' flexDir='column' gap='22px' className='customerBlockBetweenDuration'>
      <Text w='100%' as='p' fontSize='17px' color='gray.500'>Enter your message for given customer, they will going to be notified once they logged in.</Text>
      <Flex w='100%' flexDir='column' gap='22px'>
        <Flex w='100%' gap='12px' jusitfyContent='space-between' alignItems='center'>
          <Text w='100%' color='brand.500'>Between Date Range.</Text>
          <DateRangePicker
            startDate={dateRange?.selection?.startDate}
            endDate={dateRange?.selection?.endDate}
            fontSize='13px'
            height='max-content'
            gap='6px'
            isDisabled={_isDisabled}
            disabled={_isDisabled}
            bg='brand.500'
            color='white'
            setSelectDate={setDateRange}
            key={forceReRender} />
        </Flex>
        <Flex flexDir='column' w='100%' gap='12px' jusitfyContent='center' alignItems='start'>
          <Text w='100%' color='purple.600'>Also or Between Time Range.</Text>
          <Flex w='100%' gap='12px' justify='start' align='center'>
          <Text color='purple.600' fontSize='13px' fontWeight='bold'>TO.</Text>
          <TimePicker
            onChange={setBlockFrom}
            value={blockFrom}
            disableClock
            isDisabled={_isDisabled}
            disabled={_isDisabled}
            clearIcon={<HiMinusCircle />}
            hourPlaceholder='HH'
            minutePlaceholder='MM'
            secondPlaceholder='SS'
            format='h:m:s a'
            maxDetail='minute' />
          <Text color='purple.600' fontSize='13px' fontWeight='bold'>FROM.</Text>
            <TimePicker
                onChange={setBlockTo}
                value={blockTo}
                disableClock
                isDisabled={_isDisabled}
                disabled={_isDisabled}
                clearIcon={<HiMinusCircle />}
                hourPlaceholder='HH'
                minutePlaceholder='MM'
                secondPlaceholder='SS'
                format='h:m:s a'
                maxDetail='minute' />
          </Flex>
        </Flex>
        <Flex flexDir='column' w='100%' gap='22px' jusitfyContent='space-between' alignItems='center'>
          <Text w='100%' color='tomato' fontSize='md'>Also or On Special Days.</Text>
          <Flex w='100%' gap='22px' justify='start' flexWrap='wrap' p='12px'>
          {[
'MON',
'TUE',
'WED',
'THU',
'FRI',
'SAT',
'SUN'
]?.map(__day => (
          <Checkbox
              key={__day}
              colorScheme='red'
              size='md'
              fontSize='15px'
              isDisabled={_isDisabled}
              disabled={_isDisabled}
              isChecked={days?.includes(__day)}
              onChange={__event => {
                // If target is checked then add day to the list.
                if (__event.target.checked) {
                  setDays([...days, __day])
                } else {
                  // Else remove the day from the list.
                  setDays(days.filter(_day => _day !== __day))
                }
              }}>
              <Text w='100%' color='gray.500' fontSize='13px' fontWeight='bold'>{__day}</Text>
            </Checkbox>
          ))}
          </Flex>
        </Flex>
        <Flex flexDir='row' align='center' gap='12px'>
        <Button
          w='100%'
          colorScheme='red'
          size='md'
          fontSize='15px'
          fontWeight='bold'
          isDisabled={_isDisabled}
          disabled={_isDisabled}
          onClick={_SubmitForm}>
          Block traffic
        </Button>
        <Button
          w='100%'
          bg='purple.50'
          size='md'
          fontSize='15px'
          fontWeight='bold'
          color='brand.500'
          isDisabled={_isDisabled}
          disabled={_isDisabled}
          onClick={e => _SubmitForm(e, true)}>
          Clear everything.
        </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'passOn': PropTypes.object.isRequired,
  'showingFor': PropTypes.array.isRequired,
  'onClose': PropTypes.func.isRequired
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
