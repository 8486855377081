/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.

/*
 * EXPORTS
 */
export default gql`
mutation BlockIpUpsertMutation($ipDirectoryId: ID, $displayName: String!, $ip: [ID!], $blockIpId: ID) {
  BlockIpUpsert(blockIpId: $blockIpId, displayName: $displayName, ipDirectoryId: $ipDirectoryId, ip: $ip) {
    message
    status
  }
}
`
