/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation CustomerAccountAttachSmppMutation($customerAccountId: ID!, $smppId: ID!, $doCopySmpp: Boolean) {
  CustomerAccountAttachSmpp(customerAccountId: $customerAccountId, smppId: $smppId, doCopySmpp: $doCopySmpp) {
    message
    status
  }
}
`
