/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.

/*
 * EXPORTS
 */
export default gql`
  query ContentTranslationReadUniqueQuery($contentTranslationId: ID!) {
    ContentTranslationReadUnique(contentTranslationId: $contentTranslationId) {
      regex,
      suffix,
      prefix,
      omitWords,
      strategy,
      globalTranslationKeyword,
      message,
      status,
      CustomerAccount {
        id,
        displayName
      },
      VendorAccount {
        id,
        displayName
      },
      Mcc {
        id,
        mcc,
        countryName
      },
      Mnc {
        id,
        mnc,
        network
      },
      Keyword {
        id,
        keyword
      },
      KeywordDirectory {
        id,
        displayName
      },
      SenderId {
        id,
        senderId
      },
      SenderIdDirectory {
        id,
        displayName
      }
    }
  }
`
