/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query EntityReadQuery($take: PositiveInt, $skip: Int, $search: String!) {
    EntityRead(take: $take, skip: $skip, search: $search) {
      id,
      displayName,
      address,
      _totalCount,
      message,
      status
    }
  }`
