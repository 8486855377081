/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query VendorReadQuery($vendorId: ID!) {
  VendorRead(vendorId: $vendorId) {
    id,
        createdAt,
        displayName,
        phone,
        email,
        technicalEmail,
        country,
        address,
        status,
        message,
        _totalCount,
        Account {
            id
            displayName
        }
        Billing {
            id
            email
            period
            currency
        }
  }
}
`
