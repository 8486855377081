/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * GRAPHS
 */
const Index = gql`
query SystemInformationReadUniqueQuery {
  SystemInformationReadUnique {
    id,
    createdAt,
    updatedAt,
    operatingSystem,
    release,
    cpuArchitecture,
    uptime,
    totalMemory,
    freeMemory,
    message,
    status
  }
}
`


/*
 * EXPORTS
 */
export default Index
