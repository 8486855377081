/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query RoutePlanReadQuery($routePlanId: ID) {
  RoutePlanRead(routePlanId: $routePlanId) {
    id,
    createdAt,
    updatedAt,
    priority,
    isPaused,
    smsType,
    message,
    fakeDlrRate,
    loadBalancerConfiguration,
    status,
    Mcc {
      mcc,
      countryName,
      Mnc {
        mnc,
        network
      }
    },
    Mnc {
      id,
      mnc,
      network
    },
    Route {
      displayName
    },
    SenderIdDirectory {
      id,
      displayName
    },
    SenderId {
      id,
      senderId
    },
    PhoneNumberoDirectory {
      id,
      displayName
    },
    PhoneNumbero {
      id,
      phoneNumbero
    },
    SenderIdVendorAccount {
      id,
      displayName
    }
    PhoneNumberoVendorAccount {
      id,
      displayName
    }
    VendorAccount {
      id,
      displayName
    }
    VendorAccounttDirectory {
      id,
      displayName
    }
    VendorAccountt {
      id,
      displayName,
      VendorAccount {
        id,
        displayName
      }
    }
  }
}
`
