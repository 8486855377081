/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.

/*
 * EXPORTS
 */
export default gql`
  query BlockIpReadUniqueQuery($blockIpId: ID!) {
    BlockIpReadUnique(blockIpId: $blockIpId) {
      displayName,
      message,
      status,
      Ip {
        id,
        ip
      },
      IpDirectory {
        id,
        displayName
      }
    }
  }
`
