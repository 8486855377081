/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
    mutation OperatorPrefixCreateThroughExcelMutation($mncId: ID!, $file: File!) {
    OperatorPrefixCreateThroughExcel(mncId: $mncId, file: $file) {
        id,
        createdAt,
        updatedAt,
        status,
        message
    }
  }
`
