/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import Moment from 'moment' // Npm: moment.js library.
import _ from 'underscore' // Npm: underscore library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useQuery } from '@apollo/client' // Npm: Apollo client.
import { IoMdAdd } from 'react-icons/io' // Npm: React icons.
import {
  Button,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tooltip,
  Tr,
  useBreakpointValue,
  useDisclosure
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import TableSpinner from 'components/TableSpinner'
import DownloadToExcel from 'components/DownloadToExcel'
import PaginationWithPageInformation from 'components/PaginationWithPageInformation'
import Modal from 'components/Modal'
import PaymentUpsert from 'components/PaymentUpsert'
import PaymentDelete from 'components/PaymentDelete'
import PaymentDropDownOptions from 'components/PaymentDropDownOptions'


/*
 * STYLES
 */
import {
  buttonStyle,
  cellStyle,
  headerStyle,
  rowStyle
} from './index.style'


/*
 * GRAPHS
 */
import PaymentReadQuery from './__query__/index.payment.read.query'


/*
 * OBJECTS
 */
const Index = ({ passOn }) => {
  // Const assignment.
  const _skipDifference = 8
  const _successFlags = Object.React.App.enums.GRAPHQL_SUCCESSFUL_QUERY_FLAGS.enums.map(i => i.key)

  // Hook assignment.
  const [skipPage, setSkipPage] = React.useState(0)
  const [skipDifference, setSkipDifference] = React.useState(_skipDifference)
  const { 'onOpen': onPaymentCreateOpen, 'isOpen': isPaymentCreateOpen, 'onClose': onPaymentCreateClose } = useDisclosure()
  const { 'onOpen': onPaymentUpdateOpen, 'isOpen': isPaymentUpdateOpen, 'onClose': onPaymentUpdateClose } = useDisclosure()
  const { 'onOpen': onPaymentDeleteOpen, 'isOpen': isPaymentDeleteOpen, 'onClose': onPaymentDeleteClose } = useDisclosure()
  const _QueryPaymentRead = useQuery(PaymentReadQuery, { 'variables': { 'customerId': passOn?.customerId, 'skip': skipPage * skipDifference, 'take': skipDifference }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })
  const _isFirstLoadCompleted = React.useRef(false)
  const _tableHeaderHeightRef = React.useRef(0)
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })

  // Data assignment.
  if (!_QueryPaymentRead?.loading && 0 < _QueryPaymentRead?.data?.PaymentRead?.length) _isFirstLoadCompleted.current = true
  if (0 === _QueryPaymentRead?.data?.PaymentRead?.length || !_.every(_.pluck(_QueryPaymentRead?.data?.PaymentRead, 'status'), i => _successFlags.includes(i))) _isFirstLoadCompleted.current = false

  // Return component.
  return (
    <>
      <Flex className='payment base'>
        <Flex
          display='flex'
          flex={1}
          flexDirection='column'
          gap={_isCurrentViewMobile ? '12px' : '22px'}
          bg='white'
          height='100%'
          borderRadius='20px'
          p={_isCurrentViewMobile ? '12px' : '22px'}>
          <Flex pb='0px' justify='space-between' flexWrap={{ 'base': 'wrap', 'md': 'nowrap' }}>
            <Tooltip label='Create new payment.' fontSize='sm'>
              <Button
                onClick={onPaymentCreateOpen}
                leftIcon={<IoMdAdd />}
                style={buttonStyle}>
                Create Payment
              </Button>
            </Tooltip>
            <DownloadToExcel
              cellsData={_QueryPaymentRead?.data?.PaymentRead?.map((item, __index) => _successFlags.includes(item.status) ? ({
                'S.No.': `${(__index + (skipDifference * (skipPage))) + 1}.`,
                [`Amount ( in ${passOn?.currency ?? 'EUR'} )`]: item?.amount ?? '-',
                'CreatedAt': Moment(item?.Smpp?.createdAt).format('DD-MM-YYYY'),
                'UpdatedAt': Moment(item?.Smpp?.updatedAt).format('DD-MM-YYYY')
              }) : ({}))}
              headersData={['S.No.', `Amount ( in ${passOn?.currency ?? 'EUR'} )`, 'CreatedAt', 'UpdatedAt'].map(i => ({ 'key': i, 'label': i }))} />
          </Flex>
          <TableContainer
            flex={1}
            display='flex' å
            borderRadius='15px'
            outline='1px solid #C5CFE8'>
            <Table variant='simple' size='sm'>
              <Thead
                ref={_tableHeaderHeightRef}
                color='rgba(141, 151, 182, 1)'
                fontStyle='DM Sans'
                fontWeight='500'
                fontSize='18px'
                lineHeight='24px'>
                <Tr style={headerStyle}>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>S.No.</Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    {`Amount ( in ${passOn?.currency ?? 'EUR'} )`}
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>CreatedAt</Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>UpdatedAt</Td>
                  <Td
                    style={rowStyle}>
                    Actions</Td>
                </Tr>
              </Thead>
              {_QueryPaymentRead?.loading && !_isFirstLoadCompleted.current ? (
                <TableSpinner isLoading={true} chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight} />
              ) : !_isFirstLoadCompleted.current && (0 === _QueryPaymentRead?.data?.PaymentRead?.length || !_.every(_.pluck(_QueryPaymentRead?.data?.PaymentRead, 'status'), i => _successFlags.includes(i.status))) ? (
                <TableSpinner isLoading={false} isEmpty={true} chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight} />
              ) : (
                <Tbody style={cellStyle}>
                  {_QueryPaymentRead?.data?.PaymentRead?.map((__item, __index) => (
                    <Tr key={__item?.id}>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {' '}
                        {`${(__index + (skipDifference * (skipPage))) + 1}.`}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {__item?.amount ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {Moment(__item?.Smpp?.createdAt).format('DD-MM-YYYY')}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {Moment(__item?.Smpp?.updatedAt).format('DD-MM-YYYY')}
                      </Td>
                      <Td
                        style={rowStyle}>
                        <Flex align='center' justify='center'>
                          {
                            <PaymentDropDownOptions
                              type={passOn?.type}
                              paymentId={__item?.id}
                              onPaymentUpdate={onPaymentUpdateOpen}
                              onPaymentDelete={onPaymentDeleteOpen}
                            />
                          }
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              )}
            </Table>
          </TableContainer>
        </Flex>
        <Modal
          size='md'
          title='Add New Payment'
          isOpen={isPaymentCreateOpen}
          onClose={onPaymentCreateClose}>
          <PaymentUpsert isCreateOnly={true} />
        </Modal>
        <Modal
          size='md'
          title='Update Payment'
          isOpen={isPaymentUpdateOpen}
          onClose={onPaymentUpdateClose}>
          <PaymentUpsert />
        </Modal>
        <Modal
          size='md'
          title='Delete Payment'
          isOpen={isPaymentDeleteOpen}
          onClose={onPaymentDeleteClose}>
          <PaymentDelete />
        </Modal>
      </Flex>
      <PaginationWithPageInformation
        skipPage={skipPage}
        skipDifference={_skipDifference}
        setSkipDifference={setSkipDifference}
        itemsPerPage={_skipDifference}
        totalCount={_.pluck(_QueryPaymentRead?.data?.PaymentRead, '_totalCount')?.[0]}
        onPageChange={setSkipPage}
      />
    </>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'passOn': PropTypes.object
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
