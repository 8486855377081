/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { HiMiniBoltSlash, HiPencil, HiTrash } from 'react-icons/hi2' // Npm: React icons.
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * OBJECTS
 */
const Index = ({
  type,
  customerAccountId,
  onCustomerBlockDuration,
  onCustomerAccountUpdate,
  onCustomerAccountDelete,
  PassOnUpdate
}) => (
  <Menu placement='left'>
    <MenuButton
      as={Button}
      margin='auto'
      borderRadius='8px'
      color='gray.500'
      bg='transparent'>
      <HiPencil size={18} />
    </MenuButton>
    <MenuList
      className='dropDownMenu'
      maxHeight='400px'
      overflow='auto'>
      <MenuItem
        gap='12px'
        display='flex'
        color='gray.500'
        onClick={() => {
          // Push customerAccount id to url.
          PassOnUpdate({ customerAccountId, type })

          // Execute handler.
          onCustomerAccountUpdate()
        }}
        minH='40px'>
        <HiPencil size={18} />
        <span>Edit Customer Account</span>
      </MenuItem>
      <MenuItem
        gap='12px'
        display='flex'
        pl='20px'
        pr='20px'
        color='gray.500'
        onClick={() => {
          // Push customer id to url.
          PassOnUpdate({ customerAccountId, type })

          // Execute handler.
          onCustomerBlockDuration()
        }}
        minH='40px'>
        <HiMiniBoltSlash size={18} />
        <span>Block Traffic</span>
      </MenuItem>
      <MenuItem
        gap='12px'
        display='flex'
        color='gray.500'
        onClick={() => {
          // Push customerAccount id to url.
          PassOnUpdate({ customerAccountId })

          // Execute handler.
          onCustomerAccountDelete()
        }}
        minH='40px'>
        <HiTrash size={18} />
        <span>Delete Customer Account</span>
      </MenuItem>
    </MenuList>
  </Menu>
)


/*
 * PROPTYPES
 */
Index.propTypes = {
  'type': PropTypes.string,
  'customerAccountId': PropTypes.string,
  'onCustomerBlockDuration': PropTypes.func,
  'onCustomerAccountUpdate': PropTypes.func,
  'onCustomerAccountDelete': PropTypes.func,
  'PassOnUpdate': PropTypes.func
}


/*
 * REDUX
 */
const _MapDispatchToProps = __dispatch => ({
  'PassOnUpdate': u => __dispatch({ 'type': 'PASS_ON_UPDATE', 'PassOn': u })
})


/*
 * EXPORT
 */
export default connect(void 0, _MapDispatchToProps)(Index)
