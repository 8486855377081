/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation VendorInvoiceCreateMutation($vendorId: ID!, $amount: Float!, $invoiceNumber: String!, $datedOn: DateTime!, $billingStartDate: DateTime!, $billingEndDate: DateTime!) {
  VendorInvoiceCreate(vendorId: $vendorId, amount: $amount, invoiceNumber: $invoiceNumber, datedOn: $datedOn, billingStartDate: $billingStartDate, billingEndDate: $billingEndDate) {
    id
    message
    status
  }
}
`
