/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation MccUpdateMutation(
  $mccId: ID!,
  $countryName: String!,
  $dialingCode: String,
  $mcc: String!,
  $minOperatorPrefixLength: Int,
  $maxOperatorPrefixLength: Int
) {
  MccUpdate(
    mccId: $mccId,
    countryName: $countryName,
    dialingCode: $dialingCode,
    mcc: $mcc,
    minOperatorPrefixLength: $minOperatorPrefixLength,
    maxOperatorPrefixLength: $maxOperatorPrefixLength,
  ) {
    id
    message
    status
  }
}
`
