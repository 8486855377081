/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import JoiBrowser from 'joi-browser' // Npm: Joi for frontend validation.
import _ from 'underscore' // Npm: Underscore.js library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { useLazyQuery, useMutation } from '@apollo/client' // Npm: Apollo client.


/*
 * PACKAGES
 */
import SubmitButton from 'components/SubmitButton'
import { MemoizedInput } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import VendorAccounttDirectoryReadUniqueQuery from './__query__/index.vendorAccounttDirectory.read.query'
import VendorAccounttDirectoryUpsertMutation from './__mutation__/index.vendorAccounttDirectory.upsert.mutation'


/*
 * OBJECTS
 */
const Index = ({ isOpen, isCreateOnly, onClose, passOn }) => {
  // Hook assignment.
  const [error, setError] = React.useState('')
  const [forceReRender, setForceReRender] = React.useState('')
  const [QueryVendorAccounttDirectoryReadUnique, QueryVendorAccounttDirectoryReadUniqueResponse] = useLazyQuery(VendorAccounttDirectoryReadUniqueQuery, { 'variables': { 'vendorAccounttDirectoryId': passOn?.vendorAccounttDirectoryId } })
  const [MutationVendorAccounttDirectoryUpsert, MutationVendorAccounttDirectoryUpsertResponse] = useMutation(VendorAccounttDirectoryUpsertMutation)
  const _formDataRef = React.useRef({})

  // Object assignment.
  const _SubmitForm = async e => {
    // Prevent default behavior.
    e.preventDefault()

    // Reset error.
    setError('')

    // Const assignment.
    const _JoiSchema = JoiBrowser.object({
      'displayName': JoiBrowser.string().required()
    }).options({ 'allowUnknown': true })

    // Remove all keys from _formDataRef.current which are undefined.
    _formDataRef.current = _.pick(_formDataRef.current, _.identity)

    // Validate form data.
    const _JoiSchemaValidate = _JoiSchema.validate(_formDataRef.current)

    // If error exists then report failure.
    if (_JoiSchemaValidate.error) return setError(_JoiSchemaValidate.error?.message)

    // Execute account registration mutation.
    const _MutationVendorAccounttDirectoryUpsert = await MutationVendorAccounttDirectoryUpsert({
      'variables': {
        'displayName': _formDataRef?.current?.displayName,
        'vendorAccounttDirectoryId': isCreateOnly ? void 0 : passOn?.vendorAccounttDirectoryId
      }
    })

    // If mutation caught an exception then report failure.
    if (_MutationVendorAccounttDirectoryUpsert instanceof Error) return _MutationVendorAccounttDirectoryUpsert

    // On Successful response from the mutation.
    if ('UPSERT_SUCCESSFUL' === _MutationVendorAccounttDirectoryUpsert?.data?.VendorAccounttDirectoryUpsert?.status) onClose?.()

    // Style Guide.
    return toast(_MutationVendorAccounttDirectoryUpsert?.data?.VendorAccounttDirectoryUpsert?.message)
  }

  // Event handler.
  React.useEffect(() => {
    // _Async handler.
    const _Async = async () => {
      // Const assignment.
      const _QueryVendorAccounttDirectoryReadUniqueQuery = await QueryVendorAccounttDirectoryReadUnique({ 'variables': { 'vendorAccounttDirectoryId': isCreateOnly ? 'UN_KNOWN' : passOn?.vendorAccounttDirectoryId } })

      // If query caught an exception then report failure.
      if (_QueryVendorAccounttDirectoryReadUniqueQuery instanceof Error) return _QueryVendorAccounttDirectoryReadUniqueQuery

      /*
       * If customer details fetch complete then
       * update its value.
       */
      if (_QueryVendorAccounttDirectoryReadUniqueQuery?.data?.VendorAccounttDirectoryReadUnique) {
        // Update form data.
        _formDataRef.current = {
          'vendorAccounttDirectoryId': passOn?.vendorAccounttDirectoryId,
          'displayName': _QueryVendorAccounttDirectoryReadUniqueQuery?.data?.VendorAccounttDirectoryReadUnique?.displayName
        }

        // Update state.
        return setForceReRender(String.random(8))
      }

      // Report failure.
      return void 0
    }; _Async()
  }, [passOn, isOpen])

  // Const assignment.
  const _isLoading = MutationVendorAccounttDirectoryUpsertResponse.loading
  const _isInputDisabled = isCreateOnly ? false : _isLoading || QueryVendorAccounttDirectoryReadUniqueResponse?.loading

  // Return component.
  return (
    <form style={{ 'width': '100%' }} onSubmit={_SubmitForm} key={forceReRender}>
      <MemoizedInput
        disabled={_isInputDisabled}
        isRequired={true}
        name='displayName'
        label='Directory Name'
        placeholder='e.g. "Otp vendorAccounts"'
        onChange={({ target }) => {
          // Over spreading.
          const { name, value } = target

          // Update form data.
          _formDataRef.current = {
            ..._formDataRef?.current,
            [name]: value
          }
        }}
        error={error}
        isInvalid={error?.includes('displayName')}
        data={_formDataRef?.current?.displayName}
      />
      <SubmitButton
        defaultText={isCreateOnly ? 'Create Folder' : 'Update Folder'}
        onSubmit={_SubmitForm}
        disabled={_isInputDisabled}
        isLoading={_isLoading} />
    </form>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'isCreateOnly': PropTypes.bool,
  'isOpen': PropTypes.bool,
  'onClose': PropTypes.func,
  'passOn': PropTypes.object
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
