/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation CustomerUpdateMutation(
  $customerId: ID!,
  $blockBetweenFromHour: DateTime
  $blockBetweenToHour: DateTime
  $blockBetweenFromDate: DateTime
  $blockBetweenToDate: DateTime
  $blockInBetweenWeeks: [String]
) {
  CustomerUpdate(
    customerId: $customerId,
    blockBetweenFromHour: $blockBetweenFromHour
    blockBetweenToHour: $blockBetweenToHour
    blockBetweenFromDate: $blockBetweenFromDate
    blockBetweenToDate: $blockBetweenToDate
    blockInBetweenWeeks: $blockInBetweenWeeks
  ) {
    message
    status
  }
}
`
