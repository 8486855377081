/*
 * IMPORTS
 */
import React from 'react' // Npm: React library.
import _ from 'underscore' // Npm: underscore library.
import { useQuery } from '@apollo/client' // NPM: Apollo client library.
import {
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tr,
  useMediaQuery
} from '@chakra-ui/react' // NPM: Chakra UI library.


/*
 * PACKAGES
 */
import DownloadToExcel from 'components/DownloadToExcel'
import MccMncSelector from 'components/MccMncForLcr'
import TableSpinner from 'components/TableSpinner'
import PaginationWithPageInformation from 'components/PaginationWithPageInformation'
import { MemoizedSelect } from 'components/MemoizedInput'


/*
 * STYLES
 */
import { cellStyle, headerStyle, rowStyle } from './index.style.js'


/*
 * GRAPHS
 */
import LcrReadQuery from './__query__/index.rateLcr.read.query'


/*
 * OBJECTS
 */
const Index = () => {
  // Const assignment.
  const _skipDifference = 8

  // Hook assignment.
  const [skipPage, setSkipPage] = React.useState(0)
  const [_isCurrentViewMobile] = useMediaQuery('(max-width: 768px)')
  const [skipDifference, setSkipDifference] = React.useState(_skipDifference)
  const [mcc, setMcc] = React.useState('UN_KNOWN')
  const [mnc, setMnc] = React.useState(['UN_KNOWN'])
  const [trunkType, setTrunkType] = React.useState('DIRECT')
  const _isFirstLoadCompleted = React.useRef(false)
  const _tableHeaderHeightRef = React.useRef(0)
  const _QueryLcrRead = useQuery(LcrReadQuery, {
    'variables': {
      mcc,
      mnc,
      trunkType,
      'skip': skipPage * skipDifference,
      'take': skipDifference
    },
    'fetchPolicy': Object.React.App.fetchPolicy,
    'pollInterval': Object.React.App.pollInterval
  })

  // Return component.
  return (
    <>
      <Flex
        w='100%'
        className='ratePlan base'
        p={{ 'base': '4' }}
        flexDirection='column'
        bg='white'
        height='100%'
        borderRadius='20px'
        overflowX='auto'>
        <Flex
          w='100%'
          pb='0px'
          flexDirection={{ 'base': 'column', 'md': 'row' }}
          justify='space-between'
          alignItems={{ 'base': 'flex-start', 'md': 'center' }}>
          <Flex
            w='100%'
            flexDirection={{ 'base': 'column', 'md': 'row' }}
            gap='12px'>
            <Flex flex={1}>
              <MccMncSelector onChange={__mcc => {
                setMcc(__mcc?.mcc)
                setMnc(__mcc?.mnc)
              }} />
            </Flex>
            <MemoizedSelect
              label='Type'
              w={160}
              options={[
                'DIRECT',
                'HQ',
                'SIM',
                'SPAM',
                'WHOLESALE',
                'FULLY_FEATURE',
                'SS7'
              ]}
              onChange={i => setTrunkType(i.target.value)}
            />
          </Flex>
          <Flex mt={{ 'base': '4', 'md': '0' }}>
            <DownloadToExcel
              cellsData={_QueryLcrRead.data?.RateLcrRead?.map((item, index) =>
                'READ_SUCCESSFUL' === item.status ? {
                  'S.No': `${index + (skipDifference * skipPage) + 1}.`,
                  'Dialing Code': item?.Mcc?.dialingCode,
                  'Mcc': item?.Mcc?.mcc,
                  'Mnc': item?.Mnc?.mnc,
                  'Country Name': item?.Mcc?.countryName,
                  'Operator': item?.Mnc?.network,
                  'Base Currency Rate': item?.rate,
                  'Actual Rate': item?.rate,
                  'Currency': item?.Rate?.currency,
                  'Account Name': item?.Rate?.displayName,
                  'Rate Type': item?.Rate?.type
                } : {})}
              headersData={[
                'S.No.',
                'Dialing Code',
                'Mcc',
                'Mnc',
                'Country Name',
                'Operator',
                'Base Currency Rate',
                'Actual Rate',
                'Currency',
                'Account Name',
                'Rate Type'
              ].map(i => ({ 'key': i, 'label': i }))}
            />
          </Flex>
        </Flex>
        <TableContainer
          flex={1}
          display='flex'
          borderRadius='15px'
          outline='1px solid #C5CFE8'
          mt='4'
          minH='220px'
          overflowX='auto'>
          <Table variant='simple' size={_isCurrentViewMobile ? 'sm' : 'md'}> {/* Adjust table size for mobile */}
            <Thead ref={_tableHeaderHeightRef}>
              <Tr style={headerStyle}>
                <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>
                  Sr. No.
                </Td>
                <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>
                  Dialing Code
                </Td>
                <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>
                  Mcc
                </Td>
                <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>
                  Mnc
                </Td>
                <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>
                  Country Name
                </Td>
                <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>
                  Operator
                </Td>
                <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>
                  Base Currency Rate
                </Td>
                <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>
                  Actual Rate
                </Td>
                <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>
                  Currency
                </Td>
                <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>
                  Account Name
                </Td>
                <Td style={rowStyle}>
                  Rate Type
                </Td>
              </Tr>
            </Thead>
            {_QueryLcrRead.loading && !_isFirstLoadCompleted.current ? (
              <TableSpinner
                isLoading={true}
                chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight}
              />
            ) : !_QueryLcrRead.loading && (_.isEmpty(_QueryLcrRead.data?.RateLcrRead) || 'READ_SUCCESSFUL' !== _QueryLcrRead.data?.RateLcrRead?.[0]?.status) ? (
              <TableSpinner
                isLoading={true}
                isEmpty={true}
                title='Kindly select Mcc and Mnc.'
                chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight}
              />
            ) : (
              <Tbody style={cellStyle}>
                {_QueryLcrRead.data?.RateLcrRead?.map((item, index) => (
                  <Tr key={index}>
                    <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {`${index + (skipDifference * skipPage) + 1}.`}
                    </Td>
                    <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {item?.Mcc?.dialingCode}
                    </Td>
                    <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {item?.Mcc?.mcc}
                    </Td>
                    <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {item?.Mnc?.mnc}
                    </Td>
                    <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {item?.Mcc?.countryName}
                    </Td>
                    <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {item?.Mnc?.network}
                    </Td>
                    <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {item?.rate}
                    </Td>
                    <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {item?.rate}
                    </Td>
                    <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {item?.Rate?.currency}
                    </Td>
                    <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>
                      {item?.Rate?.displayName}
                    </Td>
                    <Td style={rowStyle}>
                      {item?.Rate?.type}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            )}
          </Table>
        </TableContainer>
      </Flex>
      <PaginationWithPageInformation
        skipPage={skipPage}
        skipDifference={_skipDifference}
        setSkipDifference={setSkipDifference}
        itemsPerPage={_skipDifference}
        totalCount={_QueryLcrRead.data?.RateLcrRead?.[0]?._totalCount}
        onPageChange={setSkipPage}
      />
    </>
  )
}

Index.propTypes = {}


/*
 * REDUX
 */
export default Index
