/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: Prop types for checking props type.
import Moment from 'moment' // Npm: Moment.js library for date and time formatting.
import Debounce from 'lodash/debounce' // Npm: lodash library.
import _ from 'underscore' // Npm: utility module.
import { HiPlusCircle } from 'react-icons/hi2' // Npm: React icons.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useQuery } from '@apollo/client' // Npm: Apollo client for GraphQL queries and mutations.
import {
  Button,
  Checkbox,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tr,
  useBreakpointValue,
  useDisclosure
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import TableSpinner from 'components/TableSpinner'
import DownloadToExcel from 'components/DownloadToExcel'
import PaginationWithPageInformation from 'components/PaginationWithPageInformation'
import Modal from 'components/Modal'
import VendorAccountAttachRoutePlan from 'components/VendorAccountAttachRoutePlan'
import RoutePlanUpsert from 'components/RoutePlanUpsert'
import RoutePlanDelete from 'components/RoutePlanDelete'
import RoutePlanDropDownOptions from 'components/RoutePlanDropDownOptions'
import PlanFilterOptions from 'components/PlanFilterOptions'
import { MemoizedInput } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import RoutePlanReadQuery from './__query__/index.routePlan.read.query'


/*
 * STYLES
 */
import { buttonStyle, cellStyle, headerStyle, rowStyle } from './index.style'


/*
 * OBJECTS
 */
const Index = ({ passOn }) => {
  // Local variable.
  let _rows

  // Const assignment.
  const _skipDifference = 8
  const _successFlags = Object.React.App.enums.GRAPHQL_SUCCESSFUL_QUERY_FLAGS.enums.map(i => i.key)

  // Hook assignment.
  const [selectAll, setSelectAll] = React.useState(false)
  const [selectedIds, setSelectedIds] = React.useState([])
  const [onlyShowSenderIdBasedRouting, setOnlyShowSenderIdBasedRouting] = React.useState(false)
  const [onlyShowPhoneNumberBasedRouting, setOnlyShowPhoneNumberBasedRouting] = React.useState(false)
  const [onlyShowLBRRouting, setOnlyShowLBRRouting] = React.useState(false)
  const [onlyShowLCRRouting, setOnlyShowLCRRouting] = React.useState(false)
  const [skipDifference, setSkipDifference] = React.useState(_skipDifference)
  const [mccNameToSearch, setMccNameToSearch] = React.useState('')
  const [mncNameToSearch, setMncNameToSearch] = React.useState('')
  const [operatorNameToSearch, setOperatorNameToSearch] = React.useState('')
  const [skipPage, setSkipPage] = React.useState(0)
  const { 'isOpen': isRoutePlanCreateOpen, 'onOpen': onRoutePlanCreateOpen, 'onClose': onRoutePlanCreateClose } = useDisclosure()
  const { 'isOpen': isRoutePlanUpdateOpen, 'onOpen': onRoutePlanUpdateOpen, 'onClose': onRoutePlanUpdateClose } = useDisclosure()
  const { 'isOpen': isRoutePlanDeleteOpen, 'onOpen': onRoutePlanDeleteOpen, 'onClose': onDeleteModalClose } = useDisclosure()
  const { 'isOpen': isVendorAccountAttachRoutePlanOpen, 'onOpen': onVendorAccountAttachRoutePlanOpen, 'onClose': onVendorAccountAttachRoutePlanClose } = useDisclosure()
  const _QueryRoutePlanRead = useQuery(RoutePlanReadQuery, { 'variables': { 'routeId': passOn?.routeId, 'displayName': passOn?.routeId?.displayName, mccNameToSearch, mncNameToSearch, operatorNameToSearch, 'skip': skipPage * skipDifference, 'take': skipDifference }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })
  const _isFirstLoadCompleted = React.useRef(false)
  const _onMccNameToSearchInputChange = React.useCallback(Debounce(e => setMccNameToSearch(e.target.value), 800), [])
  const _onMncNameToSearchInputChange = React.useCallback(Debounce(e => setMncNameToSearch(e.target.value), 800), [])
  const _onOperatorNameToSearchInputChange = React.useCallback(Debounce(e => setOperatorNameToSearch(e.target.value), 800), [])
  const _tableHeaderHeightRef = React.useRef(0)
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })
  
  // Event handler.
  React.useEffect(() => {
    // Reset selectedIds when the data is re-fetched
    if (_QueryRoutePlanRead.data) {
      // Reset states.
      setSelectedIds([])
      setSelectAll(false)
    }
  }, [
    isRoutePlanCreateOpen,
    isRoutePlanUpdateOpen,
    isRoutePlanDeleteOpen,
    isVendorAccountAttachRoutePlanOpen,
    _QueryRoutePlanRead.data
  ])

  // Object assignment.
  const _HandleCheckboxChange = id => {
    // Update state.
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter(selectedId => selectedId !== id))
    } else {
      setSelectedIds([...selectedIds, id])
    }
  }
  const _HandleSelectAll = () => {
    // Update state.
    setSelectAll(!selectAll)
    setSelectedIds(selectAll ? [] : _QueryRoutePlanRead?.data?.RoutePlanRead?.map(item => item.id) || [])
  }

  // Data assignment.
  if (!_QueryRoutePlanRead.loading && 0 < _QueryRoutePlanRead.data?.RoutePlanRead?.length) _isFirstLoadCompleted.current = true
  if (0 === _QueryRoutePlanRead.data?.RoutePlanRead?.length || (0 < _QueryRoutePlanRead.data?.RoutePlanRead?.length && (!_.every(_.pluck(_QueryRoutePlanRead.data?.RoutePlanRead, 'status'), i => _successFlags?.includes(i)) || _.some(_.pluck(_QueryRoutePlanRead.data?.RoutePlanRead, 'status'), i => 'ROUTE_PLAN_NOT_FOUND' === i)))) _isFirstLoadCompleted.current = false

  // Conditional check if any filter are turned on.
  if (onlyShowSenderIdBasedRouting) {
    // Update rows.
    _rows = _QueryRoutePlanRead.data?.RoutePlanRead?.filter(j => !_.isEmpty(j?.SenderIdDirectory?.displayName))
  } else if (onlyShowPhoneNumberBasedRouting) {
    // Update rows.
    _rows = _QueryRoutePlanRead.data?.RoutePlanRead?.filter(j => !_.isEmpty(j?.PhoneNumberoDirectory?.displayName))
  } else if (onlyShowLBRRouting) {
    // Update rows.
    _rows = _QueryRoutePlanRead.data?.RoutePlanRead?.filter(j => !_.isEmpty(j?.loadBalancerConfiguration))
  } else if (onlyShowLCRRouting) {
    // Update rows.
    _rows = _QueryRoutePlanRead.data?.RoutePlanRead?.filter(j => !_.isEmpty(j?.VendorAccounttDirectory?.displayName))
  } else {
    // Update rows.
    _rows = _QueryRoutePlanRead.data?.RoutePlanRead
  }

  // Return component.
  return (
    <>
      <Flex className='routePlan base' >
        <Flex
          display='flex'
          flex={1}
          flexDirection='column'
          gap={_isCurrentViewMobile ? '12px' : 0}
          bg='white'
          height='100%'
          borderRadius='20px'
          p={_isCurrentViewMobile ? '12px' : '22px'}>
          <Flex w='100%' flexDir='column' gap='12px' overflow='hidden' overflowY='scroll' overflowX='scroll' flexWrap={{ 'base': 'wrap', 'md': 'wrap' }}>
            <Flex w='100%' flexDir='row' justifyContent='space-between'>
              <Flex w='100%' pb='0px' justify='space-between'>
                <Flex display='flex' flexDir='row' gap='12px'>
                  <Button
                    onClick={onRoutePlanCreateOpen}
                    leftIcon={<HiPlusCircle />}
                    style={buttonStyle}>
                    New Route Plan
                  </Button>
                  <Flex zIndex={1000}>
                    <PlanFilterOptions
                      onChange={e => {
                        // Conditional based toggling.
                        if ('SENDER_ID' === e) {
                          // Only show senderId based routing.
                          setOnlyShowSenderIdBasedRouting(!onlyShowSenderIdBasedRouting)

                          // Disable other filters.
                          setOnlyShowPhoneNumberBasedRouting(false)
                          setOnlyShowLBRRouting(false)
                          setOnlyShowLCRRouting(false)
                        } else if ('PHONE_NUMBER' === e) {
                          // Only show phone number based routing.
                          setOnlyShowPhoneNumberBasedRouting(!onlyShowPhoneNumberBasedRouting)

                          // Disable other filters.
                          setOnlyShowSenderIdBasedRouting(false)
                          setOnlyShowLBRRouting(false)
                          setOnlyShowLCRRouting(false)
                        } else if ('LB' === e) {
                          // Only show load balanced routing.
                          setOnlyShowLBRRouting(!onlyShowLBRRouting)

                          // Disable other filters.
                          setOnlyShowSenderIdBasedRouting(false)
                          setOnlyShowPhoneNumberBasedRouting(false)
                          setOnlyShowLCRRouting(false)
                        } else if ('LCR' === e) {
                          // Only show least cost routing.
                          setOnlyShowLCRRouting(!onlyShowLCRRouting)

                          // Disable other filters.
                          setOnlyShowSenderIdBasedRouting(false)
                          setOnlyShowPhoneNumberBasedRouting(false)
                          setOnlyShowLBRRouting(false)
                        } else {
                          // Show all.
                          setOnlyShowSenderIdBasedRouting(false)
                          setOnlyShowPhoneNumberBasedRouting(false)
                          setOnlyShowLBRRouting(false)
                          setOnlyShowLCRRouting(false)
                        }
                      }}
                    />
                  </Flex>
                </Flex>
                <Flex ml='10px'>
                  <DownloadToExcel
                    cellsData={0 === _QueryRoutePlanRead.data?.RoutePlanRead?.length || !_.every(_.pluck(_QueryRoutePlanRead.data?.RoutePlanRead, 'status'), i => _successFlags?.includes(i)) ? [] : _QueryRoutePlanRead.data?.RoutePlanRead?.map((item, __index) => ({
                      'S.No.': `${(__index + (skipDifference * (skipPage))) + 1}.`,
                      'Route Name': item.Route.displayName ?? '-',
                      'Vendor Account': item?.VendorAccounttDirectory?.displayName ?? item.VendorAccount?.displayName ?? '-',
                      'Mcc': item?.Mcc?.mcc ?? '-',
                      'Mnc': item?.Mnc?.mnc ?? '-',
                      'Operator': item?.Mnc?.network ?? '-',
                      'Priority': item?.priority ?? '-',
                      'Country Name': item?.Mcc?.countryName ?? '-',
                      'Dialing Code': item?.Mcc?.dialingCode ?? '-',
                      'SenderId Directory': item?.SenderIdDirectory?.displayName ?? '-',
                      'PhoneNumbers Directory': item?.PhoneNumberoDirectory?.displayName ?? '-',
                      'CreatedAt': Moment(item.createdAt).format('YYYY-MM-DD'),
                      'UpdatedAt': Moment(item.updatedAt).format('YYYY-MM-DD')
                    }))}
                    headersData={[
                      'S.No.',
                      'Route Name',
                      'Vendor Account',
                      'Mcc',
                      'Mnc',
                      'Operator',
                      'Priority',
                      'Country Name',
                      'Dialing Code',
                      'SenderId Directory',
                      'PhoneNumbers Directory',
                      'CreatedAt',
                      'UpdatedAt'
                    ].map(i => ({ 'key': i, 'label': i }))} />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <TableContainer
            flex={1}
            display='flex'
            borderRadius='15px'
            outline='1px solid #C5CFE8'>
            <Table variant='simple' size='sm'>
              <Thead ref={_tableHeaderHeightRef}>
                <Tr style={{ ...headerStyle, 'position': 'sticky', 'top': 0, 'zIndex': 1 }}>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    <Checkbox bg='gray.100' onChange={_HandleSelectAll} isChecked={selectAll} />
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    S No.
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Route Name
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Vendor Account
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Mcc
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Mnc
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Operator
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Priority
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Country Name
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    {' '}
                    Dialing Code
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    {' '}
                    SenderId Directory
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    {' '}
                    PhoneNumbers Directory
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    CreatedAt
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    UpdatedAt
                  </Td>
                  <Td
                    style={rowStyle}>
                    Actions
                  </Td>
                </Tr>
                <Tr>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)' />
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)' />
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)' />
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)' />
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>
                    <MemoizedInput
                      placeholder='Search'
                      className='filter searchInput'
                      defaultValue={mccNameToSearch}
                      onChange={_onMccNameToSearchInputChange}
                    />
                  </Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>
                    <MemoizedInput
                      placeholder='Search'
                      className='filter searchInput'
                      defaultValue={mncNameToSearch}
                      onChange={_onMncNameToSearchInputChange}
                    />
                  </Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>
                    <MemoizedInput
                      placeholder='Search'
                      className='filter searchInput'
                      defaultValue={operatorNameToSearch}
                      onChange={_onOperatorNameToSearchInputChange}
                    />
                  </Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)' />
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)' />
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)' />
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)' />
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)' />
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)' />
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)' />
                  <Td style={rowStyle} />
                </Tr>
              </Thead>
              {
                _QueryRoutePlanRead.loading && _isFirstLoadCompleted.current ? (
                  <TableSpinner isLoading={true} chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight} />
                ) : !_isFirstLoadCompleted.current && (0 === _QueryRoutePlanRead.data?.RoutePlanRead?.length || (0 < _QueryRoutePlanRead.data?.RoutePlanRead?.length && (!_.every(_.pluck(_QueryRoutePlanRead.data?.RoutePlanRead, 'status'), i => _successFlags?.includes(i)) || _.some(_.pluck(_QueryRoutePlanRead.data?.RoutePlanRead, 'status'), i => 'ROUTE_PLAN_NOT_FOUND' === i)))) ? (
                  <TableSpinner isLoading={false} isEmpty={true} chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight} />
                ) : (
                  <Tbody style={cellStyle}>
                    {
                      _rows?.map((item, __index) => (
                        <Tr key={String.random(8)}>
                          <Td style={rowStyle}
                            borderRight='1px solid rgba(216, 227, 252, 1)'>
                            <Checkbox
                              bg='gray.100'
                              isChecked={selectedIds.includes(item.id)}
                              onChange={() => {
                                _HandleCheckboxChange(item.id)
                              }}
                            ></Checkbox></Td>
                          <Td
                            style={rowStyle}
                            borderRight='1px solid rgba(216, 227, 252, 1)'>
                            {' '}
                            {`${(__index + (skipDifference * (skipPage))) + 1}.`}
                          </Td>
                          <Td
                            style={rowStyle}
                            borderRight='1px solid rgba(216, 227, 252, 1)'>
                            {item.Route?.displayName ?? '-'}
                          </Td>
                          <Td
                            style={rowStyle}
                            borderRight='1px solid rgba(216, 227, 252, 1)'>
                            {item?.VendorAccounttDirectory?.displayName ?? item.VendorAccount?.displayName ?? '-'}
                          </Td>
                          <Td
                            style={rowStyle}
                            borderRight='1px solid rgba(216, 227, 252, 1)'>
                            {item?.Mcc?.mcc ?? '-'}
                          </Td>
                          <Td
                            style={rowStyle}
                            borderRight='1px solid rgba(216, 227, 252, 1)'>
                            {item?.Mnc?.mnc ?? '-'}
                          </Td>
                          <Td
                            style={rowStyle}
                            borderRight='1px solid rgba(216, 227, 252, 1)'>
                            {item?.Mnc?.network ?? '-'}
                          </Td>
                          <Td
                            style={rowStyle}
                            borderRight='1px solid rgba(216, 227, 252, 1)'>
                            {item?.priority ?? '-'}
                          </Td>
                          <Td
                            style={rowStyle}
                            borderRight='1px solid rgba(216, 227, 252, 1)'>
                            {item?.Mcc?.countryName ?? '-'}
                          </Td>
                          <Td
                            style={rowStyle}
                            borderRight='1px solid rgba(216, 227, 252, 1)'>
                            {item?.Mcc?.dialingCode ?? '-'}
                          </Td>
                          <Td
                            style={rowStyle}
                            borderRight='1px solid rgba(216, 227, 252, 1)'>
                            {item?.SenderIdDirectory?.displayName ?? '-'}
                          </Td>
                          <Td
                            style={rowStyle}
                            borderRight='1px solid rgba(216, 227, 252, 1)'>
                            {item?.PhoneNumberoDirectory?.displayName ?? '-'}
                          </Td>
                          <Td
                            style={rowStyle}
                            borderRight='1px solid rgba(216, 227, 252, 1)'>
                            {Moment(item.createdAt).format('YYYY-MM-DD')}
                          </Td>
                          <Td
                            style={rowStyle}
                            borderRight='1px solid rgba(216, 227, 252, 1)'>
                            {Moment(item.updatedAt).format('YYYY-MM-DD')}
                          </Td>
                          <Td
                            style={rowStyle}>
                            {' '}
                            {
                              <RoutePlanDropDownOptions
                                routePlanIds={[...selectedIds, item.id]}
                                routePlanId={item.id}
                                routeId={item.Route?.id}
                                onRoutePlanUpdate={onRoutePlanUpdateOpen}
                                onRoutePlanDelete={onRoutePlanDeleteOpen}
                                onVendorAccountAttachRoutePlanOpen={onVendorAccountAttachRoutePlanOpen}
                                selectedIds={selectedIds}
                              />
                            }
                          </Td>
                        </Tr>
                      ))}
                  </Tbody>
                )}
            </Table>
          </TableContainer>
        </Flex>
        <Modal
          size='3xl'
          title='Route Plan Create'
          isOpen={isRoutePlanCreateOpen}
          onClose={onRoutePlanCreateClose}
          isCentered={false}>
          <RoutePlanUpsert isCreateOnly={true} />
        </Modal>
        <Modal
          size='3xl'
          title='Route Plan Update'
          isOpen={isRoutePlanUpdateOpen}
          onClose={onRoutePlanUpdateClose}
          isCentered={false}>
          <RoutePlanUpsert />
        </Modal>
        <Modal
          size='md'
          title='Route Plan Delete'
          isOpen={isRoutePlanDeleteOpen}
          onClose={onDeleteModalClose}>
          <RoutePlanDelete />
        </Modal>
        <Modal
          size='md'
          title='Attach Vendor Account'
          isOpen={isVendorAccountAttachRoutePlanOpen}
          onClose={onVendorAccountAttachRoutePlanClose}>
          <VendorAccountAttachRoutePlan />
        </Modal>
      </Flex>
      <PaginationWithPageInformation
        skipPage={skipPage}
        skipDifference={_skipDifference}
        setSkipDifference={setSkipDifference}
        itemsPerPage={_skipDifference}
        totalCount={_.pluck(_QueryRoutePlanRead?.data?.RoutePlanRead, '_totalCount')?.[0]}
        onPageChange={setSkipPage}
      />
    </>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'passOn': PropTypes.object
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
