/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query VendorInvoiceReadQuery($vendorId: ID, $vendorInvoiceId: ID) {
  VendorInvoiceRead(vendorId: $vendorId, vendorInvoiceId: $vendorInvoiceId) {
    id,
    amount,
    billingStartDate,
    billingEndDate,
    datedOn,
    invoiceNumber,
    message,
    status
  }
}
`
