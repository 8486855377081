/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.

/*
 * EXPORTS
 */
export default gql`
  query VendorAccounttReadQuery($vendorAccounttDirectoryId: ID!, $take: PositiveInt!, $skip: Int!) {
    VendorAccounttRead(vendorAccounttDirectoryId: $vendorAccounttDirectoryId, take: $take, skip: $skip) {
      id,
        displayName,
        status,
        message,
        _totalCount
        VendorAccount {
          id,
          displayName,
          Vendor {
            id,
            displayName
          }
        }
    }
  }
`
