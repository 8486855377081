/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query MccReadQuery($mccId: ID!) {
  MccRead(mccId: $mccId) {
    id,
    countryName,
    dialingCode,
    mcc,
    minOperatorPrefixLength,
    maxOperatorPrefixLength
  }
}
`
