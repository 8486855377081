/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation PhoneNumberoDeleteMutation($phoneNumberoId: ID!) {
  PhoneNumberoDelete(phoneNumberoId: $phoneNumberoId) {
    id
    message
    status
  }
}
`
