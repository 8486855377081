/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation VendorAttachAccountLoginMutation($vendorId: ID!, $accountId: ID!) {
  VendorAttachAccountLogin(vendorId: $vendorId, accountId: $accountId) {
    message
    status
  }
}
`
