/*
 * EXPORTS
 */
export const headerStyle = {
  'textAlign': 'left',
  'fontStyle': 'DM Sans',
  'fontWeight': '400',
  'fontSize': '21px',
  'lineHeight': '32px',
  'color': 'rgba(43, 54, 116, 0.8)'
}
