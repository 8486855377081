/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation AccountUploadMutation($accountId: ID, $thumbnailStoredAt: Image) {
  AccountUpload(accountId: $accountId, thumbnailStoredAt: $thumbnailStoredAt) {
    id,
    thumbnailStoredAt,
    message,
    status
  }
}
`
