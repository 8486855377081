/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import Moment from 'moment' // Npm: Moment library.
import PropTypes from 'prop-types' // Npm: react.js library.
import Debounce from 'lodash/debounce' // Npm: lodash library.
import _ from 'underscore' // Npm: underscore library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { toast } from 'react-hot-toast' // Npm: React toastify.
import { useMutation, useQuery } from '@apollo/client' // Npm: Apollo client.
import { IoMdAdd } from 'react-icons/io' // Npm: React icons.
import {
  Button,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tooltip,
  Tr,
  useBreakpointValue,
  useDisclosure
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import DownloadToExcel from 'components/DownloadToExcel'
import PaginationWithPageInformation from 'components/PaginationWithPageInformation'
import Modal from 'components/Modal'
import QuickFileUpload from 'components/QuickFileUpload'
import TableSpinner from 'components/TableSpinner'
import SenderIdDropDownOptions from 'components/SenderIdsDropDownOptions'
import SenderIdUpsert from 'components/SenderIdsUpsert'
import SenderIdDelete from 'components/SenderIdsDelete'
import { MemoizedInput } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import SenderIdReadQuery from './__query__/index.senderId.read.query'
import SenderIdUploadMutation from './__mutation__/index.senderId.upload.mutation'


/*
 * STYLES
 */
import {
  buttonStyle,
  cellStyle,
  headerStyle,
  rowStyle
} from './index.style'


/*
 * OBJECTS
 */
const Index = ({ passOn }) => {
  // Const assignment.
  const _skipDifference = 8
  const _successFlags = Object.React.App.enums.GRAPHQL_SUCCESSFUL_QUERY_FLAGS.enums.map(i => i.key)

  // Hook assignment._QuerySenderIdRead.data?.SenderIdRead
  const [skipPage, setSkipPage] = React.useState(0)
  const [skipDifference, setSkipDifference] = React.useState(_skipDifference)
  const [senderIdToSearch, setSenderIdToSearch] = React.useState('')
  const [MutationSenderIdUpload] = useMutation(SenderIdUploadMutation)
  const { 'isOpen': isSenderIdCreateOpen, 'onOpen': onSenderIdCreateOpen, 'onClose': onSenderIdCreateClose } = useDisclosure()
  const { 'isOpen': isSenderIdUpdateOpen, 'onOpen': onSenderIdUpdateOpen, 'onClose': onSenderIdUpdateClose } = useDisclosure()
  const { 'isOpen': isSenderIdDeleteOpen, 'onOpen': onSenderIdDeleteOpen, 'onClose': onSenderIdDeleteClose } = useDisclosure()
  const _QuerySenderIdRead = useQuery(SenderIdReadQuery, { 'variables': { 'senderIdDirectoryId': passOn?.senderIdDirectoryId, senderIdToSearch, 'skip': skipPage * skipDifference, 'take': skipDifference }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })
  const _isFirstLoadCompleted = React.useRef(false)
  const _onSenderIdToSearchInputChange = React.useCallback(Debounce(e => setSenderIdToSearch(e.target.value), 800), [])
  const _tableHeaderHeightRef = React.useRef(0)
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })

  // Data Assignment.
  if (!_QuerySenderIdRead.loading && 0 < _QuerySenderIdRead.data?.SenderIdRead?.length && !_isFirstLoadCompleted.current) _isFirstLoadCompleted.current = true
  if (!_.every(_.pluck(_QuerySenderIdRead.data?.SenderIdRead, 'status'), i => _successFlags.includes(i))) _isFirstLoadCompleted.current = false

  // Return component.
  return (
    <>
      <Flex className='senderIds base'>
        <Flex
          display='flex'
          flex={1}
          flexDirection='column'
          gap={_isCurrentViewMobile ? '12px' : '22px'}
          bg='white'
          height='100%'
          borderRadius='20px'
          p={_isCurrentViewMobile ? '12px' : '22px'}>
          <Flex pb='0px' justify='space-between' gap='12px'>
            <Tooltip label='Create New SenderId' fontSize='sm'>
              <Button
                w='170px'
                leftIcon={<IoMdAdd />}
                onClick={onSenderIdCreateOpen}
                style={buttonStyle}>
                Create Sender Id
              </Button>
            </Tooltip>
            <Flex gap='12px'>
              <QuickFileUpload
                onChange={j => {
                  // Const assignment.
                  const _senderIds = Array.from({ 'length': j?.rows?.length }, (__, i) => _.first(j?.rows[i]))

                  // Execute mutation.
                  return MutationSenderIdUpload({ 'variables': { 'senderIdDirectoryId': passOn?.senderIdDirectoryId, 'senderIds': _senderIds } }).then(jp => {
                    // On Successful response from the mutation.
                    if ('UPSERT_SUCCESSFUL' === jp?.data?.SenderIdUpload?.status) {
                      // Toast success.
                      toast('Successfully uploaded the senderIds in your senderId directory.')
                    } else {
                      // Toast failure.
                      toast(jp?.data?.SenderIdUpload?.message ?? 'Failed to upload the senderIds in your senderId directory.')
                    }
                  })
                }}
                isLoading={false}
              />
              <DownloadToExcel
                cellsData={0 === _QuerySenderIdRead?.data?.SenderIdRead?.length || !_.every(_.pluck(_QuerySenderIdRead?.data?.SenderIdRead, 'status'), i => _successFlags.includes(i)) ? [] : _QuerySenderIdRead?.data?.SenderIdRead.map((item, __index) => ({
                  'S.No.': `${(__index + (skipDifference * (skipPage))) + 1}.`,
                  'CreatedAt': Moment(item?.createdAt).format('YYYY-MM-DD'),
                  'UpdatedAt': Moment(item?.updatedAt).format('YYYY-MM-DD'),
                  'SenderId': item.senderId ?? '-'
                }))}
                headersData={['S.No.', 'CreatedAt', 'UpdatedAt', 'senderId'].map(i => ({ 'key': i, 'label': i }))} />
            </Flex>
          </Flex>
          <TableContainer
            flex={1}
            display='flex'
            borderRadius='15px'
            outline='1px solid #C5CFE8'>
            <Table variant='simple' size='sm'>
              <Thead ref={_tableHeaderHeightRef}>
                <Tr style={{ ...headerStyle, 'position': 'sticky', 'top': 0, 'zIndex': 1 }}>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>S.No</Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>SenderId</Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>Created At</Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>Updated At</Td>
                  <Td
                    style={rowStyle}>Actions</Td>
                </Tr>
                <Tr key={String.random(5)} >
                  <Td borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>
                    <MemoizedInput
                      placeholder='Search'
                      className='filter searchInput'
                      defaultValue={senderIdToSearch}
                      onChange={_onSenderIdToSearchInputChange}
                    />
                  </Td>
                  <Td borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td></Td>
                </Tr>
              </Thead>
              {_QuerySenderIdRead.loading && !_isFirstLoadCompleted.current ? (
                <TableSpinner isLoading={true} chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight} />
              ) : (0 === _QuerySenderIdRead.data?.SenderIdRead?.length || (0 < _QuerySenderIdRead.data?.SenderIdRead?.length && !_.every(_.pluck(_QuerySenderIdRead.data?.SenderIdRead, 'status'), i => _successFlags.includes(i)))) ? (
                <TableSpinner isLoading={false} isEmpty={true} chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight} />
              ) : (
                <Tbody style={cellStyle}>
                  {_QuerySenderIdRead.data?.SenderIdRead?.map(({ id, createdAt, updatedAt, senderId }, __index) => (
                    <Tr key={String.random(5)}>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {`${(__index + (skipDifference * (skipPage))) + 1}.`}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {senderId ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {Moment(createdAt).format('YYYY-MM-DD')}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {Moment(updatedAt).format('YYYY-MM-DD')}
                      </Td>
                      <Td
                        style={rowStyle}>
                        {' '}
                        {
                          <SenderIdDropDownOptions
                            senderIdId={id}
                            senderIdDirectoryId={passOn?.senderIdDirectoryId}
                            onSenderIdUpdate={onSenderIdUpdateOpen}
                            onSenderIdDelete={onSenderIdDeleteOpen}
                          />
                        }
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              )
              }
            </Table>
          </TableContainer>
        </Flex>
        <Modal
          size='md'
          title='SenderId Create'
          isOpen={isSenderIdCreateOpen}
          onClose={onSenderIdCreateClose}>
          <SenderIdUpsert isCreateOnly={true} />
        </Modal>
        <Modal
          size='md'
          title='SenderId Update'
          isOpen={isSenderIdUpdateOpen}
          onClose={onSenderIdUpdateClose}>
          <SenderIdUpsert />
        </Modal>
        <Modal
          size='md'
          title='SenderId Delete'
          isOpen={isSenderIdDeleteOpen}
          onClose={onSenderIdDeleteClose}>
          <SenderIdDelete />
        </Modal>
      </Flex>
      <PaginationWithPageInformation
        skipPage={skipPage}
        skipDifference={_skipDifference}
        setSkipDifference={setSkipDifference}
        itemsPerPage={_skipDifference}
        totalCount={_.pluck(_QuerySenderIdRead.data?.SenderIdRead, '_totalCount')?.[0]}
        onPageChange={setSkipPage}
      />
    </>
  )
}



/*
 * PROPTYPES
 */
Index.propTypes = {
  'passOn': PropTypes.object
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)

