/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import Moment from 'moment' // Npm: moment.js library.
import Debounce from 'lodash/debounce' // Npm: lodash library.
import _ from 'underscore' // Npm: underscore library.
import { useQuery } from '@apollo/client' // Npm: Apollo client.
import { IoMdAdd } from 'react-icons/io' // Npm: React icons.
import {
  Button,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tooltip,
  Tr,
  useBreakpointValue,
  useDisclosure
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import DownloadToExcel from 'components/DownloadToExcel'
import TableSpinner from 'components/TableSpinner'
import PaginationWithPageInformation from 'components/PaginationWithPageInformation'
import Modal from 'components/Modal'
import VendorUpsert from 'components/VendorUpsert'
import VendorDelete from 'components/VendorDelete'
import VendorDropDownOptions from 'components/VendorDropDownOptions'
import BilateralRelation from 'components/BilateralRelation'
import CreditManagementUpsert from 'components/VendorCreditManagementUpsert'
import VendorPasswordChange from 'components/VendorPasswordChange'
import { MemoizedInput } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import VendorReadQuery from './__query__/index.vendor.read.query'


/*
 * STYLES
 */
import { buttonStyle, cellStyle, headerStyle, rowStyle } from './index.style'


/*
 * OBJECTS
 */
const Index = () => {
  // Const assignment.
  const _skipDifference = 8
  const _successFlags = Object.React.App.enums.GRAPHQL_SUCCESSFUL_QUERY_FLAGS.enums.map(i => i.key)

  // Hook assignment.
  const [skipPage, setSkipPage] = React.useState(0)
  const [skipDifference, setSkipDifference] = React.useState(_skipDifference)
  const [searchQuery, setSearchQuery] = React.useState('')
  const { 'onOpen': onVendorCreateOpen, 'isOpen': isVendorCreateOpen, 'onClose': onVendorCreateClose } = useDisclosure()
  const { 'onOpen': onVendorUpdateOpen, 'isOpen': isVendorUpdateOpen, 'onClose': onVendorUpdateClose } = useDisclosure()
  const { 'onOpen': onVendorDeleteOpen, 'isOpen': isVendorDeleteOpen, 'onClose': onVendorDeleteClose } = useDisclosure()
  const { 'onOpen': onCreditManagementUpsertOpen, 'isOpen': isCreditManagementUpsertOpen, 'onClose': onCreditManagementUpsertClose } = useDisclosure()
  const { 'onOpen': onBilateralRelationOpen, 'isOpen': isBilateralRelationOpen, 'onClose': onBilateralRelationClose } = useDisclosure()
  const { 'onOpen': onVendorPasswordChangeOpen, 'isOpen': isVendorPasswordChangeOpen, 'onClose': onVendorPasswordChangeClose } = useDisclosure()
  const _QueryVendorRead = useQuery(VendorReadQuery, { 'variables': { 'skip': skipPage * skipDifference, 'take': skipDifference, 'search': searchQuery }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })
  const _onSearchInputChange = React.useCallback(Debounce(e => setSearchQuery(e.target.value), 800), [])
  const _tableHeaderHeightRef = React.useRef(0)
  const _isFirstLoadCompleted = React.useRef(false)
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })

  // Data assignment.
  if (!_QueryVendorRead.loading && 0 < _QueryVendorRead?.data?.VendorRead?.length) _isFirstLoadCompleted.current = true
  if (0 === _QueryVendorRead?.data?.VendorRead?.length || !_.every(_.pluck(_QueryVendorRead?.data?.VendorRead, 'status'), j => _successFlags.includes(j))) _isFirstLoadCompleted.current = false

  // Return component.
  return (
    <>
      <Flex className='vendor base'>
        <Flex
          display='flex'
          flex={1}
          flexDirection='column'
          gap={_isCurrentViewMobile ? '12px' : '22px'}
          bg='white'
          height='100%'
          borderRadius='20px'
          p={_isCurrentViewMobile ? '12px' : '22px'}>
          <Flex pb='0px' gap='22px' justify='space-between'>
            <Tooltip label='Create new vendor.' fontSize='sm'>
              <Button
                w='170px'
                onClick={onVendorCreateOpen}
                leftIcon={<IoMdAdd />}
                style={buttonStyle}>
                New Vendor
              </Button>
            </Tooltip>
            <DownloadToExcel
              cellsData={
                0 === _QueryVendorRead.data?.VendorRead?.length || !_.every(
                  _.pluck(_QueryVendorRead.data?.VendorRead, 'status'),
                  i => _successFlags.includes(i)
                ) ? [] : _QueryVendorRead.data?.VendorRead?.map((item, __index) => ({
                  'S.No.': `${__index + (skipDifference * skipPage) + 1}.`,
                  'Name': item.displayName,
                  'Email': item?.email ?? '-',
                  'Phone': item?.phone ?? '-',
                  'CreatedAt': Moment(item.createdAt).format('YYYY-MM-DD'),
                  'UpdatedAt': Moment(item.updatedAt).format('YYYY-MM-DD'),
                  'Created By': item?.Account?.displayName ?? '-'
                }))
              }
              headersData={[
                'S.No.',
                'Name',
                'Email',
                'Phone',
                'CreatedAt',
                'UpdatedAt',
                'Created By'
              ].map(i => ({ 'key': i, 'label': i }))}
            />
          </Flex>
          <TableContainer
            flex={1}
            display='flex'
            borderRadius='15px'
            outline='1px solid #C5CFE8'>
            <Table variant='simple' size='sm'>
              <Thead ref={_tableHeaderHeightRef}>
                <Tr
                  style={{
                    ...headerStyle,
                    'top': '0',
                    'position': 'sticky',
                    'zIndex': '1'
                  }}>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    <Text>S.No</Text>
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Name
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Email
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Phone
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    <Text>CreatedAt</Text>
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    <Text>UpdatedAt</Text>
                  </Td>
                  <Td style={rowStyle}>Actions</Td>
                </Tr>
                <Tr key={String.random(8)}>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)' />
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    <MemoizedInput
                      placeholder='Search'
                      className='filter searchInput'
                      defaultValue={searchQuery}
                      onChange={_onSearchInputChange}
                      autoFocus
                    />
                  </Td>
                  <Td minW='100%' style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)' />
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)' />
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)' />
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)' />
                  <Td style={rowStyle} />
                </Tr>
              </Thead>
              {_QueryVendorRead.loading && !_isFirstLoadCompleted.current ? (
                <TableSpinner
                  isLoading={true}
                  chopHeightFromHundredPercentage={
                    _tableHeaderHeightRef?.current?.clientHeight
                  }
                />
              ) : !_isFirstLoadCompleted.current && (0 === _QueryVendorRead.data?.VendorRead?.length || !_.every(
                _.pluck(_QueryVendorRead.data?.VendorRead, 'status'),
                i => _successFlags?.includes(i)
              )) ? (
                <TableSpinner
                  isLoading={false}
                  isEmpty={true}
                  chopHeightFromHundredPercentage={
                    _tableHeaderHeightRef?.current?.clientHeight
                  }
                />
              ) : (
                <Tbody style={cellStyle}>
                  {_QueryVendorRead.data?.VendorRead?.map(
                    (
                      {
                        id,
                        updatedAt,
                        displayName,
                        email,
                        phone,
                        createdAt,
                        Billing
                      },
                      __index
                    ) => (
                      <Tr key={String.random(9)}>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {`${__index + (skipDifference * skipPage) + 1}.`}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {displayName}
                        </Td>
                        <Td
                          style={rowStyle}
                          width='100%'
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {email}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {phone}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {Moment(createdAt).format('YYYY-MM-DD')}{' '}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {' '}
                          {Moment(updatedAt).format('YYYY-MM-DD')}
                        </Td>
                        <Td style={rowStyle}>
                          {
                            <VendorDropDownOptions
                              vendorId={id}
                              currency={Billing?.currency}
                              onVendorPasswordChange={onVendorPasswordChangeOpen}
                              onVendorUpdate={onVendorUpdateOpen}
                              onVendorDelete={onVendorDeleteOpen}
                              onBilateralRelationAttach={onBilateralRelationOpen}
                              onCreditManagementUpsert={onCreditManagementUpsertOpen}
                            />
                          }
                        </Td>
                      </Tr>
                    )
                  )}
                </Tbody>
              )}
            </Table>
          </TableContainer>
        </Flex>
        <Modal
          size='lg'
          title='Vendor Create'
          isOpen={isVendorCreateOpen}
          onClose={onVendorCreateClose}
          isCentered={false}>
          <VendorUpsert isCreateOnly={true} />
        </Modal>
        <Modal
          size='lg'
          title='Vendor Update'
          isOpen={isVendorUpdateOpen}
          onClose={onVendorUpdateClose}
          isCentered={false}>
          <VendorUpsert />
        </Modal>
        <Modal
          size='md'
          title='Vendor Delete'
          isOpen={isVendorDeleteOpen}
          onClose={onVendorDeleteClose}>
          <VendorDelete />
        </Modal>
        <Modal
          size='md'
          title='Bilateral Relation'
          isOpen={isBilateralRelationOpen}
          onClose={onBilateralRelationClose}>
          <BilateralRelation />
        </Modal>
        <Modal
          size='md'
          title='Payment Management'
          isOpen={isCreditManagementUpsertOpen}
          onClose={onCreditManagementUpsertClose}>
          <CreditManagementUpsert />
        </Modal>
        <Modal
          size='md'
          title='Vendor Password Change'
          isOpen={isVendorPasswordChangeOpen}
          onClose={onVendorPasswordChangeClose}>
          <VendorPasswordChange />
        </Modal>
      </Flex>
      <PaginationWithPageInformation
        skipPage={skipPage}
        skipDifference={_skipDifference}
        setSkipDifference={setSkipDifference}
        itemsPerPage={_skipDifference}
        totalCount={_.pluck(_QueryVendorRead.data?.VendorRead, '_totalCount')?.[0]}
        onPageChange={setSkipPage}
      />
    </>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {}


/*
 * EXPORTS
 */
export default Index
