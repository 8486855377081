/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import Moment from 'moment' // Npm: moment.js library.
import Debounce from 'lodash/debounce' // Npm: lodash library.
import _ from 'underscore' // Npm: underscore.js library.
import { useQuery } from '@apollo/client' // Npm: Apollo client.
import { IoMdAdd } from 'react-icons/io' // Npm: React icons.
import {
  Button,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tooltip,
  Tr,
  useBreakpointValue,
  useDisclosure
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import DownloadToExcel from 'components/DownloadToExcel'
import TableSpinner from 'components/TableSpinner'
import PaginationWithPageInformation from 'components/PaginationWithPageInformation'
import Modal from 'components/Modal'
import CustomerNotify from 'components/CustomerNotify'
import CustomerChangeTps from 'components/CustomerChangeTps'
import CustomerBlockBetweenDuration from 'components/CustomerBlockBetweenDuration'
import CustomerUpsert from 'components/CustomerUpsert'
import CustomerDelete from 'components/CustomerDelete'
import AttachRouteAndRate from 'components/AttachRouteAndRate'
import AttachAccountManager from 'components/AttachAccountManager'
import CreditManagementUpsert from 'components/CustomerCreditManagementUpsert'
import CustomerPasswordChange from 'components/CustomerPasswordChange'
import CustomerDropDownOptions from 'components/CustomerDropDownOptions'
import { MemoizedInput, MemoizedSelect } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import CustomerReadQuery from './__query__/index.customer.read.query'


/*
 * STYLES
 */
import { buttonStyle, cellStyle, headerStyle, rowStyle } from './index.style'


/*
 * OBJECTS
 */
const Index = () => {
  // Const assignment.
  const _skipDifference = 8
  const _successFlags = Object.React.App.enums.GRAPHQL_SUCCESSFUL_QUERY_FLAGS.enums.map(i => i.key)

  // Hook assignment.
  const [skipPage, setSkipPage] = React.useState(0)
  const [skipDifference, setSkipDifference] = React.useState(_skipDifference)
  const [searchQuery, setSearchQuery] = React.useState('')
  const [customerType, setCustomerType] = React.useState(void 0)
  const { 'onOpen': onCustomerBlockDurationOpen, 'isOpen': isCustomerBlockDurationOpen, 'onClose': onCustomerBlockDurationClose } = useDisclosure()
  const { 'onOpen': onCustomerCreateOpen, 'isOpen': isCustomerCreateOpen, 'onClose': onCustomerCreateClose } = useDisclosure()
  const { 'onOpen': onCustomerUpdateOpen, 'isOpen': isCustomerUpdateOpen, 'onClose': onCustomerUpdateClose } = useDisclosure()
  const { 'onOpen': onCustomerDeleteOpen, 'isOpen': isCustomerDeleteOpen, 'onClose': onCustomerDeleteClose } = useDisclosure()
  const { 'onOpen': onCustomerNotifyOpen, 'isOpen': isCustomerNotifyOpen, 'onClose': onCustomerNotifyClose } = useDisclosure()
  const { 'onOpen': onCustomerChangeTpsOpen, 'isOpen': isCustomerChangeTpsOpen, 'onClose': onCustomerChangeTpsClose } = useDisclosure()
  const { 'onOpen': onCustomerAttachAccountManagerOpen, 'isOpen': isCustomerAttachAccountManagerOpen, 'onClose': onCustomerAttachAccountManagerClose } = useDisclosure()
  const { 'onOpen': onCreditManagementUpsertOpen, 'isOpen': isCreditManagementUpsertOpen, 'onClose': onCreditManagementUpsertClose } = useDisclosure()
  const { 'onOpen': onCustomerRatePlanAndRoutePlanAttachFormOpen, 'isOpen': isCustomerRatePlanAndRoutePlanAttachFormOpen, 'onClose': onCustomerRatePlanAndRoutePlanAttachFormClose } = useDisclosure()
  const { 'onOpen': onCustomerPasswordChangeOpen, 'isOpen': isCustomerPasswordChangeOpen, 'onClose': onCustomerPasswordChangeClose } = useDisclosure()
  const _QueryCustomerRead = useQuery(CustomerReadQuery, { 'variables': { customerType, 'skip': skipPage * skipDifference, 'take': skipDifference, 'search': searchQuery }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })
  const _isFirstLoadCompleted = React.useRef(false)
  const _tableHeaderHeightRef = React.useRef(0)
  const _onSearchInputChange = React.useCallback(Debounce(e => setSearchQuery(e.target.value), 800), [])
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })

  // Data assignment.
  if (!_QueryCustomerRead.loading && 0 < _QueryCustomerRead.data?.CustomerRead?.length) _isFirstLoadCompleted.current = true
  if (0 === _QueryCustomerRead?.data?.CustomerRead?.length || (0 < _QueryCustomerRead?.data?.CustomerRead?.length && !_.every(_.pluck(_QueryCustomerRead?.data?.CustomerRead, 'status'), j => _successFlags.includes(j)))) _isFirstLoadCompleted.current = false

  // Return component.
  return (
    <>
      <Flex className='customer base'>
        <Flex
          display='flex'
          flex={1}
          flexDirection='column'
          gap={_isCurrentViewMobile ? '12px' : '22px'}
          bg='white'
          height='100%'
          borderRadius='20px'
          p={_isCurrentViewMobile ? '12px' : '22px'}>
          <Flex pb='0px' gap='22px' justify='space-between'>
            <Tooltip label='Create new customer.' fontSize='sm'>
              <Button
                w='170px'
                onClick={onCustomerCreateOpen}
                leftIcon={<IoMdAdd />}
                style={buttonStyle}>
                New Customer
              </Button>
            </Tooltip>
            <DownloadToExcel
              cellsData={_QueryCustomerRead?.data?.CustomerRead?.map(
                (item, __index) =>
                  _successFlags.includes(item.status) ? {
                    'S.No.': `${__index + (skipDifference * skipPage) + 1}.`,
                    'Company Name': _.isEmpty(item?.Company?.displayName) ? '-' : item?.Company?.displayName,
                    'Ref.Number': _.isEmpty(item.Company?.referenceNumber) ? '-' : item.Company?.referenceNumber,
                    'Type': _.isEmpty(item?.type) ? '-' : item?.type,
                    'Rate Email': _.isEmpty(item?.rateEmail) ? '-' : item?.rateEmail?.join?.(', '),
                    'Phone': _.isEmpty(item.phone) ? '-' : item.phone,
                    'Currency': _.isEmpty(item.Billing?.currency) ? '-' : item.Billing?.currency,
                    'Balance': item.Credit?.balance || '0',
                    'Over Draft Limit Amount': item?.overDraftLimit ?? '-',
                    'Account Manager':
                      item?.AccountManager?.displayName ?? '-',
                    'CreatedAt': Moment(item.createdAt).format('YYYY-MM-DD'),
                    'UpdatedAt': Moment(item.updatedAt).format('YYYY-MM-DD')
                  } : {}
              )}
              headersData={[
                'S.No.',
                'Company Name',
                'Ref.Number',
                'Type',
                'Rate Email',
                'Phone',
                'Currency',
                'Balance',
                'Over Draft Limit Amount',
                'Account Manager',
                'CreatedAt',
                'UpdatedAt'
              ].map(i => ({ 'key': i, 'label': i }))}
            />
          </Flex>
          <TableContainer
            flex={1}
            display='flex'
            borderRadius='15px'
            outline='1px solid #C5CFE8'>
            <Table variant='simple' size='sm'>
              <Thead ref={_tableHeaderHeightRef}>
                <Tr style={headerStyle}>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    S No.
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Company Name
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Ref.Number
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Type
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Rate Email
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Phone
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Currency
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Balance
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    Over Draft Limit Amount
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    {' '}
                    Account Manager
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    CreatedAt
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    UpdatedAt
                  </Td>
                  <Td
                    style={rowStyle}>
                    Actions
                  </Td>
                </Tr>
                <Tr key={String.random(8)}>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    <MemoizedInput
                      placeholder='Search'
                      className='filter searchInput'
                      defaultValue={searchQuery}
                      onChange={_onSearchInputChange}
                      autoFocus
                    />
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    <MemoizedSelect
                      placeholder='Select Account Type'
                      className='filter searchInput'
                      onChange={e => setCustomerType(_.isEmpty(e?.target?.value) ? void 0 : e?.target?.value)}
                      options={_.without(Object.React.App.enums.CUSTOMER_TYPE.enums?.map(i => i?.key), 'TERMINATION')}
                      data={customerType}
                    />
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td
                    style={rowStyle}></Td>
                </Tr>
              </Thead>
              {_QueryCustomerRead.loading && !_isFirstLoadCompleted.current ? (
                <TableSpinner
                  isLoading={true}
                  chopHeightFromHundredPercentage={
                    _tableHeaderHeightRef?.current?.clientHeight
                  }
                />
              ) : !_isFirstLoadCompleted.current && (0 === _QueryCustomerRead?.data?.CustomerRead?.length || (0 < _QueryCustomerRead?.data?.CustomerRead?.length && !_.every(
                _.pluck(_QueryCustomerRead?.data?.CustomerRead, 'status'),
                j => _successFlags.includes(j)
              ))) ? (
                <TableSpinner
                  isLoading={false}
                  isEmpty={true}
                  chopHeightFromHundredPercentage={
                    _tableHeaderHeightRef?.current?.clientHeight
                  }
                />
              ) : (
                <Tbody style={cellStyle}>
                  {_QueryCustomerRead?.data?.CustomerRead?.filter(({ displayName, phone }) => displayName?.toLowerCase?.()?.includes?.(searchQuery?.toLowerCase?.()) || phone?.toLowerCase?.()?.includes?.(searchQuery?.toLowerCase?.())).map((item, __index) => (
                    <Tr key={String.random(8)}>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {`${__index + (skipDifference * skipPage) + 1}.`}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {_.isEmpty(item?.Company?.displayName) ? '-' : item?.Company?.displayName}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {_.isEmpty(item.Company?.referenceNumber) ? '-' : item.Company?.referenceNumber}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {_.isEmpty(item?.type) ? '-' : item?.type}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {_.isEmpty(item?.rateEmail) ? '-' : item?.rateEmail?.join?.(', ')}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {_.isEmpty(item.phone) ? '-' : item.phone}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {_.isEmpty(item.Billing?.currency) ? '-' : item.Billing?.currency}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {item.Credit?.balance?.toFixed?.(5) ?? '0'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {item?.overDraftLimit?.toFixed?.(5) ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {item.AccountManager ? (
                          <Text
                            width='max-content'
                            margin='auto'
                            borderRadius='10px'
                            padding='5px 15px 5px 15px'
                            color='rgba(117, 81, 255, 1)'
                            bg='rgba(117, 81, 255, .1)'>
                            {item.AccountManager.displayName ?? '-'}
                          </Text>
                        ) : (
                          <Text
                            width='max-content'
                            margin='auto'
                            borderRadius='10px'
                            padding='5px 15px 5px 15px'
                            color='rgba(238, 93, 80, 1)'
                            bg='rgba(255, 242, 242, 1)'>
                            Not Assigned
                          </Text>
                        )}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {Moment(item.createdAt).format('YYYY-MM-DD')}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {Moment(item.updatedAt).format('YYYY-MM-DD')}
                      </Td>
                      <Td
                        style={rowStyle}>
                        {' '}
                        {
                          <CustomerDropDownOptions
                            customerId={item.id}
                            type={item.type}
                            currency={item.Billing?.currency}
                            onCustomerNotify={onCustomerNotifyOpen}
                            onCustomerUpdate={onCustomerUpdateOpen}
                            onCustomerDelete={onCustomerDeleteOpen}
                            onCustomerBlockDuration={onCustomerBlockDurationOpen}
                            onCustomerChangeTps={onCustomerChangeTpsOpen}
                            onCustomerPasswordChange={
                              onCustomerPasswordChangeOpen
                            }
                            onRateAndRoutePlanAttach={
                              onCustomerRatePlanAndRoutePlanAttachFormOpen
                            }
                            onAttachAccountManager={
                              onCustomerAttachAccountManagerOpen
                            }
                            onCreditManagementUpsert={
                              onCreditManagementUpsertOpen
                            }
                          />
                        }
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              )}
            </Table>
          </TableContainer>
        </Flex>
        <Modal
          size='3xl'
          title='Customer Create'
          isOpen={isCustomerCreateOpen}
          onClose={onCustomerCreateClose}
          isCentered={false}>
          <CustomerUpsert isCreateOnly={true} />
        </Modal>
        <Modal
          size='lg'
          title='Block Traffic'
          isOpen={isCustomerBlockDurationOpen}
          onClose={onCustomerBlockDurationClose}
          isCentered={true}>
          <CustomerBlockBetweenDuration showingFor='CUSTOMER' />
        </Modal>
        <Modal
          size='3xl'
          title='Customer Update'
          isOpen={isCustomerUpdateOpen}
          onClose={onCustomerUpdateClose}
          isCentered={false}>
          <CustomerUpsert />
        </Modal>
        <Modal
          size='md'
          title='Attach Route and Rate'
          isOpen={isCustomerRatePlanAndRoutePlanAttachFormOpen}
          onClose={onCustomerRatePlanAndRoutePlanAttachFormClose}>
          <AttachRouteAndRate />
        </Modal>
        <Modal
          size='md'
          title='Customer Delete'
          isOpen={isCustomerDeleteOpen}
          onClose={onCustomerDeleteClose}>
          <CustomerDelete />
        </Modal>
        <Modal
          size='sm'
          title='Account Manager'
          isOpen={isCustomerAttachAccountManagerOpen}
          onClose={onCustomerAttachAccountManagerClose}>
          <AttachAccountManager />
        </Modal>
        <Modal
          size='sm'
          title='Customer Notify'
          isOpen={isCustomerNotifyOpen}
          onClose={onCustomerNotifyClose}>
          <CustomerNotify />
        </Modal>
        <Modal
          size='sm'
          title='Payment Management'
          isOpen={isCreditManagementUpsertOpen}
          onClose={onCreditManagementUpsertClose}>
          <CreditManagementUpsert />
        </Modal>
        <Modal
          size='sm'
          title='Customer Login Password'
          isOpen={isCustomerPasswordChangeOpen}
          onClose={onCustomerPasswordChangeClose}>
          <CustomerPasswordChange />
        </Modal>
        <Modal
          size='sm'
          title='Change Tps'
          isOpen={isCustomerChangeTpsOpen}
          onClose={onCustomerChangeTpsClose}>
          <CustomerChangeTps />
        </Modal>
      </Flex>
      <PaginationWithPageInformation
        skipPage={skipPage}
        skipDifference={_skipDifference}
        setSkipDifference={setSkipDifference}
        itemsPerPage={_skipDifference}
        totalCount={_.pluck(_QueryCustomerRead?.data?.CustomerRead, '_totalCount')?.[0]}
        onPageChange={setSkipPage}
      />
    </>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {}


/*
 * EXPORTS
 */
export default Index
