/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
    query PhoneNumberoDirectoryReadQuery($phoneNumberoDirectoryToSearch: String, $take: PositiveInt, $skip: Int) {
      PhoneNumberoDirectoryRead(phoneNumberoDirectoryToSearch: $phoneNumberoDirectoryToSearch, take: $take, skip: $skip) {
        id,
        createdAt,
        updatedAt,
        displayName,
        status,
        message,
        _totalCount,
        _count {
          PhoneNumbero
        }
    }
  }
`
