/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * GRAPH
 */
const Index = gql`
query PaymentReadQuery($vendorId: ID, $take: PositiveInt, $skip: Int)  {
  PaymentRead(vendorId: $vendorId, take: $take, skip: $skip) {
    id,
    message,
    status,
    amount
  }
} 
`

/*
 * EXPORTS
 */
export default Index
