/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query VendorReadQuery($vendorId: ID!) {
  VendorRead(vendorId: $vendorId) {
    status,
    AccountLogin {
      id
    }
  }
}
`
