/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import Moment from 'moment' // Npm: Moment library.
import PropTypes from 'prop-types' // Npm: react.js library.
import Debounce from 'lodash/debounce' // Npm: lodash library.
import * as XLSX from 'xlsx' // Npm: XLSX library.
import _ from 'underscore' // Npm: underscore library.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useMutation, useQuery } from '@apollo/client' // Npm: Apollo client.
import { IoMdAdd } from 'react-icons/io' // Npm: React icons.
import {
  Button,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tooltip,
  Tr,
  useBreakpointValue,
  useDisclosure
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import PaginationWithPageInformation from 'components/PaginationWithPageInformation'
import Modal from 'components/Modal'
import TableSpinner from 'components/TableSpinner'
import OperatorPrefixDropDownOptions from 'components/OperatorPrefixDropDownOptions'
import FileUpload from 'components/FileUpload'
import OperatorPrefixUpsert from 'components/OperatorPrefixUpsert'
import OperatorPrefixDelete from 'components/OperatorPrefixDelete'
import { MemoizedInput } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import OperatorPrefixReadQuery from './__query__/index.operatorPrefix.read.query'
import OperatorPrefixCreateThroughExcelMutation from './__mutation__/index.operatorPrefix.createThroughExcel.mutation'


/*
 * STYLES
 */
import {
  buttonStyle,
  cellStyle,
  headerStyle,
  rowStyle
} from './index.style'


/*
 * OBJECTS
 */
const Index = ({ passOn }) => {
  // Const assignment.
  const _skipDifference = 8
  const _successFlags = Object.React.App.enums.GRAPHQL_SUCCESSFUL_QUERY_FLAGS.enums.map(i => i.key)

  // Hook assignment.
  const [skipPage, setSkipPage] = React.useState(0)
  const [searchQuery, setSearchQuery] = React.useState('')
  const [skipDifference, setSkipDifference] = React.useState(_skipDifference)
  const [uploadingFile, setUploadingFile] = React.useState(false)
  const [onSuccessfulFileUpload, setOnSuccessfulFileUpload] = React.useState(false)
  const { 'isOpen': isOperatorPrefixCreateOpen, 'onOpen': onOperatorPrefixCreateOpen, 'onClose': onOperatorPrefixCreateClose } = useDisclosure()
  const { 'isOpen': isOperatorPrefixUpdateOpen, 'onOpen': onOperatorPrefixUpdateOpen, 'onClose': onOperatorPrefixUpdateClose } = useDisclosure()
  const { 'isOpen': isOperatorPrefixDeleteOpen, 'onOpen': onOperatorPrefixDeleteOpen, 'onClose': onOperatorPrefixDeleteClose } = useDisclosure()
  const [MutationOperatorPrefixCreateThroughExcel] = useMutation(OperatorPrefixCreateThroughExcelMutation)
  const _QueryOperatorPrefixRead = useQuery(OperatorPrefixReadQuery, { 'variables': { 'mncId': passOn?.mncId, 'skip': skipPage * skipDifference, 'take': skipDifference, 'search': searchQuery }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })
  const _isFirstLoadCompleted = React.useRef(false)
  const _tableHeaderHeightRef = React.useRef(0)
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })
  const _onSearchInputChange = React.useCallback(Debounce(e => setSearchQuery(e.target.value), 800), [])

  // Data assignment.
  if (!_QueryOperatorPrefixRead.loading && 0 < _QueryOperatorPrefixRead.data?.OperatorPrefixRead?.length) _isFirstLoadCompleted.current = true
  if (0 === _QueryOperatorPrefixRead.data?.OperatorPrefixRead?.length || !_.every(_.pluck(_QueryOperatorPrefixRead.data?.OperatorPrefixRead, 'status'), i => _successFlags?.includes(i))) _isFirstLoadCompleted.current = false

  // Return component.
  return (
    <>
      <Flex className='operatorPrefix base'>
        <Flex
          display='flex'
          flex={1}
          flexDirection='column'
          gap={_isCurrentViewMobile ? '12px' : '22px'}
          bg='white'
          height='100%'
          borderRadius='20px'
          p={_isCurrentViewMobile ? '12px' : '22px'}>
          <Flex pb='0px' justify='space-between' gap='12px' flexWrap={{ 'base': 'wrap', 'md': 'nowrap' }}>
            <Tooltip label='Create New Operator' fontSize='sm'>
              <Button
                leftIcon={<IoMdAdd />}
                onClick={onOperatorPrefixCreateOpen}
                style={buttonStyle}>
                Create Prefix
              </Button>
            </Tooltip>
            <FileUpload
              uploadingFile={uploadingFile}
              bg={onSuccessfulFileUpload ? 'green.100' : void 0}
              color={onSuccessfulFileUpload ? 'green' : void 0}
              label={_isCurrentViewMobile ? '' : onSuccessfulFileUpload ? 'Upload Successful' : 'Upload Prefix'}
              onChange={e => Promise.all(Array.from(e.target.files).map(file => {
                // Set uploading file to true.
                setUploadingFile(true)

                // File reader.
                const _FileReader = new FileReader()

                // Read file as binary string.
                _FileReader.readAsBinaryString(file)

                // Event handler.
                _FileReader.onload = () => {
                  const _data = _FileReader.result
                  const _workbook = XLSX.read(_data, { 'type': 'binary' })
                  const _sheetName = _workbook.SheetNames[0]
                  const _sheet = _workbook.Sheets[_sheetName]
                  const _parsedData = XLSX.utils.sheet_to_json(_sheet)

                  // Execute mutation.
                  return MutationOperatorPrefixCreateThroughExcel({ 'variables': { 'mncId': passOn?.mncId, 'file': _parsedData, 'name': file.name } }).then(e => {
                    // Set uploadingFile to false when upload completes
                    setUploadingFile(false)
                    setOnSuccessfulFileUpload(true); const j = setTimeout(() => { setOnSuccessfulFileUpload(false); clearTimeout(j) }, 3000)

                    // Toast the success message
                    e?.data?.OperatorPrefixCreateThroughExcel?.map(r => !_.isEmpty(r?.message) && toast(r?.message))
                  })
                }

                // Return void.
                return void 0
              }))}
              accept='.xlsx, .xls, .csv'
              disabled={uploadingFile} />
          </Flex>
          <TableContainer
            flex={1}
            display='flex'
            borderRadius='15px'
            outline='1px solid #C5CFE8'>
            <Table variant='simple' size='sm'>
              <Thead ref={_tableHeaderHeightRef}>
                <Tr style={{ ...headerStyle, 'position': 'sticky', 'zIndex': '1', 'top': '0' }}>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>S.No</Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>Operator Name</Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>Operator Prefix</Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>Mnc</Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>CreatedAt</Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>UpdatedAt</Td>
                  <Td
                    style={rowStyle}>Action</Td>
                </Tr>
                <Tr>
                  <Td
                    borderRight='1px solid rgba(216, 227, 252, 1)'>{''}</Td>
                  <Td
                    borderRight='1px solid rgba(216, 227, 252, 1)'>{''}</Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>
                    <MemoizedInput
                      placeholder='Search'
                      className='filter searchInput'
                      onChange={_onSearchInputChange}
                    />
                  </Td>
                  <Td
                    borderRight='1px solid rgba(216, 227, 252, 1)'>{''}</Td>
                  <Td
                    borderRight='1px solid rgba(216, 227, 252, 1)'>{''}</Td>
                  <Td
                    borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td></Td>
                </Tr>
              </Thead>
              {_QueryOperatorPrefixRead.loading && !_isFirstLoadCompleted.current ? (
                <TableSpinner isLoading={true} chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight} />
              ) : !_isFirstLoadCompleted.current && (0 === _QueryOperatorPrefixRead.data?.OperatorPrefixRead?.length || !_.every(_.pluck(_QueryOperatorPrefixRead.data?.OperatorPrefixRead, 'status'), i => _successFlags?.includes(i))) ? (
                <TableSpinner isLoading={false} isEmpty={true} chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight} />
              ) : (
                <Tbody style={cellStyle}>
                  {_QueryOperatorPrefixRead.data?.OperatorPrefixRead?.map(({ id, createdAt, updatedAt, prefix, Mnc }, __index) => (
                    <Tr key={String.random(9)}>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {`${(__index + (skipDifference * (skipPage))) + 1}.`}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {Mnc?.network ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {prefix ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {Mnc?.mnc ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {Moment(createdAt).format('YYYY-MM-DD hh:mm:ss A')}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {Moment(updatedAt).format('YYYY-MM-DD hh:mm:ss A')}
                      </Td>
                      <Td
                        style={rowStyle}>
                        {' '}
                        {
                          <OperatorPrefixDropDownOptions
                            operatorPrefixId={id}
                            onOperatorPrefixUpdate={onOperatorPrefixUpdateOpen}
                            onOperatorPrefixDelete={onOperatorPrefixDeleteOpen}
                          />
                        }
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              )
              }
            </Table>
          </TableContainer>
        </Flex>
        <Modal
          size='sm'
          title='Operator Prefix Create'
          isOpen={isOperatorPrefixCreateOpen}
          onClose={onOperatorPrefixCreateClose}>
          <OperatorPrefixUpsert isCreateOnly={true} />
        </Modal>
        <Modal
          size='sm'
          title='Operator Prefix Update'
          isOpen={isOperatorPrefixUpdateOpen}
          onClose={onOperatorPrefixUpdateClose}>
          <OperatorPrefixUpsert />
        </Modal>
        <Modal
          size='md'
          title='Operator Prefix Delete'
          isOpen={isOperatorPrefixDeleteOpen}
          onClose={onOperatorPrefixDeleteClose}>
          <OperatorPrefixDelete />
        </Modal>
      </Flex>
      <PaginationWithPageInformation
        skipPage={skipPage}
        skipDifference={_skipDifference}
        setSkipDifference={setSkipDifference}
        itemsPerPage={_skipDifference}
        totalCount={_.pluck(_QueryOperatorPrefixRead.data?.OperatorPrefixRead, '_totalCount')?.[0]}
        onPageChange={setSkipPage}
      />
    </>
  )
}



/*
 * PROPTYPES
 */
Index.propTypes = {
  'passOn': PropTypes.object
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)

