/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import { Flex, Text } from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * OBJECTS
 */
const Index = () => (
  <Flex h='100%' flexDir='column' justify='center' align='center'>
    <Text fontSize='71' color='gray.700' fontWeight={900}>OH! NO</Text>
    <Text fontSize='lg' color='gray.500'>Seems that there is some issue. Reporting this bug to the team.</Text>
  </Flex>
)



/*
 * PROPTYPES
 */
Index.propTypes = {}


/*
 * EXPORTS
 */
export default Index
