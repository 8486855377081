/*
 * IMPORTS
 */
import React from 'react' // Npm: React.js library.
import PropTypes from 'prop-types' // Npm: Prop Types for checking the props type.
import NaturalCompare from 'natural-compare' // Npm: Sorting library.
import _ from 'underscore' // Npm: Underscore.js for utility functions.
import { BsCheckAll } from 'react-icons/bs' // Npm: React Icons for icons.
import { useQuery } from '@apollo/client' // Npm: Apollo client.
import { HiCheckCircle, HiPlusCircle, HiXCircle } from 'react-icons/hi2' // Npm: React Icons for icons.
import {
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text
} from '@chakra-ui/react' // Npm: Chakra UI A simple, modular and accessible component library for React.


/*
 * COMPONENTS
 */
import { MemoizedSelect } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import VendorReadQuery from './__query__/index.vendor.read.query'


/*
 * OBJECTS
 */
const Index = ({ vendorValue, vendorAccountValue, inValidVendorAccount, disabled, inValidVendor, isRequired, onChange }) => {
  // Hook assignment.
  const [vendorId, setVendorId] = React.useState(vendorValue)
  const [vendorAccountId, setVendorAccountId] = React.useState([])
  const _QueryVendorRead = useQuery(VendorReadQuery, { 'variables': { 'take': 1000, 'skip': 0 }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })

  // Event handler.
  React.useEffect(() => {
    /*
     * Update selected country if vendorId value
     * Is passed in params.
     */
    vendorValue && _.isEmpty(vendorId) && setVendorId(vendorValue ?? '')
    vendorAccountValue && _.isEmpty(vendorAccountId) && setVendorAccountId(vendorAccountValue ?? [])
  }, [vendorValue, vendorAccountValue])

  // Local variable.
  let _selectedVendor

  // Const assignment.
  const _selectedTarget = (vendorId)?.split?.('(')[1]?.split?.(')')?.[0]

  // Variable assignment.
  _selectedVendor = _QueryVendorRead?.data?.VendorRead?.filter?.(r => r.id?.includes(_selectedTarget) ? r.VendorAccount : void 0)
  _selectedVendor = _.compact(_.flatten(_.pluck(_selectedVendor, 'VendorAccount')))

  // Return the JSX.
  return (
    <Flex w='inherit' className='vendorAccounttDirectoryAndVendorAccountSelector' flexDir='column' gap='22px'>
      <MemoizedSelect
        disabled={_QueryVendorRead?.loading || disabled}
        name='vendorId'
        value={vendorId}
        label='Vendor&apos;s Folder'
        placeholder='Select Folder'
        options={_.compact(_QueryVendorRead?.data?.VendorRead?.map?.(j => 'READ_SUCCESSFUL' === j.status ? `${j.displayName} (${j.id})` : void 0))?.sort((a, b) => NaturalCompare(a, b))}
        isInvalid={inValidVendor}
        onChange={__event => {
          // Update states.
          setVendorId(__event.target.value)
          setVendorAccountId([])

          // Return updated vendorId and vendorAccountId.
          onChange({
            'vendorAccountId': [],
            'vendorId': __event.target.value
          })
        }}
      />
      <FormControl flexDir='column'>
        <FormLabel
          display='flex'
          alignItems='center'
          style={{ 'pointerEvents': 'none' }}>
          Account Id&apos;s{' '}{isRequired ? <Text color='red'>*</Text> : void 0}
        </FormLabel>
        <Menu closeOnSelect={false} position='relative' disabled={_.isEmpty(_selectedVendor) || _QueryVendorRead?.loading || 0 === _selectedVendor?.length || disabled} w='100%'>
          <MenuButton
            type='button'
            disabled={_.isEmpty(_selectedVendor) || _QueryVendorRead?.loading || disabled || 0 === _selectedVendor?.length}
            h='40px'
            color={disabled ? '#aaafc8' : void 0}
            px='4'
            onClick={e => e.stopPropagation()}
            m='0'
            w='100%'
            transition='all 0.2s'
            textAlign='left'
            borderRadius='12px'
            rightIcon={<BsCheckAll />}
            bg={inValidVendorAccount ? 'rgb(255,255,255,0.8)' : 'gray.100'}
            _hover={{ 'bg': 'gray.100' }}
            _expanded={{ 'bg': 'gray.100' }}
            _focus={{ 'bg': 'gray.100' }}
            boxShadow={inValidVendorAccount ? '0 0 0 1.5px #EE5D50' : void 0}>
            {(_.isString(vendorAccountId) && !_.isEmpty(vendorAccountId) ? [vendorAccountId] : vendorAccountId)?.length ?? 0} Selected
          </MenuButton>
          <MenuList borderRadius={12} h='250px' overflow='auto' minW='300px'>
            {_selectedVendor?.map(r =>
              <MenuItem closeOnSelect={false} key={String.random(8)}>
                <Checkbox
                  disabled={0 === _selectedVendor.length}
                  name='vendorAccount'
                  onChange={() => {
                    // Update state of vendorAccountId.
                    setVendorAccountId(j => {
                      /*
                       * If only one selection is allowed
                       * Then clear the array.
                       */
                      if (j.includes(`${r.displayName} (${r?.id})`)) {
                        // Const assignment.
                        const _data = _.without(j, `${r.displayName} (${r?.id})`)

                        // Return updated mcc and mnc.
                        onChange({ 'vendorAccountId': _data, 'vendorId': vendorId })

                        // Update state of vendorAccountId.
                        return _data
                      }

                      // Return updated vendorAccountId.
                      onChange({ 'vendorAccountId': [...j, `${r.displayName} (${r?.id})`], 'vendorId': vendorId })

                      // Update state of vendorAccountId.
                      return [...j, `${r.displayName} (${r?.id})`]
                    })
                  }}
                  isChecked={vendorAccountId.includes(`${r.displayName} (${r?.id})`)}>
                  {`${r.displayName} (${r?.id})`}
                </Checkbox>
              </MenuItem>)}
          </MenuList>
          <Flex position='absolute' right={0} bottom={0} zIndex={1000}>
            <IconButton
              aria-label='Select All'
              disabled={_.isEmpty(vendorId) || _.isEmpty(_selectedVendor) || _QueryVendorRead?.loading || disabled || 0 === _selectedVendor?.length}
              _hover={{ 'bg': 'none' }}
              _active={{ 'bg': 'none' }}
              onClick={() => {
                // Const assignment.
                const _vendorAccountIds = []

                // Loop through selected vendor.
                for (const jam of _QueryVendorRead.data.VendorRead) {
                  // If selected target is found.
                  if (jam.id.includes(_selectedTarget)) {
                    // Loop through vendor account.
                    for (const j of jam.VendorAccount) {
                      // Update vendor account ids.
                      _vendorAccountIds.push(`${j.displayName} (${j.id})`)
                    }
                  }
                }

                // Update vendorAccountId.
                setVendorAccountId(_vendorAccountIds)

                // Return updated vendorAccountId.
                onChange({ 'vendorAccountId': _vendorAccountIds, 'vendorId': vendorId })
              }}
              right='-10px'
              bg='none'
              p='0'>
              {vendorAccountId?.length === _selectedVendor?.length ? (<HiCheckCircle fontSize='20px' color='#c4c4c4' />) : (<HiPlusCircle fontSize='20px' color='#3CA55C' />)}
            </IconButton>
            <IconButton
              aria-label='Clear All'
              disabled={_.isEmpty(_selectedTarget) || _.isEmpty(_selectedVendor) || _QueryVendorRead?.loading || disabled || 0 === _selectedVendor?.length}
              _hover={{ 'bg': 'none' }}
              _active={{ 'bg': 'none' }}
              onClick={() => {
                // Update vendorAccountId.
                setVendorAccountId([])
                setVendorId('')

                // Return updated state.
                onChange({ 'vendorAccountId': [], 'vendorId': '' })
              }}
              bg='none'
              p='0'>
              {0 === vendorAccountId?.length ? (<HiXCircle fontSize='20px' color='#c4c4c4' />) : (<HiXCircle fontSize='20px' color='#FF416C' />)}
            </IconButton>
          </Flex>
        </Menu>
      </FormControl>
    </Flex>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'vendorValue': PropTypes.string,
  'vendorAccountValue': PropTypes.array,
  'inValidVendorAccount': PropTypes.bool,
  'disabled': PropTypes.bool,
  'inValidVendor': PropTypes.bool,
  'onChange': PropTypes.func,
  'isRequired': PropTypes.bool
}


/*
 * EXPORT
 */
export default Index
