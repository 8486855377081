/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query IpDirectoryReadUniqueQuery($ipDirectoryId: ID!) {
  IpDirectoryReadUnique(ipDirectoryId: $ipDirectoryId) {
    id,
    displayName,
    message,
    status
  }
}
`
