/*
 * IMPORTS
 */
import React from 'react' // Npm: React.js library.
import PropTypes from 'prop-types' // Npm: Prop Types for checking the props type.
import Debounce from 'lodash/debounce' // Npm: Debounce library.
import AutoSizer from 'react-virtualized-auto-sizer' // Npm: React virtualized auto sizer.
import InfiniteLoader from 'react-window-infinite-loader' // Npm: React window infinite loader.
import NaturalCompare from 'natural-compare' // Npm: Sorting library.
import _ from 'underscore' // Npm: Underscore.js for utility functions.
import { BsCheckAll } from 'react-icons/bs' // Npm: React Icons for icons.
import { useLazyQuery, useQuery } from '@apollo/client' // Npm: Apollo client.
import { FixedSizeList as List } from 'react-window' // Npm: React Virtualized for virtualized list.
import { HiCheckCircle, HiPlusCircle, HiXCircle } from 'react-icons/hi2' // Npm: React Icons for icons.
import {
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text
} from '@chakra-ui/react' // Npm: Chakra UI A simple, modular and accessible component library for React.


/*
 * COMPONENTS
 */
import { MemoizedSelect } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import SenderIdDirectoryReadQuery from './__query__/index.senderIdDirectory.read.query'
import SenderIdReadQuery from './__query__/index.senderId.read.query'


/*
 * OBJECTS
 */
const Index = ({ senderIdDirectoryValue, senderIdValue, inValidSenderId, disabled, inValidSenderIdDirectory, isRequired, onChange }) => {
  // Const assignment.
  const _skipDifference = 1000

  // Hook assignment.
  const [senderIdDirectory, setSenderIdDirectory] = React.useState('')
  const [senderId, setSenderId] = React.useState([])
  const [, setForceReRender] = React.useState(void 0)
  const [QuerySenderIdRead, QuerySenderIdReadResponse] = useLazyQuery(SenderIdReadQuery)
  const _senderIdListRef = React.useRef([])
  const _oldSenderIdDirectory = React.useRef(void 0)
  const _QuerySenderIdDirectoryRead = useQuery(SenderIdDirectoryReadQuery, { 'variables': { 'take': _skipDifference, 'skip': 0 }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })

  // Object assignment.
  const _LoadMore = async (__take, __skip, __replaceInstead) => {
    // Const assignment.
    const _senderIdDirectoryId = (senderIdDirectory)?.split('(')[1]?.split(')')[0]

    // If id is empty then just return.
    if (_.isEmpty(_senderIdDirectoryId)) return void 0

    // Query for given directory read.
    const _QuerySenderIdRead = await QuerySenderIdRead({ 'variables': { 'take': __take, 'skip': __skip, 'senderIdDirectoryId': _senderIdDirectoryId } })

    // Report failure if caught exception.
    if (_QuerySenderIdRead?.error) throw _QuerySenderIdRead?.error

    // Update senderId list available.
    _senderIdListRef.current = __replaceInstead ? _QuerySenderIdRead?.data?.SenderIdRead?.filter(r => 'READ_SUCCESSFUL' === r.status) : [..._senderIdListRef.current, ...(_QuerySenderIdRead?.data?.SenderIdRead?.filter(r => 'READ_SUCCESSFUL' === r.status) ?? [])]

    // Return void.
    return void 0
  }

  // Event handler.
  React.useEffect(() => {
    /*
     * Update selected country if senderIdDirectory value
     * Is passed in params.
     */
    senderIdDirectoryValue && _.isEmpty(senderIdDirectory) && setSenderIdDirectory(senderIdDirectoryValue ?? '')
    senderIdValue && _.isEmpty(senderId) && setSenderId(senderIdValue ?? [])
  }, [senderIdDirectoryValue, senderIdValue])
  React.useEffect(() => {
    // Load more senderIds.
    !_.isEmpty(senderIdDirectory) && _LoadMore(10, 0).then(() => setForceReRender(Math.random()))
  }, [senderIdDirectory])
  React.useEffect(() => {
    /*
     * If directory change occurs then refetch
     * new data.
     */
    if (_oldSenderIdDirectory.current !== senderIdDirectory) {
      // Load new batch of data.
      _LoadMore(10, 0, true).then(() => setForceReRender(Math.random()))

      // Update old ref.
      _oldSenderIdDirectory.current = senderIdDirectory
    }
  }, [senderIdDirectory])

  // Component assignment.
  const _List = ({ index, style }) => _.isEmpty(_senderIdListRef.current[index]) ? void 0 : (
    <MenuItem key={String.random(8)} style={style}>
      <Checkbox
        disabled={0 === _senderIdListRef.current.length}
        name='senderId'
        onChange={() => {
          // Update state of senderIds.
          setSenderId(j => {
            /*
             * If only one selection is allowed
             * Then clear the array.
             */
            if (j.includes(`${_senderIdListRef.current[index].senderId} (${_senderIdListRef.current[index]?.id})`)) {
              // Const assignment.
              const _data = _.without(j, `${_senderIdListRef.current[index].senderId} (${_senderIdListRef.current[index]?.id})`, 'ALL')

              // Return updated mcc and mnc.
              onChange({ 'senderId': _data, 'senderIdDirectory': senderIdDirectory })

              // Return updated senderId.
              return _data
            }

            // Return updated senderId.
            onChange({ 'senderId': [...j, `${_senderIdListRef.current[index].senderId} (${_senderIdListRef.current[index]?.id})`], 'senderIdDirectory': senderIdDirectory })

            // Return updated senderId and id.
            return [...j, `${_senderIdListRef.current[index].senderId} (${_senderIdListRef.current[index]?.id})`]
          })
        }}
        isChecked={senderId.includes('ALL') || senderId?.includes('ALL (ALL)') || senderId.includes(`${_senderIdListRef.current[index].senderId} (${_senderIdListRef.current[index]?.id})`)}>
        {`${_senderIdListRef.current[index].senderId} (${_senderIdListRef.current[index]?.id})`}
      </Checkbox>
    </MenuItem>
  )

  // Prop types.
  _List.propTypes = {
    'index': PropTypes.number,
    'style': PropTypes.object
  }

  // Return the JSX.
  return (
    <Flex w='100%' className='senderIdDirectoryAndSenderIdSelector' flexDir='column' gap='22px'>
      <MemoizedSelect
        label='SenderId Folder'
        disabled={_QuerySenderIdDirectoryRead?.loading || disabled}
        name='senderIdDirectory'
        value={senderIdDirectory}
        placeholder='Select Folder'
        options={_.compact(_QuerySenderIdDirectoryRead?.data?.SenderIdDirectoryRead?.map?.(j => 'READ_SUCCESSFUL' === j.status ? `${j.displayName} (${j.id})` : void 0))?.sort((a, b) => NaturalCompare(a, b))}
        isInvalid={inValidSenderIdDirectory}
        onChange={__event => {
          // Update states.
          setSenderIdDirectory(__event.target.value)
          setSenderId([])

          // Return updated senderIdDirectory and senderId.
          onChange({ 'senderId': [], 'senderIdDirectory': __event.target.value })
        }}
      />
      <FormControl flexDir='column' w='100%'>
        <FormLabel
          display='flex'
          alignItems='center'
          style={{ 'pointerEvents': 'none' }}>
          SenderIds{' '}{isRequired ? <Text color='red'>*</Text> : void 0}
        </FormLabel>
        <Menu closeOnSelect={false} position='relative' disabled={QuerySenderIdReadResponse.loading || _QuerySenderIdDirectoryRead?.loading || disabled} w='100%'>
          <MenuButton
            type='button'
            disabled={QuerySenderIdReadResponse.loading || _QuerySenderIdDirectoryRead?.loading || disabled}
            h='40px'
            color={disabled ? '#aaafc8' : void 0}
            px='4'
            onClick={e => e.stopPropagation()}
            m='0'
            w='100%'
            borderRadius='12px'
            transition='all 0.2s'
            textAlign='left'
            rightIcon={<BsCheckAll />}
            bg={inValidSenderId ? 'rgb(255,255,255,0.8)' : 'gray.100'}
            _hover={{ 'bg': 'gray.100' }}
            _expanded={{ 'bg': 'gray.100' }}
            _focus={{ 'bg': 'gray.100' }}
            boxShadow={inValidSenderId ? '0 0 0 1.5px #EE5D50' : void 0}>
            {senderId.includes('ALL') || senderId.includes('ALL (ALL)') ? _.first(_senderIdListRef.current)?._totalCount : (_.isString(senderId) && !_.isEmpty(senderId) ? [senderId] : senderId)?.length ?? 0} Selected
          </MenuButton>
          <MenuList borderRadius={12} h='250px' w='100%'>
            <AutoSizer>
              {({ height, width }) => (
                <InfiniteLoader
                  width={width}
                  height={height}
                  isItemLoaded={__index => Boolean(_senderIdListRef.current[__index])}
                  itemCount={_senderIdListRef.current.length}
                  threshold={0.9}
                  loadMoreItems={(__startIndex, __endIndex) => _LoadMore(10, parseInt(__endIndex, 10) + 1)}>
                  {({ onItemsRendered, ref }) => (
                    <List
                      ref={ref}
                      height={height}
                      itemCount={_senderIdListRef.current.length}
                      itemSize={52}
                      onItemsRendered={onItemsRendered}
                      overflow='hidden'
                      width={width}>
                      {_List}
                    </List>
                  )}
                </InfiniteLoader>
              )}
            </AutoSizer>
          </MenuList>
          <Flex position='absolute' right={0} bottom={0} zIndex={1000}>
            <IconButton
              aria-label='Select All'
              disabled={0 === _senderIdListRef.current.length || QuerySenderIdReadResponse.loading || _QuerySenderIdDirectoryRead?.loading || disabled}
              _hover={{ 'bg': 'none' }}
              _active={{ 'bg': 'none' }}
              onClick={() => {
                // Const assignment.
                const _senderIdIds = ['ALL']

                // Update senderId.
                setSenderId(_senderIdIds)

                // Return updated senderId.
                onChange({ 'senderId': _senderIdIds, 'senderIdDirectory': senderIdDirectory })
              }}
              right='-10px'
              bg='none'
              p='0'>
              {senderId?.length === _senderIdListRef.current?.length ? (<HiCheckCircle fontSize='20px' color='#c4c4c4' />) : (<HiPlusCircle fontSize='20px' color='#3CA55C' />)}
            </IconButton>
            <IconButton
              aria-label='Clear All'
              disabled={0 === _senderIdListRef.current.length || QuerySenderIdReadResponse.loading || _QuerySenderIdDirectoryRead?.loading || disabled}
              _hover={{ 'bg': 'none' }}
              _active={{ 'bg': 'none' }}
              onClick={() => {
                // Update states.
                setSenderId([])

                // Return updated mcc and mnc.
                onChange({ 'senderId': [], 'senderIdDirectory': '' })
              }}
              bg='none'
              p='0'>
              {0 === senderId?.length ? (<HiXCircle fontSize='20px' color='#c4c4c4' />) : (<HiXCircle fontSize='20px' color='#FF416C' />)}
            </IconButton>
          </Flex>
        </Menu>
      </FormControl>
    </Flex>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'senderIdDirectoryValue': PropTypes.string,
  'senderIdIdValue': PropTypes.array,
  'senderIdValue': PropTypes.array,
  'inValidSenderId': PropTypes.bool,
  'disabled': PropTypes.bool,
  'inValidSenderIdDirectory': PropTypes.bool,
  'onChange': PropTypes.func,
  'isRequired': PropTypes.bool
}


/*
 * EXPORT
 */
export default Index
