/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
    query OperatorPrefixReadQuery($mncId: ID!, $take: PositiveInt, $skip: Int , $search:  String!) {
    OperatorPrefixRead(mncId: $mncId, take: $take, skip: $skip ,search: $search) {
        id,
        createdAt,
        updatedAt,
        prefix,
        status,
        message,
        _totalCount,
        Mnc {
          mnc,
          network
      }
    }
  }
`
