/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query BilateralReadRelationshipQuery($vendorId: ID) {
  BilateralReadRelationship(vendorId: $vendorId) {
    id,
    Customer {
      id,
      displayName,
      type,
      message
      status
    }
    message
    status
  }
}
`
