/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import JoiBrowser from 'joi-browser' // Npm: Joi for frontend validation.
import _ from 'underscore' // Npm: Underscore.js library.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useLazyQuery, useMutation } from '@apollo/client' // Npm: Apollo client.


/*
 * PACKAGES
 */
import SubmitButton from 'components/SubmitButton'
import { MemoizedInput, MemoizedSelect } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import RouteReadQuery from './__query__/index.route.read.query'
import RouteUpdateMutation from './__mutation__/index.route.update.mutation'
import RouteCreateMutation from './__mutation__/index.route.create.mutation'


/*
 * OBJECTS
 */
const Index = ({ isOpen, isCreateOnly, onClose, type, passOn, containerStyle }) => {
  // Hook assignment.
  const [error, setError] = React.useState('')
  const [forceReRender, setForceReRender] = React.useState('')
  const [QueryRouteRead, QueryRouteReadResponse] = useLazyQuery(RouteReadQuery, { 'variables': { 'routeId': passOn?.routeId } })
  const [MutationRouteCreate, MutationRouteCreateResponse] = useMutation(RouteCreateMutation)
  const [MutationRouteUpdate, MutationRouteUpdateResponse] = useMutation(RouteUpdateMutation)
  const _formDataRef = React.useRef({ type })

  // Object assignment.
  const _SubmitForm = async e => {
    // Prevent default behavior.
    e.preventDefault()

    // Reset error.
    setError('')

    // Update _formDataRef with type
    _formDataRef.current = { ..._formDataRef.current, 'type': type }

    // Const assignment.
    const _JoiSchema = JoiBrowser.object({
      'displayName': JoiBrowser.string().required(),
      'type': JoiBrowser.string().required()
    }).options({ 'allowUnknown': true })

    // Remove all keys from _formDataRef.current which are undefined.
    _formDataRef.current = _.pick(_formDataRef.current, _.identity)

    // Validate form data.
    const _JoiSchemaValidate = _JoiSchema.validate(_formDataRef.current)

    // If error exists then report failure.
    if (_JoiSchemaValidate.error) return setError(_JoiSchemaValidate.error?.message)

    /*
     * Push routeId if not found on the _formDataRef.
     * also make sure that isCreateOnly is false.
     */
    if (!isCreateOnly && _.isEmpty(_formDataRef.current?.routeId)) _formDataRef.current = { ..._formDataRef.current, 'routeId': passOn?.routeId }

    // Execute update mutation.
    const _MutationRouteUpdate = await [isCreateOnly ? MutationRouteCreate : MutationRouteUpdate]?.[0]({ 'variables': _.omit(_formDataRef?.current, isCreateOnly ? 'routeId' : void 0) })

    // If mutation caught an exception then report failure.
    if (_MutationRouteUpdate instanceof Error) return _MutationRouteUpdate

    // Style Guide.
    toast(_MutationRouteUpdate?.data?.RouteUpdate?.message ?? _MutationRouteUpdate?.data?.RouteCreate?.message)

    // Execute onClose only if response is successful.
    if ('UPDATE_SUCCESSFUL' === _MutationRouteUpdate?.data?.RouteUpdate?.status || 'CREATE_SUCCESSFUL' === _MutationRouteUpdate?.data?.RouteCreate?.status) return onClose?.()

    // Report void 0.
    return void 0
  }

  // Event handler.
  React.useEffect(() => {
    // _Async handler.
    const _Async = async () => {
      // Const assignment.
      const _QueryRouteReadQuery = await QueryRouteRead({ 'variables': { 'routeId': isCreateOnly ? 'UN_KNOWN' : passOn?.routeId } })

      // If query caught an exception then report failure.
      if (_QueryRouteReadQuery instanceof Error) return _QueryRouteReadQuery

      /*
       * If route details fetch complete then
       * update its value.
       */
      if (0 < _QueryRouteReadQuery?.data?.RouteRead?.length) {
        // Const assignment.
        const _data = _.first(_QueryRouteReadQuery?.data?.RouteRead)

        // Update form data.
        _formDataRef.current = {
          'routeId': _data.id,
          'displayName': _data.displayName,
          'type': _data.type
        }

        // Update state.
        return setForceReRender(String.random(8))
      }

      // Report failure.
      return void 0
    }; _Async()
  }, [passOn, isOpen])

  // Component assignment.
  const _isLoading = MutationRouteUpdateResponse.loading || MutationRouteCreateResponse.loading
  const _isInputDisabled = isCreateOnly ? false : _isLoading || QueryRouteReadResponse.loading

  // Return component.
  return (
    <form style={containerStyle} onSubmit={_SubmitForm} key={forceReRender}>
      <MemoizedInput
        disabled={_isInputDisabled}
        name='displayName'
        label='Name'
        placeholder='e.g. "US Route"'
        onChange={({ target }) => {
          // Over spreading.
          const { name, value } = target

          // Update form data.
          _formDataRef.current = {
            ..._formDataRef?.current,
            [name]: value
          }
        }}
        error={error}
        isInvalid={error?.includes('displayName')}
        data={_formDataRef?.current?.displayName}
      />
      <MemoizedSelect
        disabled={isCreateOnly || _isInputDisabled}
        name='type'
        label='Type'
        placeholder='e.g. "WHOLESALE"'
        onChange={({ target }) => {
          // Over spreading.
          const { name, value } = target

          // Update form data.
          _formDataRef.current = {
            ..._formDataRef?.current,
            [name]: value
          }
        }}
        error={error}
        options={Object.React.App.enums.CUSTOMER_TYPE.enums?.map(i => i.key)}
        isInvalid={error?.includes('type')}
        data={isCreateOnly ? type : _formDataRef?.current?.type}
      />
      <SubmitButton
        margin={0}
        disabled={_isInputDisabled}
        onSubmit={_SubmitForm}
        defaultText={isCreateOnly ? 'Create Route' : 'Update Route'}
        isLoading={_isLoading} />
    </form>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'isCreateOnly': PropTypes.bool,
  'isOpen': PropTypes.bool,
  'onClose': PropTypes.func,
  'passOn': PropTypes.object,
  'type': PropTypes.string,
  'containerStyle': PropTypes.object
}
Index.defaultProps = {
  'containerStyle': {
    'width': '100%',
    'display': 'flex',
    'flexDirection': 'column',
    'gap': '22px'
  }
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
