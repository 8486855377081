/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
    query KeywordDirectoryReadQuery($keywordDirectoryToSearch: String, $take: PositiveInt, $skip: Int) {
      KeywordDirectoryRead(keywordDirectoryToSearch: $keywordDirectoryToSearch, take: $take, skip: $skip) {
        id,
        createdAt,
        updatedAt,
        displayName,
        status,
        message,
        _totalCount,
        _count {
          Keyword
        }
    }
  }
`
