/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation RatePlanUpdateMutation($ratePlanId: ID, $ratePlanIds: [ID!], $input: [RatePlanUpdateInput!]!) {
  RatePlanUpdate(ratePlanId: $ratePlanId, ratePlanIds: $ratePlanIds, input: $input) {
    message
    status
  }
}
`
