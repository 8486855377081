/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.

/*
 * EXPORTS
 */
export default gql`
  query IpReadQuery($ipDirectoryId: ID!, $take: PositiveInt!, $skip: Int!) {
    IpRead(ipDirectoryId: $ipDirectoryId, take: $take, skip: $skip) {
      message,
      status,
      id,
      ip,
      _totalCount,
      IpDirectory {
        id,
        displayName
      }
    }
  }
`
