/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
    query KeywordReadQuery($keywordDirectoryId: ID, $keywordToSearch: String, $take: PositiveInt, $skip: Int) {
      KeywordRead(keywordDirectoryId: $keywordDirectoryId, keywordToSearch: $keywordToSearch, take: $take, skip: $skip) {
        id,
        createdAt,
        updatedAt,
        keyword,
        status,
        message,
        _totalCount
    }
  }
`
