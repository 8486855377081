/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation SmppCreateMutation(
  $displayName: String!,
  $ip: IPv4!,
  $username: String!,
  $password: String!,
  $rxPort: PositiveInt!,
  $txPort: PositiveInt!,
  $sessionTimeoutInterval: Float!,
  $sessionAllowed: Int,
  $tps: Int,
  $enquireLinkInterval: Float!,
  $maxReconnectAttempts: Int,
  $reconnectBackoffFactor: Float,
  $initialReconnectDelay: Float,
  $sourceAddressTon: Int,
  $sourceAddressNpi: Int,
  $destinationAddressTon: Int,
  $destinationAddressNpi: Int,
  $trunkType: TRUNK_TYPE!,
  $isSticky: Boolean,
  $debuggingLevel: SMPP_DEBUGGING_LEVEL!
) {
  SmppCreate(
    displayName: $displayName,
    ip: $ip,
    username: $username,
    password: $password,
    rxPort: $rxPort,
    txPort: $txPort,
    sessionTimeoutInterval: $sessionTimeoutInterval,
    sessionAllowed: $sessionAllowed,
    tps: $tps,
    enquireLinkInterval: $enquireLinkInterval,
    maxReconnectAttempts: $maxReconnectAttempts,
    reconnectBackoffFactor: $reconnectBackoffFactor,
    initialReconnectDelay: $initialReconnectDelay,
    sourceAddressTon: $sourceAddressTon,
    sourceAddressNpi: $sourceAddressNpi,
    destinationAddressTon: $destinationAddressTon,
    destinationAddressNpi: $destinationAddressNpi,
    trunkType: $trunkType,
    isSticky: $isSticky,
    debuggingLevel: $debuggingLevel
  ) {
    id
    message
    status
  }
}
`
