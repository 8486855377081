/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import _ from 'underscore' // Npm: utility module.
import { HiAdjustmentsVertical, HiBars4, HiBolt, HiCurrencyEuro, HiDevicePhoneMobile, HiHashtag } from 'react-icons/hi2' // Npm: React icons.
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useBreakpointValue
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * OBJECTS
 */
const Index = ({ onChange }) => {
  // Hook assignment.
  const [currentlyVisible, setCurrentlyVisible] = React.useState('')
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': true, 'lg': true, 'xl': false, 'sm': true, 'xs': true })

  // Return component.
  return (
    <Menu placement='bottom'>
      <MenuButton
        as={Button}
        margin='auto'
        borderRadius='12px'
        color='brand.500'
        bg='gray.50'
        _hover={{ 'bg': 'gray.100', 'color': 'brand.500' }}
        _active={{ 'bg': 'gray.100', 'color': 'brand.500' }}
        _focus={{ 'boxShadow': 'none' }}
        display='flex'
        flexDir='row'>
        <Text as='span' display='flex' gap='6px' fontWeight={500}>{_isCurrentViewMobile ? void 0 : 'Plan Filter'}<HiAdjustmentsVertical size={18} /></Text>
      </MenuButton>
      <MenuList
        className='dropDownMenu'
        maxHeight='400px'
        w='350px'
        overflow='auto'>
        <MenuItem
          gap='12px'
          display='flex'
          color='gray.500'
          bg={'ALL' === currentlyVisible || _.isEmpty(currentlyVisible) ? 'gray.200' : void 0}
          onClick={() => {
            // Set the currently visible.
            setCurrentlyVisible('ALL')

            // Call the function.
            onChange?.('ALL')
          }}
          minH='40px'>
          <HiBars4 size={18} />
          <span>Show All</span>
        </MenuItem>
        <MenuItem
          gap='12px'
          display='flex'
          color='gray.500'
          bg={'SENDER_ID' === currentlyVisible ? 'gray.200' : void 0}
          onClick={() => {
            // Set the currently visible.
            setCurrentlyVisible('SENDER_ID')

            // Call the function.
            onChange?.('SENDER_ID')
          }}
          minH='40px'>
          <HiHashtag size={18} />
          <span>Show Sender Id Based Routing</span>
        </MenuItem>
        <MenuItem
          gap='12px'
          display='flex'
          color='gray.500'
          bg={'PHONE_NUMBER' === currentlyVisible ? 'gray.200' : void 0}
          onClick={() => {
            // Set the currently visible.
            setCurrentlyVisible('PHONE_NUMBER')

            // Call the function.
            onChange?.('PHONE_NUMBER')
          }}
          minH='40px'>
          <HiDevicePhoneMobile />
          <span>Show Phone Number Based Routing</span>
        </MenuItem>
        <MenuItem
          gap='12px'
          display='flex'
          color='gray.500'
          bg={'LCR' === currentlyVisible ? 'gray.200' : void 0}
          onClick={() => {
            // Set the currently visible.
            setCurrentlyVisible('LCR')

            // Call the function.
            onChange?.('LCR')
          }}
          minH='40px'>
          <HiCurrencyEuro />
          <span>Show LCR Based Routing</span>
        </MenuItem>
        <MenuItem
          gap='12px'
          display='flex'
          color='gray.500'
          bg={'LB' === currentlyVisible ? 'gray.200' : void 0}
          onClick={() => {
            // Set the currently visible.
            setCurrentlyVisible('LB')

            // Call the function.
            onChange?.('LB')
          }}
          minH='40px'>
          <HiBolt />
          <span>Show Load Balanced Routing</span>
        </MenuItem>
      </MenuList>
    </Menu>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'onChange': PropTypes.func
}


/*
 * EXPORT
 */
export default Index
