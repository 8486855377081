/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import _ from 'underscore' // Npm: Underscore.js library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useSubscription } from '@apollo/client' // Npm: Apollo client for handling graphql request.
import { HiBellSnooze } from 'react-icons/hi2' // Npm: React icons.
import { RiMenu3Fill } from 'react-icons/ri' // Npm: React icons.
import { MdNotificationsNone } from 'react-icons/md' // Npm: React icons.
import {
  Avatar,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useBreakpointValue,
  useColorModeValue
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import TableSpinner from 'components/TableSpinner'
import PlatformLogo from 'components/PlatformLogo'
import DrawerContext from 'components/SideBar/index.context'


/*
 * GRAPHS
 */
import SmppNotificationSubscription from './__subscription__/index.smpp.notification.subscription'


/*
 * OBJECTS
 */
function Index({ ClearEverything, account }) {
  // Hook assignment.
  const [isOpen, setIsDrawerOpen] = React.useContext(DrawerContext)
  const [, setForceReRender] = React.useState(void 0)
  const _menuBg = useColorModeValue('white', 'navy.800')
  const _textColor = useColorModeValue('secondaryGray.900', 'white')
  const _borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)')
  const _shadow = useColorModeValue('14px 17px 40px 4px rgba(112, 144, 176, 0.18)', '14px 17px 40px 4px rgba(112, 144, 176, 0.06)')
  const _notificationRef = React.useRef([])
  const _gotNewNotificationRef = React.useRef(false)
  const _isCurrentViewTablet = useBreakpointValue({ 'base': 'false', 'md': true, 'lg': true, 'xl': false, 'sm': false, 'xs': false })
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })

  // Event handler.
  useSubscription(SmppNotificationSubscription, {
    'onData': ({ data, error }) => {
      // If subscription caught an exception then report failure.
      if (error instanceof Error) return error

      // Only update if data is available.
      if (!_.isEmpty(data?.data?.SmppNotification)) {
        // Push notification to user.
        _notificationRef.current = _.compact([..._notificationRef.current, data?.data?.SmppNotification])
        _gotNewNotificationRef.current = true
      }

      // Update state.
      return setForceReRender(String.random(9))
    }
  })

  // Return component.
  return (
    <Flex
      w='100%'
      justifyContent='space-between'
      bg='transparent'
      p='12px'
      borderRadius='20px'
      boxShadow={_shadow}>
      {
        _isCurrentViewMobile && !_isCurrentViewTablet ? (
          <Flex w='45px' h='45px' ml='0' mr='auto'>
            <PlatformLogo />
          </Flex>
        ) : void 0
      }
      <Flex flex={_isCurrentViewMobile ? 'calc(100% - 35px)' : '100%'} justifyContent='flex-end'>
        <Menu>
          <MenuButton position='relative' display='flex' alignItems='center' p='0px' onClick={() => { (_gotNewNotificationRef.current = false); setForceReRender(String.random(9)) }}>
            {_gotNewNotificationRef.current && <HiBellSnooze style={{ 'position': 'absolute', 'right': '-2.5px', 'top': '-5px' }} color='white' w='18px' h='18px' me='10px' mt='6px' />}
            <Icon
              mt='6px'
              as={MdNotificationsNone}
              color='white'
              w='18px'
              h='18px'
              me='10px'
            />
          </MenuButton>
          <MenuList
            boxShadow={_shadow}
            p='22px'
            borderRadius='20px'
            bg={_menuBg}
            border='none'
            mt='22px'
            width={380}
            me={{ 'base': '30px', 'md': 'unset' }}
            minW={{ 'base': 'unset', 'md': '300px', 'xl': '350px' }}
            maxW={{ 'base': '360px', 'md': 'unset' }}>
            <Flex justify='space-between' w='100%' mb='20px'>
              <Text fontSize='md' fontWeight='600' color='brand.500'>
                Notifications
              </Text>
              <Text
                fontSize='sm'
                fontWeight='500'
                bg='gray.50'
                px='10px'
                py='2.5px'
                borderRadius='6px'
                color='gray.500'
                ms='auto'
                _hover={{ 'bg': 'gray.100' }}
                onClick={() => { (_notificationRef.current = []); (_gotNewNotificationRef.current = false); setForceReRender(String.random(9)) }}
                cursor='pointer'>
                Mark All Read
              </Text>
            </Flex>
            <Flex flexDirection='column' maxH='350px' overflow='auto'>
              {
                _.isEmpty(_notificationRef.current) || 0 >= _notificationRef.current?.length ? (
                  <Flex flexDirection='column' minH='100px' alignItems='center' justifyContent='center'>
                    <TableSpinner isEmpty={true} />
                  </Flex>
                ) : _notificationRef.current?.map((j, __index) => (
                  <Flex flexDirection='column' key={String.random(8)}>
                    <Text fontSize='md' color={_textColor} py='8px' px='12px' my='5px' bg='gray.50' _hover={{ 'bg': 'gray.100' }} borderRadius={6}>
                      {__index + 1}. {j?.message}
                    </Text>
                  </Flex>
                ))
              }
            </Flex>
          </MenuList>
        </Menu>
        <Menu>
          <MenuButton
            p='0px'
            marginLeft='5px'>
            <Avatar
              _hover={{ 'cursor': 'pointer' }}
              color={Object.React.CreateColorBasedOnAlphabet(account.displayName).fontColor}
              name={account.displayName}
              bg={Object.React.CreateColorBasedOnAlphabet(account.displayName).bgColor}
              size='sm'
              w='40px'
              h='40px'
            />
          </MenuButton>
          <MenuList
            boxShadow={_shadow}
            p='0px'
            mt='10px'
            borderRadius='20px'
            bg={_menuBg}
            border='none'>
            <Flex w='100%' mb='0px'>
              <Text
                ps='20px'
                pt='16px'
                pb='10px'
                w='100%'
                borderBottom='1px solid'
                borderColor={_borderColor}
                fontSize='sm'
                fontWeight='700'
                color={_textColor}>  👋&nbsp; Hey,{account.displayName}</Text>
            </Flex>
            <Flex flexDirection='column' p='10px'>
              <MenuItem
                _hover={{ 'bg': 'none' }}
                _focus={{ 'bg': 'none' }}
                color='red.400'
                borderRadius='8px'
                px='14px'
                onClick={() => ClearEverything()}>
                <Text fontSize='sm'>Log out</Text>
              </MenuItem>
            </Flex>
          </MenuList>
        </Menu>
        {
          !_isCurrentViewMobile && _isCurrentViewTablet ? (
            <Flex w='45px' h='45px' ml='12px' mr='auto'>
              <PlatformLogo />
            </Flex>
          ) : void 0
        }
        {
          _isCurrentViewMobile || _isCurrentViewTablet ? (
            <Menu>
              <MenuButton ml='6px' onClick={() => setIsDrawerOpen(!isOpen)}>
                <Flex bg='purple.600' width='45px' height='45px' maxW='45px' minW='45px' minH='45px' borderRadius='100%' w='100%' h='max-content' onClick={e => e} justify='flex-end' zIndex={1000} color='white' alignItems='center' justifyContent='center'>
                  <RiMenu3Fill fontSize={21} />
                </Flex>
              </MenuButton>
            </Menu>
          ) : void 0
        }
      </Flex>
    </Flex>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'account': PropTypes.object,
  'ClearEverything': PropTypes.func
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'account': __state.Account })
const _MapDispatchToProps = __dispatch => ({
  'ClearEverything': () => {
    // Clear all states.
    __dispatch({ 'type': 'ACCOUNT_CLEAR' })
    __dispatch({ 'type': 'PASS_ON_CLEAR' })
  }
})


/*
 * EXPORT
 */
export default connect(_MapStateToProps, _MapDispatchToProps)(Index)



