/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: prop-types library.
import Debounce from 'lodash/debounce' // Npm: lodash debounce.
import { useQuery } from '@apollo/client' // Npm: Apollo client.


/*
 * PACKAGES
 */
import { MemoizedSelect } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import EntityReadQuery from './__query__/index.entity.read.query'


/*
 * OBJECTS
 */
const Index = ({ onChange, ...props }) => {
  // Const assignment.
  const _skipDifference = 1000

  // Hook assignment.
  const _QueryEntityRead = useQuery(EntityReadQuery, { 'variables': { 'take': _skipDifference, 'skip': 0 }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })

  // Return component.
  return (
    <MemoizedSelect
      w='100%'
      onChange={onChange}
      options={_QueryEntityRead?.data?.EntityRead?.map(({ id, displayName }) => `${displayName} (${id})`)}
      isRequired={true}
      {...props}
    />
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'onChange': PropTypes.func.isRequired
}


/*
 * EXPORT
 */
export default Index
