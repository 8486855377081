/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import Moment from 'moment' // Npm: Moment library.
import Debounce from 'lodash/debounce' // Npm: lodash library.
import _ from 'underscore' // Npm: underscore library.
import { useQuery } from '@apollo/client' // Npm: Apollo client.
import { IoMdAdd } from 'react-icons/io'
import {
  Button,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tooltip,
  Tr,
  useBreakpointValue,
  useDisclosure
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import DownloadToExcel from 'components/DownloadToExcel'
import PaginationWithPageInformation from 'components/PaginationWithPageInformation'
import TableSpinner from 'components/TableSpinner'
import Modal from 'components/Modal'
import VendorAccounttDirectoryUpsert from 'components/VendorAccounttDirectoryUpsert'
import VendorAccounttDirectoryDropDownOptions from 'components/VendorAccounttDirectoryDropDownOptions'
import VendorAccounttDirectoryDelete from 'components/VendorAccounttDirectoryDelete'
import { MemoizedInput } from 'components/MemoizedInput'


/*
 * STYLES
 */
import {
  buttonStyle,
  cellStyle,
  headerStyle,
  rowStyle
} from './index.style'


/*
 * GRAPHS
 */
import VendorAccounttDirectoryReadQuery from './__query__/index.vendorAccounttDirectory.read.query'


/*
 * OBJECTS
 */
const Index = () => {
  // Const assignment.
  const _skipDifference = 8
  const _successFlags = Object.React.App.enums.GRAPHQL_SUCCESSFUL_QUERY_FLAGS.enums.map(i => i.key)

  // Hook assignment.
  const [skipPage, setSkipPage] = React.useState(0)
  const [skipDifference, setSkipDifference] = React.useState(_skipDifference)
  const [vendorAccounttDirectoryToSearch, setVendorAccounttDirectoryToSearch] = React.useState('')
  const { 'isOpen': isVendorAccounttDirectoryCreateOpen, 'onOpen': onVendorAccounttDirectoryCreateOpen, 'onClose': onVendorAccounttDirectoryCreateClose } = useDisclosure()
  const { 'isOpen': isVendorAccounttDirectoryUpdateOpen, 'onOpen': onVendorAccounttDirectoryUpdateOpen, 'onClose': onVendorAccounttDirectoryUpdateClose } = useDisclosure()
  const { 'isOpen': isVendorAccounttDirectoryDeleteOpen, 'onOpen': onVendorAccounttDirectoryDeleteOpen, 'onClose': onVendorAccounttDirectoryDeleteClose } = useDisclosure()
  const _QueryVendorAccounttDirectoryRead = useQuery(VendorAccounttDirectoryReadQuery, { 'variables': { vendorAccounttDirectoryToSearch, 'skip': skipPage * skipDifference, 'take': skipDifference }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })
  const _isFirstLoadCompleted = React.useRef(false)
  const _onVendorAccounttDirectoryToSearchInputChange = React.useCallback(Debounce(e => setVendorAccounttDirectoryToSearch(e.target.value), 800), [])
  const _tableHeaderHeightRef = React.useRef(0)
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })

  // Check if first load is completed.
  if (!_QueryVendorAccounttDirectoryRead.loading && 0 < _QueryVendorAccounttDirectoryRead.data?.VendorAccounttDirectoryRead?.length && !_isFirstLoadCompleted.current) _isFirstLoadCompleted.current = true
  if (!_.every(_.pluck(_QueryVendorAccounttDirectoryRead.data?.VendorAccounttDirectoryRead, 'status'), i => _successFlags.includes(i))) _isFirstLoadCompleted.current = false

  // Return component.
  return (
    <>
      <Flex className='vendorAccounttDirectory base'>
        <Flex
          display='flex'
          flex={1}
          flexDirection='column'
          gap={_isCurrentViewMobile ? '12px' : '22px'}
          bg='white'
          height='100%'
          borderRadius='20px'
          p={_isCurrentViewMobile ? '12px' : '22px'}>
          <Flex pb='0px' justify='space-between'>
            <Flex gap='12px'>
              <Tooltip label='Create New Folder' fontSize='sm'>
                <Button
                  leftIcon={<IoMdAdd />}
                  onClick={onVendorAccounttDirectoryCreateOpen}
                  style={buttonStyle}>
                  Create New
                </Button>
              </Tooltip>
            </Flex>
            <DownloadToExcel
              cellsData={0 === _QueryVendorAccounttDirectoryRead?.data?.VendorAccounttDirectoryRead?.length || !_.every(_.pluck(_QueryVendorAccounttDirectoryRead?.data?.VendorAccounttDirectoryRead, 'status'), i => _successFlags.includes(i)) ? [] : _QueryVendorAccounttDirectoryRead?.data?.VendorAccounttDirectoryRead.map((item, __index) => ({
                'S.No.': `${(__index + (skipDifference * (skipPage))) + 1}.`,
                'Display Name': item.displayName ?? '-',
                'Total VendorAccount': item?.VendorAccount?.length ?? '-',
                'CreatedAt': Moment(item?.createdAt).format('YYYY-MM-DD'),
                'UpdatedAt': Moment(item?.updatedAt).format('YYYY-MM-DD')
              }))}
              headersData={[
                'S.No.',
                'Display Name',
                'Total VendorAccount',
                'CreatedAt',
                'UpdatedAt'
              ].map(i => ({ 'key': i, 'label': i }))} />
          </Flex>
          <TableContainer
            flex={1}
            display='flex'
            borderRadius='15px'
            outline='1px solid #C5CFE8'>
            <Table variant='simple' size='sm'>
              <Thead ref={_tableHeaderHeightRef}>
                <Tr style={{ ...headerStyle, 'position': 'sticky', 'top': 0, 'zIndex': 1 }}>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    S.No
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>Display Name</Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>Total VendorAccount</Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>Created At</Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>Updated At</Td>
                  <Td
                    style={rowStyle}>Actions</Td>
                </Tr>
                <Tr >
                  <Td borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>
                    <MemoizedInput
                      placeholder='Search'
                      className='filter searchInput'
                      defaultValue={vendorAccounttDirectoryToSearch}
                      onChange={_onVendorAccounttDirectoryToSearchInputChange}
                    />
                  </Td>
                  <Td borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td></Td>
                </Tr>
              </Thead>
              {_QueryVendorAccounttDirectoryRead.loading && !_isFirstLoadCompleted.current ? (
                <TableSpinner isLoading={true} chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight} />
              ) : !_isFirstLoadCompleted.current && (0 === _QueryVendorAccounttDirectoryRead.data?.VendorAccounttDirectoryRead?.length || !_.every(_.pluck(_QueryVendorAccounttDirectoryRead.data?.VendorAccounttDirectoryRead, 'status'), i => _successFlags.includes(i))) ? (
                <TableSpinner isLoading={false} isEmpty={true} chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight} />
              ) : (
                <Tbody style={cellStyle}>
                  {_QueryVendorAccounttDirectoryRead.data?.VendorAccounttDirectoryRead?.map(({ id, createdAt, updatedAt, displayName, VendorAccountt }, __index) => (
                    <Tr key={String.random(9)}>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {`${(__index + (skipDifference * (skipPage))) + 1}.`}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {displayName ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {VendorAccountt?.length ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {Moment(createdAt).format('YYYY-MM-DD')}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {Moment(updatedAt).format('YYYY-MM-DD')}
                      </Td>
                      <Td
                        style={rowStyle}>
                        {' '}
                        {
                          <VendorAccounttDirectoryDropDownOptions
                            vendorAccounttDirectoryId={id}
                            onVendorAccounttDirectoryUpdate={onVendorAccounttDirectoryUpdateOpen}
                            onVendorAccounttDirectoryDelete={onVendorAccounttDirectoryDeleteOpen}
                          />
                        }
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              )
              }
            </Table>
          </TableContainer>
        </Flex>
        <Modal
          size='md'
          title='VendorAccount Directory Create'
          isOpen={isVendorAccounttDirectoryCreateOpen}
          onClose={onVendorAccounttDirectoryCreateClose}>
          <VendorAccounttDirectoryUpsert isCreateOnly={true} />
        </Modal>
        <Modal
          size='md'
          title='VendorAccount Directory Update'
          isOpen={isVendorAccounttDirectoryUpdateOpen}
          onClose={onVendorAccounttDirectoryUpdateClose}>
          <VendorAccounttDirectoryUpsert />
        </Modal>
        <Modal
          size='md'
          title='VendorAccount Directory Delete'
          isOpen={isVendorAccounttDirectoryDeleteOpen}
          onClose={onVendorAccounttDirectoryDeleteClose}>
          <VendorAccounttDirectoryDelete />
        </Modal>
      </Flex>
      <PaginationWithPageInformation
        skipPage={skipPage}
        skipDifference={_skipDifference}
        setSkipDifference={setSkipDifference}
        itemsPerPage={_skipDifference}
        totalCount={_.pluck(_QueryVendorAccounttDirectoryRead.data?.VendorAccounttDirectoryRead, '_totalCount')?.[0]}
        onPageChange={setSkipPage}
      />
    </>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {}


/*
 * EXPORT
 */
export default Index
