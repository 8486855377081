/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { useMutation } from '@apollo/client' // Npm: Apollo client.


/*
 * ASSETS
 */
import DeleteCard from 'components/DeleteCard'


/*
 * GRAPHS
 */
import RouteDeleteMutation from './__mutation__/index.route.delete.mutation'


/*
 * OBJECTS
 */
const Index = ({ passOn, onClose }) => {
  // Hook assignment.
  const [MutationRouteDelete, MutationRouteDeleteResponse] = useMutation(RouteDeleteMutation)

  // Return Component.
  return (
    <DeleteCard onClose={onClose} disabled={MutationRouteDeleteResponse.loading} onConfirm={() => MutationRouteDelete({
      'variables': {
        'routeId': passOn?.routeId
      }
    }).then(i => {
      // Style Guide.
      toast(i?.data?.RouteDelete?.message)

      // Report success.
      return onClose?.()
    }).catch(() =>
      // Style Guide.
      toast('Something went wrong please try after some time.'))} isLoading={MutationRouteDeleteResponse.loading} />
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'passOn': PropTypes.object,
  'onClose': PropTypes.func
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
