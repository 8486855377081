/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import Moment from 'moment' // Npm: moment.js library.
import _ from 'underscore' // Npm: underscore.js library.
import { useQuery } from '@apollo/client' // Npm: Apollo client.
import { IoMdAdd } from 'react-icons/io' // Npm: React icons.
import {
  Button,
  Checkbox,
  Flex,
  Tab,
  Table,
  TableContainer,
  TabList,
  Tabs,
  Tbody,
  Td,
  Thead,
  Tooltip,
  Tr,
  useBreakpointValue,
  useDisclosure
} from '@chakra-ui/react' // Npm: Chakra UI components.

/*
 * PACKAGES
 */
import TableSpinner from 'components/TableSpinner'
import PaginationWithPageInformation from 'components/PaginationWithPageInformation'
import Modal from 'components/Modal'
import BlockDestinationAddressUpsert from 'components/BlockDestinationAddressUpsert'
import BlockDestinationAddressDelete from 'components/BlockDestinationAddressDelete'
import BlockDestinationAddressDropDownOptions from 'components/BlockDestinationAddressDropDownOptions'


/*
 * GRAPHS
 */
import BlockDestinationAddressReadQuery from './__query__/index.blockDestinationAddress.read.query'


/*
 * STYLES
 */
import { buttonStyle, headerStyle, rowStyle, tabStyles } from './index.style'


/*
 * OBJECTS
 */
const Index = () => {
  // Const assignment.
  const _skipDifference = 8
  const _Customers = Object.React.App.enums.CONTENT_TRANSLATION_TYPE.enums.map(i => i.key)
  const _successFlags = Object.React.App.enums.GRAPHQL_SUCCESSFUL_QUERY_FLAGS.enums.map(i => i.key)

  // Hook assignment.
  const [skipPage, setSkipPage] = React.useState(0)
  const [skipDifference, setSkipDifference] = React.useState(_skipDifference)
  const [selectAll, setSelectAll] = React.useState(false)
  const [tabIndex, setTabIndex] = React.useState(0)
  const [selectedIds, setSelectedIds] = React.useState([])
  const _QueryBlockDestinationAddressRead = useQuery(
    BlockDestinationAddressReadQuery,
    {
      'variables': {
        'type': _Customers[tabIndex],
        'skip': skipPage * skipDifference,
        'take': skipDifference
      },
      'fetchPolicy': Object.React.App.fetchPolicy,
      'pollInterval': Object.React.App.pollInterval
    }
  )
  const _isFirstLoadCompleted = React.useRef(false)
  const { 'onOpen': onBlockDestinationAddressOpen, 'isOpen': isBlockDestinationAddressOpen, 'onClose': onBlockDestinationAddressClose } = useDisclosure()
  const { 'isOpen': isBlockDestinationAddressUpdateOpen, 'onOpen': onBlockDestinationAddressUpdateOpen, 'onClose': onBlockDestinationAddressUpdateClose } = useDisclosure()
  const { 'isOpen': isBlockDestinationAddressDeleteOpen, 'onOpen': onBlockDestinationAddressDeleteOpen, 'onClose': onBlockDestinationAddressDeleteClose } = useDisclosure()
  const _tableHeaderHeightRef = React.useRef(0)
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })

  // Event handler.
  React.useEffect(() => {
    // Reset selectedIds when the data is re-fetched
    if (_QueryBlockDestinationAddressRead.data) {
      // Update states.
      setSelectedIds([])
      setSelectAll(false)
    }
  }, [
    isBlockDestinationAddressUpdateOpen,
    isBlockDestinationAddressOpen,
    isBlockDestinationAddressDeleteOpen,
    tabIndex,
    skipPage
  ])

  // Event handler.
  const _HandleSelectAll = () => {
    // Check if selectAll is true.
    setSelectAll(!selectAll)

    // Check if selectAll is true.
    setSelectedIds(
      selectAll ? [] : _QueryBlockDestinationAddressRead?.data?.BlockDestinationAddressRead?.map(
        item => item.id
      ) || []
    )
  }
  const _HandleCheckboxChange = id => {
    // Check if id is already in selectedIds.
    if (selectedIds.includes(id)) {
      // Remove id from selectedIds.
      setSelectedIds(selectedIds.filter(selectedId => selectedId !== id))
    } else {
      // Add id to selectedIds.
      setSelectedIds([...selectedIds, id])
    }
  }

  // Data assignment.
  if (!_QueryBlockDestinationAddressRead.loading && 0 < _QueryBlockDestinationAddressRead?.data?.BlockDestinationAddressRead?.length) _isFirstLoadCompleted.current = true
  if (0 === _QueryBlockDestinationAddressRead?.data?.BlockDestinationAddressRead?.length || !_.every(_.pluck(_QueryBlockDestinationAddressRead?.data?.BlockDestinationAddressRead, 'status'), j => _successFlags.includes(j))) _isFirstLoadCompleted.current = false

  // Return component.
  return (
    <>
      <Flex className='blockDestinationAddress base'>
        <Flex
          display='flex'
          flex={1}
          flexDirection='row'
          justifyContent='space-between'
          bg='linear-Gradient(73deg,#390EF6 0%, #9244F4 100%)'
          height='100%'
          borderTopLeftRadius='20px'
          borderTopRadius='20px'
          p={_isCurrentViewMobile ? '12px' : '22px'}>
          <Tooltip label='Block new Number.' fontSize='sm'>
            <Button
              w='auto'
              onClick={onBlockDestinationAddressOpen}
              leftIcon={<IoMdAdd />}
              style={buttonStyle}>
              Block Number
            </Button>
          </Tooltip>
        </Flex>
        <Tabs
          variant='enclosed'
          onChange={r => setTabIndex(r)}
          display='flex'
          flexDirection='column'
          align='center'
          w='100%'
          h='100%'
          bg='white'
          borderBottomLeftRadius='20px'
          borderBottomRightRadius='20px'
          isFitted>
          <TabList bg='linear-Gradient(73deg,#390EF6 0%, rgba(146, 68, 244, 1) 100%)' overflowX={_isCurrentViewMobile ? 'auto' : 'none'}>
            {_Customers.map(u => (
              <>
                <Tab
                  color='white'
                  textDecoration={'line-through'}
                  _focus={{ 'boxShadow': 'none' }}
                  _selected={{ 'textDecoration': 'none' }}
                  style={{ ...tabStyles, 'border': 'none', 'borderRadius': 0 }}
                  p='1rem'
                  key={String.random(8)}
                >
                  {u}
                </Tab>
              </>
            ))}
          </TabList>
          <Flex h='100%'>
            <TableContainer
              display='flex'
              border='2px solid #3A0FF6'
              borderBottomRadius='20px'
            >
              <Table
                __css={{ 'table-layout': 'fixed', 'width': 'full' }}
                variant='simple'
                size='sm'
              >
                <Thead ref={_tableHeaderHeightRef}>
                  <Tr
                    style={{
                      ...headerStyle,
                      'top': '0',
                      'zIndex': '1',
                      'position': 'sticky'
                    }}
                  >
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'
                    >
                      <Checkbox bg='gray.100' onChange={_HandleSelectAll} />
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'
                    >
                      S No.
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'
                    >
                      Customer Name
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'
                    >
                      Destination Address
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'
                    >
                      Sender Id&apos;s
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'
                    >
                      Created At
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'
                    >
                      Updated At
                    </Td>
                    <Td
                      style={rowStyle}
                    >
                      Actions
                    </Td>
                  </Tr>
                </Thead>
                {_QueryBlockDestinationAddressRead.loading && !_isFirstLoadCompleted.current ? (
                  <TableSpinner
                    isLoading={true}
                    chopHeightFromHundredPercentage={
                      _tableHeaderHeightRef?.current?.clientHeight
                    }
                  />
                ) : !_isFirstLoadCompleted.current && (0 === _QueryBlockDestinationAddressRead?.data
                  ?.BlockDestinationAddressRead?.length || (0 < _QueryBlockDestinationAddressRead?.data
                    ?.BlockDestinationAddressRead?.length && _.every(
                      _.pluck(
                        _QueryBlockDestinationAddressRead?.data
                          ?.BlockDestinationAddressRead,
                        'status'
                      ),
                      j => !_successFlags.includes(j)
                    ))) ? (
                  <TableSpinner
                    isLoading={false}
                    isEmpty={true}
                    chopHeightFromHundredPercentage={
                      _tableHeaderHeightRef?.current?.clientHeight
                    }
                  />
                ) : (
                  <Tbody>
                    {_QueryBlockDestinationAddressRead?.data?.BlockDestinationAddressRead?.map(
                      (item, __index) => (
                        <Tr key={String.random(8)}>
                          <Td
                            style={rowStyle}
                            borderRight='1px solid rgba(216, 227, 252, 1)'
                          >
                            <Checkbox
                              bg='gray.100'
                              isChecked={selectedIds.includes(item.id)}
                              onChange={() => {
                                _HandleCheckboxChange(item.id)
                              }}
                            ></Checkbox>
                          </Td>
                          <Td
                            style={rowStyle}
                            borderRight='1px solid rgba(216, 227, 252, 1)'>
                            {`${__index + (skipDifference * skipPage) + 1}.`}
                          </Td>
                          <Td
                            style={rowStyle}
                            borderRight='1px solid rgba(216, 227, 252, 1)'>
                            {item?.['TERMINATION' === _Customers[tabIndex] ? 'VendorAccount' : 'CustomerAccount']?.displayName ?? '-'}
                          </Td>
                          <Td
                            style={rowStyle}
                            borderRight='1px solid rgba(216, 227, 252, 1)'>
                            {_.isEmpty(item?.omitDestinationAddress) ? '-' : item?.omitDestinationAddress?.join(', ')}
                          </Td>
                          <Td
                            style={rowStyle}
                            borderRight='1px solid rgba(216, 227, 252, 1)'>
                            {_.isEmpty(item?.SenderId) ? '-' : _.compact(
                              _.flatten(_.pluck(item?.SenderId, 'senderId'))
                            )?.join(', ')}
                          </Td>
                          <Td
                            style={rowStyle}
                            borderRight='1px solid rgba(216, 227, 252, 1)'>
                            {_.isEmpty(item.createdAt) ? '-' : Moment(item.createdAt).format('YYYY-MM-DD')}
                          </Td>
                          <Td
                            style={rowStyle}
                            borderRight='1px solid rgba(216, 227, 252, 1)'>
                            {_.isEmpty(item?.updatedAt) ? '-' : Moment(item?.updatedAt).format('YYYY-MM-DD')}
                          </Td>
                          <Td style={rowStyle}>
                            {' '}
                            {
                              <BlockDestinationAddressDropDownOptions
                                blockDestinationAddressId={item?.id}
                                onBlockDestinationAddressUpdate={
                                  onBlockDestinationAddressUpdateOpen
                                }
                                onBlockDestinationAddressDelete={
                                  onBlockDestinationAddressDeleteOpen
                                }
                                selectedIds={selectedIds}
                                blockDestinationAddressIds={[...selectedIds, item?.id]}
                              />
                            }
                          </Td>
                        </Tr>
                      )
                    )}
                  </Tbody>
                )}
              </Table>
            </TableContainer>
          </Flex>
        </Tabs>
        <Modal
          size='md'
          title='BD Address Create'
          isOpen={isBlockDestinationAddressOpen}
          onClose={onBlockDestinationAddressClose}
          isCentered={false}
        >
          <BlockDestinationAddressUpsert
            isCreateOnly={true}
            type={_Customers[tabIndex]}
            isVendor={'TERMINATION' === _Customers[tabIndex]}
          />
        </Modal>
        <Modal
          size='md'
          title='BD Address Update'
          isOpen={isBlockDestinationAddressUpdateOpen}
          onClose={onBlockDestinationAddressUpdateClose}
          isCentered={false}
        >
          <BlockDestinationAddressUpsert
            isCreateOnly={false}
            type={_Customers[tabIndex]}
            isVendor={'TERMINATION' === _Customers[tabIndex]}
          />
        </Modal>
        <Modal
          size='md'
          title='BD Address Delete'
          isOpen={isBlockDestinationAddressDeleteOpen}
          onClose={onBlockDestinationAddressDeleteClose}
        >
          <BlockDestinationAddressDelete />
        </Modal>
      </Flex>
      <PaginationWithPageInformation
        skipPage={skipPage}
        skipDifference={_skipDifference}
        setSkipDifference={setSkipDifference}
        itemsPerPage={_skipDifference}
        totalCount={_.pluck(_QueryBlockDestinationAddressRead?.data?.BlockDestinationAddressRead, '_totalCount')?.[0]}
        onPageChange={setSkipPage}
      />
    </>
  )
}

/*
 * PROPTYPES
 */
Index.propTypes = {}


/*
 * EXPORT
 */
export default Index
