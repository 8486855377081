/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
    query VendorAccounttReadQuery($vendorAccounttDirectoryId: ID, $vendorAccounttToSearch: String, $take: PositiveInt, $skip: Int) {
      VendorAccounttRead(vendorAccounttDirectoryId: $vendorAccounttDirectoryId, vendorAccounttToSearch: $vendorAccounttToSearch, take: $take, skip: $skip) {
        id,
        createdAt,
        updatedAt,
        displayName,
        VendorAccount {
          id,
          displayName
        },
        status,
        message,
        _totalCount
    }
  }
`
