/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query KeywordDirectoryReadUniqueQuery($keywordDirectoryId: ID!) {
  KeywordDirectoryReadUnique(keywordDirectoryId: $keywordDirectoryId) {
    id,
    displayName,
    message,
    status
  }
}
`
