/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import Moment from 'moment' // Npm: Moment library.
import Debounce from 'lodash/debounce' // Npm: lodash library.
import _ from 'underscore' // Npm: underscore library.
import { useQuery } from '@apollo/client' // Npm: Apollo client.
import { IoMdAdd } from 'react-icons/io' // Npm: React icons.
import {
  Button,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tooltip,
  Tr,
  useBreakpointValue,
  useDisclosure
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import DownloadToExcel from 'components/DownloadToExcel'
import PaginationWithPageInformation from 'components/PaginationWithPageInformation'
import TableSpinner from 'components/TableSpinner'
import Modal from 'components/Modal'
import IpDirectoryUpsert from 'components/IpDirectoryUpsert'
import IpDirectoryDropDownOptions from 'components/IpDirectoryDropDownOptions'
import IpDirectoryDelete from 'components/IpDirectoryDelete'
import { MemoizedInput } from 'components/MemoizedInput'


/*
 * STYLES
 */
import {
  buttonStyle,
  cellStyle,
  headerStyle,
  rowStyle
} from './index.style'


/*
 * GRAPHS
 */
import IpDirectoryReadQuery from './__query__/index.ipDirectory.read.query'


/*
 * OBJECTS
 */
const Index = () => {
  // Const assignment.
  const _skipDifference = 8
  const _successFlags = Object.React.App.enums.GRAPHQL_SUCCESSFUL_QUERY_FLAGS.enums.map(i => i.key)

  // Hook assignment.
  const [skipPage, setSkipPage] = React.useState(0)
  const [skipDifference, setSkipDifference] = React.useState(_skipDifference)
  const [ipDirectoryToSearch, setIpDirectoryToSearch] = React.useState('')
  const { 'isOpen': isIpDirectoryCreateOpen, 'onOpen': onIpDirectoryCreateOpen, 'onClose': onIpDirectoryCreateClose } = useDisclosure()
  const { 'isOpen': isIpDirectoryUpdateOpen, 'onOpen': onIpDirectoryUpdateOpen, 'onClose': onIpDirectoryUpdateClose } = useDisclosure()
  const { 'isOpen': isIpDirectoryDeleteOpen, 'onOpen': onIpDirectoryDeleteOpen, 'onClose': onIpDirectoryDeleteClose } = useDisclosure()
  const _QueryIpDirectoryRead = useQuery(IpDirectoryReadQuery, { 'variables': { ipDirectoryToSearch, 'skip': skipPage * skipDifference, 'take': skipDifference }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })
  const _isFirstLoadCompleted = React.useRef(false)
  const _onIpDirectoryToSearchInputChange = React.useCallback(Debounce(e => setIpDirectoryToSearch(e.target.value), 800), [])
  const _tableHeaderHeightRef = React.useRef(0)
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })

  // Data loading check.
  if (!_QueryIpDirectoryRead.loading && 0 < _QueryIpDirectoryRead.data?.IpDirectory?.length && !_isFirstLoadCompleted.current) _isFirstLoadCompleted.current = true
  if (!_.every(_.pluck(_QueryIpDirectoryRead.data?.IpDirectoryRead, 'status'), i => _successFlags.includes(i))) _isFirstLoadCompleted.current = false

  // Return component.
  return (
    <>
      <Flex className='ipDirectory base'>
        <Flex
          display='flex'
          flex={1}
          flexDirection='column'
          gap={_isCurrentViewMobile ? '12px' : '22px'}
          bg='white'
          height='100%'
          borderRadius='20px'
          p={_isCurrentViewMobile ? '12px' : '22px'}>
          <Flex pb='0px' justify='space-between'>
            <Flex gap='12px'>
              <Tooltip label='Create New Folder' fontSize='sm'>
                <Button
                  leftIcon={<IoMdAdd />}
                  onClick={onIpDirectoryCreateOpen}
                  style={buttonStyle}>
                  Create New
                </Button>
              </Tooltip>
            </Flex>
            <DownloadToExcel
              cellsData={0 === _QueryIpDirectoryRead?.data?.IpDirectoryRead?.length || !_.every(_.pluck(_QueryIpDirectoryRead?.data?.IpDirectoryRead, 'status'), i => _successFlags.includes(i)) ? [] : _QueryIpDirectoryRead?.data?.IpDirectoryRead.map((item, __index) => ({
                'S.No.': `${(__index + (skipDifference * (skipPage))) + 1}.`,
                'CreatedAt': Moment(item?.createdAt).format('YYYY-MM-DD'),
                'UpdatedAt': Moment(item?.updatedAt).format('YYYY-MM-DD'),
                'Display Name': item.displayName ?? '-',
                'Total Ips': item?._count?.Ip ?? 0
              }))}
              headersData={[
                'S.No.',
                'CreatedAt',
                'UpdatedAt',
                'Display Name',
                'Total Ips'
              ].map(i => ({ 'key': i, 'label': i }))} />
          </Flex>
          <TableContainer
            flex={1}
            display='flex'
            borderRadius='15px'
            outline='1px solid #C5CFE8'>
            <Table variant='simple' size='sm'>
              <Thead ref={_tableHeaderHeightRef}>
                <Tr style={{ ...headerStyle, 'position': 'sticky', 'top': 0, 'zIndex': 1 }}>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    S.No
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>Display Name</Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>Total Ips</Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>Created At</Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>Updated At</Td>
                  <Td
                    style={rowStyle}>Actions</Td>
                </Tr>
                <Tr >
                  <Td borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>
                    <MemoizedInput
                      placeholder='Search'
                      className='filter searchInput'
                      defaultValue={ipDirectoryToSearch}
                      onChange={_onIpDirectoryToSearchInputChange}
                    />
                  </Td>
                  <Td borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td></Td>
                </Tr>
              </Thead>
              {_QueryIpDirectoryRead.loading && !_isFirstLoadCompleted.current ? (
                <TableSpinner isLoading={true} chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight} />
              ) : !_isFirstLoadCompleted.current && (0 === _QueryIpDirectoryRead.data?.IpDirectoryRead?.length || !_.every(_.pluck(_QueryIpDirectoryRead.data?.IpDirectoryRead, 'status'), i => _successFlags.includes(i))) ? (
                <TableSpinner isLoading={false} isEmpty={true} chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight} />
              ) : (
                <Tbody style={cellStyle}>
                  {_QueryIpDirectoryRead.data?.IpDirectoryRead?.map(({ id, createdAt, updatedAt, displayName, _count }, __index) => (
                    <Tr key={String.random(9)}>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {`${(__index + (skipDifference * (skipPage))) + 1}.`}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {displayName ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {_count?.Ip ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {Moment(createdAt).format('YYYY-MM-DD')}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {Moment(updatedAt).format('YYYY-MM-DD')}
                      </Td>
                      <Td
                        style={rowStyle}>
                        {' '}
                        {
                          <IpDirectoryDropDownOptions
                            ipDirectoryId={id}
                            onIpDirectoryUpdate={onIpDirectoryUpdateOpen}
                            onIpDirectoryDelete={onIpDirectoryDeleteOpen}
                          />
                        }
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              )
              }
            </Table>
          </TableContainer>
        </Flex>
        <Modal
          size='md'
          title='Ip Directory Create'
          isOpen={isIpDirectoryCreateOpen}
          onClose={onIpDirectoryCreateClose}>
          <IpDirectoryUpsert isCreateOnly={true} />
        </Modal>
        <Modal
          size='md'
          title='Ip Directory Update'
          isOpen={isIpDirectoryUpdateOpen}
          onClose={onIpDirectoryUpdateClose}>
          <IpDirectoryUpsert />
        </Modal>
        <Modal
          size='md'
          title='Ip Directory Delete'
          isOpen={isIpDirectoryDeleteOpen}
          onClose={onIpDirectoryDeleteClose}>
          <IpDirectoryDelete />
        </Modal>
      </Flex>
      <PaginationWithPageInformation
        skipPage={skipPage}
        skipDifference={_skipDifference}
        setSkipDifference={setSkipDifference}
        itemsPerPage={_skipDifference}
        totalCount={_.pluck(_QueryIpDirectoryRead.data?.IpDirectory, '_totalCount')?.[0]}
        onPageChange={setSkipPage}
      />
    </>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {}


/*
 * EXPORT
 */
export default Index
