/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation CustomerAccountCreateMutation(
    $customerId: ID!
    $displayName: String!,
    $enableLossProtection: Boolean
) {
  CustomerAccountCreate(
    customerId: $customerId
    displayName: $displayName,
    enableLossProtection: $enableLossProtection
  ) {
    id,
    message
    status
  }
}
`
