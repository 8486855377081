/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import JoiBrowser from 'joi-browser' // Npm: Joi for frontend validation.
import _ from 'underscore' // Npm: Underscore.js library.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useLazyQuery, useMutation } from '@apollo/client' // Npm: Apollo client.
import { HiMinusCircle, HiPlusCircle } from 'react-icons/hi' // Npm: React icons.
import { HiBolt } from 'react-icons/hi2' // Npm: React icons.
import {
  Button,
  Checkbox,
  Flex,
  Text,
  useBreakpointValue
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import SubmitButton from 'components/SubmitButton'
import MccMncSelector from 'components/MccMncSelector'
import VendorAccountSelector from 'components/VendorAccountSelector'
import SenderIdsDirectorySelector from 'components/SenderIdsDirectorySelector'
import PhoneNumberoDirectorySelector from 'components/PhoneNumberoDirectorySelector'
import VendorAccounttDirectorySelector from 'components/VendorAccounttDirectorySelector'
import { MemoizedInput, MemoizedSelect } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import RoutePlanReadQuery from './__query__/index.routePlan.read.query'
import RoutePlanUpdateMutation from './__mutation__/index.routePlan.update.mutation'
import RoutePlanCreateMutation from './__mutation__/index.routePlan.create.mutation'
import VendorAccountAttachRoutePlanMutation from './__mutation__/index.vendorAccount.attachRoutePlan.mutation'


/*
 * OBJECTS
 */
const Index = ({
  isOpen,
  isCreateOnly,
  onClose,
  passOn
}) => {
  // Hook assignment.
  const [vendorAccount, setVendorAccount] = React.useState(void 0)
  const [isLoadBalancingOn, setIsLoadBalancingOn] = React.useState(false)
  const [isLeastCostRouteOn, setIsLeastCostRouteOn] = React.useState(false)
  const [forms, setForms] = React.useState([1])
  const [MutationVendorAccountAttachRoutePlan, MutationVendorAccountAttachRoutePlanResponse] = useMutation(VendorAccountAttachRoutePlanMutation)
  const [error, setError] = React.useState('')
  const [forceReRender, setForceReRender] = React.useState('')
  const [QueryRoutePlanRead, QueryRoutePlanReadResponse] = useLazyQuery(RoutePlanReadQuery, { 'variables': { 'routePlanId': passOn?.routePlanId } })
  const [MutationRoutePlanCreate, MutationRoutePlanCreateResponse] = useMutation(RoutePlanCreateMutation)
  const [MutationRoutePlanUpdate, MutationRoutePlanUpdateResponse] = useMutation(RoutePlanUpdateMutation)
  const _formDataRef = React.useRef({})
  const _loadBalancingRef = React.useRef({})
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })

  // Object assignment.
  const _AttachVendorAccountToRoutePlan = async __id => {
    // Only Execute if route has data.
    if (!_.isEmpty(vendorAccount) && vendorAccount.includes('(') && vendorAccount.includes(')')) {
      // Const assignment.
      const vendorAccountId = vendorAccount.split(' ')[1].replace('(', '').replace(')', '')

      // Execute mutation.
      const _MutationVendorAccountAttachRoutePlan = await MutationVendorAccountAttachRoutePlan({
        'variables': {
          'routePlanId': __id,
          'vendorAccountId': vendorAccountId
        }
      })

      // If mutation caught an exception then report failure.
      if (_MutationVendorAccountAttachRoutePlan instanceof Error) return _MutationVendorAccountAttachRoutePlan

      // Style Guide.
      toast(_.every(_.pluck(_MutationVendorAccountAttachRoutePlan?.data?.VendorAccountAttachRoutePlan, 'status'), i => 'UPDATE_SUCCESSFUL' === i) ? 'Successfully attached the vendor with routePlan' : 'Something went wrong. Please try again after some time.')

      // On successful bind close modal.
      if (_.every(_.pluck(_MutationVendorAccountAttachRoutePlan?.data?.VendorAccountAttachRoutePlan, 'status'), i => 'UPDATE_SUCCESSFUL' === i)) onClose()
    }

    // Report void 0.
    return void 0
  }
  const _SubmitForm = async e => {
    // Local variable.
    let _routePlanIds

    // Const assignment.
    const _loadBalancerFilteredData = []

    // Prevent default behavior.
    e.preventDefault()

    // Reset error.
    setError('')

    /*
     * If _loadBalancingRef is not empty then
     * validate it first.
     */
    if (isLoadBalancingOn) {
      // Local variable.
      let _loadCounter

      // Variable assignment.
      _loadCounter = 0

      // Const assignment.
      const _alreadyPushedData = []

      // If form is empty then report failure.
      if (_.isEmpty(_loadBalancingRef.current)) return setError('LoadBalancing: Please fill out the load balancing form.')

      // Loop over _loadBalancing and report failure if any key is empty.
      for (const [key, value] of Object.entries(_loadBalancingRef.current)) {
        // Const assignment.
        const _keySplit = key.split?.('__')

        // If key is empty then report failure.
        if (key.includes('vendorAccountId') && !_.isNumber(_loadBalancingRef?.current?.[`percentage__${_keySplit?.[1]}`])) return setError(`LoadBalancing: Field percentage__${_keySplit?.[1]} at position ${_keySplit?.[1]} is empty.`)
        if (key.includes('percentage') && _.isEmpty(_loadBalancingRef?.current?.[`vendorAccountId__${_keySplit?.[1]}`])) return setError(`LoadBalancing: Field vendorAccountId__${_keySplit?.[1]} at position ${_keySplit?.[1]} is empty.`)
        if (key.includes('percentage') && 0 > value) return setError(`LoadBalancing: Field percentage__${_keySplit?.[1]} at position ${_keySplit?.[1]} should be greater than 0.`)

        // Increase load counter.
        if (key.includes('percentage')) _loadCounter += value

        // Only push data if it is not already pushed.
        if (!_alreadyPushedData.includes(_keySplit?.[1])) {
          // Push data to _loadBalancerFilteredData.
          _loadBalancerFilteredData.push({ 'vendorAccountId': _loadBalancingRef?.current?.[`vendorAccountId__${_keySplit?.[1]}`]?.split?.(' ')?.[1]?.replace?.('(', '').replace?.(')', ''), 'percentage': _loadBalancingRef?.current?.[`percentage__${_keySplit?.[1]}`], 'vendorDisplayName': _loadBalancingRef?.current?.[`vendorAccountId__${_keySplit?.[1]}`]?.split?.(' ')?.[0] })

          // Push data to _alreadyPushedData.
          _alreadyPushedData.push(_keySplit?.[1])
        }
      }

      // Report failure if load counter is not 100.
      if (100 !== _loadCounter) return setError('LoadBalancing: Sum of all percentages should be 100.')

      // Validate loadBalancing.
      const _JoiSchemaLoadBalancing = JoiBrowser.array().items(JoiBrowser.object({
        'vendorAccountId': JoiBrowser.string().required(),
        'percentage': JoiBrowser.number().required(),
        'vendorDisplayName': JoiBrowser.string().required()
      }))

      // Validate loadBalancing.
      const _JoiSchemaLoadBalancingValidate = _JoiSchemaLoadBalancing.validate(_loadBalancerFilteredData)

      // If error exists then report failure.
      if (_JoiSchemaLoadBalancingValidate.error) return setError(`LoadBalancing: ${_JoiSchemaLoadBalancingValidate.error?.message}`)
    }

    // Const assignment.
    const _JoiSchema = JoiBrowser.object({
      'vendorAccount': JoiBrowser.string(),
      'routeToVendorAccount': JoiBrowser.string(),
      'priority': JoiBrowser.number().greater(0).less(4),
      'smsType': ['RESELLER', 'RETAIL'].includes(passOn.type) ? JoiBrowser.string().optional() : JoiBrowser.string().optional(),
      'mcc': JoiBrowser.string().required(),
      'mnc': JoiBrowser.array().items(JoiBrowser.string().required()).required(),
      'senderIdDirectoryId': JoiBrowser.string(),
      'senderId': JoiBrowser.array().items(JoiBrowser.string()),
      'phoneNumberoDirectory': JoiBrowser.string(),
      'phoneNumbero': JoiBrowser.array().items(JoiBrowser.string()),
      'fakeDlrRate': JoiBrowser.number()
    }).options({ 'allowUnknown': true })

    // Make sure that mnc is array.
    if (!isCreateOnly && !_.isArray(_formDataRef.current?.mnc)) _formDataRef.current = { ..._formDataRef.current, 'mnc': [(_formDataRef.current?.mnc)?.split('(')[1]?.split(')')[0]] }

    /*
     * Report failure if leastCostRoute is off and vendorAccount
     * priority is not set.
     */
    if (!isLeastCostRouteOn && _.isEmpty(_formDataRef.current?.vendorAccount)) return setError('vendorAccount is required if you are not using Least Cost Routing.')
    if (!isLeastCostRouteOn && (0 >= _formDataRef.current?.priority || isNaN(_formDataRef.current?.priority))) return setError('priority is required if you are not using Least Cost Routing.')

    // Remove all keys from _formDataRef.current which are undefined.
    _formDataRef.current = { ..._.pick(_formDataRef.current, _.identity), 'fakeDlrRate': _formDataRef.current?.fakeDlrRate ?? 0, 'loadBalancerConfiguration': _formDataRef.current?.loadBalancerConfiguration ?? [] }

    // Validate form data.
    const _JoiSchemaValidate = _JoiSchema.validate(_formDataRef.current)

    // If error exists then report failure.
    if (_JoiSchemaValidate.error) return setError(_JoiSchemaValidate.error?.message)
    if (_formDataRef.current.senderId && 1 <= _formDataRef.current.senderId.length && _.isEmpty(_formDataRef.current.senderIdVendorAccountId)) return setError('senderIdVendorAccountId is required if you are adding SenderId based routing.')
    if (_formDataRef.current.phoneNumbero && 1 <= _formDataRef.current.phoneNumbero.length && _.isEmpty(_formDataRef.current.phoneNumberoVendorAccountId)) return setError('phoneNumberoVendorAccountId is required if you are adding PhoneNumbero based routing.')

    /*
     * Update _formDataRef with routePlanId
     * if it doesn't exist and also make sure that mode
     * is not create only.
     */
    if (!isCreateOnly && _.isEmpty(_formDataRef.current?.routePlanId)) _formDataRef.current = { ..._formDataRef.current, 'routePlanId': passOn?.routePlanId }
    if (isCreateOnly && _.isEmpty(_formDataRef.current?.routeId)) _formDataRef.current = { ..._formDataRef.current, 'routeId': passOn?.routeId }

    /*
     * Update _formDataRef with input and routePlan
     * if it doesn't exist. Also make sure that mode is
     * not create only.
     */
    if (_.isEmpty(_formDataRef.current?.input)) _formDataRef.current = ({ 'input': [_formDataRef.current], ..._formDataRef.current })

    // Execute update mutation.
    const _MutationRoutePlanUpdate = await [isCreateOnly ? MutationRoutePlanCreate : MutationRoutePlanUpdate]?.[0]({
      'variables': {
        'routePlanId': _.isEmpty(passOn?.routePlanIds) ? isCreateOnly ? '' : passOn?.routePlanId : void 0,
        'routePlanIds': _.isEmpty(passOn?.routePlanIds) ? void 0 : isCreateOnly ? '' : passOn?.routePlanIds,
        'input': _.pick(_formDataRef.current, 'input').input.map(j => {
          // Const assignment.
          let _mcc, _mnc, _phoneNumbero, _phoneNumberoDirectory, _phoneNumberoVendorAccountId, _senderIdDirectoryId, _senderIdVendorAccountId, _senderIds, _vendorAccountt, _vendorAccounttDirectory

          // Variable assignment.
          _phoneNumbero = j?.phoneNumbero
          _phoneNumberoDirectory = j?.phoneNumberoDirectory
          _senderIdDirectoryId = j?.senderIdDirectoryId
          _senderIds = j?.senderId
          _senderIdVendorAccountId = j?.senderIdVendorAccountId
          _phoneNumberoVendorAccountId = j?.phoneNumberoVendorAccountId
          _vendorAccountt = j?.vendorAccountt
          _vendorAccounttDirectory = j?.vendorAccounttDirectory
          _mcc = j?.mcc
          _mnc = j?.mnc

          // Conditionally update senderId and senderIdDirectoryId.
          if (j?.senderIdDirectoryId && (j?.senderIdDirectoryId?.includes('(') || j?.senderIdDirectoryId?.includes(')'))) _senderIdDirectoryId = j?.senderIdDirectoryId.split('(')[1].split(')')[0]
          if (j?.senderId && 0 < j?.senderId?.length) _senderIds = _.compact(_.flatten(j?.senderId.map(r => r?.includes('(') && r?.includes(')') ? r.split('(')[1].split(')')[0] : r)))
          if (j?.phoneNumberoDirectory && (j?.phoneNumberoDirectory?.includes('(') || j?.phoneNumberoDirectory?.includes(')'))) _phoneNumberoDirectory = j?.phoneNumberoDirectory.split('(')[1].split(')')[0]
          if (j?.phoneNumbero && 0 < j?.phoneNumbero?.length) _phoneNumbero = _.compact(_.flatten(j?.phoneNumbero.map(r => r?.includes('(') && r?.includes(')') ? r.split('(')[1].split(')')[0] : r)))
          if (j?.senderIdVendorAccountId && (j?.senderIdVendorAccountId?.includes('(') || j?.senderIdVendorAccountId?.includes(')'))) _senderIdVendorAccountId = j?.senderIdVendorAccountId.split('(')[1].split(')')[0]
          if (j?.phoneNumberoVendorAccountId && (j?.phoneNumberoVendorAccountId?.includes('(') || j?.phoneNumberoVendorAccountId?.includes(')'))) _phoneNumberoVendorAccountId = j?.phoneNumberoVendorAccountId.split('(')[1].split(')')[0]
          if (j?.vendorAccountt && 0 < j?.vendorAccountt?.length) _vendorAccountt = _.compact(_.flatten(j?.vendorAccountt.map(r => r?.includes('(') && r?.includes(')') ? r.split('(')[1].split(')')[0] : r)))
          if (j?.vendorAccounttDirectory && (j?.vendorAccounttDirectory?.includes('(') || j?.vendorAccounttDirectory?.includes(')'))) _vendorAccounttDirectory = j?.vendorAccounttDirectory.split('(')[1].split(')')[0]
          if (j?.mcc && (j?.mcc?.includes('(') || j?.mcc?.includes(')'))) _mcc = j.mcc.split('(')[1].split(')')[0]
          if (j?.mnc && 0 < j?.mnc?.length) _mnc = _.compact(_.flatten(j?.mnc.map(r => r?.includes('(') && r?.includes(')') ? r.split('(')[1].split(')')[0] : r)))

          // Return data.
          return ({
            'fakeDlrRate': j?.fakeDlrRate,
            'mcc': _mcc,
            'mnc': _mnc,
            'priority': j?.priority,
            'smsType': j?.smsType,
            'loadBalancerConfiguration': isLoadBalancingOn && !isLeastCostRouteOn ? _loadBalancerFilteredData : [],
            'senderIdDirectoryId': _senderIdDirectoryId,
            'senderId': _senderIds,
            'phoneNumberoDirectoryId': _phoneNumberoDirectory,
            'phoneNumbero': _phoneNumbero,
            'senderIdVendorAccountId': _.isEmpty(_senderIds) ? void 0 : _senderIdVendorAccountId,
            'phoneNumberoVendorAccountId': _.isEmpty(_phoneNumbero) ? void 0 : _phoneNumberoVendorAccountId,
            'vendorAccountt': isLeastCostRouteOn && !isLoadBalancingOn ? _vendorAccountt : void 0,
            'vendorAccounttDirectory': isLeastCostRouteOn && !isLoadBalancingOn ? _vendorAccounttDirectory : void 0,
            ...(isCreateOnly ? { 'routeId': passOn?.routeId } : {})
          })
        })
      }
    })

    // If mutation caught an exception then report failure.
    if (_MutationRoutePlanUpdate instanceof Error) return _MutationRoutePlanUpdate

    // If Update contains data then update routePlanIds.
    if (!_.isEmpty(_MutationRoutePlanUpdate?.data?.RoutePlanUpdate)) _routePlanIds = _.compact(_.pluck(_MutationRoutePlanUpdate?.data?.RoutePlanUpdate, 'id'))
    if (!_.isEmpty(_MutationRoutePlanUpdate?.data?.RoutePlanCreate)) _routePlanIds = _.compact(_.pluck(_MutationRoutePlanUpdate?.data?.RoutePlanCreate, 'id'))

    // Attach vendor account and route to customer.
    !_.isEmpty(_routePlanIds) && _.isArray(_routePlanIds) && !isLeastCostRouteOn && await _AttachVendorAccountToRoutePlan(_routePlanIds)

    // Check if create was successful.
    if (!_.isEmpty(_MutationRoutePlanUpdate?.data?.RoutePlanCreate) && _.every(_.pluck(_MutationRoutePlanUpdate?.data?.RoutePlanCreate, 'status') ?? [], j => 'CREATE_SUCCESSFUL' === j)) {
      // Style guide.
      toast('Successfully upserted the route plan.')

      // On successful create close modal.
      return onClose?.()
    }
    if (!_.isEmpty(_MutationRoutePlanUpdate?.data?.RoutePlanUpdate) && _.every(_.pluck(_MutationRoutePlanUpdate?.data?.RoutePlanUpdate, 'status') ?? [], j => 'UPDATE_SUCCESSFUL' === j)) {
      // Style guide.
      toast('Successfully upserted the route plan.')

      // Report failure.
      return onClose?.()
    }

    // Style Guide.
    toast((_.isEmpty(_MutationRoutePlanUpdate?.data?.RoutePlanCreate) ? [0 === _.pluck(_MutationRoutePlanUpdate?.data?.RoutePlanUpdate, 'message')?.length ? 'Same plan already exists. kindly delete it or modify it instead.' : _.pluck(_MutationRoutePlanUpdate?.data?.RoutePlanUpdate, 'message')]?.join(' ') : _.pluck(_MutationRoutePlanUpdate?.data?.RoutePlanCreate, 'message')?.join(' ')))

    // Report void.
    return void 0
  }

  // Event handler.
  React.useEffect(() => {
    // _Async handler.
    const _Async = async () => {
      // Const assignment.
      const _QueryRoutePlanReadQuery = await QueryRoutePlanRead({ 'variables': { 'routePlanId': isCreateOnly ? 'UN_KNOWN' : passOn?.routePlanId } })

      // If query caught an exception then report failure.
      if (_QueryRoutePlanReadQuery instanceof Error) return _QueryRoutePlanReadQuery

      /*
       * If routePlan details fetch complete then
       * update its value.
       */
      if (_QueryRoutePlanReadQuery?.data?.RoutePlanRead) {
        // Const assignment.
        const _routePlanRead = _.first(_QueryRoutePlanReadQuery?.data?.RoutePlanRead)

        // Update form data.
        _formDataRef.current = {
          'routePlanId': passOn?.routePlanId,
          'createdAt': _routePlanRead?.createdAt,
          'updatedAt': _routePlanRead?.updatedAt,
          'mcc': _routePlanRead?.Mcc?.mcc ? `${_routePlanRead?.Mcc?.countryName} (${_routePlanRead?.Mcc?.mcc})` : void 0,
          'mnc': _routePlanRead?.Mnc?.mnc ? `(${_routePlanRead?.Mnc?.mnc}) ${_routePlanRead?.Mnc?.network}` : void 0,
          'senderId': _.isEmpty(_routePlanRead?.SenderId) ? void 0 : _routePlanRead?.SenderId?.map?.(j => `${j?.senderId} (${j?.id})`),
          'senderIdDirectoryId': _routePlanRead?.SenderIdDirectory?.displayName ? `${_routePlanRead?.SenderIdDirectory?.displayName} (${_routePlanRead?.SenderIdDirectory?.id})` : void 0,
          'phoneNumbero': _.isEmpty(_routePlanRead?.PhoneNumbero) ? void 0 : _routePlanRead?.PhoneNumbero?.map?.(j => `${j?.phoneNumbero} (${j?.id})`),
          'phoneNumberoDirectory': _routePlanRead?.PhoneNumberoDirectory?.displayName ? `${_routePlanRead?.PhoneNumberoDirectory?.displayName} (${_routePlanRead?.PhoneNumberoDirectory?.id})` : void 0,
          'countryName': _routePlanRead?.Mcc?.countryName,
          'senderIdVendorAccountId': _routePlanRead?.SenderIdVendorAccount?.displayName ? `${_routePlanRead?.SenderIdVendorAccount?.displayName} (${_routePlanRead?.SenderIdVendorAccount?.id})` : void 0,
          'phoneNumberoVendorAccountId': _routePlanRead?.PhoneNumberoVendorAccount?.displayName ? `${_routePlanRead?.PhoneNumberoVendorAccount?.displayName} (${_routePlanRead?.PhoneNumberoVendorAccount?.id})` : void 0,
          'priority': _routePlanRead?.priority,
          'isPaused': _routePlanRead?.isPaused,
          'smsType': _routePlanRead?.smsType,
          'fakeDlrRate': _routePlanRead?.fakeDlrRate,
          'vendorAccount': vendorAccount ?? 0 < _QueryRoutePlanReadQuery?.data?.RoutePlanRead?.length ? _routePlanRead?.VendorAccount?.displayName ? `${_routePlanRead?.VendorAccount?.displayName} (${_routePlanRead?.VendorAccount?.id})` : void 0 : void 0,
          'vendorAccounttDirectory': _routePlanRead?.VendorAccounttDirectory?.displayName ? `${_routePlanRead?.VendorAccounttDirectory?.displayName} (${_routePlanRead?.VendorAccounttDirectory?.id})` : void 0,
          'vendorAccountt': _.isEmpty(_routePlanRead?.VendorAccountt) ? void 0 : _routePlanRead?.VendorAccountt?.map?.(j => j?.displayName ? `${j?.displayName} (${j?.id})` : void 0)
        }

        /*
         * If given routePlan contains loadBalancerConfiguration
         * then turn own load balancing.
         */
        if (_routePlanRead?.loadBalancerConfiguration) {
          // Update forms.
          if (0 < _routePlanRead?.loadBalancerConfiguration?.length) {
            // Update loadBalancing.
            setIsLoadBalancingOn(true)

            /*
             * Loop over jam and push each key to _loadBalancingRef.current
             * object.
             */
            _routePlanRead?.loadBalancerConfiguration.map((i, j) => {
              // Update loadBalancingRef.
              _loadBalancingRef.current[`vendorAccountId__${j}`] = i?.vendorAccountId && i?.vendorDisplayName ? `${i?.vendorDisplayName} (${i?.vendorAccountId})` : void 0
              _loadBalancingRef.current[`percentage__${j}`] = i?.percentage

              // Return data.
              return ({
                [`vendorAccountId__${j}`]: i?.vendorAccountId && i?.vendorDisplayName ? `${i?.vendorDisplayName} (${i?.vendorAccountId})` : void 0,
                [`percentage__${j}`]: i?.percentage
              })
            })

            setForms(Array.from({ 'length': _routePlanRead?.loadBalancerConfiguration?.length }))
          } else {
            // Update forms.
            setForms([1])
            setIsLoadBalancingOn(false)
          }
        }

        // If least cost routing is on then update form data.
        if (_routePlanRead?.VendorAccounttDirectory) setIsLeastCostRouteOn(true)

        // Update state.
        return setForceReRender(String.random(13))
      }

      // Report failure.
      return void 0
    }; _Async()
  }, [passOn, isOpen])

  // Const assignment.
  const _isLoading = MutationVendorAccountAttachRoutePlanResponse.loading || MutationRoutePlanUpdateResponse.loading || MutationRoutePlanCreateResponse.loading
  const _isInputDisabled = isCreateOnly ? false : _isLoading || QueryRoutePlanReadResponse.loading

  // Return component.
  return (
    <form onSubmit={_SubmitForm} className='routePlanUpsert'>
      <Flex gap='22px' flexDir='column' wrap='nowrap' w='100%'>
        {
          isLeastCostRouteOn ? void 0 : (
            <Flex width='100%' gap='18px' direction={{ 'base': 'column', 'md': 'row' }}>
              <VendorAccountSelector
                disabled={_isInputDisabled}
                name='vendorAccount'
                label='Vendor Account'
                placeholder='e.g. "Awesome Vendor Account"'
                value={_formDataRef?.current?.vendorAccount}
                isRequired={true}
                error={error}
                height='40px'
                isInvalid={error?.includes('vendorAccount') && !error?.includes('position')}
                onChange={({ target }) => {
                  // Over spreading.
                  const { name, value } = target

                  // Update form data.
                  _formDataRef.current = {
                    ..._formDataRef?.current,
                    [name]: value
                  }

                  // Update vendorAccount.
                  setVendorAccount(value)
                }}
              />
              <MemoizedSelect
                disabled={_isInputDisabled}
                name='priority'
                label='Priority'
                placeholder='Enter Priority'
                onChange={({ target }) => {
                  // Over spreading.
                  const { name, value } = target

                  // Update form data.
                  _formDataRef.current = {
                    ..._formDataRef?.current,
                    [name]: isNaN(parseInt(value, 10)) ? 0 : parseInt(value, 10)
                  }
                }}
                error={error}
                isInvalid={error?.includes('priority')}
                value={_formDataRef?.current?.priority}
                options={[1, 2, 3]}
                isRequired
              />
              {['RESELLER', 'RETAIL'].includes(passOn.type) ? (
                <MemoizedSelect
                  disabled={_isInputDisabled}
                  name='smsType'
                  label='SMS Type'
                  placeholder='Enter SMS Type'
                  onChange={({ target }) => {
                    // Over spreading.
                    const { name, value } = target

                    // Update form data.
                    _formDataRef.current = {
                      ..._formDataRef?.current,
                      [name]: value
                    }
                  }}
                  error={error}
                  isInvalid={error?.includes('smsType')}
                  data={_formDataRef?.current?.smsType}
                  options={Object.React.App.enums.SMS_TYPE.enums?.map(i => i.key)}
                />
              ) : void 0}
            </Flex>
          )
        }
        <Flex width='100%' gap='22px' direction={{ 'base': 'column', 'md': 'row' }}>
          <MccMncSelector
            isRequired={true}
            disabled={isLeastCostRouteOn || _isInputDisabled}
            mccValue={_formDataRef?.current?.mcc}
            mncValue={_formDataRef?.current?.mnc}
            inValidMnc={error?.includes('mnc')}
            inValidMcc={error?.includes('mcc')}
            isMccAndMncGlobal={isLeastCostRouteOn}
            onChange={i => {
              // Update form data.
              _formDataRef.current = ({ ..._formDataRef.current, 'mnc': i.mnc, 'mcc': i.mcc })

              // Force re-render.
              setForceReRender(String.random(5))
            }}
          />
          <SenderIdsDirectorySelector
            disabled={_isInputDisabled}
            senderIdDirectoryValue={_formDataRef?.current?.senderIdDirectoryId ?? ''}
            senderIdValue={_formDataRef?.current?.senderId}
            inValidSenderId={error?.includes('senderId')}
            inValidSenderIdDirectory={error?.includes('senderIdDirectoryId')}
            onChange={i => {
              // Update form data.
              _formDataRef.current = ({ ..._formDataRef.current, 'senderId': i.senderId, 'senderIdDirectoryId': i.senderIdDirectory })

              // Force re-render.
              setForceReRender(String.random(6))
            }}
          />
          <PhoneNumberoDirectorySelector
            disabled={_isInputDisabled}
            phoneNumberoDirectoryValue={_formDataRef?.current?.phoneNumberoDirectory}
            phoneNumberoValue={_formDataRef?.current?.phoneNumbero}
            inValidPhoneNumbero={error?.includes('phoneNumbero')}
            inValidPhoneNumberoDirectory={error?.includes('phoneNumberoDirectory')}
            onChange={i => {
              // Update form data.
              _formDataRef.current = ({ ..._formDataRef.current, 'phoneNumbero': i.phoneNumbero, 'phoneNumberoDirectory': i.phoneNumberoDirectory })

              // Force re-render.
              setForceReRender(String.random(14))
            }}
          />
        </Flex>
        <Flex flexDir='column' justifyContent='space-between' w='100%' zIndex={0} gap='22px' key={forceReRender}>
          {
            _.isEmpty(_formDataRef?.current?.senderId) ? void 0 : (
              <VendorAccountSelector
                disabled={_isInputDisabled}
                name='senderIdVendorAccountId'
                key={forceReRender}
                label='Sender Id based routing to which vendor account ?'
                placeholder='e.g. "Spam sender ids"'
                value={_formDataRef?.current?.senderIdVendorAccountId}
                isRequired={true}
                error={error}
                height='40px'
                isInvalid={error?.includes('senderIdVendorAccountId')}
                onChange={({ target }) => {
                  // Over spreading.
                  const { name, value } = target

                  // Update form data.
                  _formDataRef.current = {
                    ..._formDataRef?.current,
                    [name]: value
                  }

                  // Force re-render.
                  setForceReRender(String.random(8))
                }}
              />
            )
          }
          {
            _.isEmpty(_formDataRef?.current?.phoneNumbero) ? void 0 : (
              <VendorAccountSelector
                disabled={_isInputDisabled}
                name='phoneNumberoVendorAccountId'
                label='Phone Number based routing to which vendor account ?'
                placeholder='e.g. "Test phone numbers"'
                data={_formDataRef?.current?.phoneNumberoVendorAccountId}
                isRequired={true}
                error={error}
                height='40px'
                isInvalid={error?.includes('phoneNumberoVendorAccountId')}
                onChange={({ target }) => {
                  // Over spreading.
                  const { name, value } = target

                  // Update form data.
                  _formDataRef.current = {
                    ..._formDataRef?.current,
                    [name]: value
                  }
                }}
              />
            )
          }
          <MemoizedSelect
            key={forceReRender}
            disabled={_isInputDisabled}
            name='fakeDlrRate'
            label='Cutting Rate (in Percentage)'
            placeholder='e.g. "10 is 10%"'
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: isNaN(parseFloat(value, 10)) ? 0 : parseFloat(value, 10)
              }
            }}
            error={error}
            isInvalid={error?.includes('fakeDlrRate')}
            data={_formDataRef?.current?.fakeDlrRate}
            options={[
              0,
              10,
              20,
              30,
              40,
              50,
              60,
              70,
              80,
              90,
              100
            ]}
          />
        </Flex>        {
          isLoadBalancingOn && (
            <Flex boxShadow='14px 17px 40px 4px rgba(112, 144, 176, 0.2)' bg='gray.100' border='1px solid' borderColor='brand.500' p='22px' borderRadius={16} flexDir='column' gap='12px'>
              <Flex flexDir='row' alignItems='center' gap='6px' color='brand.500'><HiBolt /><Text fontSize='lg' fontWeight={700} color='brand.500'>Load Balancing</Text></Flex>
              <Text fontSize='md' my='12px' w='80%'>Load Balancing will be used instead of main plan <Text as='span' color='brand.500'>Vendor Account</Text> and all failing <Text as='span' color='brand.500'>Vendor Accounts</Text> will fallback to the main plan&apos;s <Text as='span' color='brand.500'>Vendor Account</Text>.</Text>
              {
                forms?.map((___, __index) => (
                  <Flex flexDir={_isCurrentViewMobile ? 'column' : 'row'} alignItems='flex-end' gap='12px'
                    key={String.random(18)} my='6px' mb={__index === (forms?.length - 1) ? 0 : '6px'}>
                    <Flex w='100%' gap='12px' flexDir={_isCurrentViewMobile ? 'column' : 'row'}>
                      <VendorAccountSelector
                        name={`vendorAccountId__${__index}`}
                        label='Vendor Account'
                        placeholder='e.g. "Awesome Vendor Account"'
                        value={_loadBalancingRef?.current[`vendorAccountId__${__index}`]}
                        isRequired={true}
                        error={error}
                        height='0px'
                        bg='gray.300'
                        disabled={_isInputDisabled}
                        isInvalid={error?.includes(`position ${__index}`) && error?.includes(`vendorAccountId__${__index}`)}
                        onChange={({ target }) => {
                          // Over spreading.
                          const { value } = target

                          // Update _loadBalancingRef.:
                          _loadBalancingRef.current = {
                            ..._loadBalancingRef.current,
                            [`vendorAccountId__${__index}`]: value
                          }

                          // Force re-render.
                          setForceReRender(String.random(10))
                        }}
                      />
                      <MemoizedInput
                        w='100%'
                        disabled={_isInputDisabled}
                        name={`percentage__${__index}`}
                        label='Load ( in Percentage )'
                        placeholder='e.g. "10 is 10%"'
                        type='number'
                        bg='gray.300'
                        onChange={({ target }) => {
                          // Over spreading.
                          const { value } = target

                          // Update _loadBalancingRef.
                          _loadBalancingRef.current = {
                            ..._loadBalancingRef.current,
                            [`percentage__${__index}`]: isNaN(parseFloat(value, 10)) ? 0 : parseFloat(value, 10)
                          }
                        }}
                        isInvalid={(error?.includes(`position ${__index}`) && error?.includes('percentage'))}
                        data={_loadBalancingRef.current[`percentage__${__index}`]}
                      />
                    </Flex>
                    <Button
                      disabled={_isInputDisabled}
                      variant='basic'
                      type='submit'
                      h='40px'
                      minH='40px'
                      position='relative'
                      bg={__index === (forms.length - 1) ? 'brand.400' : 'red.400'}
                      onClick={() => {
                        // If current button is remove button.
                        if (__index !== (forms.length - 1)) {
                          // If given field already exists then remove it.
                          if (Object.keys(_loadBalancingRef.current).includes(`vendorAccountId__${__index}`)) {
                            // Remove object from _loadBalancerFilteredData.
                            delete _loadBalancingRef.current[`vendorAccountId__${__index}`]
                            delete _loadBalancingRef.current[`percentage__${__index}`]
                          }

                          // Update forms.
                          return setForms(forms?.filter((u, i) => i !== __index))
                        }

                        // Update forms.
                        return setForms([...forms, 1])
                      }}
                      _hover={{ 'bg': __index === (forms.length - 1) ? 'brand.400' : 'danger.400' }}
                      _active={{ 'bg': __index === (forms.length - 1) ? 'brand.400' : 'danger.400' }}
                      minW='50px'>
                      {__index === (forms.length - 1) ? <HiPlusCircle size={16} /> : <HiMinusCircle size={16} />}
                    </Button>
                  </Flex>
                ))
              }
              {isLoadBalancingOn && error && error?.includes('LoadBalancing:') && <Text color='red.500'>{error}</Text>}
            </Flex>
          )
        }
        {
          isLeastCostRouteOn && (
            <Flex boxShadow='14px 17px 40px 4px rgba(112, 144, 176, 0.2)' bg='gray.100' border='1px solid' borderColor='brand.500' p='22px' borderRadius={16} flexDir='column' gap='12px'>
              <Flex flexDir={_isCurrentViewMobile ? 'column' : 'row'} alignItems='center' gap='12px'>
                <Flex w='inherit' flexDir='column' gap='12px'>
                  <Flex flexDir='row' alignItems='center' gap='6px' color='brand.500'><HiBolt /><Text fontSize='lg' fontWeight={700} color='brand.400'>Least Cost Route</Text></Flex>
                  <Text fontSize='md' my='22px' w='80%'>Least Cost will be used instead of main plan <Text as='span' color='brand.400' fontWeight={700}>Vendor Account</Text> and all <Text as='span' color='brand.400' fontWeight={700}>Failing Vendor Accounts</Text> will fallback to 2nd, 3rd...etc in least cost sequence.</Text>
                </Flex>
                <VendorAccounttDirectorySelector
                  disabled={_isInputDisabled}
                  bg='gray.300'
                  isRequired={true}
                  vendorAccounttDirectoryValue={_formDataRef?.current?.vendorAccounttDirectory ?? ''}
                  vendorAccounttValue={_formDataRef?.current?.vendorAccountt}
                  inValidVendorAccountt={error?.includes('vendorAccountt')}
                  inValidVendorAccounttDirectory={error?.includes('vendorAccounttDirectory')}
                  onChange={i => {
                    // Update form data.
                    _formDataRef.current = ({ ..._formDataRef.current, 'vendorAccountt': i.vendorAccountt, 'vendorAccounttDirectory': _.isEmpty(i.vendorAccounttDirectory) ? void 0 : i.vendorAccounttDirectory })
                  }}
                />
              </Flex>
              {isLoadBalancingOn && error && error?.includes('LoadBalancing:') && <Text color='red.500'>{error}</Text>}
            </Flex>
          )
        }
      </Flex>
      <Flex
        mt='22px'
        alignItems={_isCurrentViewMobile ? 'flex-end' : 'center'}
        justifyContent='space-between'
        flexDir='row'>
        <Flex flexDir={_isCurrentViewMobile ? 'column' : 'row'} alignItems={_isCurrentViewMobile ? 'flex-start' : 'center'} gap='12px'>
          <Checkbox
            colorScheme='brand'
            color='gray.500'
            isChecked={isLoadBalancingOn && !isLeastCostRouteOn}
            name='isLoadBalancingCheck'
            disabled={_isLoading}
            onChange={({ target }) => { setIsLoadBalancingOn(target.checked); setIsLeastCostRouteOn(false) }}>
            <Text as='p' fontSize={_isCurrentViewMobile ? 'sm' : 'md'} fontWeight={500}>Load Balancer ?</Text>
          </Checkbox>
          <Checkbox
            colorScheme='brand'
            color='gray.500'
            isChecked={isLeastCostRouteOn && !isLoadBalancingOn}
            name='isLeastCostRouteCheck'
            disabled={_isLoading}
            onChange={({ target }) => { setIsLeastCostRouteOn(target.checked); setIsLoadBalancingOn(false) }}>
            <Text as='p' fontSize={_isCurrentViewMobile ? 'sm' : 'md'} fontWeight={500}>Least Cost Route ?</Text>
          </Checkbox>
        </Flex>
        <SubmitButton
          mt={0}
          disabled={_isInputDisabled}
          onSubmit={_SubmitForm}
          defaultText={isCreateOnly ? 'Create RoutePlan' : 'Update RoutePlan'}
          isLoading={_isLoading} />
      </Flex>
    </form>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'isCreateOnly': PropTypes.bool,
  'isOpen': PropTypes.bool,
  'onClose': PropTypes.func,
  'passOn': PropTypes.object,
  'submitRef': PropTypes.object,
  'disabled': PropTypes.bool,
  'isUpsertMixerContext': PropTypes.bool,
  'setSubmitIsLoading': PropTypes.func
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
