/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.

/*
 * EXPORTS
 */
export default gql`
mutation ContentTranslationCreateMutation(
  $customerAccountIds: [ID!],
  $vendorAccountIds: [ID!],
  $senderIdDirectoryId: ID,
  $senderId: [ID], 
  $keywordDirectoryId: ID, 
  $keywordId: [ID],
  $type: CUSTOMER_TYPE,
  $mcc: String,
  $mnc: [String!],
  $regex: String,
  $suffix: String,
  $prefix: String,
  $omitWords: [String!],
  $globalTranslationKeyword: String,
  $strategy: CONTENT_TRANSLATION_STRATEGY
) {
  ContentTranslationCreate(
    customerAccountIds: $customerAccountIds,
    type: $type,
    vendorAccountIds: $vendorAccountIds,
    regex: $regex,
    mcc: $mcc,
    mnc: $mnc,
    strategy: $strategy,
    suffix: $suffix,
    prefix: $prefix,
    omitWords: $omitWords,
    senderIdDirectoryId: $senderIdDirectoryId,
    senderId: $senderId, 
    keywordDirectoryId: $keywordDirectoryId, 
    keywordId: $keywordId,
    globalTranslationKeyword: $globalTranslationKeyword
  ) {
    message
    status
  }
}
`
