/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import { Flex, useBreakpointValue } from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import AccessTokenGenerator from 'components/AccessTokenGenerator'
import ApiCollectionAndTokens from 'components/ApiCollectionAndTokens'
import ApiDocumentation from 'components/ApiDocumentation'


/*
 * OBJECTS
 */
const Index = () => {
  // Hook assignment.
  const [forceReRender, setForceReRender] = React.useState(void 0)
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })

  // Return component.
  return (
    <>
      <Flex w='100%' height={_isCurrentViewMobile ? void 0 : '100%'} flexDir={{ 'base': 'column', 'md': 'row' }} gap='22px' >
        <Flex
          direction={{ 'base': 'column' }}
          display='flex'
          h='100%'
          w={{ 'base': '100%', 'md': '40%' }}
          gap='22px'>
          <AccessTokenGenerator key={forceReRender} />
          <ApiCollectionAndTokens onUpdate={() => setForceReRender(String.random(9))} />
        </Flex>
        {_isCurrentViewMobile ? void 0 : (<Flex borderRadius={28} w='7.5%' h='100%' bg='#FFF' />)}
        <Flex w={{ 'base': '100%', 'md': '50%' }} h='100%'>
          <ApiDocumentation />
        </Flex>
      </Flex>
    </>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {}


/*
 * EXPORT
 */
export default Index
