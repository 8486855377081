/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { HiPencil, HiTrash } from 'react-icons/hi2' // Npm: React icons.
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * OBJECTS
 */
const Index = ({
  keywordDirectoryId,
  keywordId,
  onKeywordUpdate,
  onKeywordDelete,
  PassOnUpdate
}) => (
  <Menu placement='left'>
    <MenuButton
      as={Button}
      margin='auto'
      borderRadius='8px'
      color='gray.500'
      bg='transparent'>
      <HiPencil size={18} />
    </MenuButton>
    <MenuList
      className='dropDownMenu'
      maxHeight='400px'
      overflow='auto'>
      <MenuItem
        gap='12px'
        display='flex'
        pl='20px'
        pr='20px'
        color='gray.500'
        onClick={() => {
          // Push keyword id to url.
          PassOnUpdate({ keywordDirectoryId, keywordId })

          // Execute handler.
          onKeywordUpdate()
        }}
        minH='40px'>
        <HiPencil size={18} />
        <span>Edit Keyword</span>
      </MenuItem>
      <MenuItem
        gap='12px'
        display='flex'
        pl='20px'
        pr='20px'
        color='gray.500'
        onClick={() => {
          // Push keyword id to url.
          PassOnUpdate({ keywordDirectoryId, keywordId })

          // Execute handler.
          onKeywordDelete()
        }}
        minH='40px'>
        <HiTrash />
        <span>Delete Keyword</span>
      </MenuItem>
    </MenuList>
  </Menu>
)


/*
 * PROPTYPES
 */
Index.propTypes = {
  'keywordId': PropTypes.string,
  'onKeywordUpdate': PropTypes.func,
  'onKeywordDelete': PropTypes.func,
  'PassOnUpdate': PropTypes.func,
  'keywordDirectoryId': PropTypes.string
}


/*
 * REDUX
 */
const _MapDispatchToProps = __dispatch => ({
  'PassOnUpdate': u => __dispatch({ 'type': 'PASS_ON_UPDATE', 'PassOn': u })
})


/*
 * EXPORT
 */
export default connect(void 0, _MapDispatchToProps)(Index)
