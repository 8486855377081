/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation SenderIdDeleteMutation($senderIdId: ID!) {
  SenderIdDelete(senderIdId: $senderIdId) {
    id
    message
    status
  }
}
`
