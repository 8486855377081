/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import { IoIosSave } from 'react-icons/io' // Npm: React icons.
import { Button, Spinner } from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * OBJECTS
 */
const Index = ({ bg, iconColor, disabled, onSubmit, isLoading, defaultText, loadingText, icon, noIcon, ...props }) => (
  <Button
    disabled={disabled}
    variant='basic'
    type='submit'
    ml='auto'
    mr='0'
    mt='22px'
    display='flex'
    onClick={onSubmit}
    _hover={{ bg }}
    _active={{ bg }}
    bg={bg}
    borderRadius='12px'
    minW='150px'
    gap={icon ? '6px' : '0'}
    {...props}>
    {isLoading && loadingText ? loadingText : defaultText}{isLoading ? <Spinner color={iconColor} size='sm' ml='10px' /> : noIcon ? void 0 : icon ?? (<IoIosSave color={iconColor} size={16} style={{ 'marginLeft': '10px' }} />)}
  </Button>
)


/*
 * PROPTYPES
 */
Index.propTypes = {
  'defaultText': PropTypes.string,
  'disabled': PropTypes.bool,
  'isLoading': PropTypes.bool,
  'loadingText': PropTypes.string,
  'onSubmit': PropTypes.func,
  'icon': PropTypes.element,
  'noIcon': PropTypes.bool,
  'bg': PropTypes.string,
  'iconColor': PropTypes.string
}
Index.defaultProps = {
  'bg': 'brand.400',
  'loadingText': 'Please wait'
}


/*
 * EXPORT
 */
export default Index
