/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import { useHistory } from 'react-router-dom' // Npm: react-rater-dom for routing.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { HiArrowPathRoundedSquare, HiPencil, HiTrash } from 'react-icons/hi2' // Npm: React icons.
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * OBJECTS
 */
const Index = ({
  type,
  rateId,
  onRateUpdate,
  onRateDelete,
  PassOnUpdate
}) => {
  // Hook assignment.
  const _historyRef = useHistory()

  // Return Component.
  return (
    <Menu placement='left'>
      <MenuButton
        as={Button}
        margin='auto'
        borderRadius='8px'
        color='gray.500'
        bg='transparent'>
        <HiPencil size={18} />
      </MenuButton>
      <MenuList
        className='dropDownMenu'
        maxHeight='400px'
        overflow='auto'>
        <MenuItem
          gap='12px'
          display='flex'
          color='gray.500'
          onClick={() => {
            // Push rate id to url.
            PassOnUpdate({ rateId, type })

            // Execute handler.
            onRateUpdate()
          }}
          minH='40px'>
          <HiPencil size={18} />
          <span>Edit Rate</span>
        </MenuItem>
        <MenuItem
          gap='12px'
          display='flex'
          color='gray.500'
          onClick={() => {
            // Push rate id to url.
            PassOnUpdate({ rateId, type })

            // Execute handler.
            onRateDelete()
          }}
          minH='40px'>
          <HiTrash />
          <span>Delete Rate</span>
        </MenuItem>
        <MenuItem
          gap='12px'
          display='flex'
          color='gray.500'
          onClick={() => {
            // Push rate id to url.
            PassOnUpdate({ rateId, type })

            // Execute handler.
            _historyRef.push('/rate/plan')
          }}
          minH='40px'>
          <HiArrowPathRoundedSquare />
          <span>Manage Rate Plan</span>
        </MenuItem>
      </MenuList>
    </Menu>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'type': PropTypes.string,
  'rateId': PropTypes.string,
  'onRateUpdate': PropTypes.func,
  'onRateDelete': PropTypes.func,
  'PassOnUpdate': PropTypes.func
}


/*
 * REDUX
 */
const _MapDispatchToProps = __dispatch => ({
  'PassOnUpdate': u => __dispatch({ 'type': 'PASS_ON_UPDATE', 'PassOn': u })
})


/*
 * EXPORT
 */
export default connect(void 0, _MapDispatchToProps)(Index)
