/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import _ from 'underscore' // Npm: underscore.js library.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useMutation, useQuery } from '@apollo/client' // Npm: Apollo client.
import { IoIosSave } from 'react-icons/io' // Npm: React icons.
import {
  Button,
  Flex,
  Spinner
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import CustomerSelector from 'components/CustomerSelector'


/*
 * GRAPHS
 */
import BilateralCreateMutation from './__mutation__/index.bilateral.create.mutation'
import BilateralReadRelationshipQuery from './__query__/index.bilateral.readRelationship.query'


/*
 * OBJECTS
 */
const Index = ({ isOpen, onClose, passOn }) => {
  // Hook assignment.
  const [customer, setCustomer] = React.useState('')
  const [MutationBilateralCreate, MutationBilateralCreateResponse] = useMutation(BilateralCreateMutation)
  const _QueryBilateralReadRelationship = useQuery(BilateralReadRelationshipQuery, { 'variables': { 'vendorId': passOn?.vendorId }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })

  // Object assignment.
  const _BilateralCreate = async () => {
    // Only proceed if customer has data.
    if (!_.isEmpty(customer)) {
      // Check if customer contains ( and ).
      const _customer = customer?.includes('(') && customer?.includes(')') ? customer?.split('(')[1]?.split(')')[0] : customer

      // Bind Customer to given vendor.
      const _MutationBilateralCreate = await MutationBilateralCreate({
        'variables': {
          'vendorId': passOn?.vendorId,
          'customerId': _customer
        }
      })

      // If mutation caught an exception then report failure.
      if (_MutationBilateralCreate instanceof Error) {
        // Report failure.
        return _MutationBilateralCreate
      }

      // Report failure if both customer are empty.
      if (!_.isEmpty(_MutationBilateralCreate) && 'CREATE_SUCCESSFUL' === _MutationBilateralCreate?.data?.BilateralCreate?.status) onClose?.()

      // Play toast on completion.
      return toast(_MutationBilateralCreate?.data?.BilateralCreate?.message)
    }

    // Play toast on failure.
    return toast('Customer is required.')
  }

  // Refetch data on successful mutation.
  React.useEffect(() => {
    // Refetch data.
    _QueryBilateralReadRelationship.refetch()
  }, [isOpen, passOn])

  // Return component.
  return (
    <>
      <Flex w='100%' className='attachRouteAndCustomer' direction='column' gap='30px'>
        <CustomerSelector
          label='Assign Customer'
          value={_.isEmpty(customer) ? (_QueryBilateralReadRelationship?.data?.BilateralReadRelationship?.Customer?.displayName ? `${_QueryBilateralReadRelationship?.data?.BilateralReadRelationship?.Customer?.displayName} (${_QueryBilateralReadRelationship?.data?.BilateralReadRelationship?.Customer?.id})` : '') : customer}
          placeholder='e.g. "Awesome Customer"'
          onChange={e => setCustomer(e.target.value)}
          disabled={_QueryBilateralReadRelationship?.loading}
        />
      </Flex>
      <Flex justifyContent='flex-end'>
        <Button
          w='100%'
          height='40px'
          mt='22px'
          borderRadius='10px'
          bg='rgba(117, 81, 255, 1)'
          color='rgba(255, 255, 255, 1)'
          _hover={{ 'bg': 'rgba(117, 81, 255, 1)' }}
          disabled={_.isEmpty(customer)}
          onClick={_BilateralCreate}>
          Save Setting {MutationBilateralCreateResponse.loading ? <Spinner color='#c1a9f4' size='sm' ml='10px' /> : <IoIosSave size={16} style={{ 'marginLeft': '10px' }} />}
        </Button>
      </Flex>
    </>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'isOpen': PropTypes.bool,
  'passOn': PropTypes.object,
  'onClose': PropTypes.func
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
