/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query VendorAccountReadQuery($vendorAccountId: ID!) {
  VendorAccountRead(vendorAccountId: $vendorAccountId) {
    id,
    displayName,
    Rate {
      id,
      displayName,
    }
    Smpp {
        displayName,
        ip,
        username,
        password,
        txPort,
        rxPort,
        isActive,
        sessionTimeoutInterval,
        sessionAllowed,
        tps,
        isSticky,
        enquireLinkInterval,
        maxReconnectAttempts,
        reconnectBackoffFactor,
        initialReconnectDelay,
        sourceAddressTon,
        sourceAddressNpi,
        destinationAddressTon,
        destinationAddressNpi,
        trunkType,
        debuggingLevel
        message,
        status
    }
  }
}
`
