/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation CustomerAccountUpdateMutation(
    $customerAccountId: ID!,
    $blockBetweenFromHour: DateTime
    $blockBetweenToHour: DateTime
    $blockBetweenFromDate: DateTime
    $blockBetweenToDate: DateTime
    $blockInBetweenWeeks: [String]
) {
  CustomerAccountUpdate(
    customerAccountId: $customerAccountId,
    blockBetweenFromHour: $blockBetweenFromHour
    blockBetweenToHour: $blockBetweenToHour
    blockBetweenFromDate: $blockBetweenFromDate
    blockBetweenToDate: $blockBetweenToDate
    blockInBetweenWeeks: $blockInBetweenWeeks
  ) {
      id,
    message,
    status,
    Smpp {
      id
    }
  }
}
`
