/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * GRAPHS
 */
const Index = gql`
  query OperatorPrefixLocalHlrLookupQuery($destinationAddress: String!) {
    OperatorPrefixLocalHlrLookup(destinationAddress: $destinationAddress) {
      Mnc {
        id,
        mnc,
        network,
        Mcc {
          id,
          mcc,
          countryName
        }
      }
      message,
      status
    }
  }
`

/*
 * EXPORTS
 */
export default Index
