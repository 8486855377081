/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation CustomerAttachRateMutation($customerId: ID!, $rateId: ID!) {
  CustomerAttachRate(customerId: $customerId, rateId: $rateId) {
    message
    status
  }
}
`
