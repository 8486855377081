/*
 * EXPORTS
 */
export const rowStyle = {
  'fontFamily': 'Dm Sans',
  'fontWeight': 400,
  'fontSize': 'clamp(13px, 1.5vw, 15px)',
  'textAlign': 'center',
  'minWidth': '160px',
  'maxWidth': '360px',
  'overflowX': 'auto',
  'textTransform': 'unset'
}
export const headerStyle = {
  'fontFamily': 'DM Sans',
  'fontWeight': '500',
  'fontSize': 'clamp(13px, 1.5vw, 15px)',
  'height': '50px',
  'textAlign': 'center',
  'letterSpacing': 'unset',
  'color': '#8D97B4',
  'textTransform': 'unset'
}
