/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * GRAPHS
 */
const Index = gql`
  mutation NotifyCustomerMutation($customerId: ID!, $message: String!) {
    NotifyCustomer(customerId: $customerId, message: $message) {
      message,
      status
    }
  }
`

/*
 * EXPORTS
 */
export default Index
