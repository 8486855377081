/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
    query MccReadQuery($mccToSearch: String, $dialingCodeToSearch: String, $countryNameToSearch: String, $take: PositiveInt, $skip: Int) {
    MccRead(mccToSearch: $mccToSearch, dialingCodeToSearch: $dialingCodeToSearch, countryNameToSearch: $countryNameToSearch, take: $take, skip: $skip) {
        id,
        createdAt,
        updatedAt,
        dialingCode,
        countryName,
        mcc,
        minOperatorPrefixLength,
        maxOperatorPrefixLength,
        status,
        message,
        _totalCount
    }
  }
`
