/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query PhoneNumberoReadUniqueQuery($phoneNumberoId: ID!) {
  PhoneNumberoReadUnique(phoneNumberoId: $phoneNumberoId) {
    id,
    phoneNumbero,
    message,
    status
  }
}
`
