/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import Moment from 'moment' // Npm: Moment library.
import PropTypes from 'prop-types' // Npm: react.js library.
import Debounce from 'lodash/debounce' // Npm: lodash library.
import _ from 'underscore' // Npm: underscore library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { toast } from 'react-hot-toast' // Npm: React toastify.
import { useMutation, useQuery } from '@apollo/client' // Npm: Apollo client.
import { IoMdAdd } from 'react-icons/io' // Npm: React icons.
import {
  Button,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tooltip,
  Tr,
  useBreakpointValue,
  useDisclosure
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import DownloadToExcel from 'components/DownloadToExcel'
import QuickFileUpload from 'components/QuickFileUpload'
import PaginationWithPageInformation from 'components/PaginationWithPageInformation'
import Modal from 'components/Modal'
import TableSpinner from 'components/TableSpinner'
import KeywordDropDownOptions from 'components/KeywordDropDownOptions'
import KeywordUpsert from 'components/KeywordUpsert'
import KeywordDelete from 'components/KeywordDelete'
import { MemoizedInput } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import KeywordReadQuery from './__query__/index.keyword.read.query'
import KeywordUploadMutation from './__mutation__/index.keyword.upload.mutation'


/*
 * STYLES
 */
import {
  buttonStyle,
  cellStyle,
  headerStyle,
  rowStyle
} from './index.style'


/*
 * OBJECTS
 */
const Index = ({ passOn }) => {
  // Const assignment.
  const _skipDifference = 8
  const _successFlags = Object.React.App.enums.GRAPHQL_SUCCESSFUL_QUERY_FLAGS.enums.map(i => i.key)

  // Hook assignment.
  const [skipPage, setSkipPage] = React.useState(0)
  const [skipDifference, setSkipDifference] = React.useState(_skipDifference)
  const [keywordToSearch, setKeywordToSearch] = React.useState('')
  const [MutationKeywordUpload] = useMutation(KeywordUploadMutation)
  const { 'isOpen': isKeywordCreateOpen, 'onOpen': onKeywordCreateOpen, 'onClose': onKeywordCreateClose } = useDisclosure()
  const { 'isOpen': isKeywordUpdateOpen, 'onOpen': onKeywordUpdateOpen, 'onClose': onKeywordUpdateClose } = useDisclosure()
  const { 'isOpen': isKeywordDeleteOpen, 'onOpen': onKeywordDeleteOpen, 'onClose': onKeywordDeleteClose } = useDisclosure()
  const _QueryKeywordRead = useQuery(KeywordReadQuery, { 'variables': { 'keywordDirectoryId': passOn?.keywordDirectoryId, keywordToSearch, 'skip': skipPage * skipDifference, 'take': skipDifference }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })
  const _isFirstLoadCompleted = React.useRef(false)
  const _onKeywordToSearchInputChange = React.useCallback(Debounce(e => setKeywordToSearch(e.target.value), 800), [])
  const _tableHeaderHeightRef = React.useRef(0)
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })

  // Data Assignment.
  if (!_QueryKeywordRead.loading && 0 < _QueryKeywordRead.data?.KeywordRead?.length && !_isFirstLoadCompleted.current) _isFirstLoadCompleted.current = true
  if (!_.every(_.pluck(_QueryKeywordRead.data?.KeywordRead, 'status'), i => _successFlags.includes(i))) _isFirstLoadCompleted.current = false

  // Return component.
  return (
    <>
      <Flex className='keyword base'>
        <Flex
          display='flex'
          flex={1}
          flexDirection='column'
          gap={_isCurrentViewMobile ? '12px' : '22px'}
          bg='white'
          height='100%'
          borderRadius='20px'
          p={_isCurrentViewMobile ? '12px' : '22px'}>
          <Flex pb='0px' justify='space-between' gap='12px' flexWrap={{ 'base': 'wrap', 'md': 'nowrap' }}>
            <Tooltip label='Create New Keyword' fontSize='sm'>
              <Button
                w='170px'
                leftIcon={<IoMdAdd />}
                onClick={onKeywordCreateOpen}
                style={buttonStyle}>
                Create Keyword
              </Button>
            </Tooltip>
            <Flex gap='12px'>
              <QuickFileUpload
                onChange={j => {
                  // Const assignment.
                  const _keywords = Array.from({ 'length': j?.rows?.length }, (__, i) => _.first(j?.rows[i]))

                  // Execute mutation.
                  return MutationKeywordUpload({ 'variables': { 'keywordDirectoryId': passOn?.keywordDirectoryId, 'keywords': _keywords } }).then(jp => {
                    // On Successful response from the mutation.
                    if ('UPSERT_SUCCESSFUL' === jp?.data?.KeywordUpload?.status) {
                      // Toast success.
                      toast('Successfully uploaded the keywords in your keyword directory.')
                    } else {
                      // Toast failure.
                      toast(jp?.data?.KeywordUpload?.message ?? 'Failed to upload the keywords in your keyword directory.')
                    }
                  })
                }}
                isLoading={false}
              />
              <DownloadToExcel
                cellsData={0 === _QueryKeywordRead?.data?.KeywordRead?.length || !_.every(_.pluck(_QueryKeywordRead?.data?.KeywordRead, 'status'), i => _successFlags.includes(i)) ? [] : _QueryKeywordRead?.data?.KeywordRead.map((item, __index) => ({
                  'S.No.': `${(__index + (skipDifference * (skipPage))) + 1}.`,
                  'CreatedAt': Moment(item?.createdAt).format('YYYY-MM-DD'),
                  'UpdatedAt': Moment(item?.updatedAt).format('YYYY-MM-DD'),
                  'Keyword': item.keyword ?? '-'
                }))}
                headersData={['S.No.', 'CreatedAt', 'UpdatedAt', 'Keyword'].map(i => ({ 'key': i, 'label': i }))} />
            </Flex>
          </Flex>
          <TableContainer
            flex={1}
            display='flex'
            borderRadius='15px'
            outline='1px solid #C5CFE8'>
            <Table variant='simple' size='sm'>
              <Thead ref={_tableHeaderHeightRef}>
                <Tr style={{ ...headerStyle, 'position': 'sticky', 'top': 0, 'zIndex': 1 }}>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>S.No</Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>Keyword</Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>Created At</Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>Updated At</Td>
                  <Td
                    style={rowStyle}>Actions</Td>
                </Tr>
                <Tr key={String.random(5)} >
                  <Td borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>
                    <MemoizedInput
                      placeholder='Search'
                      className='filter searchInput'
                      defaultValue={keywordToSearch}
                      onChange={_onKeywordToSearchInputChange}
                    />
                  </Td>
                  <Td borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td></Td>
                </Tr>
              </Thead>
              {_QueryKeywordRead.loading && !_isFirstLoadCompleted.current ? (
                <TableSpinner isLoading={true} chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight} />
              ) : !_isFirstLoadCompleted.current && (0 === _QueryKeywordRead.data?.KeywordRead?.length || !_.every(_.pluck(_QueryKeywordRead.data?.KeywordRead, 'status'), i => _successFlags.includes(i))) ? (
                <TableSpinner isLoading={false} isEmpty={true} chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight} />
              ) : (
                <Tbody style={cellStyle}>
                  {_QueryKeywordRead.data?.KeywordRead?.map(({ id, createdAt, updatedAt, keyword }, __index) => (
                    <Tr key={String.random(5)}>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {`${(__index + (skipDifference * (skipPage))) + 1}.`}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {keyword ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {Moment(createdAt).format('YYYY-MM-DD')}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {Moment(updatedAt).format('YYYY-MM-DD')}
                      </Td>
                      <Td
                        style={rowStyle}>
                        {' '}
                        {
                          <KeywordDropDownOptions
                            keywordId={id}
                            keywordDirectoryId={passOn?.keywordDirectoryId}
                            onKeywordUpdate={onKeywordUpdateOpen}
                            onKeywordDelete={onKeywordDeleteOpen}
                          />
                        }
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              )
              }
            </Table>
          </TableContainer>
        </Flex>
        <Modal
          size='md'
          title='Keyword Create'
          isOpen={isKeywordCreateOpen}
          onClose={onKeywordCreateClose}>
          <KeywordUpsert isCreateOnly={true} />
        </Modal>
        <Modal
          size='md'
          title='Keyword Update'
          isOpen={isKeywordUpdateOpen}
          onClose={onKeywordUpdateClose}>
          <KeywordUpsert />
        </Modal>
        <Modal
          size='md'
          title='Keyword Delete'
          isOpen={isKeywordDeleteOpen}
          onClose={onKeywordDeleteClose}>
          <KeywordDelete />
        </Modal>
      </Flex>
      <PaginationWithPageInformation
        skipPage={skipPage}
        skipDifference={_skipDifference}
        setSkipDifference={setSkipDifference}
        itemsPerPage={_skipDifference}
        totalCount={_.pluck(_QueryKeywordRead?.data?.KeywordRead, '_totalCount')?.[0]}
        onPageChange={setSkipPage}
      />
    </>
  )
}



/*
 * PROPTYPES
 */
Index.propTypes = {
  'passOn': PropTypes.object
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)

