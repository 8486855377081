/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation IpUpsertMutation($ipDirectoryId: ID, $ipId: ID, $ip: String) {
  IpUpsert(ipDirectoryId: $ipDirectoryId, ipId: $ipId, ip: $ip) {
    id
    message
    status
  }
}
`
